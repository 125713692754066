import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faCheck,
  faCheckCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Moment from "moment";
import { Datepicker, setOptions } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { connect } from "react-redux";

//TO DO: fix this
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

setOptions({
  theme: "ios",
  themeVariant: "light",
});

function AvailabilityCard(props) {
  const company = props.data.company;


  // console.log(props);

  const [update, setUpdate]     = useState(false);
  const [time, setTime]         = useState(props.props.time);
  const [selected, setSelected] = useState(props.props.selected);

  const [addTimePopUp, setAddTimePopUp] = useState(false);

  const timeFromRef = useRef(null);
  const timeToRef   = useRef(null);

  const forceUpdate = useForceUpdate();

  const convertTime = (t) => {
    return Moment(t).format("LT");
  };

  function checkTime(dFromX, dToX) {
    //This function should check (at least) 2 things. 1) time to should not be less then time from. 2) not to overlap with other (already set) time durations
    //Time must end at 11:59pm???

    if (dFromX >= dToX) {
      return false;
    } else {
      return true;
    }
  }

  function removeTime(from, to) {
    const timeObj = time;
    timeObj.splice(
      timeObj.findIndex((el) => el.from === from && el.to === to),
      1
    );

    setTime(timeObj);
    forceUpdate();
  }

  const daysOfWeek = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  };

  function addTime(dayOfWeek) {
    if (!timeFromRef.current.value || !timeToRef.current.value) {
      alert("You need to fill all inputs");
      return false;
    }

    let dFrom =
      Moment().isoWeekday(daysOfWeek[dayOfWeek]).format("MM/DD/YYYY") +
      ", " +
      timeFromRef.current._valueText;
    let dFromX = Moment(dFrom).format("x") * 1;

    let dTo =
      Moment().isoWeekday(daysOfWeek[dayOfWeek]).format("MM/DD/YYYY") +
      ", " +
      timeToRef.current._valueText;
    let dToX = Moment(dTo).format("x") * 1;

    let correctTime = checkTime(dFromX, dToX);

    if (!correctTime) {
      alert("Wrong time range!");
      return false;
    }

    const obj = {
      from: dFromX,
      to: dToX,
    };

    //check if 'to' is set to 59mins and add one minute to it
    //because mentor can not set his availability to midnight (last option is 23:59)

    let toInMinutes = Moment(dToX).format("mm") * 1;

    if (toInMinutes === 59) {
      obj.to += 1 * 60 * 1000 - 1;
    }

    // console.log(toInMinutes);

    const timeObj = time;

    timeObj.push(obj);
    setTime(timeObj);
    forceUpdate();

    setAddTimePopUp(false);
  }

  const finishTime = () => {
    setUpdate(false);
    props.parentEvent(props.props.day, time, selected);
  };

  return (
    <>
      {addTimePopUp && (
        <div className="grayPopupWrapperBg01">
          <div className="availabilityPopUp">
            <div className="mTop200 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
              <div className="w90 mXAuto">
                <div className="verticallyAlignMid mY30 NewNotePoUpSub1Wrapper">
                  <div className="w30">
                    <div className="mY10 w100">
                      <p>Select Time From</p>
                    </div>
                    <div className="mY10 w50 mXAuto">
                      <Datepicker
                        controls={["time"]}
                        name={"from"}
                        className={
                          "mRight10 pY6 outlineNone borderNone borderRadius12 colLabelGray pLeft4"
                        }
                        ref={timeFromRef}
                      />
                    </div>
                  </div>
                  <div className="w30">
                    <div className="mY10 w100">
                      <p>Select Time To</p>
                    </div>
                    <div className="mY10 w50 mXAuto">
                      <Datepicker
                        controls={["time"]}
                        name={"to"}
                        className={
                          "mRight10 pY6 outlineNone borderNone borderRadius12 colLabelGray pLeft4"
                        }
                        ref={timeToRef}
                      />
                    </div>
                  </div>
                </div>
                <div className="verticallyAlignMid mY30 NewNotePoUpSub1Wrapper">
                  <div className="w40 mRight20">
                    <button
                      onClick={() => setAddTimePopUp(false)}
                      className={`w60 pY10 Pregular h100 borderNone borderRadius12`}
                      style={{
                        color: `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
                        background: `${company?.whiteLabel?.color?.buttons?.secondary}`,
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="w40 mLeft20">
                    <button
                      onClick={() => addTime(props.props.day)}
                      className={`w60 pY10 Pregular h100 borderNone borderRadius12`}
                      style={{
                        color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                        backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="OnboardingMentor8SetAvailabilitySubb1 verticallyAlignMidJustConStart mLeft40 pY30">
        <div className="verticallyAlignMidJustConStart mRight40">
          <div className="onOffSwitchInputOnFilltersMain posRel verticallyAlignMidJustConStart colPlaceHolderGray cursorPointer">
            <input
              defaultChecked={!selected}
              onChange={(e) => {
                setSelected(!e.target.checked);
                props.parentEvent(props.props.day, time, !e.target.checked);
              }}
              className="onOffSwitchInputOnFillters"
              type="checkbox"
            />
            <span className="slider round"></span>
            <h5 className="P1bold textAlignLeft mLeft8 colLinksAndTitlesBlack">
              {props.props.day}
            </h5>
          </div>
        </div>

        {!update ? (
          <div className="verticallyAlignMidJustConStart w100">
            <div className="mY8">
              {time.map((el, i) => {
                return (
                  <p key={i} className="Pmedium sessionDurationP">
                    {convertTime(el.from)} - {convertTime(el.to)}
                  </p>
                );
              })}
            </div>
            {props.props.selected && (
              <div className="mLeftAuto mRight40 pencilIcon">
                <FontAwesomeIcon
                  onClick={() => setUpdate(true)}
                  icon={faPen}
                  size="1x"
                  className="cursorPointer"
                />
              </div>
            )}
          </div>
        ) : (
          <div className="verticallyAlignMidJustConStart w100">
            <div className="mY8">
              {time.map((el, i) => {
                return (
                  <span
                    key={i}
                    className="verticallyAlignMidJustConStart mRight40"
                  >
                    <p className="Pmedium">
                      {convertTime(el.from)} - {convertTime(el.to)}
                    </p>
                    <FontAwesomeIcon
                      color="red"
                      onClick={() => removeTime(el.from, el.to)}
                      icon={faTimes}
                      size="1x"
                      className="mLeft10 cursorPointer"
                    />
                  </span>
                );
              })}
            </div>
            <div className="mY8 mLeftAuto verticallyAlignMidJustConStart mRight20">
              <div
                onClick={() => setAddTimePopUp(true)}
                icon={faCheck}
                size="1x"
                style={{
                  color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                  backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                }}
                className="mLeft10 OnboardingMentor8SetAvailabilitySubb1FormButton cursorPointer Small-Labelnormal pY4 pX4 borderRadius12 mRight20"
              >
                {" "}
                Add New{" "}
              </div>
              <FontAwesomeIcon
                className="colPrimaryGreen cursorPointer checkForRes1"
                onClick={finishTime}
                icon={faCheckCircle}
                size="2x"
              />
              <FontAwesomeIcon
                className="colPrimaryGreen cursorPointer mRightAuto disFlex checkForRes"
                onClick={finishTime}
                icon={faCheckCircle}
                size="2x"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function getData(state) {
  return {
    data: state.auth.authData,
  };
}

export default connect(getData)(AvailabilityCard);
