// za Pedju - on click dobijaju borderRadiusForSocialMediaButtons, backColWhite, boxShadowForSearchLanding i gube colLabelGray
const UpcomingPastCancelledNav = ({ type, parentEvent }) => {
  // console.log('---------------------------------')

  return (
    <div className="verticallyAlignMidJustConStart backColElementBackgroundGray upcomingPastCancelledNavMain borderRadiusForSocialMediaButtons  w400 mBottom40">
      {(() => {
        if (type === "Upcoming" || type === "Scheduled" || !type) {
          return (
            <>
              <div
                onClick={() => parentEvent("Upcoming")}
                className="borderRadiusForSocialMediaButtons backColWhite boxShadowForSearchLanding mY4 mLeft10 mRight10 cursorPointer"
              >
                <p className="pX20 pY10 Pbold">Upcoming</p>
              </div>
              <div
                onClick={() => parentEvent("Past")}
                className="cursorPointer mY4 colLabelGray mRight10"
              >
                <p className="pX30 pY10 Pbold ">Past</p>
              </div>
              <div
                onClick={() => parentEvent("Cancelled")}
                className="mY4 mRight10 colLabelGray cursorPointer"
              >
                <p className="pX20 pY10 Pbold">Cancelled</p>
              </div>
            </>
          );
        }

        if (type === "Past" || type === "Completed") {
          return (
            <>
              <div
                onClick={() => parentEvent("Upcoming")}
                className="cursorPointer mY4 colLabelGray mRight10"
              >
                <p className="pX20 pY10 Pbold">Upcoming</p>
              </div>
              <div
                onClick={() => parentEvent("Past")}
                className="borderRadiusForSocialMediaButtons backColWhite boxShadowForSearchLanding mY4 mLeft10 mRight10 cursorPointer"
              >
                <p className="pX30 pY10 Pbold c">Past</p>
              </div>
              <div
                onClick={() => parentEvent("Cancelled")}
                className="mY4 mRight10 colLabelGray cursorPointer"
              >
                <p className="pX20 pY10 Pbold">Cancelled</p>
              </div>
            </>
          );
        }

        if (type === "Cancelled") {
          return (
            <>
              <div
                onClick={() => parentEvent("Upcoming")}
                className="cursorPointer mY4 colLabelGray mRight10"
              >
                <p className="pX20 pY10 Pbold">Upcoming</p>
              </div>
              <div
                onClick={() => parentEvent("Past")}
                className="cursorPointer mY4 colLabelGray mRight10"
              >
                <p className="pX30 pY10 Pbold c">Past</p>
              </div>
              <div
                onClick={() => parentEvent("Cancelled")}
                className="borderRadiusForSocialMediaButtons backColWhite boxShadowForSearchLanding mY4 mLeft10 mRight10 cursorPointer"
              >
                <p className="pX20 pY10 Pbold">Cancelled</p>
              </div>
            </>
          );
        }
      })()}
    </div>
  );
};

export default UpcomingPastCancelledNav;
