import { Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

function RestrictedLoggedInRoute(props) {
	//console.log('RestrictedLoggedInRoute', props.userData.user?.token);
	return props.userData.user?.token ? <Outlet /> : <Navigate to="/LogIn" />;
};

function getUser(state) {
	return {
		userData: {
			user : state.auth.authData
		}
	}
}

export default connect(getUser)(RestrictedLoggedInRoute);