import {
    BsCameraVideo,
    BsTelephone,
    BsChatText,
    BsAlarm,
} from "react-icons/bs";
import { RiStarFill, RiArrowRightSLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    getSession,
    getAllAdditionalCategories,
    getReviews,
} from "../../api/index.js";
import Moment, { duration } from "moment";
import ReschedulePopUp from "../ReschedulePopUp/ReschedulePopUp.js";
import SuccessPopUpMentorFlow from "../SuccessPopUpMentorFlow/SuccessPopUpMentorFlow.js";
import YourSessionHasEndedPopUp from "../YourSessionHasEndedPopUp/YourSessionHasEndedPopUp.js";
import ThankYouAfterSessionPopUp from "../ThankYouAfterSessionPopUp/ThankYouAfterSessionPopUp.js";
import { connect } from "react-redux";
import BigScheduleCalendar from "../../components/BigScheduleCalendar/BigScheduleCalendar.js";
import { RiArrowLeftSLine } from "react-icons/ri";

function OnboardingMentor11ClientSingleView(props) {
    const company = props.data.company;

    const location = useLocation();
    const navigate = useNavigate();

    if (!company._id || !props.data.data.user._id || !location.state.id) {
        navigate("/LogIn");
    }

    const initialSession = {
        mentorID: "",
        clientID: "",
        userID: "",
        serviceID: "",
    };

    const [session, setSession] = useState(initialSession);
    const [message, setMessage] = useState("");

    const [service, setService] = useState({});
    const [client, setClient] = useState({});
    const [mentor, setMentor] = useState({});
    const [event, setEvent] = useState(false);
    const [categories, setCategories] = useState([]);
    const [review, setReview] = useState(false);

    const [sessionEnd, setSessionEnd] = useState(false);
    const [reviewDone, setReviewDone] = useState(false);
    const [rescheduleView, setRescheduleView] = useState(false);
    const [thisMoment, setThisMoment] = useState(Moment().unix());
    const [sessionMoment, setSessionMoment] = useState(false);
    const [duration, setDuration] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        async function getResults() {
            let queryString = `?populate=mentorID,clientID,serviceID&_id=${location.state.id}`;
            const { data } = await getSession(queryString);

            let queryStringAddCat = `?companyID=${props.data.company.id}`;
            const catData = await getAllAdditionalCategories(queryStringAddCat);

            let queryStringReview = `?clientID=${data.data.sessions[0].clientID._id}&mentorID=${data.data.sessions[0].mentorID._id}&serviceID=${data.data.sessions[0].serviceID._id}`;

            const reviewData = await getReviews(queryStringReview);

            setSessionMoment(Moment(data.data.sessions[0].date).unix());
            setDuration(data.data.sessions[0].serviceID.duration * 60);

            setSession(data.data.sessions[0]);
            setMessage(data.data.message);

            setService(data.data.sessions[0].serviceID);
            setClient(data.data.sessions[0].clientID);
            setMentor(data.data.sessions[0].mentorID);

            setCategories(catData.data.data.additionalCategories);

            if (
                reviewData.data.data.reviews[0] &&
                Object.keys(reviewData.data.data.reviews[0]).length > 0
            ) {
                setReview(reviewData.data.data.reviews[0]);
            }
        }
        getResults();
    }, [location.state.id, event, rescheduleView, props.data.company.id]);

    function dateFormat(date) {
        // Wednsday, Nov 3 11:00-11:30am

        if (!service.duration) {
            return "";
        }

        let localDate = Moment(date).format("LLLL");
        let firstPart = Moment(localDate).format("dddd, MMM DD LT");
        let secondPart = Moment(date)
            .add(service.duration, "minutes")
            .format("LT");

        return firstPart + " - " + secondPart;
    }

    function goToMessages() {
        let userID = 0;

        if (props.data.data.user.role === "mentor") {
            userID = session.clientID._id;
        } else {
            userID = session.mentorID._id;
        }

        navigate("/Messages", {
            state: {
                userID,
            },
        });
    }

    function goLive() {
        setThisMoment(Moment().unix());
        if (
            Moment().unix() >= sessionMoment &&
            Moment().unix() <= sessionMoment + duration
        ) {
            navigate("/InSession", {
                state: {
                    sessionID: location.state.id,
                },
            });
        } else {
            props.statusPopUp("sessionUnavailable");
            setTimeout(() => {
                props.statusPopUp(false);
            }, "1200");
        }
    }

    function goToNotes() {
        navigate("/ClientNotes");
    }

    var gapi            = window.gapi;
    //var CLIENT_ID       = "567198460390-eqfp1i18tf1lbphu7h50a9c7h547s1rs.apps.googleusercontent.com";
    //var API_KEY         = "AIzaSyB9CZ-gLK9Lz7muomsBXySPpFhdjauxj8k";

    // var API_KEY         = "AIzaSyCH8lH5zJMamXwQxkVxZbFhBw1tTuyvI7o";

    // var CLIENT_ID       = "241251241244-6cmsret0k7g8aovg1unrauu1gvk4dfhc.apps.googleusercontent.com";
    // var API_KEY         = "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";

    var CLIENT_ID       = "241251241244-4sckjn6mdd2n969nscj4e0ahhqurg38r.apps.googleusercontent.com";
    var API_KEY         = "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";

    var DISCOVERY_DOCS  = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",];
    var SCOPES          = "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events";
    // var SCOPES          = "https://www.googleapis.com/auth/calendar.events"

    function addToCalendar() {

        let startDate   = Moment(session.date).format();
        let endDate     = Moment(session.date)
            .add(session.serviceID.duration, "minutes")
            .format();

        let email =
            props.data.data.user.email === session.mentorID.email
                ? session.clientID.email
                : session.mentorID.email;

        gapi.load("client:auth2", () => {
            // console.log("loaded client");

            gapi.client.init({
                apiKey          : API_KEY,
                clientId        : CLIENT_ID,
                discoveryDocs   : DISCOVERY_DOCS,
                scope           : SCOPES,
            });

            gapi.client.load("calendar", "v3");

            gapi.auth2
                .getAuthInstance()
                .signIn()
                .then(() => {
                    var event = {
                        summary: "Scheduled Session",
                        location: "MentorX",
                        description: `Service name: ${session.serviceID.name}, Duration : ${session.serviceID.duration}min`,
                        start: {
                            dateTime: startDate,
                        },
                        end: {
                            dateTime: endDate,
                        },
                        // 'recurrence': [
                        //   'RRULE:FREQ=DAILY;COUNT=2'
                        // ],
                        attendees: [{ email: email }],
                        reminders: {
                            useDefault: false,
                            overrides: [
                                { method: "email", minutes: 24 * 60 },
                                { method: "popup", minutes: 10 },
                            ],
                        },
                    };

                    var request = gapi.client.calendar.events.insert({
                        calendarId  : "primary",
                        resource    : event,
                    });

                    request.execute((event) => {
                        // console.log(event);
                        window.open(event.htmlLink);
                    });
                });
        });

        // var event;

        // if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
        //     event = {
        //         'summary': 'Scheduled Session',
        //         'location': 'MentorX',
        //         'description': `Service name: ${session.serviceID.name}, Duration : ${session.serviceID.duration}min`,
        //         'start': {
        //             'dateTime': startDate
        //         },
        //         'end': {
        //             'dateTime': endDate
        //         },
        //         // 'recurrence': [
        //         //   'RRULE:FREQ=DAILY;COUNT=2'
        //         // ],
        //         'attendees': [
        //             {'email': email},
        //         ],
        //         'reminders': {
        //             'useDefault': false,
        //             'overrides': [
        //             {'method': 'email', 'minutes': 24 * 60},
        //             {'method': 'popup', 'minutes': 10}
        //             ]
        //         }
        //     }
        // } else {

        //     gapi.auth2.getAuthInstance().signIn()
        //     .then(() => {

        //         event = {
        //             'summary': 'Scheduled Session',
        //             'location': 'MentorX',
        //             'description': `Service name: ${session.serviceID.name}, Duration : ${session.serviceID.duration}min`,
        //             'start': {
        //             'dateTime': startDate
        //             },
        //             'end': {
        //             'dateTime': endDate
        //             },
        //             // 'recurrence': [
        //             //   'RRULE:FREQ=DAILY;COUNT=2'
        //             // ],
        //             'attendees': [
        //             {'email': email},
        //             ],
        //             'reminders': {
        //             'useDefault': false,
        //             'overrides': [
        //                 {'method': 'email', 'minutes': 24 * 60},
        //                 {'method': 'popup', 'minutes': 10}
        //             ]
        //             }
        //         }
        //     })
        // }

        // var request = gapi.client.calendar.events.insert({
        // 'calendarId': 'primary',
        // 'resource': event,
        // })

        // request.execute(event => {
        // console.log(event)
        // window.open(event.htmlLink)
        // })
        // })
    }

    function userReschedule() {
        if (props.data.data.user.role === "mentor") {
            setEvent("Reschedule");
        } else {
            setRescheduleView(true);
        }
    }

    function getInitials(name) {
        let words = name.split(" ");
        return (
            words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
        );
    }

    return (
        <div className="OnboardingMentor11ClientSingleView">
            {sessionEnd && (
                <div className="filterMentorPopupWrapper">
                    <YourSessionHasEndedPopUp
                        mentor={session.mentorID}
                        service={session.serviceID}
                        user={props.data.data.user}
                        parentEventSession={setSessionEnd}
                        parentEventReview={setReviewDone}
                    />
                </div>
            )}

            {reviewDone && (
                <div className="filterMentorPopupWrapper">
                    <ThankYouAfterSessionPopUp user={props.data.data.user} />
                </div>
            )}

            <div className="mTop50 mBottom70 w70 mXAuto onboardingMentor11ClientSingleViewSubb1">
                <div className="pRight10 mBottom20">
                    <button
                        onClick={() => navigate(-1)}
                        className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding"
                    >
                        <RiArrowLeftSLine
                            size="2em"
                            className="colPrimaryGreen"
                        />
                    </button>
                </div>
                <div className="verticallyAlignMidStretchStart onboardingMentor11ClientSingleViewSubb4">
                    <div className="mRightAuto w600 onboardingMentor11ClientSingleViewSubb2">
                        <div className="verticallyAlignMidJustConStart mBottom10 onboardingMentor11ClientSingleViewSubb2HeadMain">
                            <h5 className="P1bold textAlignLeft">
                                {dateFormat(session.date)}
                            </h5>

                            {(() => {
                                if (session?.status === "completed") {
                                    return (
                                        <div className="borderRadiusForSocialMediaButtons backColSuccessGreen mLeftAuto">
                                            <p className="Small-Label-11 colOffWhite pX20">
                                                Completed
                                            </p>
                                        </div>
                                    );
                                } else if (session?.status === "cancelled") {
                                    return (
                                        <div className="borderRadiusForSocialMediaButtons backColErrorRed mLeftAuto">
                                            <p className="Small-Label-11 colOffWhite pX20">
                                                Cancelled
                                            </p>
                                        </div>
                                    );
                                }
                            })()}
                        </div>
                        <h3 className="H2medium textAlignLeft mBottom20">
                            {service.name ?? ""}
                        </h3>
                        <div className="verticallyAlignMidJustConStart mBottom20">
                            <div className="verticallyAlignMid borderRadius50 mRight12 onboardingMentor11ClientSubImage">
                                {/* <img className="reviewsCardImg2" alt="" src="https://i.ibb.co/fD1yxzd/img.png"/> */}
                                {props.data.data.user.role === "mentor" ? (
                                    <div className="w100 h100 verticallyAlignMid">
                                        {client?.photo ? (
                                            <img
                                                alt=""
                                                className="w100 h100"
                                                src={client.photo}
                                            />
                                        ) : (
                                            <div className="userInitialsMedium">
                                                <p>
                                                    {client.fullName &&
                                                        getInitials(
                                                            client.fullName
                                                        )}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="w100 h100 verticallyAlignMid">
                                        {mentor?.photo ? (
                                            <img
                                                alt=""
                                                className="w100 h100"
                                                src={mentor.photo}
                                            />
                                        ) : (
                                            <div className="userInitialsMedium">
                                                <p>
                                                    {mentor.fullName &&
                                                        getInitials(
                                                            mentor.fullName
                                                        )}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <h5 className="P1medium textAlignLeft">
                                {props.data.data.user.role === "mentor"
                                    ? client.fullName
                                    : mentor.fullName}
                            </h5>
                        </div>

                        {props.data.data.user.role === "client" ? (
                            <>
                                <h4 className="H3medium textAlignLeft">Bio</h4>
                                <p className="Pregular textAlignLeft mBottom50">
                                    {mentor.biography}
                                </p>
                            </>
                        ) : (
                            <>
                                <div className="verticallyAlignMidStretchStart w100">
                                    <div className="mRight50">
                                        <h5 className="P1medium textAlignLeft mBottom10">
                                            Interests
                                        </h5>
                                        <div className="verticallyAlignMidJustConStart flexWrap">
                                            {client.selectCategories &&
                                                client.selectCategories.additionalInterests.map(
                                                    (el, i) => {
                                                        let addCat =
                                                            categories.filter(
                                                                (e) =>
                                                                    e._id === el
                                                            )[0];

                                                        if (!addCat) {
                                                            return <></>;
                                                        }

                                                        return (
                                                            <div
                                                                key={i}
                                                                className="borderRadiusForSocialMediaButtons backColCardsGray mRight10 mBottom8"
                                                            >
                                                                <p className="colPrimaryGreen Small-Labelnormal pX20 pY6 textAlignCenter">
                                                                    {
                                                                        addCat.name
                                                                    }
                                                                </p>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="verticallyAlignMidStretchStart w100 mTop20">
                                    <div>
                                        <h5 className="P1medium textAlignLeft mBottom10">
                                            Comunication
                                        </h5>
                                        <div className="verticallyAlignMidJustConStart">
                                            {client.selectCategories &&
                                                client.selectCategories.communicationMethods.indexOf(
                                                    "video"
                                                ) > -1 && (
                                                    <BsCameraVideo
                                                        size="2em"
                                                        className="mRight20"
                                                    />
                                                )}
                                            {client.selectCategories &&
                                                client.selectCategories.communicationMethods.indexOf(
                                                    "audio"
                                                ) > -1 && (
                                                    <BsTelephone
                                                        size="2em"
                                                        className="mRight20"
                                                    />
                                                )}
                                            {client.selectCategories &&
                                                client.selectCategories.communicationMethods.indexOf(
                                                    "chat"
                                                ) > -1 && (
                                                    <BsChatText
                                                        size="2em"
                                                        className="mRight20"
                                                    />
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="borderGrayTopInput mY30"></div>
                    </div>
                    <div className="w540 onboardingMentor11ClientSingleViewSubb3 posRel">
                        <div className="backColElementBackgroundGray borderRadius24 onboardingMentor11ClientSingleViewSubb3Subb1">
                            <div className="w80 mXAuto mY50 onboardingMentor11ClientSingleViewSubb5">
                                <h4 className="H3medium textAlignLeft mBottom20">
                                    What to bring?
                                </h4>
                                <div className="mBottom50 onboardingMentor11ClientSingleViewSubb6">
                                    <h5 className="P1medium textAlignLeft">
                                        {service?.requirements}
                                    </h5>
                                </div>
                                <h4 className="H3medium textAlignLeft mBottom20">
                                    Message from client
                                </h4>
                                <div className="mBottom50 onboardingMentor11ClientSingleViewSubb6">
                                    <h5 className="P1medium textAlignLeft">
                                        {message}
                                    </h5>
                                </div>
                                <div className="verticallyAlignMidJustConStart onboardingMentor11ClientSingleViewSubb7 mBottom30">
                                    <div className="verticallyAlignMidJustConStart mRight50">
                                        {(() => {
                                            if (
                                                session?.method &&
                                                session.method.indexOf(
                                                    "video"
                                                ) >= 0
                                            ) {
                                                return (
                                                    <>
                                                        <BsCameraVideo
                                                            size="2.4em"
                                                            className="mRight20 onboardingMentor11ClientSingleViewIcons"
                                                        />
                                                        <h5 className="P1medium">
                                                            Video Call
                                                        </h5>
                                                    </>
                                                );
                                            } else if (
                                                session?.method &&
                                                session.method.indexOf(
                                                    "audio"
                                                ) >= 0
                                            ) {
                                                return (
                                                    <>
                                                        <BsTelephone
                                                            size="2.4em"
                                                            className="mRight20 onboardingMentor11ClientSingleViewIcons"
                                                        />
                                                        <h5 className="P1medium">
                                                            Audio Call
                                                        </h5>
                                                    </>
                                                );
                                            } else {
                                                return (
                                                    <>
                                                        <BsChatText
                                                            size="2.4em"
                                                            className="mRight20 onboardingMentor11ClientSingleViewIcons"
                                                        />
                                                        <h5 className="P1medium">
                                                            Chat
                                                        </h5>
                                                    </>
                                                );
                                            }
                                        })()}
                                    </div>
                                    <div className="verticallyAlignMidJustConStart">
                                        <BsAlarm
                                            size="2.4em"
                                            className="mRight20 onboardingMentor11ClientSingleViewIcons"
                                        />
                                        <h5 className="P1medium">
                                            {service.duration ?? 0} Minutes
                                        </h5>
                                    </div>
                                </div>
                                <div className="verticallyAlignMid">
                                    {(() => {
                                        if (session?.status === "scheduled") {
                                            return (
                                                <div className="verticallyAlignMidSpaceBetween w100 onboardingMentor11ClientSingleViewSubb8">
                                                    <button
                                                        onClick={goLive}
                                                        style={{
                                                            color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                                            backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                                        }}
                                                        className={`w180 borderRadius12 borderNone mRight10 P1regular colOffWhite pY10 opacity-25 ${
                                                            thisMoment >=
                                                                sessionMoment &&
                                                            thisMoment <=
                                                                sessionMoment +
                                                                    duration &&
                                                            "opacity-100"
                                                        }`}
                                                    >
                                                        Start{" "}
                                                        {
                                                            company.whiteLabel
                                                                .texts
                                                                .label4SessionSingle
                                                        }
                                                    </button>
                                                    <button
                                                        onClick={goToMessages}
                                                        style={{
                                                            color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                                            backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                                        }}
                                                        className="w180 borderRadius12 borderNone mLeft10 P1regular colOffWhite pY10"
                                                    >
                                                        Message
                                                    </button>
                                                </div>
                                            );
                                        }
                                        if (session?.status === "cancelled") {
                                            if (
                                                props.data.data.user.role ===
                                                "client"
                                            ) {
                                                return (
                                                    <>
                                                        <button
                                                            onClick={
                                                                goToMessages
                                                            }
                                                            style={{
                                                                color: `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
                                                                backgroundImage: `${company?.whiteLabel?.color?.buttons?.secondary}`,
                                                            }}
                                                            className="boxShadowForSearchLanding colPrimaryGreen verticallyAlignMid w180 borderRadius12 borderNone P1regular pY10"
                                                        >
                                                            Message
                                                        </button>
                                                        {/* <button style={{color : `${company?.whiteLabel?.color?.buttons?.primaryText}`, backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`}} className="verticallyAlignMid w180 borderRadius12 borderNone P1regular colOffWhite pY10 mLeftAuto">Book Again</button> */}
                                                    </>
                                                );
                                            } else {
                                                return (
                                                    <button
                                                        onClick={goToMessages}
                                                        style={{
                                                            color: `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
                                                            backgroundImage: `${company?.whiteLabel?.color?.buttons?.secondary}`,
                                                        }}
                                                        className="boxShadowForSearchLanding colPrimaryGreen verticallyAlignMid w100 borderRadius12 borderNone P1regular pY10"
                                                    >
                                                        Message
                                                    </button>
                                                );
                                            }
                                        } else {
                                            if (
                                                props.data.data.user.role ===
                                                "client"
                                            ) {
                                                return (
                                                    <>
                                                        <button
                                                            onClick={
                                                                goToMessages
                                                            }
                                                            style={{
                                                                color: `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
                                                                backgroundImage: `${company?.whiteLabel?.color?.buttons?.secondary}`,
                                                            }}
                                                            className="boxShadowForSearchLanding colPrimaryGreen verticallyAlignMid w180 borderRadius12 borderNone P1regular pY10"
                                                        >
                                                            Message
                                                        </button>
                                                        {/* <button style={{color : `${company?.whiteLabel?.color?.buttons?.primaryText}`, backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`}} className="verticallyAlignMid w180 borderRadius12 borderNone P1regular colOffWhite pY10 mLeftAuto">Book Again</button> */}
                                                    </>
                                                );
                                            } else {
                                                return (
                                                    <button
                                                        onClick={goToMessages}
                                                        style={{
                                                            color: `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
                                                            backgroundImage: `${company?.whiteLabel?.color?.buttons?.secondary}`,
                                                        }}
                                                        className="boxShadowForSearchLanding colPrimaryGreen verticallyAlignMid w100 borderRadius12 borderNone P1regular pY10"
                                                    >
                                                        Message
                                                    </button>
                                                );
                                            }
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                        {rescheduleView && (
                            <div className="w400 mTop20 posAbs bigScheduleCalendarMain1">
                                <BigScheduleCalendar
                                    mentor={mentor}
                                    service={service}
                                    type={"reschedule"}
                                    typeID={session._id}
                                    parentEvent={setRescheduleView}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="w600 onboardingMentor11ClientSingleViewSubb9">
                    {session?.status === "completed" && review && (
                        <>
                            <h5 className="P1medium textAlignLeft">Review</h5>
                            <div className="reviewOnCompletedSessionWrapper backColElementBackgroundGray borderRadius12 w600 mBottom20">
                                <div className="w90 mXAuto mY20">
                                    <div className="verticallyAlignMidJustConStart mBottom20 reviewOnCompletedSessionSubWrapper">
                                        <div className="verticallyAlignMidJustConStart borderRadius50 mRight12 reviewOnCompletedSessionSub2Wrapper">
                                            <img
                                                alt=""
                                                className="reviewsCardImg"
                                                src="https://i.ibb.co/fD1yxzd/img.png"
                                            />
                                        </div>
                                        <div>
                                            <div className="textAlignLeft Pbold">
                                                {client.fullName}
                                            </div>
                                            <p className="textAlignLeft Small-Label-11 colLabelGray">
                                                {Moment(
                                                    Moment(session.date).format(
                                                        "LLLL"
                                                    )
                                                ).format("MMMM D")}
                                            </p>
                                        </div>
                                        <div className="verticallyAlignMidStretch mLeftAuto">
                                            <RiStarFill
                                                size="1.5em"
                                                className="cursorPointer colPrimaryGreen mRight8"
                                            />
                                            <h5 className="Small-LabelCaps">
                                                {review?.rating}/5
                                            </h5>
                                        </div>
                                    </div>
                                    <p className="Small-Labelnormal textAlignLeft">
                                        {review?.text}
                                    </p>
                                </div>
                            </div>
                            <div className="borderGrayTopInput mY50"></div>
                        </>
                    )}
                    <div className="w400 onboardingMentor11ClientSingleViewSubb10">
                        <h4 className="H3medium textAlignLeft">
                            Manage booking
                        </h4>
                        {/* <div
                            onClick={addToCalendar}
                            className="verticallyAlignMidJustConStart onboardingMentor11ClientSingleViewSubb11 w380 mY14 cursorPointer"
                        >
                            <h5 className="P1medium textAlignLeft mRightAuto">
                                Add to Calendar
                            </h5>
                            <RiArrowRightSLine size="1.5em" />
                        </div>
                        <div className="borderBottomInputGray"></div> */}
                        <div
                            onClick={goToNotes}
                            className="verticallyAlignMidJustConStart onboardingMentor11ClientSingleViewSubb11 w380 mY14 cursorPointer"
                        >
                            <h5 className="P1medium textAlignLeft mRightAuto">
                                View my Notes
                            </h5>
                            <RiArrowRightSLine size="1.5em" />
                        </div>
                        <div className="borderBottomInputGray"></div>

                        {session?.status === "scheduled" ? (
                            <>
                                <div
                                    onClick={userReschedule}
                                    className="verticallyAlignMidJustConStart onboardingMentor11ClientSingleViewSubb11 w380 mY14 cursorPointer"
                                >
                                    {/* <div onClick={() => setEvent('Reschedule')} className="verticallyAlignMidJustConStart w380 mY14 cursorPointer"> */}
                                    <h5 className="P1medium textAlignLeft mRightAuto">
                                        Reschedule{" "}
                                        {
                                            company.whiteLabel.texts
                                                .label4SessionSingle
                                        }
                                    </h5>
                                    <RiArrowRightSLine size="1.5em" />
                                </div>
                                <div className="borderBottomInputGray"></div>

                                <div
                                    onClick={() => setEvent("Cancel")}
                                    className="verticallyAlignMidJustConStart onboardingMentor11ClientSingleViewSubb11 w380 mY14 cursorPointer"
                                >
                                    <h5 className="P1medium textAlignLeft mRightAuto">
                                        Cancel{" "}
                                        {
                                            company.whiteLabel.texts
                                                .label4SessionSingle
                                        }
                                    </h5>
                                    <RiArrowRightSLine size="1.5em" />
                                </div>
                                <div className="borderBottomInputGray"></div>
                            </>
                        ) : (
                            ""
                        )}

                        {/* <h4 className="H3medium textAlignLeft mY50">Payment Info</h4>
            <p className="Pbold textAlignLeft">Confirmation code</p>
            <h5 className="P1medium textAlignLeft mBottom20">325356778</h5>
            <p className="Pbold textAlignLeft">Total earned</p>
            <p className="P1medium textAlignLeft mBottom50">$20.28</p>
            <div className="verticallyAlignMidJustConStart onboardingMentor11ClientSingleViewSubb11 w380 mY14 cursorPointer">
              <h5 className="P1medium textAlignLeft mRightAuto">Get receipt</h5>
              <RiArrowRightSLine size="1.5em" />
            </div>
            <div className="borderBottomInputGray"></div>
            <h4 className="H3medium textAlignLeft mTop50">Support</h4>
            <div className="verticallyAlignMidJustConStart onboardingMentor11ClientSingleViewSubb11 w380 mY14 cursorPointer">
              <h5 className="P1medium textAlignLeft mRightAuto">Help Center</h5>
              <RiArrowRightSLine size="1.5em" />
            </div>
            <div className="borderBottomInputGray"></div> */}
                    </div>
                </div>
            </div>
            {(event === "Reschedule" || event === "Cancel") && (
                <div className="filterMentorPopupWrapper">
                    <ReschedulePopUp
                        type={event}
                        session={session}
                        client={client}
                        service={service}
                        parentEvent={setEvent}
                        parentEventView={setRescheduleView}
                    />
                </div>
            )}
            {(event === "popupReschedule" || event === "popupCancel") && (
                <div className="filterMentorPopupWrapper">
                    <SuccessPopUpMentorFlow
                        type={event}
                        parentEvent={setEvent}
                    />
                </div>
            )}
        </div>
    );
}

function getUserDate(state) {
    return {
        data: state.auth.authData || {},
    };
}

export default connect(getUserDate)(OnboardingMentor11ClientSingleView);
