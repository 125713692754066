import { forwardRef } from "react";

const TextareaComponent = forwardRef((props, ref) => {
   return (
      <div className="textareaComponentMain">
         <textarea ref={ref} defaultValue={props.defValue ? props.defValue : ""} onChange={(e) => props.parentEvent && props.parentEvent(e.target.value)} rows={props.rows ? props.rows : "8"} className="pX20 pTop10 verticallyAlignMidJustConStartForText colLabelGray w100 Pregular textAlignLeft mBottom40 borderRadius12 borderNone backColInput">
         </textarea>
      </div>
   )
});

export default TextareaComponent;
