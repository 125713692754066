import { Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

function RestrictedMentorApplyRoute(props) {
	return props.userData.user.mentorOnboarding.mentorApplyCompleted ? <Navigate to="/MOProfileDetails" /> : <Outlet />;
};

function getUser(state) {
	// console.log('RestrictedMentorApplyRoute', state.auth.authData?.data.user)
	return {
		userData: {
			user : state.auth.authData?.data.user			
		}
	}
}

export default connect(getUser)(RestrictedMentorApplyRoute);