import { Link, useNavigate } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import { connect, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getUser, updateUser } from "../../api";
import { uploadProfilePhoto } from "../../actions/auth.js";
import AvatarEditor from "react-avatar-editor";

function ClientSettingsPanelPersonalInfo(props) {
    const navigate = useNavigate();

    if (!props.company._id || !props.user._id) {
        navigate("/LogIn");
    }

    const [name, setName] = useState(false);
    const [gender, setGender] = useState(false);
    const [phone, setPhone] = useState(false);
    const [address, setAddress] = useState(false);
    // const [value, setValue] 	= useState(0);
    const [user, setUser] = useState({});

    const refName = useRef("");
    const refPhone = useRef("");
    const refAddress = useRef("");

    useEffect(() => {
        async function getResults() {
            const { data } = await getUser(props.user._id);
            setUser(data.data.user);
        }

        getResults();
    }, [name, gender, phone, address, props.user._id]);

    function changeUserInfo(type) {
        if (type === "name") {
            if (refName.current.value !== "") {
                updateUser(props.user._id, { fullName: refName.current.value });
            }

            setName(false);
        } else if (type === "gender") {
            if (!gender) {
                updateUser(props.user._id, { gender });
            }
            setGender(false);
        } else if (type === "phone") {
            if (refPhone.current.value !== "") {
                updateUser(props.user._id, { phone: refPhone.current.value });
            }

            setPhone(false);
        } else if (type === "address") {
            if (refAddress.current.value !== "") {
                updateUser(props.user._id, {
                    address: refAddress.current.value,
                });
            }
            setAddress(false);
        }
    }

    function getInitials(name) {
        let words = name.split(" ");
        return (
            words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
        );
    }

    const imageFileRef = useRef(null);
    const imageRef = useRef(null);
    const imageRoundRef = useRef(null);

    const [image, setImage] = useState(false);
    const [imageScale, setImageScale] = useState(1);

    const [img, setImg] = useState(false);

    const [errorSize, setErrorSize] = useState(false);

    const onChange = (e) => {
        if (e.target.files[0].size > 5242880) {
            imageFileRef.current.value = null;
            setErrorSize(true);
        } else {
            setImage(e.target.files[0]) && setErrorSize(false);
        }
    };

    const dispatch = useDispatch();

    async function saveImage() {
        setImg(imageRef.current.getImageScaledToCanvas().toDataURL());

        let file = imageRef.current.getImage().toDataURL();

        dispatch(uploadProfilePhoto(props.user._id, { image: file }));

        setImage(false);
        setImg(false);
        // setValue(value => value + 1);
    }

    function remove() {
        imageFileRef.current.value = null;

        setImage(false);
        setImageScale(1);
        setImg(false);
    }

    return (
        <div className="ClientSettingsPanelPersonalInfo">
            <div className="verticallyAlignMidJustConStart mTopForSmallerCreens w80 mXAuto mTop70 mBottom40">
                <Link to="/MentorHome2SettingsPanel">
                    <div className="posRel mRight20">
                        <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                            <RiArrowLeftSLine
                                size="2em"
                                className="colPrimaryGreen"
                            />
                        </button>
                    </div>
                </Link>
                <h2 className="H1regular textAlignLeft">Personal Info</h2>
            </div>
            <div className="w80 mXAuto pBottom50">
                <div className="w50 ClientSettingsPanelPersonalInfoSubb1">
                    <h4 className="textAlignLeft H2medium mBottom50">Login</h4>

                    <div className="verticallyAlignMidJustConStart ClientSettingsPanelPersonalInfoAll">
                        <div className="w80">
                            <h5 className="Pbold textAlignLeft mBottom8">
                                Full name
                            </h5>
                            {name ? (
                                <input
                                    ref={refName}
                                    className="Pregular borderRadius12 pX6 verticallyAlignMidJustConStartForText breakWord w300 borderNone backColOffWhite pY10"
                                    type="text"
                                    placeholder={user?.fullName}
                                />
                            ) : (
                                <p>{user?.fullName}</p>
                            )}
                        </div>
                        {name ? (
                            <button
                                onClick={() => changeUserInfo("name")}
                                className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold"
                            >
                                Update
                            </button>
                        ) : (
                            <button
                                onClick={() => setName(true)}
                                className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold"
                            >
                                Edit
                            </button>
                        )}
                    </div>
                    <div className="borderGrayTopInput mY20"></div>

                    {/*<div className="verticallyAlignMidJustConStart ClientSettingsPanelPersonalInfoAll">
						<div className="w80">
							<h5 className="Pbold textAlignLeft mBottom8">Gender</h5>
							{
								gender ?
									<>
										<label className="cursorPointer" htmlFor="male_select_gender">Male</label>
										<input className="mLeft5 verticallyAlignMidJustConStartForText" onChange={() => setGender('Male')} id="male_select_gender" type="radio" name="gender" value="Male" defaultChecked={user?.gender === "Male"}/>
										<label className="cursorPointer mLeft10" htmlFor="female_select_gender">Female</label>
										<input className="mLeft5 verticallyAlignMidJustConStartForText" onChange={() => setGender('Female')} id="female_select_gender" type="radio" name="gender" value="Female" defaultChecked={user?.gender === "Female"}/>
									</>
								:
									<p className="Pregular">{user?.gender}</p>
							}
						</div>
						{
							gender ?
								<button onClick={() => changeUserInfo('gender')} className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold">Update</button>
							:
								<button onClick={() => setGender(user?.gender)} className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold">Edit</button>
						}
					</div>*/}
                    {/*<div className="borderGrayTopInput mY20"></div>*/}

                    <div className="verticallyAlignMidJustConStart ClientSettingsPanelPersonalInfoAll">
                        <div className="w80">
                            <h5 className="Pbold textAlignLeft mBottom8">
                                Phone Number
                            </h5>
                            {phone ? (
                                <input
                                    ref={refPhone}
                                    className="Pregular verticallyAlignMidJustConStartForText borderRadius12 pX6 breakWord w300 borderNone backColOffWhite pY10"
                                    type="text"
                                    placeholder={user?.phone}
                                />
                            ) : (
                                <p className="Pregular">{user?.phone}</p>
                            )}
                        </div>
                        {phone ? (
                            <button
                                onClick={() => changeUserInfo("phone")}
                                className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold"
                            >
                                Update
                            </button>
                        ) : (
                            <button
                                onClick={() => setPhone(true)}
                                className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold"
                            >
                                Edit
                            </button>
                        )}
                    </div>
                    <div className="borderGrayTopInput mY20"></div>

                    <div className="verticallyAlignMidJustConStart ClientSettingsPanelPersonalInfoAll">
                        <div className="w80">
                            <h5 className="Pbold textAlignLeft mBottom8">
                                Address
                            </h5>
                            {address ? (
                                <input
                                    ref={refAddress}
                                    className="Pregular verticallyAlignMidJustConStartForText borderRadius12 pX6 breakWord w300 borderNone backColOffWhite pY10"
                                    type="text"
                                    placeholder={user?.address}
                                />
                            ) : (
                                <p className="Pregular">{user?.address}</p>
                            )}
                        </div>
                        {address ? (
                            <button
                                onClick={() => changeUserInfo("address")}
                                className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold"
                            >
                                Update
                            </button>
                        ) : (
                            <button
                                onClick={() => setAddress(true)}
                                className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold"
                            >
                                Edit
                            </button>
                        )}
                    </div>
                    <div className="borderGrayTopInput mY20"></div>

                    <div className="w60">
                        {image ? (
                            <>
                                <AvatarEditor
                                    image={image}
                                    width={250}
                                    height={250}
                                    border={50}
                                    color={[255, 255, 255, 0.6]} // RGBA
                                    scale={imageScale}
                                    rotate={0}
                                    ref={imageRef}
                                />

                                <AvatarEditor
                                    image={image}
                                    width={250}
                                    height={250}
                                    border={50}
                                    color={[255, 255, 255, 0.6]} // RGBA
                                    scale={imageScale}
                                    rotate={0}
                                    ref={imageRoundRef}
                                    className="d-none"
                                    borderRadius={150}
                                />

                                <input
                                    type="range"
                                    name="scale"
                                    min="1"
                                    max="5"
                                    step="0.2"
                                    defaultValue={1}
                                    onChange={(e) =>
                                        setImageScale(
                                            parseFloat(e.target.value)
                                        )
                                    }
                                />
                            </>
                        ) : (
                            <>
                                {props.user.photo !== "" ? (
                                    <>
                                        {console.log(props.user.photo)}
                                        <img
                                            src={props.user.photo}
                                            alt=""
                                            width="300px"
                                            height="300px"
                                        />
                                        {/* <h1>Preview</h1>
										<div className="verticallyAlignMid">
											<br/>
											<br/>
											<img src={props.user.photo} width="300px" height="300px" />
											<img src={props.user.photo} width="100px" height="100px" className="borderRadius50 mX10" />
											<img src={props.user.photo} width="50px" height="50px" className="borderRadius50 mX10" />
											<img src={props.user.photo} width="30px" height="30px" className="borderRadius50 mX10" />
										</div> */}
                                    </>
                                ) : (
                                    <div className="userInitialsLarge m-5">
                                        <p>
                                            {getInitials(props.user.fullName)}
                                        </p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    <input
                        onChange={(e) => onChange(e)}
                        type="file"
                        className="customFileInput"
                        ref={imageFileRef}
                    />

                    {image && (
                        <button
                            onClick={saveImage}
                            className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold"
                        >
                            Save
                        </button>
                    )}

                    {image && (
                        <button
                            onClick={remove}
                            className="mLeftAuto borderNone backColWhite borderBottomForButtons colErrorRed Pbold mX10"
                        >
                            Remove
                        </button>
                    )}

                    <br />

                    {errorSize && (
                        <div className="disFlex">
                            <p className="P1bold colErrorRed">
                                The image is to large! Maximum size is 5MB
                            </p>
                        </div>
                    )}

                    {/* <br/>
					<br/>
					<br/>
					{img ?
						<>
							<h1>Preview</h1>
							<br/>
							<br/>
							<img src={img} width="300px" height="300px" />
							<img src={img} width="100px" height="100px" className="borderRadius50 mX10" />
							<img src={img} width="50px" height="50px" className="borderRadius50 mX10" />
							<img src={img} width="30px" height="30px" className="borderRadius50 mX10" />
						</> : ""
					} */}

                    {/* <br/>
					<br/>
					<br/>

					{img2 ? <img src={img2} width="300px" height="300px" /> : "" } */}
                </div>
            </div>
        </div>
    );
}

function getUserData(state) {
    return {
        user: state.auth?.authData.data.user || {},
        company: state.auth?.authData.company,
    };
}

export default connect(getUserData)(ClientSettingsPanelPersonalInfo);
