import { useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mentorOnboarding } from "../../actions/auth.js";

function OnboardingMentor5(props) {
    const company = props.data.company;

    const formData = { setThankYouForApplying: true };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (!company._id || !props?.data.data.user._id) {
        navigate("/LogIn");
    }

    function goToNextPage(e) {
        e.preventDefault();
        dispatch(mentorOnboarding(formData, navigate));
    }

    return (
        <div className="OnboardingMentor5">
            <div className="w80 mXAuto mY70 backColPrimaryLight borderRadius24">
                <div className="verticallyAlignMidJustConStart OnboardingMentor5Subb mY70 w80 mXAuto">
                    <div className="w50 OnboardingMentor5Subb1">
                        {/* <p className="P1regular textAlignLeft">We're looking for</p> */}
                        <h2 className="H2medium textAlignLeft mBottom20">
                            Thank you for applying to work with{" "}
                            <span className="colPrimaryGreen">
                                {company.whiteLabel.texts.label1Text}
                            </span>
                            !
                        </h2>
                        <div className="mY20 loginStartingPagesImg2">
                            <img
                                alt="Welcome"
                                className="welcomeImg"
                                src="https://i.ibb.co/fD1yxzd/img.png"
                            />
                        </div>
                        <p className="textAlignLeft Pmedium mBottom30 mRight20">
                            We'll be in touch in the next 7 days to inform you
                            whether your application was successfull.
                        </p>
                        {/*<p className="textAlignLeft Pmedium mBottom30 mRight20">If the wmail doesn't arrive within 5 minutees, please <span><b>click here</b></span> and we'll send another one.</p>*/}
                        <button
                            onClick={goToNextPage}
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            className="borderRadius12 borderNone verticallyAlignMid textAlignCenter mBottom30 P1regular colOffWhite w180 pY10"
                        >
                            Back Home
                        </button>
                    </div>
                    <div className="w50 mY50 loginStartingPagesImg">
                        <img
                            alt=""
                            className="welcomeImg"
                            src="https://i.ibb.co/fD1yxzd/img.png"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function checkIfIsWisited(state) {
    return {
        data: state.auth.authData,
    };
}

export default connect(checkIfIsWisited)(OnboardingMentor5);
