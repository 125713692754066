import { useEffect, useState } from "react";
import MultiRangeSlider from "multi-range-slider-react";
import { RiArrowLeftSLine } from "react-icons/ri";
import { getAllCategories } from "../../api";
import { connect } from "react-redux";

function FilterMentorPopUp({ company, initialState, parentEvent }) {
  const [formData, setFormData] = useState(initialState);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function getData() {
      const { data } = await getAllCategories(
        `?companyID=${company._id}&active=true`
      );
      setCategories(data.data.categories);
    }

    getData();
  }, [company._id]);

  const handleInput = (e) => {
    setMinValue(e.minValue);
    setMaxValue(e.maxValue);

    setFormData({ ...formData, priceMin: e.minValue, priceMax: e.maxValue });
  };

  const handleChange = (e) => {
    if (e.target.name === "duradion") {
      setFormData({ ...formData, duradion: e.target.value });
      return false;
    }

    if (e.target.name === "status") {
      setFormData({ ...formData, status: e.target.value });
      return false;
    }

    if (e.target.name === "availableNow") {
      setFormData({ ...formData, availableNow: e.target.checked });
      return false;
    }

    let array = formData.expertise;
    let index = array.indexOf(e.target.id);

    if (e.target.checked) {
      if (index < 0) {
        array.push(e.target.id);
      } else {
        array.splice(index, 1);
      }
    } else {
      array.splice(index, 1);
    }

    setFormData({ ...formData, expertise: array });
  };

  const handleSubmit = () => {
    // console.log("123");
    parentEvent(formData);
  };

  function clearFilter(e) {
    e.preventDefault();
    setFormData({
      priceMin: 0,
      priceMax: 100,
      duration: 0,
      expertise: [],
      availableNow: false,
      status: "",
    });
  }

  return (
    <div className="FilterMentorPopUp mXAuto backColWhite mTop70 borderRadius24">
      <div className="posRel mX20 pY20">
        <button
          onClick={() => parentEvent(false)}
          className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding"
        >
          <RiArrowLeftSLine size="2em" className="colPrimaryGreen" />
        </button>
      </div>
      <form className="">
        <div className="verticallyAlignMidStretch FilterMentorPopUp1MainSubb mXAuto">
          <div className="mRightAuto">
            <div className="FilterMentorPopUp1 borderRadius24 backColElementBackgroundGray">
              <div className="FilterMentorPopUp4">
                <h5 className="H3medium textAlignLeft">Filter by price</h5>
                <div className="FilterMentorPopUp3 mY30">
                  <MultiRangeSlider
                    min={0}
                    max={100}
                    step={5}
                    ruler={false}
                    label={false}
                    preventWheel={false}
                    minValue={minValue}
                    maxValue={maxValue}
                    onInput={(e) => {
                      handleInput(e);
                    }}
                  />
                  <div className="verticallyAlignMidJustConStart">
                    <h6 className="P1medium textAlignLeft colLabelGray mRightAuto">
                      min ${minValue}
                    </h6>
                    <h6 className="P1medium textAlignLeft colLabelGray">
                      max ${maxValue}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="borderRadius24 backColElementBackgroundGray mTop20">
              <div className="FilterMentorPopUp4">
                <h5 className="H3medium textAlignLeft mBottom10">Expertise</h5>
                {categories.length > 0 &&
                  categories.map((el, i) => {
                    return (
                      <div
                        key={i}
                        className="verticallyAlignMidJustConStart mBottom10"
                      >
                        <label className="filterLabelCheckBox colPlaceHolderGray cursorPointer mRight10 P1regular">
                          <input
                            onChange={(e) => handleChange(e)}
                            id={el._id}
                            className="filterInputLabelCheckBox"
                            name="anxiety"
                            type="checkbox"
                            checked={
                              formData.expertise.indexOf(el._id) !== -1
                                ? true
                                : false
                            }
                          />
                          <div className="divForCheckBox"></div>
                          {el.name}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="h100 FilterMentorPopUp5">
            <div className="FilterMentorPopUp2 borderRadius24 backColElementBackgroundGray">
              <div className="FilterMentorPopUp4">
                <div className="onOffSwitchInputOnFilltersMain posRel verticallyAlignMidJustConStart mBottom10">
                  <input
                    onChange={(e) => handleChange(e)}
                    className="onOffSwitchInputOnFillters posRel"
                    type="checkbox"
                    defaultChecked={!!formData.availableNow}
                  />
                  <span className="slider round"></span>
                  <h5 className="H3medium textAlignLeft mLeft8">
                    Available now
                  </h5>
                </div>
                {/*<div className="verticallyAlignMid">
									<input onChange={(e) => handleChange(e)} name="availableNow" type="checkbox" defaultChecked={!!formData.availableNow} />
									<span className="slider"></span>
									<h5>Available now</h5>
								</div>*/}
                <h6 className="P1medium textAlignLeft mBottom30">
                  Only show mentors that are available now
                </h6>
                {/* <div className="verticallyAlignMidJustConStart mBottom10">
                  <label
                    htmlFor="filter_mentor_available_offers"
                    className="labelForRadio colPlaceHolderGray cursorPointer mRight10 P1regular"
                  >
                    <input
                      onChange={(e) => handleChange(e)}
                      className="inputForRadioLabel"
                      id="filter_mentor_available_offers"
                      type="radio"
                      name="status"
                    />
                    <div className="divForRadio"></div>
                    Offers
                  </label>
                </div>
                <div className="verticallyAlignMidJustConStart mBottom10">
                  <label
                    htmlFor="filter_mentor_available_best_seller"
                    className="labelForRadio colPlaceHolderGray cursorPointer mRight10 P1regular"
                  >
                    <input
                      onChange={(e) => handleChange(e)}
                      className="inputForRadioLabel"
                      id="filter_mentor_available_best_seller"
                      type="radio"
                      name="status"
                    />
                    <div className="divForRadio"></div>
                    Best Seller
                  </label>
                </div>
                <div className="verticallyAlignMidJustConStart mBottom10">
                  <label
                    htmlFor="filter_mentor_available_top_rated"
                    className="labelForRadio colPlaceHolderGray cursorPointer mRight10 P1regular"
                  >
                    <input
                      onChange={(e) => handleChange(e)}
                      className="inputForRadioLabel"
                      id="filter_mentor_available_top_rated"
                      type="radio"
                      name="status"
                    />
                    <div className="divForRadio"></div>
                    Top Rated
                  </label>
                </div>
                <div className="verticallyAlignMidJustConStart mBottom10">
                  <label
                    htmlFor="filter_mentor_available_recommended"
                    className="labelForRadio colPlaceHolderGray cursorPointer mRight10 P1regular"
                  >
                    <input
                      onChange={(e) => handleChange(e)}
                      className="inputForRadioLabel"
                      id="filter_mentor_available_recommended"
                      type="radio"
                      name="status"
                    />
                    <div className="divForRadio"></div>
                    Recommended
                  </label>
                </div> */}
              </div>
            </div>
            {/* <div className="borderRadius24 backColElementBackgroundGray mTop20">
              <div className="FilterMentorPopUp4">
                <h5 className="H3medium textAlignLeft">Maximum Duration</h5>
                <input
                  onChange={(e) => handleChange(e)}
                  type="range"
                  className="maximumDuration mBottom50 mTop30"
                  id="maximum_duration_input"
                  defaultValue="0"
                  name="duradion"
                  min="0"
                  max="60"
                  step="15"
                />
              </div>
            </div> */}
          </div>
        </div>
        <div className="verticallyAlignMidJustConStart mXAuto pY30 FilterMentorPopUpButtonsMain">
          <button
            onClick={clearFilter}
            className="backColTransparent colLabelGray w180 Pbold textAlignCenter borderNone outlineNone pY10 mRightAuto"
          >
            Clear filters
          </button>
          <button
            onClick={handleSubmit}
            className="backColLinksAndTitlesBlack colOffWhite w180 borderRadius12 Pbold textAlignCenter borderNone outlineNone pY10"
          >
            Show Results
          </button>
        </div>
      </form>
    </div>
  );
}

function getCompanyData(state) {
  return {
    company: state.auth.authData.company,
  };
}

export default connect(getCompanyData)(FilterMentorPopUp);
