import { Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

function RestrictedNOTLoggedInRoute(props) {
	//console.log('RestrictedNOTLoggedInRoute', props.userData.user?.company?.active);
	//console.log('RestrictedNOTLoggedInRoute', props.userData.user?.token);
	if(props.userData.user?.token) {
		if(props.userData.user.data.user.role === 'client' && props.userData.user?.company?.active) {
			return <Navigate to="/WhatKindOfMentor" />
		} else if(props.userData.user.data.user.role === 'mentor' && props.userData.user?.company?.active) {
			return <Navigate to="/OnboardingMentor12WelcomePage" />
		} else if(props.userData.user.data.user.role === 'owner' || props.userData.user.data.user.role === 'director') {
			return <Navigate to="/Owner" />
		} else if(props.userData.user.data.user.role === 'admin') {
			return <Navigate to="/Admin" />
		} else if(!props.userData.user?.company?.active) {
			return <Navigate to="/Page404" />
		}
	} else {
		return <Outlet />
	}
};

function getUser(state) {
	// console.log('RestrictedNOTLoggedInRoute', state.auth.authData);
	return {
		userData: {
			user : state.auth.authData
		}
	}
}

export default connect(getUser)(RestrictedNOTLoggedInRoute);