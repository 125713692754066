import { useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { FaUser } from "react-icons/fa";
// import {socket} from "./../../actions/socket";
// import { getSessions } from "../../api/index.js";
// import Moment from "moment";

const NotificationBottomPopup = (props) => {
  // const navigate = useNavigate();
  const location = useLocation();

  // const [session, setSession]     = useState(false);
  // const [inFive, setInFive] = useState(false);
  const inFive = false;
  // const [duration, setDuration] = useState(0);
  const duration = 0;

  /* useEffect(() => {

        async function getData() {

            if (props.user._id) {

                let queryString = `?populate=serviceID,clientID&status=scheduled&sort=date&limit=1`;

                if (props.user.role === "mentor") {
                    queryString += `&mentorID=${props.user._id}`
                } else {
                    queryString += `&clientID=${props.user._id}`
                }

                const { data } = await getSessions(queryString);

                //console.log(data)

                setSession(data.data.sessions[0])

                let currDate = Moment(new Date)
                let sessDate = Moment(data.data.sessions[0].date)

                let dur = sessDate.diff(currDate, "seconds");
                //console.log(dur)

                if (dur <= 300 && dur > 0) {
                    setDuration(Math.ceil(dur / 60));
                    setInFive(true);
                } else {
                    setInFive(false);
                }
            }
        }

        getData()
    }, [navigate]);*/

  return (
    <>
      {inFive && location.pathname !== "/InSession" && (
        <div className="notificationBottomPopup">
          <div className="row">
            <div className="col-1">
              <FaUser />
              <img src="#" alt="User" width="50" height="50" />
            </div>

            <div className="col-9">
              <h3 className="notificationBottomPopupContent">
                {props.user.fullName.split(" ")[0]}, your session is starting in{" "}
                {duration} min
              </h3>
            </div>

            <div className="col-2">
              <p> Some Button Here </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function getUser(state) {
  return {
    user: state.auth.authData?.data?.user || {},
  };
}

export default connect(getUser)(NotificationBottomPopup);
