function ClientSettingsPanelPaymentAndPayouts3() {
    return (
        <div className="ClientSettingsPanelPaymentAndPayouts3">
            <div className="verticallyAlignMid">
                <button>Back Button</button>
                <h2>Payment and Payouts</h2>
            </div>
            <h4>Billing Country</h4>
            <select>
                <option>United Kingdom</option>
            </select>
            <h4>Payout Methods</h4>
            <label className="mainForRadioButtons radioPaymentAndPayout1 mBottom40">
                <input type="radio" checked="checked" name="radio" />
                <span className="checkmark"></span>
            </label>
            <label className="mainForRadioButtons radioPaymentAndPayout2">
                <input type="radio" checked="checked" name="radio" />
                <span className="checkmark"></span>
            </label>
            <div className="verticallyAlignMid">
                <div>
                    <h5>Stripe in AU$</h5>
                    <p>Payment in 4-6 hours</p>
                    <p>Connect with your stripe account</p>
                    <p>Fees may occur</p>
                </div>
                <div>
                    <input type="radio" name="payouth_methods" />
                </div>
            </div>
            <div className="verticallyAlignMid">
                <div>
                    <h5>Bank Transfer</h5>
                    <p>Payment in 1-2 days</p>
                    <p>No Fees</p>
                </div>
                <div>
                    <input type="radio" name="payouth_methods" />
                </div>
            </div>
            <button>Save</button>
        </div>
    );
}

export default ClientSettingsPanelPaymentAndPayouts3;
