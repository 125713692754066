import { Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

function RestrictedAcceptedMentorsRoute(props) {
	if(props.userData.user.mentorOnboarding.acceptedMentor) {
		return <Outlet />;
	} else if(!props.userData.user.mentorOnboarding.mentorApplyCompleted) {
		return <Navigate to="/MOMentorsApply" />;
	} else if(!props.userData.user.mentorOnboarding.thankYouCompleted) {
		return <Navigate to="/MOProfileDetails" />;
	} else {
		//Wait until you are accepted!
		return <Navigate to="/HowMentorXWorks" />;
	}
}

function getUser(state) {
	// console.log('RestrictedAcceptedMentorsRoute', state.auth.authData?.data.user)
	return {
		userData: {
			user : state.auth.authData?.data.user		
		}
	}
}

export default connect(getUser)(RestrictedAcceptedMentorsRoute);