import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import TextareaComponent from "../../components/TextareaComponent/TextareaComponent";
import { BsFillStarFill, BsStar } from "react-icons/bs";
import { useRef, useState } from "react";
// import { addReview, createNotification } from "../../api/index.js";
import Rating from "react-rating";
import { connect } from "react-redux";
import {
    BsCameraVideo,
    BsChatFill,
    BsMicFill,
    BsThreeDots,
    BsFillMicMuteFill,
    BsExclamationLg,
    BsCameraVideoOffFill,
} from "react-icons/bs";
function Page404() {
    // function handleCallbackResponse(response) {
    // 	const user = jwt_decode(response.credential)
    // 	console.log(user);
    // }

    // useEffect(() => {
    // 	/* global google */

    // 	google.accounts.id.initialize({
    // 	  	// client_id   : "567198460390-ga96uugu7b8hnoi04get5nvevr4oomlc.apps.googleusercontent.com",
    // 		// client_id: "567198460390-oo360r7n7jsiruap9nilpe4ooohqkhfs.apps.googleusercontent.com",
    // 	  	client_id: "567198460390-eqfp1i18tf1lbphu7h50a9c7h547s1rs.apps.googleusercontent.com",
    // 	  	callback: handleCallbackResponse,
    // 	});

    // 	google.accounts.id.renderButton(document.getElementById("signInDiv"), {
    // 	  	theme: "outline",
    // 	  	size: "large",
    // 	});

    // 	google.accounts.id.prompt();
    //   }, []);
    // // const [isTimeRemaining, setIsTimeRemaining] = useState(false);
    // // console.log("YourSessionHasEndedPopUp(props)");
    // //console.log(props);
    const [reviewRating] = useState(0);

    const reviewTextRef = useRef(null);

    function finishReview() {
    }
    return (






        // <div className="filterMentorPopupWrapper">
        //     <div className="yourSessionHasEndedPopUp">
        //         <div className="mTop70 mBottom50 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
        //             <div className="w90 mXAuto">
        //                 <div className="verticallyAlignMid mTop20 mBottom12">
        //                     <h2 className="mRightAuto H2medium">
        //                         Your {"company.whiteLabel.texts.label4SessionSingle"}{" "}
        //                         has ended!
        //                     </h2>
        //                     {/* <CloseButton parentEvent={props.parentEventSession} /> */}
        //                 </div>
        //                 <div className="mBottom40">
        //                     {true && <p className="Pmedium textAlignLeft mBottom12">
        //                         Your session was ended earlier by the mentor {"props?.mentor?.fullName?.split"}.
        //                         You can always  book another session in your dashboard.
        //                     </p>}
        //                     {false && <p className="Pmedium textAlignLeft mBottom12">
        //                         Your session was ended earlier by the user {"props?.user?.fullName?.split"}.
        //                         You can always  book another session in your dashboard.
        //                     </p>}
        //                     <p className="Pmedium textAlignLeft mBottom12">
        //                         How did{" "}
        //                         <span>
        //                             {"props?.mentor?.fullName?.split"}
        //                         </span>{" "}
        //                         do?
        //                     </p>
        //                     <div className="verticallyAlignMidJustConStart">
        //                         <Rating
        //                             emptySymbol={
        //                                 <BsStar
        //                                     size="2em"
        //                                     className="colPrimaryGreen mRight10 cursorPointer"
        //                                 />
        //                             }
        //                             fullSymbol={
        //                                 <BsFillStarFill
        //                                     size="2em"
        //                                     className="colPrimaryGreen mRight10 cursorPointer"
        //                                 />
        //                             }
        //                             onClick={() => { }}
        //                             initialRating={reviewRating}
        //                         />
        //                     </div>
        //                 </div>
        //                 <div className="mBottom30">
        //                     <p className="Pmedium textAlignLeft mBottom12">
        //                         Review (optional)
        //                     </p>
        //                     <TextareaComponent ref={reviewTextRef} rows="3" />
        //                 </div>
        //                 <p className="Small-Labelnormal textAlignLeft">Mentor</p>
        //                 <div className="verticallyAlignMidJustConStart mBottom30">
        //                     <div className="verticallyAlignMid borderRadius50 mRight12">
        //                         <img
        //                             alt=""
        //                             className="reviewsCardImg2"
        //                             src="https://i.ibb.co/fD1yxzd/img.png"
        //                         />
        //                     </div>
        //                     <div className="P1bold textAlignLeft">
        //                         {"props.mentor.fullName"}
        //                     </div>
        //                     <button
        //                         onClick={finishReview}
        //                         style={{
        //                             color: `${"red"}`,
        //                             //backgroundImage: `linear-gradient(to right top, ${"company?.whiteLabel?.color?.buttons?.primary?.from"}, ${"company?.whiteLabel?.color?.buttons?.primary?.to"})`,
        //                         }}
        //                         className="verticallyAlignMid w180 borderRadius12 borderNone Pregular colOffWhite pY10 mLeftAuto"
        //                     >
        //                         Submit
        //                     </button>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div >




        // <div>
        //     <div className="VideoCallMain">
        //         <div className={`h-100 backColElementBackgroundGray VideoCallImageElement posRel`}>
        //             <div className="w100 h100 verticallyAlignMid">
        //                 <div className="userInitialsLarge">
        //                     <p>
        //                         {"AB"}
        //                     </p>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     <div
        //         className={"buttonsForAudioCallOnly borderRadius18 posAbs buttonsForVideoCall backColGrayOpacity"}>
        //         <div className="buttonsForVideoCallSubb mXAuto verticallyAlignMidSpaceBetween pY14">
        //             {false && (
        //                 <div
        //                     className="buttonForVideoCall backColGrayOpacity2 borderRadius50 verticallyAlignMid cursorPointer"
        //                 >
        //                     {false ? (
        //                         <BsCameraVideo
        //                             className="colOffWhite"
        //                             size="1.7em"
        //                         />
        //                     ) : (
        //                         <BsCameraVideoOffFill
        //                             className="colOffWhite"
        //                             size="1.7em"
        //                         />
        //                     )}
        //                 </div>
        //             )}



        //             <div
        //                 className="buttonForVideoCall backColGrayOpacity2 borderRadius50 verticallyAlignMid cursorPointer "
        //             >
        //                 <div className="icon-container">
        //                     <BsChatFill className="colOffWhite" size="1.7em" />
        //                     {true && <span className="notification-dot"></span>}
        //                 </div>

        //             </div>





        //             {/* <div
        //             className={`${true ? "" : "background__red"
        //                 } buttonForVideoCall backColGrayOpacity2 borderRadius50 verticallyAlignMid cursorPointer`}
        //         >
        //             {false ? (
        //                 <BsMicFill
        //                     className="colOffWhite"
        //                     size="1.7em"
        //                 />
        //             ) : (
        //                 <BsFillMicMuteFill
        //                     className="colOffWhite"
        //                     size="1.7em"
        //                 />
        //             )}
        //         </div> */}
        //             <div

        //                 className="threeDotsSessionClick buttonForVideoCall backColGrayOpacity2 borderRadius50 verticallyAlignMid cursorPointer"
        //             >
        //                 <BsThreeDots
        //                     className="colOffWhite"
        //                     size="1.7em"
        //                 >
        //                 </BsThreeDots>

        //             </div>
        //         </div>
        //     </div>
        // </div >
        // { true && (
        //     <div className="posAbs threeDotsSessionWrapper backColGrayOpacity2 borderRadius24">
        //         <div
        //             onClick={endSessionFirst}
        //             className="w100 pY10 cursorPointer borderBottomInputGray"
        //         >
        //             <p className="P1medium colOffWhite">
        //                 End Session
        //             </p>
        //         </div>
        //         {/* <div onClick={seeSessionNotes} className="w100 pY10 cursorPointer">
        //                             <p className="P1medium colOffWhite">See Notes</p>
        //                         </div> */}
        //         {fullScreen ? (
        //             <div
        //                 onClick={exitFullScreen}
        //                 className="w100 pY10 cursorPointer"
        //             >
        //                 <p className="P1medium colOffWhite">
        //                     Exit Full Screen
        //                 </p>
        //             </div>
        //         ) : (
        //             <div
        //                 onClick={goFullScreen}
        //                 className="w100 pY10 cursorPointer"
        //             >
        //                 <p className="P1medium colOffWhite">
        //                     Full Screen
        //                 </p>
        //             </div>
        //         )}
        //     </div>
        //     );
        // }

        <div className="Page404 mY50">
                        <h1 className="mY20"> Oops 404! </h1>
                        <h2> That Page Can't Be Found </h2>
                        {/* <div id="signInDiv"></div> */}
                    </div>
    );
}

export default Page404;
