import { Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

function RestrictedWhatKindOfMentor(props) {

	if(!props.userData.user.clientOnboarding.selectCategoriesCompleted) {
		return <Navigate to="/ClientSelectCategories" />;
    } else if (props?.company?.charity) {
		return <Outlet />;
	// } else if(!props.userData.user.clientOnboarding.paymentMethodCompleted) {
    //     return <Navigate to="/LogInClientSetYourPaymentDetails" />;
	} else {
		return <Outlet />;
	}
};

function getUser(state) {
	return {
		userData : {
			user : state.auth.authData?.data.user
		},
		company : state.auth.authData?.company
	}
}

export default connect(getUser)(RestrictedWhatKindOfMentor);
