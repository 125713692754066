import { useEffect, useState } from "react";
import { connect } from "react-redux";
import NotificationsComponent from "../../components/NotificationsComponent/NotificationsComponent";
import { getNotifications, setAllNotificationsSeen } from "../../api/index.js";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";

function Notifications(props) {
    const navigate = useNavigate();

    if (!props.company._id || !props.user._id) {
        navigate("/LogIn");
    }

    const initialState = {
        today: [],
        yesterday: [],
        other: {},
    };

    const [notifications, setNotifications] = useState({});

    useEffect(() => {
        async function getResults() {
            const queryString = `?populate=typeID,fromID&userID=${props.user._id}&sort=-createdAt&limit=50`;

            const { data } = await getNotifications(queryString);

            let obj = initialState;

            let dateToday = Moment(new Date()).format("YYYY-MM-DD");
            let dateYesterday = Moment(new Date())
                .subtract(1, "day")
                .format("YYYY-MM-DD");

            data.data.notifications &&
                data.data.notifications.forEach((el) => {
                    let date = el.createdAt.split("T")[0];

                    if (date === dateToday) {
                        obj.today.push(el);
                    } else if (date === dateYesterday) {
                        obj.yesterday.push(el);
                    } else {
                        date = Moment(date).format("DD MMM");

                        if (obj.other[date]) {
                            obj.other[date].push(el);
                        } else {
                            obj.other[date] = [];
                            obj.other[date].push(el);
                        }
                    }
                });

            setNotifications(obj);

            // let objForAPI = {markAllSeen : true, userID : props.user._id}

            // editNotification(objForAPI);
            props.parentEvent(false);
        }
        getResults();
    }, [props.user._id, props.redDot, initialState]);

    useEffect(() => {
        async function markAllNotificationsAsSeen() {
            //Set all Notifications to 'seen'
            await setAllNotificationsSeen();
        }
        markAllNotificationsAsSeen();
    }, []);

    // Why react doesn't want to re-render the page? Because in his eyes state wasn't changed -> props inside {} needs to be changed as well!!!!

    return (
        <div className="Notifications">
            <div className="mTop70 mBottom70 w80 mXAuto">
                <div className="verticallyAlignMidJustConStart mBottom70">
                    <div
                        onClick={() => navigate(-1)}
                        className="posRel mRight20"
                    >
                        <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                            <RiArrowLeftSLine
                                size="2em"
                                className="colPrimaryGreen"
                            />
                        </button>
                    </div>
                    <h2 className="H2medium">Notifications</h2>
                </div>
                <div>
                    {notifications.today && notifications.today.length > 0 && (
                        <h5 className="Pmedium textAlignLeft pTop10">TODAY</h5>
                    )}

                    {notifications.today &&
                        notifications.today.map((el, i) => {
                            return (
                                <NotificationsComponent
                                    key={i}
                                    notification={el}
                                />
                            );
                        })}

                    {notifications.yesterday &&
                        notifications.yesterday.length > 0 && (
                            <h5 className="Pmedium textAlignLeft pTop10">
                                YESTERDAY
                            </h5>
                        )}

                    {notifications.yesterday &&
                        notifications.yesterday.map((el, i) => {
                            return (
                                <NotificationsComponent
                                    key={i}
                                    notification={el}
                                />
                            );
                        })}

                    {notifications.other &&
                        Object.keys(notifications.other).length > 0 &&
                        Object.keys(notifications?.other).map(
                            (otherDate, i) => {
                                let html = notifications.other[otherDate].map(
                                    (el) => (
                                        <NotificationsComponent
                                            key={el._id}
                                            notification={el}
                                        />
                                    )
                                );

                                return (
                                    <div key={i}>
                                        <h5 className="Pmedium textAlignLeft pTop10">
                                            {otherDate}
                                        </h5>
                                        {html}
                                    </div>
                                );
                            }
                        )}
                </div>
            </div>
        </div>
    );
}

function getUserData(state) {
    return {
        user: state.auth.authData?.data.user,
        company: state.auth.authData?.company,
    };
}

export default connect(getUserData)(Notifications);
