import { RiPushpinFill, RiPushpinLine } from "react-icons/ri";
import Moment from "moment";

const NotesMainPage = ({note, parentEvent, parentEventEdit}) => {

    function dateFormat(date) {
        return Moment(date).format("LT, DD MMM")
    }

	function getInitials(name) {
		let words = name.split(' ');
		return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
	}

   return (
      	<div className="pTop40">
            <p className="Small-Labelnormal colLabelGray textAlignLeft">{dateFormat(note?.createdAt)}</p>
            <div className="verticallyAlignMidJustConStart mBottom10">
                <h5 onClick={() => parentEventEdit(note)} className="cursorPointer P1bold breakWord w90 colSecondaryGreen textAlignLeft mRight20">{note?.header}</h5>
                {note?.pinned ? <RiPushpinFill onClick={() => parentEvent(note._id, false)} size="1.5em" className="cursorPointer mLeftAuto" /> : <RiPushpinLine onClick={() => parentEvent(note._id, true)} size="1.5em" className="cursorPointer" /> }
            </div>
            <p className="Pmedium textAlignLeft mBottom10 breakWord">{note?.text}</p>
        	{ note?.assignedID?.fullName &&
                <div className="verticallyAlignMidJustConStart mBottom40">
                    <div className="verticallyAlignMidJustConStart borderRadius50 mRight5 notesMainPageSubImage">
                        <div className="w100 h100 verticallyAlignMid">
                            { note?.assignedID?.photo ?
                                <img alt="" className="w100 h100" src={note?.assignedID.photo} />
                            :
                                <div className="userInitialsSmall">
                                    <p>
                                        {getInitials(note?.assignedID?.fullName)}
                                    </p>
                                </div>
                            }
						</div>
                    </div>
                    <p className="Small-Label-11 colPrimaryGreen">{note?.serviceID?.name ? (note?.serviceID?.name + ' with') : ''} {note?.assignedID?.fullName}</p>
                </div>
            }
            <div className="borderGrayTopInput w100"></div>
     	</div>
   );
}

export default NotesMainPage;
