import { useEffect, useState } from "react";
import { RiStarFill } from "react-icons/ri";

const ReviewsTitleStar = ({ reviews }) => {
  const [avgRating, setAvgRating] = useState(0);

  useEffect(() => {
    async function getResults() {
      let rating = 0;

      if (reviews && reviews.length > 0) {
        reviews.forEach((el) => {
          rating += parseInt(el.rating);
        });

        rating = Math.round((rating / reviews.length) * 10) / 10;
      }

      setAvgRating(rating);
    }

    getResults();
  }, [reviews]);

  return (
    <div className="verticallyAlignMidJustConStart">
      <RiStarFill
        size="1.5em"
        className="cursorPointer colPrimaryGreen mRight10"
      />
      <h5 className="Pmedium mRight8">{avgRating}</h5>
      <div className="Pmedium">
        ({reviews?.length ? reviews.length : 0} reviews)
      </div>
    </div>
  );
};

export default ReviewsTitleStar;
