import { HiOutlinePencil } from "react-icons/hi";

const BookOfferSessionComponent = ({buttonStatus, text, parentEvent, id}) => {

   return (
      	<div className="mBottom50">
	        <div className="verticallyAlignMidJustConStart mBottom50">
	            <h2 className="H2regular mRight40">Session 1</h2>
	            <h5 className="P1medium mRight40"><mark className="colLabelGray backColTransparent">Date</mark> 9/10/2021</h5>
	            <h5 className="P1medium mRight40"><mark className="colLabelGray backColTransparent">Time</mark> 11:00am</h5>
	            <div className="verticallyAlignMid backColInput borderRadius24 cursorPointer">
	                <HiOutlinePencil size="1.2em" className="colPrimaryGreen mRight12 mLeft20" />
	                <p className="colPrimaryGreen Small-LabelCaps mRight20 pY6">Edit</p>
	            </div>
	        </div>
	        <div className="borderGrayTopInput w100"></div>
	    </div>
   );
}

export default BookOfferSessionComponent;
