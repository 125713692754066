import { Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

function RestrictedNotAcceptedMentorsRoute(props) {
	//If mentor is not yet accepted let him go to route
	if(!props.userData.user.mentorOnboarding.acceptedMentor) {
		return <Outlet />;
	// If mentor is accepted and he is active mentor make him go to mentor home page
	} else if(props.userData.user.mentorOnboarding.activeMentor) {
		return <Navigate to="/OnboardingMentor12WelcomePage" />;
	//If mentor is accepted, but he is not active mentor, make him go to the page he did not complete
	} else if(!props.userData.user.mentorOnboarding.welcomeCompleted) {
		return <Navigate to="/OnboardingMentor6" />;
	} else if(!props.userData.user.mentorOnboarding.firstServiceCompleted) {
		return <Navigate to="/OnboardingMentor7CreateYourFirstSession" />;
	} else if(!props.userData.user.mentorOnboarding.availabilityCompleted) {
		return <Navigate to="/OnboardingMentor8SetAvailability" />;
	} else if(!props.userData.user.mentorOnboarding.paymentDetailsCompleted) {
		return <Navigate to="/OnboardingMentor9PayoutMethod" />;
	}
};

function getUser(state) {
	return {
		userData: {
			user : state.auth.authData?.data.user
		}
	}
}

export default connect(getUser)(RestrictedNotAcceptedMentorsRoute);