import Moment from "moment";
import React from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsCameraVideo, BsTelephone, BsChatRightText, BsCalendarMinus } from "react-icons/bs";
import { RiChat1Fill, RiStarLine } from "react-icons/ri";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const ViewRescheduleSessionCardType2 = (props) => {

	// type => enum ['upcoming', 'last', 'cancelled']

	const navigate 	= useNavigate();

	const company 	= props.company;
	const date 		= Moment(props.session.date).format("LT") + ' - ' + Moment(props.session.date).add(props.session.serviceID.duration, 'minutes').format("LT");

    function goToMessages() {

        let userID = 0;

        if (props.data.data.user.role === "mentor" ) {
            userID = props.session.clientID._id;
        } else {
            userID = props.session.mentorID._id;
        }

        navigate("/Messages", {
            state : {
                userID
            }
        })
    }

	function getDateFormat(date) {
		return Moment(date).format("ll");
	}

	// function goToMessages() {
	// 	navigate("/Messages", {
	// 		state : {
	// 			userID : props.session.clientID.fullName ? props.session.mentorID : props.session.clientID
	// 		}
	// 	})
	// }

	// console.log(props.session)

	function getInitials(name) {
		let words = name.split(' ');
		return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
	}

   return (
      	<div className="backColElementBackgroundGray ViewRescheduleSessionCardType2Subb1 mBottom40 mRight20 borderRadius12 w440">
      		<div className="w90 mXAuto">
      			<div className="verticallyAlignMidJustConStart ViewRescheduleSessionCardType2Hidden">
					<div className="pRight12 ViewRescheduleSessionCardType2HiddenSub">
		    			<div className="verticallyAlignMid borderRadius50 mY20">
							{props.session.mentorID?.fullName ?
								<div className="w100 h100 verticallyAlignMid">
									{ props.session.mentorID?.photo ?
										<img alt="" className="w100 h100" src={props.session.mentorID.photo} />
									:
										<div className="userInitialsMedium">
											<p>
												{getInitials(props.session.mentorID.fullName)}
											</p>
										</div>
									}
								</div>
							:
								<div className="w100 h100 verticallyAlignMid">
									{ props.session.clientID?.photo ?
										<img alt="" className="w100 h100" src={props.session.clientID.photo} />
									:
										<div className="userInitialsMedium">
											<p>
												{getInitials(props.session.clientID.fullName)}
											</p>
										</div>
									}
								</div>
							}
		    			</div>
		    		</div>
		    		<div className="pLeft12 mLeftAuto">
		    			<div onClick={goToMessages} className="verticallyAlignMid borderRadius50 cursorPointer backColSecondaryGreen reviewsCardImg">
							<RiChat1Fill size="1.5em" className="colSuccessLightGreen"/>
						</div>
					</div>
				</div>
				<div className="verticallyAlignMidJustConStart ViewRescheduleSessionCardType2Subb2 justify-content-start w100">
					<div className="pRight12 ViewRescheduleSessionCardType2Hidden2">
		    			<div className="verticallyAlignMid borderRadius50 mY20">
							{props.session.mentorID?.fullName ?
								<div className="w100 h100 verticallyAlignMid">
									{ props.session.mentorID?.photo ?
										<img alt="" className="w100 h100" src={props.session.mentorID.photo} />
									:
										<div className="userInitialsMedium">
											<p>
												{getInitials(props.session.mentorID.fullName)}
											</p>
										</div>
									}
								</div>
							:
								<div className="w100 h100 verticallyAlignMid">
									{ props.session.clientID?.photo ?
										<img alt="" className="w100 h100" src={props.session.clientID.photo} />
									:
										<div className="userInitialsMedium">
											<p>
												{getInitials(props.session.clientID.fullName)}
											</p>
										</div>
									}
								</div>
							}
		    			</div>
		    		</div>
	    			<div className="ViewRescheduleSessionCardType2Hidden3">
	    				<div className="P1bold textAlignLeft breakWord">{props.session.clientID?.fullName ?? props.session.mentorID.fullName}</div>
	    				<p className="textAlignLeft Small-Labelnormal colLabelGray breakWord">{props.session.serviceID.name}</p>
	    			</div>
	    			{/*<div className="pLeft12 mLeftAuto ViewRescheduleSessionCardType2Hidden2">
		    			<div onClick={goToMessages} className="verticallyAlignMid borderRadius50 cursorPointer backColSecondaryGreen reviewsCardImg">
							<RiChat1Fill size="1.5em" className="colSuccessLightGreen"/>
						</div>
					</div>*/}
	    		</div>
				<div>
					{ props.profilePage &&
						<>
							<div className="verticallyAlignMidJustConStart mBottom4">
								<BsCalendarMinus size="1.2em" className="mRight8 mLeft10 iconsUpSessions"/>
								<p className="Small-LabelCaps">{getDateFormat(props.session.date)}</p>
							</div>
						</>
					}
					<div className="verticallyAlignMidJustConStart mBottom4">
						<AiOutlineClockCircle size="1.2em" className="mRight8 mLeft10 iconsUpSessions" />
						<p className="Small-LabelCaps breakWord textAlignLeft">{date} ({props.session.serviceID.duration} min)</p>
					</div>
					<div className="verticallyAlignMidJustConStart mBottom4 flexWrap">
						{
							props.session.method.map((el, i) => {

								if (el === "video") {
									return (
										<React.Fragment key={i}>
											<BsCameraVideo size="1.2em" className="mRight8 mLeft10 iconsUpSessions"/>
											<p className="Small-LabelCaps">Video Call</p>
										</React.Fragment>
									)
								} else if (el === "audio") {
									return (
										<React.Fragment key={i}>
											<BsTelephone size="1.2em" className="mRight8 mLeft10"/>
											<p className="Small-LabelCaps">Audio</p>
										</React.Fragment>
									)
								} else {
									return (
										<React.Fragment key={i}>
											<BsChatRightText size="1.2em" className="mRight8 mLeft10 iconsUpSessions"/>
											<p className="Small-LabelCaps">Chat</p>
										</React.Fragment>
									)
								}

							})
						}
					</div>

					{(() => {
						if(props.type === "last") {
							<div className="verticallyAlignMidJustConStart mBottom4">
								<RiStarLine size="1.2em" className="mRight8" />
								<p className="Small-LabelCaps">4.8</p>
								<div className="mLeftAuto">
									<p className="Small-Labelregular pY4 backColSuccessGreen colOffWhite pX14 borderRadiusForSocialMediaButtons">Completed</p>
								</div>
							</div>
						} else if(props.type === "cancelled") {
							<div className="verticallyAlignMidJustConStart mBottom4">
								<div className="mLeftAuto">
									<p className="Small-Labelregular pY4 backColLinksAndTitlesBlack colOffWhite pX14 borderRadiusForSocialMediaButtons">Cancelled</p>
								</div>
							</div>
						}
					})()}

				</div>
				<div className="borderGrayTopInput w100 mBottom20 mTop12"></div>
				<div className="verticallyAlignMidSpaceBetween mBottom20">
					{/* <button onClick={() => props.parentEvent(props.session._id, props.type)} style={{color : `${company?.whiteLabel?.color?.buttons?.secondaryText}`, background : `${company?.whiteLabel?.color?.buttons?.secondary}`}} className="verticallyAlignMid w180 borderRadiusForSocialMediaButtons borderNone Pregular pY10 mRight20">View</button> */}
					{(() => {
						// console.log(props.type)
						if(props.type === "Upcoming" || props.type === "") {
							// return <button className="verticallyAlignMid w180 borderRadiusForSocialMediaButtons borderNone Pregular colOffWhite backgroundGradientForButtons pY10">Reschedule</button>
							return <button onClick={() => props.parentEvent(props.session._id, props.type)} style={{color : `${company?.whiteLabel?.color?.buttons?.secondaryText}`, background : `${company?.whiteLabel?.color?.buttons?.secondary}`}} className="verticallyAlignMid w100 borderRadiusForSocialMediaButtons borderNone Pregular pY10">View</button>
						} else {
							if (props.user.role === "client") {
								return (
									<>
										<button onClick={() => props.parentEvent(props.session._id, props.type)} style={{color : `${company?.whiteLabel?.color?.buttons?.secondaryText}`, background : `${company?.whiteLabel?.color?.buttons?.secondary}`}} className="verticallyAlignMid w100 borderRadiusForSocialMediaButtons borderNone Pregular pY10 mRight20">View</button>
										{/* <button style={{color : `${company?.whiteLabel?.color?.buttons?.primaryText}`, backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`}} className="verticallyAlignMid w180 borderRadiusForSocialMediaButtons borderNone Pregular colOffWhite pY10">Book Again</button> */}
									</>
								)
							} else if (props.user.role === "mentor") {
								return (
									<>
										<button onClick={() => props.parentEvent(props.session._id, props.type)} style={{color : `${company?.whiteLabel?.color?.buttons?.secondaryText}`, background : `${company?.whiteLabel?.color?.buttons?.secondary}`}} className="verticallyAlignMid w180 borderRadiusForSocialMediaButtons borderNone Pregular pY10 mRight20">View</button>
										<button onClick={goToMessages} style={{color : `${company?.whiteLabel?.color?.buttons?.primaryText}`, backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`}} className="verticallyAlignMid w180 borderRadiusForSocialMediaButtons borderNone Pregular colOffWhite pY10">Message</button>
									</>
								)
							}
						}
					})()}
				</div>
			</div>
		</div>
   );
}

function getUserData(state) {
	return {
		user 	: state.auth?.authData.data.user ?? {},
		company : state.auth?.authData.company
	}
}

export default connect(getUserData)(ViewRescheduleSessionCardType2);
