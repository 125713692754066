import { BsCheck } from "react-icons/bs";

function ClientSelectCategoriesBlock({props, parentEvent, status}) {

    let borderClassName = "clientNotSelectedCathegoryBackground";
    let pTagClassName   = "backColInputGray colLinksAndTitlesBlack";

    if (status === "checked") {
        borderClassName = "backColLinksAndTitlesBlack";
        pTagClassName = "colOffWhite";
    }

    return (
        <div onClick={() => parentEvent(props['_id'])} style={{backgroungImage: `url(${props.img})`}} className={`${borderClassName} cardForSelectCategories borderRadius12 cursorPointer mRight20 mBottom20 posRel`}>
            {status && <div className="posAbs checkForSeclectCategory borderRadius50 backColSecondaryGreen verticallyAlignMid">
                <BsCheck size="20px" className="colOffWhite" />
            </div>}
            <div className="verticallyAlignMid mXAuto borderRadius50 backColErrorRed mTop20 mBottom12">
                {/* <img className="w100" alt={props.name} src={props.img} /> */}
            </div>
            <p className={`${pTagClassName} Pbold`}>{props.category}</p>
            <p className={`${pTagClassName} Small-Label-cards`}>{props.name}</p>
        </div>
    )
}

export default ClientSelectCategoriesBlock;
