function LogInClientSetYourPaymentMethod() {
    return (
        <div className="LogInClientSetYourPaymentMethod">
            <button>Back Button</button>
            <h4>Add Payment Method</h4>
            <p>Your data is safe with us.</p>
            <div className="verticallyAlignMid">
                <div>
                    <input />
                    <input />
                    <input />
                    <button>Save Card</button>
                </div>
                <div>
                    <img alt="" />
                </div>
            </div>
        </div>
    );
}

export default LogInClientSetYourPaymentMethod;
