// import CloseButton from '../../components/buttons/CloseButton/CloseButton';
import TextareaComponent from "../../components/TextareaComponent/TextareaComponent";
import { BsFillStarFill, BsStar } from "react-icons/bs";
import { useRef, useState } from "react";
import { addReview, createNotification } from "../../api/index.js";
import Rating from "react-rating";
import { connect } from "react-redux";

function YourSessionHasEndedPopUp(props) {
    const company = props.data.company;

    // mentor 	---> props.mentor
    // user 	---> props.user
    // parentEventSession && parentEventReview

    const [reviewRating, setReviewRating] = useState(0);
    const [isTimeRemaining, setIsTimeRemaining] = useState(false);
    console.log("YourSessionHasEndedPopUp(props)");
    console.log(props);
    const reviewTextRef = useRef(null);

    function finishReview() {
        let obj = {
            clientID: props.user._id,
            mentorID: props.mentor._id,
            rating: reviewRating,
            text: reviewTextRef?.current?.value,
            serviceID: props.service._id,
        };

        addReview(obj);

        createNotification({
            userID: props.mentor._id,
            type: "Review",
            typeID: undefined,
            text: `${props.user.fullName} has reviewed your service`,
            fromID: props.user._id,
        });

        // props.parentEventSession(false);
        // props.parentEventReview(true);

        props.parentEvent();
    }

    return (
        <div className="yourSessionHasEndedPopUp">
            <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                <div className="w90 mXAuto">
                    <div className="verticallyAlignMid mTop20 mBottom12">
                        <h2 className="mRightAuto H2medium">
                            Your {company.whiteLabel.texts.label4SessionSingle}{" "}
                            has ended!
                        </h2>
                        {/* <CloseButton parentEvent={props.parentEventSession} /> */}
                    </div>
                    <div className="mBottom40">
                        {props.timeRemaining && !props.isByUser && <p className="Pmedium textAlignLeft mBottom12">
                            Your session was ended earlier by the mentor {props?.mentor?.fullName?.split(" ")[0]}.
                            You can always  book another session in your dashboard.
                        </p>}
                        {props.timeRemaining && props.isByUser && <p className="Pmedium textAlignLeft mBottom12">
                            Your session was ended earlier by the user {props?.user?.fullName?.split(" ")[0]}.
                            You can always  book another session in your dashboard.
                        </p>}
                        <p className="Pmedium textAlignLeft mBottom12">
                            How did{" "}
                            <span>
                                {props?.mentor?.fullName?.split(" ")[0]}
                            </span>{" "}
                            do?
                        </p>
                        <div className="verticallyAlignMidJustConStart">
                            <Rating
                                emptySymbol={
                                    <BsStar
                                        size="2em"
                                        className="colPrimaryGreen mRight10 cursorPointer"
                                    />
                                }
                                fullSymbol={
                                    <BsFillStarFill
                                        size="2em"
                                        className="colPrimaryGreen mRight10 cursorPointer"
                                    />
                                }
                                onClick={(value) => setReviewRating(value)}
                                initialRating={reviewRating}
                            />
                        </div>
                    </div>
                    <div className="mBottom30">
                        <p className="Pmedium textAlignLeft mBottom12">
                            Review (optional)
                        </p>
                        <TextareaComponent ref={reviewTextRef} rows="3"/>
                    </div>
                    <p className="Small-Labelnormal textAlignLeft">Mentor</p>
                    <div className="verticallyAlignMidJustConStart mBottom30">
                        <div className="verticallyAlignMid borderRadius50 mRight12">
                            <img
                                alt=""
                                className="reviewsCardImg2"
                                src="https://i.ibb.co/fD1yxzd/img.png"
                            />
                        </div>
                        <div className="P1bold textAlignLeft">
                            {props.mentor.fullName}
                        </div>
                        <button
                            onClick={finishReview}
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            className="verticallyAlignMid w180 borderRadius12 borderNone Pregular colOffWhite pY10 mLeftAuto"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getUser(state) {
    return {
        data: state.auth.authData,
    };
}

export default connect(getUser)(YourSessionHasEndedPopUp);
