import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect } from "react";
import { createStripePaymentIntent } from "../../api/index.js";
// import { useNavigate } from "react-router-dom";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

function PaymentForm({ parentEvent, check, dataForm, setParentSessionID }) {
  //   const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    async function getData() {
      if (check) {
        // console.log("druga func");

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        });

        if (!error) {
          try {
            const { id } = paymentMethod;
            const response = await createStripePaymentIntent({
              amount: dataForm.session.actualPrice,
              id,
              data: dataForm,
            });

            setParentSessionID(response.data.data._id);

            // console.log({
            //   amount: 42,
            //   id,
            //   data: dataForm,
            // });

            if (response.status === 201) {
              // console.log("Successful payment");
              parentEvent(true);

              // navigate('/ClientConfirmSessionThankYou', {
              //     state : {
              //         id :  response.data.data._id
              //     }
              // });
            }
          } catch (error) {
            // console.log("Error", error);
            parentEvent(error);
          }
        } else {
          // console.log(error.message);
          parentEvent(error);
        }
      }
    }

    getData();
  }, [check]);

  return (
    <>
      <form className="w100 p-3 backColSecondaryGreen">
        <fieldset className="FormGroup">
          <div className="FormRow">
            <CardElement options={CARD_OPTIONS} />
          </div>
        </fieldset>
      </form>
    </>
  );
}

export default PaymentForm;
