// import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mentorOnboarding } from "../../actions/auth.js";
import { connect } from "react-redux";

function OnboardingMentor6(props) {
    const company = props.data.company;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (!company?._id || props.data.data.user._id) {
        navigate("/LogIn");
    }

    const formData = { setOnboardingComplete: true };

    function goToNextPage(e) {
        e.preventDefault();
        dispatch(mentorOnboarding(formData, navigate));
    }

    return (
        <div className="OnboardingMentor6">
            <div className="w80 mXAuto mY70 backColPrimaryLight borderRadius24">
                <div className="verticallyAlignMidJustConStart OnboardingMentor5Subb mY70 w80 mXAuto">
                    <div className="w50 OnboardingMentor5Subb1">
                        <h5 className="P1medium textAlignLeft">Congrats!</h5>
                        <h3 className="H2medium textAlignLeft mRight20">
                            You've been accepted
                        </h3>
                        <div className="mY20 loginStartingPagesImg2">
                            {company.whiteLabel.images?.accepted ? (
                                <img
                                    alt="Welcome"
                                    className="welcomeImg"
                                    src={company.whiteLabel.images?.accepted}
                                />
                            ) : (
                                <img
                                    alt="Welcome"
                                    className="welcomeImg"
                                    src="https://i.ibb.co/fD1yxzd/img.png"
                                />
                            )}
                        </div>
                        <p className="textAlignLeft Pmedium mBottom20 mRight20">
                            Please continue with setting up your account.
                        </p>
                        <div className="verticallyAlignMidJustConStart">
                            <button
                                onClick={goToNextPage}
                                style={{
                                    color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                    backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                }}
                                className="borderRadius12 borderNone  mBottom30 P1regular colOffWhite w180 pY10"
                            >
                                Finish
                            </button>
                        </div>
                    </div>
                    <div className="w50 mY50 loginStartingPagesImg">
                        {company.whiteLabel.images?.accepted ? (
                            <img
                                alt="Welcome"
                                className="welcomeImg"
                                src={company.whiteLabel.images?.accepted}
                            />
                        ) : (
                            <img
                                alt="Welcome"
                                className="welcomeImg"
                                src="https://i.ibb.co/fD1yxzd/img.png"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

function getUser(state) {
    return {
        data: state.auth.authData,
    };
}

export default connect(getUser)(OnboardingMentor6);
