import BookOfferSessionComponent from "../../components/BookOfferSessionComponent/BookOfferSessionComponent";

function ClientBookOffer() {
    return (
        <div className="ClientBookOffer">
            <div className="mTop70 mBottom70 w80 mXAuto">
                <div className="verticallyAlignMidJustConStart mBottom50">
                    <button>Back Arrow</button>
                    <div>
                        <h2 className="H2medium textAlignLeft">Book Offer</h2>
                        <p className="textAlignLeft Pregular">
                            You have <b>3 sessions</b> with Amanda.
                        </p>
                    </div>
                </div>
                <BookOfferSessionComponent />
                <BookOfferSessionComponent />
                <BookOfferSessionComponent />
                <button className="verticallyAlignMid borderRadius12 borderNone Pmedium colOffWhite backgroundGradientForButtons pLeft60 pRight60 pY14">
                    Book Session
                </button>
            </div>
        </div>
    );
}

export default ClientBookOffer;
