import React, { useState, useEffect, useRef } from "react";
import * as api from "../../api/index.js";
import MUIDataTable from "mui-datatables";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
// import { useNavigate } from "react-router-dom";
import CloseButton from "../../components/buttons/CloseButton/CloseButton.js";
import { RiArrowDownSLine } from "react-icons/ri";
import AdminCompany from "./../AdminCompany/AdminCompany";
import Moment from "moment";
import { Datepicker, setOptions } from "@mobiscroll/react";

function Admin(props) {
    setOptions({
        theme: "ios",
        themeVariant: "light",
    });

    // const [formData, setFormData] = useState({});
    // const [allUsers, setAllUsers] = useState({});
    // const firstRender = useRef(true);

    const [table, setTable] = useState("companies");
    const [popUp, setPopUp] = useState(false);

    // const [appliedMentors, setAppliedMentors] = useState([]);
    const [value, setValue] = useState(0);

    // const handleSubmitGetAllUsers = async (e) => {
    //   e.preventDefault();

    //   try {
    //     const { data } = await api.getUsers("");

    //     console.log(data);

    //     // navigate('/MOProfileDetails');
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    // async function getAllUsers() {
    //   try {
    //     const { data } = await api.getUsers("");

    //     console.log(data);
    //     setAllUsers(data);

    //     // navigate('/MOProfileDetails');
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    const [companies, setCompanies] = useState([]);
    const [companyID, setCompanyID] = useState(false);
    const [ownersData, setOwnersData] = useState([]);

    useEffect(() => {
        async function getData() {
            const { data } = await api.getAllCompanies(
                `?populate=ownerID&fields=ownerID.fullName,ownerID.email,ownerID.signupToken,ownerID.signupExpires`
            );
            // const { data } = await api.getAllCompanies(`?populate=ownerID`);

            // console.log(data);

            // return false;

            setCompanies(
                data.data.companies.map((el, i) => {
                    let url = el.subdomain;
                    let active = el.active;
                    let ownerName = el?.ownerID?.fullName ?? "";

                    el.owner = ownerName;
                    el.subdomain = url;
                    el.url = (
                        <p
                            className="textAlignLeft w100 cursorPointer"
                            onClick={() => goToCompanyPage(el._id)}
                        >
                            {url}.mentrx.io
                        </p>
                    );

                    if (el.ready) {
                        el.activeHTML = (
                            <input
                                key={i}
                                type="checkbox"
                                onChange={(e) => {
                                    changeCompanyStatus(e, el);
                                    // console.log(el);
                                }}
                                defaultChecked={active}
                            />
                        );
                    } else {
                        el.activeHTML = "Company isn't ready yet";
                    }

                    return el;
                })
            );

            setOwnersData(
                data.data.companies.map((el) => {
                    let url = el.subdomain;
                    let ownerName = el?.ownerID?.fullName ?? "";
                    let email = el?.ownerID?.email ?? "";
                    let signupToken = el?.ownerID?.signupToken;
                    // let signupExpires = el?.ownerID?.signupExpires;

                    el.name = ownerName;
                    el.email = email;
                    el.company = (
                        <p
                            className="textAlignLeft w100 cursorPointer"
                            onClick={() => goToCompanyPage(el._id)}
                        >
                            {url}.mentrx.io
                        </p>
                    );
                    if (!signupToken) {
                        el.activeOwner = <p> Active </p>;
                    } else {
                        el.activeOwner = (
                            <button
                                onClick={() =>
                                    handleSendInvite(ownerName, email, el._id)
                                }
                                className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold"
                            >
                                Send invite
                            </button>
                        );
                    }
                    return el;
                })
            );

            let queryString = `?populate=companyID&filter={"role":{"$ne":"admin"}}`;

            const usersData = await api.getAllUsers(queryString);

            // console.log(usersData);

            usersData.data.data.users.map((el, i) => {
                el.active = (
                    <input
                        key={i}
                        type="checkbox"
                        defaultChecked={el.active}
                        onChange={(e) => userActiveChanged(e, el)}
                    />
                );
                el.companyID = el.companyID.name;

                return el;
            });

            // console.log(usersData);
            setUsers(usersData.data.data.users);
        }

        getData();
        // getAllUsers();
    }, [value]);

    // useEffect(() => {
    //   function checkForNotAppliedMentors() {
    //     let appliedMentors = [];

    //     allUsers?.data?.users?.forEach(function (value, key) {
    //       if (
    //         !value.mentorOnboarding.acceptedMentor &&
    //         value.mentorOnboarding.thankYouCompleted &&
    //         value.role === "mentor"
    //       ) {
    //         appliedMentors.push(value);
    //       }
    //     });
    //     // console.log(appliedMentors);
    //     setAppliedMentors(appliedMentors);
    //   }

    //   if (!firstRender.current) {
    //     checkForNotAppliedMentors();
    //   }
    //   firstRender.current = false;
    // }, [allUsers]);

    // const navigate = useNavigate();

    function goToCompanyPage(id) {
        // console.log(id)
        setCompanyID(id);
        // navigate('/AdminCompany',
        // 	{
        // 		state : {
        // 			companyID : id
        // 		}
        // 	}
        // )
    }

    const companiesColumns = [
        {
            name: "url",
            label: "URL",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "owner",
            label: "Owner",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "activeHTML",
            label: "Active",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const ownersColumn = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "company",
            label: "Company",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "activeOwner",
            label: "Active",
            options: {
                filter: false,
                sort: true,
            },
        },
    ];

    const companiesOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        customToolbar: () => {
            return (
                <React.Fragment>
                    <Tooltip title={"Add Company"}>
                        <IconButton
                            className=""
                            onClick={() => setPopUp("company")}
                        >
                            <AddIcon className="" />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        },
    };

    const ownersOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        customToolbar: () => {
            return (
                <React.Fragment>
                    <Tooltip title={"Add Owner"}>
                        <IconButton
                            className=""
                            onClick={() => setPopUp("owner")}
                        >
                            <AddIcon className="" />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        },
    };

    const companyTextRef = useRef(null);

    async function handleSubmitCompany() {
        // console.log(companyTextRef.current.value)
        if (companyTextRef.current.value !== "") {
            await api.addCompany({
                subdomain: companyTextRef.current.value,
                adminPercentage: ownerPercTextRef.current.value,
                charity,
            });
        }

        setValue((value) => value + 1);
        setPopUp(false);
        setCharity(false);
    }

    const ownerNameTextRef = useRef(null);
    const ownerEmailTextRef = useRef(null);
    const ownerCompanyTextRef = useRef(null);
    const ownerPercTextRef = useRef(null);

    const [businessDateType, setBusinessDateType] = useState("today");
    const [businessTab, setBusinessTab] = useState(false);
    const [businessInitialData, setBusinessInitialData] = useState({});

    const [revenuePeaks, setRevenuePeaks] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const [sessionsData, setSessionsData] = useState([]);
    const [incomeData, setIncomeData] = useState([]);
    const [mentorsData, setMentorsData] = useState([]);
    const [clientsData, setClientsData] = useState([]);
    const [users, setUsers] = useState([]);

    async function handleSubmitOwner() {
        await api.signUp({
            fullName: ownerNameTextRef.current.value,
            email: ownerEmailTextRef.current.value,
            companyID: ownerCompanyTextRef.current.value,
            // percentage	: ownerPercTextRef.current.value,
            role: "owner",
        });

        setValue((value) => value + 1);
        setPopUp(false);
    }

    async function handleSendInvite(fullName, email, companyID) {
        const data = await api.signUp({
            invite: true,
            fullName,
            email,
            companyID,
            role: "owner",
        });

        if (data) {
            showPopUp("success");
            setTimeout(() => {
                hidePopUp();
            }, "1200");
        } else {
            showPopUp("error");
            setTimeout(() => {
                hidePopUp();
            }, "1200");
        }

        setValue((value) => value + 1);
    }

    function showPopUp(type) {
        props.statusPopUp(type);
    }

    function hidePopUp() {
        props.statusPopUp(false);
    }

    function changeBusinessDate(type) {
        setBusinessDateType(type);
        setBusinessTab(false);
    }

    //Numbers presentation and rounding
    // const COUNT_FORMATS = [
    //   {
    //     // 0 - 999
    //     letter: "",
    //     limit: 1e3,
    //   },
    //   {
    //     // 1,000 - 999,999
    //     letter: "K",
    //     limit: 1e6,
    //   },
    //   {
    //     // 1,000,000 - 999,999,999
    //     letter: "M",
    //     limit: 1e9,
    //   },
    //   {
    //     // 1,000,000,000 - 999,999,999,999
    //     letter: "B",
    //     limit: 1e12,
    //   },
    //   {
    //     // 1,000,000,000,000 - 999,999,999,999,999
    //     letter: "T",
    //     limit: 1e15,
    //   },
    // ];

    // Format Method:
    // function formatCount(value) {
    // 	const format = COUNT_FORMATS.find(format => (value < format.limit));

    // 	value = (1000 * value / format.limit);
    // 	value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

    // 	return (value + format.letter);
    // }

    // Only on first Load, get data for business Tab
    useEffect(() => {
        async function getData() {
            // console.log("setting initial business data using: ", businessDateType);

            let dateFrom;
            let dateTo = Moment().format("LLLL");
            dateTo = new Date(dateTo).toISOString();

            if (businessDateType === "today") {
                dateFrom = Moment().format("LL");
                dateFrom = new Date(dateFrom).toISOString();
            } else if (businessDateType === "week") {
                dateFrom = Moment().startOf("week").add(1, "days");
                dateFrom = new Date(dateFrom).toISOString();
            } else if (businessDateType === "month") {
                dateFrom = Moment().startOf("month");
                dateFrom = new Date(dateFrom).toISOString();
            } else if (businessDateType === "year") {
                dateFrom = Moment().startOf("year");
                dateFrom = new Date(dateFrom).toISOString();
            } else if (businessDateType === "all") {
                dateFrom = Moment().subtract(10, "years").format("LL");
                dateFrom = new Date(dateFrom).toISOString();
            } else if (businessDateType === "custom") {
                return false;
            } else {
                dateFrom = Moment("2011-07-12").format("LL");
                dateFrom = new Date(dateFrom).toISOString();
            }

            const queryString = `?dateFrom=${dateFrom}&dateTo=${dateTo}`;

            const data = await api.getBusinessInitialData(queryString);
            // console.log(data.data.data);
            setBusinessInitialData(data.data.data);

            // queryString = `?filter={"role":{"$ne":"admin"}}`;

            // const usersData = await api.getAllUsers(queryString);

            // console.log(usersData)

            // usersData.data.data.users.map((el) => {
            // 	console.log(el.active)
            // 	el.active = (<input type="checkbox" defaultChecked={el.active} onChange={(e) => userActiveChanged(e, el)} />)

            // 	return el;
            // })

            // // console.log(usersData);
            // setUsers(usersData.data.data.users);
        }

        getData();
    }, [businessDateType]);

    async function userActiveChanged(el, user) {
        // console.log(user);

        // const { data } = await api.updateUser(user._id, {
        await api.updateUser(user._id, {
            active: el.target.checked,
        });

        let queryString = `?populate=companyID&filter={"role":{"$ne":"admin"}}`;

        const usersData = await api.getAllUsers(queryString);

        // console.log(usersData);

        usersData.data.data.users.map((el) => {
            el.active = (
                <input
                    type="checkbox"
                    defaultChecked={el.active}
                    onChange={(e) => userActiveChanged(e, el)}
                />
            );
            el.companyID = el.companyID.name;

            return el;
        });
    }

    const dateRangeRef = useRef(null);

    async function getAllBusinessData(parameter) {
        let dateFrom;
        let dateTo = Moment().format("LLLL");
        dateTo = new Date(dateTo).toISOString();

        if (businessDateType === "today") {
            dateFrom = Moment().format("LL");
            dateFrom = new Date(dateFrom).toISOString();
        } else if (businessDateType === "week") {
            dateFrom = Moment().startOf("week").add(1, "days");
            dateFrom = new Date(dateFrom).toISOString();
        } else if (businessDateType === "month") {
            dateFrom = Moment().startOf("month");
            dateFrom = new Date(dateFrom).toISOString();
        } else if (businessDateType === "year") {
            dateFrom = Moment().startOf("year");
            dateFrom = new Date(dateFrom).toISOString();
        } else if (businessDateType === "all") {
            dateFrom = Moment().subtract(10, "years").format("LL");
            dateFrom = new Date(dateFrom).toISOString();
        } else if (businessDateType === "custom") {
            let range = customDate.valueText.split(" - ");

            dateFrom = new Date(range[0]).toISOString();
            dateTo = new Date(range[1]).toISOString();
        } else {
            dateFrom = Moment("2011-07-12").format("LL");
            dateFrom = new Date(dateFrom).toISOString();
        }

        const { data } = await api.getBusinessParameterData(
            parameter,
            `?dateTo=${dateTo}&dateFrom=${dateFrom}`
        );
        // console.log(data);

        setBusinessTab(parameter);

        if (parameter === "revenue") {
            setRevenueData(data.data);
        }

        if (parameter === "sessions") {
            setSessionsData(data.data);
        }

        if (parameter === "income") {
            setIncomeData(data.data);
        }

        if (parameter === "mentors") {
            setMentorsData(data.data);
        }

        if (parameter === "clients") {
            setClientsData(data.data);
        }
    }

    // function changeBusinessDate(type) {
    // 	setBusinessDateType(type)
    // 	setBusinessTab(false)
    // }

    const [customDate, setCustomDate] = useState(false);

    async function changeCustom(el) {
        let range = el.valueText.split(" - ");

        let dateFrom = new Date(range[0]).toISOString();
        let dateTo = new Date(range[1]).toISOString();

        const queryString = `?dateFrom=${dateFrom}&dateTo=${dateTo}`;
        // console.log(queryString)
        // return false

        const data = await api.getBusinessInitialData(queryString);
        // console.log(data.data.data);
        setBusinessInitialData(data.data.data);
    }

    const peaksColumns = [
        {
            name: "peak",
            label: "Peak",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "value",
            label: "Value",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const revenueColumns = [
        {
            name: "date",
            label: "Date",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "company",
            label: "Company",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "revenue",
            label: "Revenue",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "income",
            label: "Income",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "sessions",
            label: "Sessions",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "mentors",
            label: "Mentors",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "clients",
            label: "Clients",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const sessionsBusinessColumns = [
        {
            name: "date",
            label: "Date",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "company",
            label: "Company",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "revenue",
            label: "Revenue",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "income",
            label: "Income",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "duration",
            label: "Duration",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "category",
            label: "Category",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "client",
            label: "Client",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "mentor",
            label: "Mentor",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const mentorsBusinessColumns = [
        {
            name: "mentor",
            label: "Mentor",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "company",
            label: "Company",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "sessions",
            label: "Sessions",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "revenue",
            label: "Revenue",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "income",
            label: "Income",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "payout",
            label: "Payout",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "clients",
            label: "Clients",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const clientsBusinessColumns = [
        {
            name: "client",
            label: "Client",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "company",
            label: "Company",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "sessions",
            label: "Sessions",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "revenue",
            label: "Revenue",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "income",
            label: "Income",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "mentors",
            label: "Mentors",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const peaksOptions = {
        filter: false,
        download: false,
        print: false,
        search: false,
        viewColumns: false,
        selectableRows: "none",
        customFooter: () => null,
    };

    const revenueOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        selectableRows: "none",
    };

    const usersOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
    };

    const usersColumns = [
        {
            name: "companyID",
            label: "Company",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "fullName",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "role",
            label: "Role",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "active",
            label: "Active",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    async function changeCompanyStatus(el, company) {
        // console.log(el.target.checked);
        // console.log(company);
        // console.log(el.target.checked);

        let companyID = company._id;
        // const { data } = await api.updateCompany(companyID, {
        await api.updateCompany(companyID, {
            active: el.target.checked,
        });
    }

    const [charity, setCharity] = useState(false);

    return (
        <div className="adminPage">
            {companyID && (
                <div className="filterMentorPopupWrapper">
                    <AdminCompany id={companyID} parentEvent={setCompanyID} />
                </div>
            )}

            {popUp === "company" && (
                <div className="filterMentorPopupWrapper">
                    <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                        <div className="w90 mXAuto">
                            <div className="verticallyAlignMid mTop40 NewNotePoUpSub1Wrapper">
                                <h2 className="mRightAuto H3medium">
                                    New Company
                                </h2>
                                <CloseButton parentEvent={setPopUp} />
                            </div>
                            <div className="mTop40 NewNotePoUpSub1Wrapper">
                                <label className="w100 textAlignCenter mY10">
                                    Company URL
                                </label>
                                <div className="w100 verticallyAlignMid textAlignCenter">
                                    <span className="mY20"></span>
                                    <input
                                        ref={companyTextRef}
                                        className="w20 textAlignCenter"
                                        type="text"
                                        placeholder="example"
                                    />
                                    <span>.mentrx.io</span>
                                </div>

                                <div className="w100 verticallyAlignMid mTop10">
                                    <label>Percentage of Revenue:</label>
                                    <input
                                        ref={ownerPercTextRef}
                                        required
                                        type="number"
                                        placeholder="percentage"
                                    />
                                    %
                                </div>

                                <div className="w100 mTop10">
                                    <label className="textAlignCenter w100">
                                        Is this a charity company?
                                    </label>
                                    <div className="w100 verticallyAlignMid">
                                        <p>No</p>
                                        <input
                                            onClick={(e) =>
                                                setCharity(e.target.checked)
                                            }
                                            defaultChecked={!charity}
                                            type="radio"
                                            name="charity"
                                            className="mLeft5"
                                        />
                                        <p className="mLeft10">Yes</p>
                                        <input
                                            onClick={(e) =>
                                                setCharity(e.target.checked)
                                            }
                                            defaultChecked={charity}
                                            type="radio"
                                            name="charity"
                                            className="mLeft5"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                onClick={handleSubmitCompany}
                                className="verticallyAlignMid w180 borderRadius12 mBottom40 borderNone Pregular colOffWhite backgroundGradientForButtons pY10 mLeftAuto"
                            >
                                Add Company
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {popUp === "owner" && (
                <div className="filterMentorPopupWrapper">
                    <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                        <div className="w90 mXAuto">
                            <div className="verticallyAlignMid mTop40 NewNotePoUpSub1Wrapper">
                                <h2 className="mRightAuto H3medium">
                                    New Owner
                                </h2>
                                <CloseButton parentEvent={setPopUp} />
                            </div>

                            <div className="w100 verticallyAlignMid mTop10">
                                <label>Name:</label>
                                <input
                                    ref={ownerNameTextRef}
                                    required
                                    type="text"
                                    placeholder="name"
                                />
                            </div>

                            <div className="w100 verticallyAlignMid mTop10">
                                <label>Email:</label>
                                <input
                                    ref={ownerEmailTextRef}
                                    required
                                    type="email"
                                    placeholder="info@mentrx.io"
                                />
                            </div>

                            <h5 className="P1bold textAlignLeft mBottom8 mTop50">
                                Assign To Company
                            </h5>
                            <div className="posRel selectForNewNotesPopUpMainSubb1 mTop20 w30">
                                <select
                                    required
                                    ref={ownerCompanyTextRef}
                                    className="Pregular smallSelectCss pLeft8 textAlignLeft appearanceNone borderNone backColInput pY14 borderRadius12"
                                >
                                    <option value=""> Select </option>
                                    {companies.map((el) => {
                                        return (
                                            <option value={el._id}>
                                                {el.subdomain}
                                            </option>
                                        );
                                    })}
                                </select>
                                <span className="posAbs spanForArrow verticallyAlignMid backColInput borderRadiusSpanSelect">
                                    <RiArrowDownSLine
                                        size="1.5em"
                                        className="mRight10 colLabelGray"
                                    />
                                </span>
                            </div>
                            <button
                                onClick={handleSubmitOwner}
                                className="verticallyAlignMid w180 borderRadius12 mBottom40 borderNone Pregular colOffWhite backgroundGradientForButtons pY10 mLeftAuto"
                            >
                                Add Owner
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className="w100 verticallyAlignMid mTop20">
                <div className="mX10 w20">
                    <button
                        onClick={() => setTable("companies")}
                        className={`w60 pY10 Pregular ${
                            table === "companies"
                                ? "colOffWhite backgroundGradientForButtons"
                                : "colLinksAndTitlesBlack backColPrimaryLight"
                        } h100 borderNone borderRadius12`}
                    >
                        Companies
                    </button>
                </div>
                <div className="mX10 w20">
                    <button
                        onClick={() => setTable("business")}
                        className={`w60 pY10 Pregular  ${
                            table === "business"
                                ? "colOffWhite backgroundGradientForButtons"
                                : "colLinksAndTitlesBlack backColPrimaryLight"
                        } h100 borderNone borderRadius12`}
                    >
                        Business
                    </button>
                </div>
                <div className="mX10 w20">
                    <button
                        onClick={() => setTable("users")}
                        className={`w60 pY10 Pregular  ${
                            table === "users"
                                ? "colOffWhite backgroundGradientForButtons"
                                : "colLinksAndTitlesBlack backColPrimaryLight"
                        } h100 borderNone borderRadius12`}
                    >
                        Users
                    </button>
                </div>
                {/* <div className="mX10 w20">
					<button onClick={() => setTable('other')} className={`w60 pY10 Pregular  ${table === 'other' ? "colOffWhite backgroundGradientForButtons" : "colLinksAndTitlesBlack backColPrimaryLight"} h100 borderNone borderRadius12`}>Other?</button>
				</div> */}
            </div>

            {table === "companies" && (
                <div className="w100 verticallyAlignMid mTop20">
                    <div className="w50 mTop20">
                        <MUIDataTable
                            title={"Companies Table"}
                            data={companies}
                            columns={companiesColumns}
                            options={companiesOptions}
                            className={"w90 mXAuto"}
                        />
                    </div>
                    <div className="w50 mTop20">
                        <MUIDataTable
                            title={"Owners Table"}
                            data={ownersData}
                            columns={ownersColumn}
                            options={ownersOptions}
                            className={"w90 mXAuto"}
                        />
                    </div>
                </div>
            )}

            {table === "business" && (
                <>
                    <div className="w100 mx-auto mTop30 verticallyAlignMid">
                        <div
                            className="btn-group borderSecundaryGreen"
                            role="group"
                            aria-label="Basic example"
                        >
                            <button
                                onClick={() => changeBusinessDate("today")}
                                type="button"
                                className={
                                    businessDateType === "today"
                                        ? "btn backColSecondaryGreen colOffWhite"
                                        : "btn colSecondaryGreen"
                                }
                            >
                                Today
                            </button>
                            <button
                                onClick={() => changeBusinessDate("week")}
                                type="button"
                                className={
                                    businessDateType === "week"
                                        ? "btn backColSecondaryGreen colOffWhite"
                                        : "btn colSecondaryGreen"
                                }
                            >
                                This Week
                            </button>
                            <button
                                onClick={() => changeBusinessDate("month")}
                                type="button"
                                className={
                                    businessDateType === "month"
                                        ? "btn backColSecondaryGreen colOffWhite"
                                        : "btn colSecondaryGreen"
                                }
                            >
                                This Month
                            </button>
                            <button
                                onClick={() => changeBusinessDate("year")}
                                type="button"
                                className={
                                    businessDateType === "year"
                                        ? "btn backColSecondaryGreen colOffWhite"
                                        : "btn colSecondaryGreen"
                                }
                            >
                                This Year
                            </button>
                            <button
                                onClick={() => changeBusinessDate("all")}
                                type="button"
                                className={
                                    businessDateType === "all"
                                        ? "btn backColSecondaryGreen colOffWhite"
                                        : "btn colSecondaryGreen"
                                }
                            >
                                All Time
                            </button>
                            <button
                                onClick={() => changeBusinessDate("custom")}
                                type="button"
                                className={
                                    businessDateType === "custom"
                                        ? "btn backColSecondaryGreen colOffWhite"
                                        : "btn colSecondaryGreen"
                                }
                            >
                                Custom
                            </button>
                            {businessDateType === "custom" && (
                                <Datepicker
                                    controls={["calendar"]}
                                    select="range"
                                    touchUi={true}
                                    inputComponent="input"
                                    ref={dateRangeRef}
                                    inputProps={{
                                        placeholder: "Select custom range",
                                    }}
                                    onChange={(e) => {
                                        changeCustom(e);
                                        setCustomDate(e);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div className="w100 mx-auto mTop30">
                        <div className="w100 mTop15 verticallyAlignMid">
                            <div
                                className="w20"
                                onClick={() => getAllBusinessData("revenue")}
                            >
                                <div className="w100 mY5 verticallyAlignMid">
                                    <h3 className="H3bold">Revenue</h3>
                                </div>
                                <div className="verticallyAlignMid MentorSingleViewImg cursorPointer">
                                    <div className="userInitialsBig">
                                        <p>{businessInitialData.revenue}</p>
                                        {/* <p>{formatCount(543156.22)}</p> */}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="w20"
                                onClick={() => getAllBusinessData("income")}
                            >
                                <div className="w100 mY5 verticallyAlignMid">
                                    <h3 className="H3bold">Income</h3>
                                </div>
                                <div className="verticallyAlignMid MentorSingleViewImg cursorPointer">
                                    <div className="userInitialsBig">
                                        <p>{businessInitialData.income}</p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="w20"
                                onClick={() => getAllBusinessData("sessions")}
                            >
                                <div className="w100 mY5 verticallyAlignMid">
                                    <h3 className="H3bold">Sessions</h3>
                                </div>
                                <div className="verticallyAlignMid MentorSingleViewImg cursorPointer">
                                    <div className="userInitialsBig">
                                        <p>{businessInitialData.sessions}</p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="w20"
                                onClick={() => getAllBusinessData("mentors")}
                            >
                                <div className="w100 mY5 verticallyAlignMid">
                                    <h3 className="H3bold">Mentors</h3>
                                </div>
                                <div className="verticallyAlignMid MentorSingleViewImg cursorPointer">
                                    <div className="userInitialsBig">
                                        <p>{businessInitialData.mentors}</p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="w20"
                                onClick={() => getAllBusinessData("clients")}
                            >
                                <div className="w100 mY5 verticallyAlignMid">
                                    <h3 className="H3bold">Clients</h3>
                                </div>
                                <div className="verticallyAlignMid MentorSingleViewImg cursorPointer">
                                    <div className="userInitialsBig">
                                        <p>{businessInitialData.clients}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {businessTab === "revenue" && (
                            <div className="w100 mTop30">
                                <h2 className="H2bold mTop30 w100">
                                    Revenue Overview
                                </h2>
                                <div className="w90 mXAuto verticallyAlignMid">
                                    <div className="w30 pX20">
                                        <div className="w90 mXAuto mTop20 dataTableHead">
                                            <MUIDataTable
                                                data={revenuePeaks}
                                                columns={peaksColumns}
                                                options={peaksOptions}
                                                className={"w100 mXAuto"}
                                            />
                                        </div>
                                    </div>
                                    <div className="w70 pX20">
                                        <div className="w90 mXAuto mTop20">
                                            <MUIDataTable
                                                title={"Revenue Table"}
                                                data={revenueData}
                                                columns={revenueColumns}
                                                options={revenueOptions}
                                                className={"w100 mXAuto"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {businessTab === "income" && (
                            <div className="w100 mTop30">
                                <h2 className="H2bold mTop30 w100">
                                    Income Overview
                                </h2>
                                <div className="w90 mXAuto verticallyAlignMid">
                                    <div className="w30 pX20">
                                        <div className="w90 mXAuto mTop20 dataTableHead">
                                            {/* <MUIDataTable
											data={peaks}
											columns={peaksColumns}
											options={peaksOptions}
											className={"w100 mXAuto"}
										/> */}
                                        </div>
                                    </div>
                                    <div className="w70 pX20">
                                        <div className="w90 mXAuto mTop20">
                                            <MUIDataTable
                                                title={"Income Table"}
                                                data={incomeData}
                                                columns={revenueColumns}
                                                options={revenueOptions}
                                                className={"w100 mXAuto"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {businessTab === "sessions" && (
                            <div className="w100 mTop30">
                                <h2 className="H2bold mTop30 w100">
                                    Sessions Overview
                                </h2>
                                <div className="w90 mXAuto verticallyAlignMid">
                                    <div className="w30 pX20">
                                        <div className="w90 mXAuto mTop20 dataTableHead">
                                            {/* <MUIDataTable
											data={peaks}
											columns={peaksColumns}
											options={peaksOptions}
											className={"w100 mXAuto"}
										/> */}
                                        </div>
                                    </div>
                                    <div className="w70 pX20">
                                        <div className="w90 mXAuto mTop20">
                                            <MUIDataTable
                                                title={"Sessions Table"}
                                                data={sessionsData}
                                                columns={
                                                    sessionsBusinessColumns
                                                }
                                                options={revenueOptions}
                                                className={"w100 mXAuto"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {businessTab === "mentors" && (
                            <div className="w100 mTop30">
                                <h2 className="H2bold mTop30 w100">
                                    Mentors Overview
                                </h2>
                                <div className="w90 mXAuto verticallyAlignMid">
                                    <div className="w30 pX20">
                                        <div className="w90 mXAuto mTop20 dataTableHead">
                                            {/* <MUIDataTable
											data={peaks}
											columns={peaksColumns}
											options={peaksOptions}
											className={"w100 mXAuto"}
										/> */}
                                        </div>
                                    </div>
                                    <div className="w70 pX20">
                                        <div className="w90 mXAuto mTop20">
                                            <MUIDataTable
                                                title={"Mentors Table"}
                                                data={mentorsData}
                                                columns={mentorsBusinessColumns}
                                                options={revenueOptions}
                                                className={"w100 mXAuto"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {businessTab === "clients" && (
                            <div className="w100 mTop30">
                                <h2 className="H2bold mTop30 w100">Clients</h2>
                                <div className="w90 mXAuto verticallyAlignMid">
                                    <div className="w30 pX20">
                                        <div className="w90 mXAuto mTop20 dataTableHead">
                                            {/* <MUIDataTable
											data={peaks}
											columns={peaksColumns}
											options={peaksOptions}
											className={"w100 mXAuto"}
										/> */}
                                        </div>
                                    </div>
                                    <div className="w70 pX20">
                                        <div className="w90 mXAuto mTop20">
                                            <MUIDataTable
                                                title={"Clients Table"}
                                                data={clientsData}
                                                columns={clientsBusinessColumns}
                                                options={revenueOptions}
                                                className={"w100 mXAuto"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}

            {table === "users" && (
                <div className="w100 mx-auto mTop30 verticallyAlignMidSpace">
                    <div className="w100 verticallyAlignMid mTop20">
                        <div className="w80 mTop20">
                            <MUIDataTable
                                title={"Users Table"}
                                data={users}
                                columns={usersColumns}
                                options={usersOptions}
                                className={"w90 mXAuto"}
                            />
                        </div>
                    </div>
                </div>
            )}

            {/* <form onSubmit={handleSubmitGetAllUsers} className="mTop70">

	      		<button type="submit" className="borderRadius12 borderNone verticallyAlignMidJustConStart mBottom30 P1bold colOffWhite backgroundGradientForButtons pLeft60 pRight60 pTop10 pBottom10">
					Get All Users
				</button>

			</form>

			<form className="mTop30">
				{appliedMentors.map((user, i) => (
					<div key={i} className="verticallyAlignMid">
						<h2> {user.fullName} </h2>
						<p> {user.email} </p>
						<p> {user.biography} </p>
						<p> {user.categories} </p>
					</div>
				))}
			</form> */}
        </div>
    );
}

export default Admin;
