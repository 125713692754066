import { Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

function RestrictedMentorNotPayoutMethodCompletedRoute(props) {
	if(!props.userData.user.mentorOnboarding.welcomeCompleted) {
		return <Navigate to="/OnboardingMentor6" />;
	} else if(!props.userData.user.mentorOnboarding.firstServiceCompleted) {
		return <Navigate to="/OnboardingMentor7CreateYourFirstSession" />;
	} else if(!props.userData.user.mentorOnboarding.availabilityCompleted) {
		return <Navigate to="/OnboardingMentor8SetAvailability" />;
	} else if(!props.userData.user.mentorOnboarding.paymentDetailsCompleted) {
		return <Outlet />;
	// } else {
		// return <Navigate to="/OnboardingMentor9PayoutMethod" />;
	} else {
		return <Outlet />;
	}

	// THERE IS AN ERROR ON THIS PAGE -> If the lines 13 and 14 are uncommented, they will go into an infinite loop
};

function getUser(state) {
	return {
		userData: {
			user : state.auth.authData?.data.user
		}
	}
}

export default connect(getUser)(RestrictedMentorNotPayoutMethodCompletedRoute);
