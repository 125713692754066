import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clientOnboarding } from "../../actions/auth.js";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { BsCameraVideo, BsTelephone } from '@fortawesome/free-solid-svg-icons';
import { BsCameraVideo, BsTelephone, BsChatRightText } from "react-icons/bs";
import ButtonTagsCategories from "../../components/buttons/ButtonTagsCategories/ButtonTagsCategories";
import ClientSelectCategoriesBlock from "../../components/ClientSelectCategoriesBlock/ClientSelectCategoriesBlock";
import { RiArrowLeftSLine } from "react-icons/ri";

import {
    getAllAdditionalCategories,
    getAllCategories,
} from "../../api/index.js";

function ClientSelectCategories(props) {
    const [categories, setCategories] = useState([]);
    const [additionalCategories, setAdditionalCategories] = useState([]);

    const company = props.company;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (!company._id || !props.userID) {
        navigate("/LogIn");
    }

    async function getCategories() {
        try {
            const { data } = await getAllCategories(
                `?companyID=${props.clientCategories.companyID}`
            );
            setCategories(data.data.categories);
        } catch (error) {
            // console.log(error);
        }
    }

    async function getAdditionalCategories() {
        try {
            const { data } = await getAllAdditionalCategories(
                `?companyID=${props.clientCategories.companyID}`
            );
            setAdditionalCategories(data.data.additionalCategories);
        } catch (error) {
            // console.log(error);
        }
    }

    useEffect(() => {
        getCategories();
        getAdditionalCategories();
    }, []);

    const initialState = {
        setSelectCategories: true,
        categories: [],
        communicationMethods: [],
        additionalInterests: [],
        charity: false,
    };

    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        setFormData({
            setSelectCategories: true,
            communicationMethods: props.clientCategories.communicationMethods,
            additionalInterests: props.clientCategories.additionalInterests,
            categories: props.clientCategories.categories,
            charity: company?.charity,
        });
    }, [
        props.clientCategories.communicationMethods,
        props.clientCategories.additionalInterests,
        props.clientCategories.categories,
        company?.charity,
    ]);

    function goToNextPage(e) {
        e.preventDefault();
        dispatch(clientOnboarding(formData, navigate));
    }

    function skipThisPage(e) {
        e.preventDefault();
        dispatch(clientOnboarding(initialState, navigate));
    }

    const categoryChange = (id) => {
        const selectedCategories = formData.categories;
        const index = selectedCategories.indexOf(id);

        if (index === -1) {
            selectedCategories.push(id);
        } else {
            selectedCategories.splice(index, 1);
        }

        setFormData({ ...formData, [categories]: selectedCategories });
    };

    const communicationChange = (val) => {
        const communicationMethods = formData.communicationMethods;
        const index = communicationMethods.indexOf(val);

        if (index === -1) {
            communicationMethods.push(val);
        } else {
            communicationMethods.splice(index, 1);
        }

        setFormData({ ...formData, communicationMethods });
    };

    function handleButtonClick(id) {
        const additionalInterests = formData.additionalInterests;

        if (additionalInterests.indexOf(id) > -1) {
            const index = additionalInterests.indexOf(id);
            if (index > -1) {
                additionalInterests.splice(index, 1);
            }
        } else {
            if (additionalInterests.length > 5) {
                alert("You selected maximum number of Additional Interests!");
            } else {
                additionalInterests.push(id);
            }
        }

        setFormData({ ...formData, additionalInterests });
    }

    return (
        <div className="ClientSelectCategories">
            <div className="backColPrimaryLight borderRadius24 ClientSelectCategoriesSubb3 mTop50 mBottom70 w80 mXAuto">
                <div className="verticallyAlignMidJustConStart ClientSelectCategoriesSubb1 w80 mXAuto pTop40">
                    {/* <div className="posRel mRight20">
            <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
              <RiArrowLeftSLine size="2em" className="colPrimaryGreen" />
            </button>
          </div> */}
                    <h2 className="H2medium textAlignLeft">
                        Select the categories you're interested in:
                    </h2>
                </div>
                <div className="mTop40 w80 mXAuto">
                    <div className="">
                        <p className="textAlignLeft P1bold mBottom12">
                            Main category
                        </p>
                        <p className="textAlignLeft Pmedium colLabelGray mBottom8">
                            Select all that apply
                        </p>
                        <div className="verticallyAlignMidJustConStart flexWrap ClientSelectCategoriesSubb2">
                            {categories.map((cat, i) => {
                                let status = "";

                                if (
                                    formData.categories.indexOf(cat["_id"]) !==
                                    -1
                                ) {
                                    status = "checked";
                                }

                                return (
                                    <ClientSelectCategoriesBlock
                                        key={i}
                                        props={cat}
                                        parentEvent={categoryChange}
                                        status={status}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="mTop40">
                        <p className="textAlignLeft P1bold mBottom12">
                            Choose your preferred communication method
                        </p>
                        <p className="textAlignLeft Pmedium colLabelGray mBottom8">
                            Select all that apply
                        </p>
                        <div className="verticallyAlignMidJustConStart flexWrap">
                            {(() => {
                                if (
                                    formData.communicationMethods.indexOf(
                                        "video"
                                    ) !== -1
                                ) {
                                    return (
                                        <div
                                            onClick={() =>
                                                communicationChange("video")
                                            }
                                            className="verticallyAlignMid communcationOptions cursorPointer backColLinksAndTitlesBlack borderRadius12 mRight30"
                                        >
                                            <BsCameraVideo
                                                size="2em"
                                                className="colPlaceHolderGray mLeft16"
                                            />
                                            <p className="Pbold colPlaceHolderGray mLeft8 pY20 mRight16">
                                                Video Sessions
                                            </p>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            onClick={() =>
                                                communicationChange("video")
                                            }
                                            className="verticallyAlignMid communcationOptions cursorPointer borderRadius12 mRight30"
                                        >
                                            <BsCameraVideo
                                                size="2em"
                                                className="mLeft16"
                                            />
                                            <p className="Pbold mLeft8 pY20 mRight16">
                                                Video Sessions
                                            </p>
                                        </div>
                                    );
                                }
                            })()}
                            {(() => {
                                if (
                                    formData.communicationMethods.indexOf(
                                        "audio"
                                    ) !== -1
                                ) {
                                    return (
                                        <div
                                            onClick={() =>
                                                communicationChange("audio")
                                            }
                                            className="verticallyAlignMid communcationOptions cursorPointer backColLinksAndTitlesBlack borderRadius12 mRight30"
                                        >
                                            <BsTelephone
                                                size="2em"
                                                className="colPlaceHolderGray mLeft16"
                                            />
                                            <p className="Pbold colPlaceHolderGray mLeft8 pY20 mRight16">
                                                Audio Sessions
                                            </p>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            onClick={() =>
                                                communicationChange("audio")
                                            }
                                            className="verticallyAlignMid communcationOptions cursorPointer borderRadius12 mRight30"
                                        >
                                            <BsTelephone
                                                size="2em"
                                                className="mLeft16"
                                            />
                                            <p className="Pbold mLeft8 pY20 mRight16">
                                                Audio Sessions
                                            </p>
                                        </div>
                                    );
                                }
                            })()}
                            {(() => {
                                if (
                                    formData.communicationMethods.indexOf(
                                        "chat"
                                    ) !== -1
                                ) {
                                    return (
                                        <div
                                            onClick={() =>
                                                communicationChange("chat")
                                            }
                                            className="verticallyAlignMid communcationOptions cursorPointer backColLinksAndTitlesBlack borderRadius12"
                                        >
                                            <BsChatRightText
                                                size="2em"
                                                className="colPlaceHolderGray mLeft16"
                                            />
                                            <p className="Pbold colPlaceHolderGray mLeft8 pY20 mRight16">
                                                Chat Sessions
                                            </p>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            onClick={() =>
                                                communicationChange("chat")
                                            }
                                            className="verticallyAlignMid communcationOptions cursorPointer borderRadius12"
                                        >
                                            <BsChatRightText
                                                size="2em"
                                                className="mLeft16"
                                            />
                                            <p className="Pbold mLeft8 pY20 mRight16">
                                                Chat Sessions
                                            </p>
                                        </div>
                                    );
                                }
                            })()}
                        </div>
                    </div>
                    <div className="mTop40">
                        <p className="textAlignLeft P1bold mBottom12">
                            Add any additional interests
                        </p>
                        <p className="textAlignLeft Pmedium colLabelGray mBottom8">
                            Select up to 6 options
                        </p>
                        <div className="verticallyAlignMidJustConStart flexWrap">
                            {additionalCategories.map((item, i) => {
                                let itemStatus = "";

                                if (
                                    formData.additionalInterests.indexOf(
                                        item["_id"]
                                    ) !== -1
                                ) {
                                    itemStatus = "checked";
                                }

                                return (
                                    <ButtonTagsCategories
                                        key={i}
                                        buttonStatus={itemStatus}
                                        text={item.name}
                                        id={item["_id"]}
                                        parentEvent={handleButtonClick}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="verticallyAlignMidJustConEnd mTop20">
                        <button
                            onClick={skipThisPage}
                            className="mBottom30 borderNone pX20 mRightAuto backColTransparent Pmedium colPrimaryGreen"
                        >
                            Skip
                        </button>
                        <button
                            onClick={goToNextPage}
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            className="verticallyAlignMid borderRadius12 borderNone mBottom30 Pmedium colOffWhite pLeft60 pRight60 pTop10 pBottom10"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getClientsCategories(state) {
    return {
        clientCategories: {
            communicationMethods:
                state.auth.authData?.data.user.selectCategories
                    .communicationMethods || [],
            additionalInterests:
                state.auth.authData?.data.user.selectCategories
                    .additionalInterests || [],
            categories:
                state.auth.authData?.data.user.selectCategories.categories ||
                [],
            companyID: state.auth.authData?.company._id || [],
        },
        company: state.auth.authData.company,
        userID: state.auth.authData?.data.user._id,
    };
}

export default connect(getClientsCategories)(ClientSelectCategories);
