function ClientSettingsPanelPaymentAndPayouts4() {
    return (
        <div className="ClientSettingsPanelPaymentAndPayouts4">
            <div className="verticallyAlignMid">
                <button>Back Button</button>
                <h2>Payment and Payouts</h2>
            </div>
            <h4>Stripe in AU$</h4>
            <p>Connect your Stripe account</p>
            <p>Payment in 4-6 hours</p>
            <p>May include fees</p>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
            </p>
            <button>Login With Stripe</button>
        </div>
    );
}

export default ClientSettingsPanelPaymentAndPayouts4;
