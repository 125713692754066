import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";

function RestrictedOwnersRoute(props) {
  // console.log('RestrictedOwnersRoute', props.userData.user.role);
  return props.userData.user.role === "owner" ||
    props.userData.user.role === "director" ? (
    <Outlet />
  ) : (
    <Navigate to="/LogIn" />
  );
}

function getUser(state) {
  return {
    userData: {
      user: state.auth.authData?.data.user,
    },
  };
}

export default connect(getUser)(RestrictedOwnersRoute);
