import { useEffect, useState } from "react";
import Moment from "moment";
import { connect } from "react-redux";

const MessageContactsComponent = (props) => {

	const [lastMessage, setLastMessages] = useState({text: "" , time : ""});
	let className = "";

	useEffect(() => {
		let text 	= props.data.message;
		let time 	= props.data.createdAt;
		let timeNow = new Date();
		let seconds = Moment(timeNow).diff(Moment(time), "seconds");

		if (seconds > 59) {
			let minutes = Moment(timeNow).diff(Moment(time), "minutes");
			if (minutes > 59) {
				let hours = Moment(timeNow).diff(Moment(time), "hours");
				if (hours > 23) {
					let days = Moment(timeNow).diff(Moment(time), "days");
					time = days + " d ago";
				} else {
					time = hours + " h ago";
				}
			} else {
				time = minutes + " m ago";
			}
		} else {
			time = seconds + " s ago";
		}

		setLastMessages(prevState => ({text, time}));
	},[props.data])

	if (props.user === props.data.id) {
		className = "backColPrimaryLight";
	}

	function getInitials(name) {
		let words = name.split(' ');
		return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
	}

   return (
      	<div onClick={() => props.parentEvent(props.data._id)} className={`mBottom20 ${className} borderRadius12 cursorPointer backColOffWhite boxShadowForSearchLanding`}>
        	<div className="verticallyAlignMidJustConStart pY14">
        		<div className="pRight1 mRight10 mLeft10">

	    			<div className="verticallyAlignMidStretch messageImg borderRadius50">
						<div className="w100 h100 verticallyAlignMid">
						{ props.data?.photo ?
                            <img alt="" className="w100 h100" src={props.data.photo} />
                        :
							<div className="userInitialsMedium">
								<p>
									{props?.data?.fullName && getInitials(props.data.fullName)}
								</p>
							</div>
						}
						</div>
	    			</div>
	    		</div>
    			<div className="posRel pRight20">
    				<div className="textAlignLeft Pbold">{props.data.fullName}</div>
					{(props.data.read && props.data.id === props.currentUser._id) &&
						<span className="pLeft10 posAbs redDotForMessages borderRadius50 backColRed"></span>
					}
    			</div>
    			<div className="verticallyAlignMidStretch mLeftAuto Pregular colPlaceHolderGray mRight10">{lastMessage.time}</div>
    		</div>
    		<p className="textAlignLeft Small-Label-cards colLabelGray breakWord w90 mXAuto mBottom10">{lastMessage.text}</p>
		</div>
   );
}

function getUserData(state) {
	return {
		currentUser : state.auth.authData?.data.user || {}
	}
}

export default connect(getUserData)(MessageContactsComponent);
