import { VscTriangleRight } from "react-icons/vsc";
import Moment from'moment';

const MentorHomeLastSessionsComponent = ({session, parentEvent}) => {

	let date 	= Moment(session.date).format("LLL");
	date 		= Moment(date).format("DD MMM: h:mm A");

	function getInitials(name) {
		let words = name.split(' ');
		return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
	}

   return (
   		<div>
	      	<div className="verticallyAlignMidJustConStart">
	      		<div className="pRight1">
					<div className="borderRadius50 mRight10">
					{ session.clientID.photo ?
						<img alt="" className="w100 h100" src={session.clientID.photo} />
					:
						<div className="userInitialsSmall">
							<p>
								{getInitials(session.clientID.fullName)}
							</p>
						</div>
					}
					</div>
				</div>
				<div className="mRightAuto">
					<h5 className="Pbold breakWord nameRecommendedMentor textAlignLeft">{session.clientID.fullName}</h5>
					<p className="textAlignLeft Small-Labelnormal colLabelGray">{date}</p>
				</div>
				<div onClick={() => parentEvent(session._id)} className="verticallyAlignMid fakeView2">
					<h5 className="Small-Labelnormal colPrimaryGreen mRight10 mLeft8 mY8">View</h5>
					<VscTriangleRight size="0.8em" className="cursorPointer colPrimaryGreen mRight8"/>
				</div>
			</div>
			<div onClick={() => parentEvent(session._id)} className="verticallyAlignMid fakeView1">
				<h5 className="Small-Labelnormal colPrimaryGreen mRight10 mLeft8 mY8">View</h5>
				<VscTriangleRight size="0.8em" className="cursorPointer colPrimaryGreen mRight8"/>
			</div>
			<div className="borderGrayTopInput w100 mY14"></div>
		</div>
   );
}

export default MentorHomeLastSessionsComponent;
