import { Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

function RestrictedClientsRoute(props) {
	//console.log('RestrictedClientsRoute', props.userData.user.role);
	return props.userData.user.role === 'client' ? <Outlet /> : <Navigate to="/LogIn" />;
};

function getUser(state) {
	// console.log('RestrictedClientsRoute', state.auth.authData?.data.user);
	return {
		userData: {
			user : state.auth.authData?.data.user
		}
	}
}

export default connect(getUser)(RestrictedClientsRoute);