import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getSession } from "../../api/index.js";
import Moment from "moment";

function MentorFlowClientProfile() {
    const location = useLocation();
    const navigate = useNavigate();

    if (!location.state.id) {
        navigate("/LogIn");
    }

    const initialState = {
        mentorID: "",
        clientID: "",
        userID: "",
        serviceID: "",
    };

    const duration = 60;

    const [session, setSession] = useState(initialState);
    const [date, setDate] = useState({ time: "", date: "" });

    useEffect(() => {
        async function getResults() {
            const { data } = await getSession(location.state.id);

            setSession(data.data.session);

            let timeForState =
                Moment(data.data.session.date).format("LT") +
                " - " +
                Moment(data.data.session.date)
                    .add(duration, "minutes")
                    .format("LT");
            let dateForState = Moment(data.data.session.date).format("DD MMM");
            let objForState = {
                time: timeForState,
                date: dateForState,
            };

            setDate(objForState);
        }
        getResults();
    }, [location.state.id]);

    return (
        <div className="MentorFlowClientProfile">
            <div className="verticallyAlignMid">
                <div>
                    <h4>Amand Jones</h4>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </p>
                    <div>
                        <h4>Interests</h4>
                        <div className="verticallyAlignMid">
                            <div>Tarot</div>
                            <div>Breathwork</div>
                            <div>Yoga</div>
                        </div>
                    </div>
                    <div>
                        <h4>Communication</h4>
                        <div className="verticallyAlignMid">
                            <div>
                                <img alt="" />
                            </div>
                            <div>
                                <img alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <img alt="" />
                    </div>
                    <button>Send Offer</button>
                </div>
            </div>
            <div className="verticallyAlignMid">
                {(() => {
                    if (session.status && session.status === "scheduled") {
                        return <div>Upcoming</div>;
                    } else if (
                        session.status &&
                        session.status === "completed"
                    ) {
                        return <div>Past</div>;
                    } else if (
                        session.status &&
                        session.status === "cancelled"
                    ) {
                        return <div>Canceled</div>;
                    }
                })()}
            </div>

            {/*COMPONENT FOR ALL PAGES THAT HAVE THIS SAME CARD LAYOUT START*/}
            <div>
                <div className="verticallyAlignMidJustConStart">
                    <div>
                        <p>Date</p>
                        <h4>{date.date}</h4>
                    </div>
                    <div>
                        <p>Session</p>
                        <h4>Breathwork</h4>
                    </div>
                </div>
                <div className="verticallyAlignMidJustConStart">
                    <div>
                        <img alt="" />
                    </div>
                    <p>
                        {date.time} ({duration} min)
                    </p>
                </div>
                <div className="verticallyAlignMidJustConStart">
                    <div>
                        <img alt="" />
                    </div>
                    <p>Video Call</p>
                </div>
                <div className="verticallyAlignMid mXAuto">
                    <button>View</button>
                    <button>Reschedule</button>
                </div>
            </div>
            {/*COMPONENT FOR ALL PAGES THAT HAVE THIS SAME CARD LAYOUT END*/}
            <h4>Notes</h4>
            <div className="verticallyAlignMid mXAuto">
                <div>
                    <p>07:33PM, JAN 7TH</p>
                    <div className="verticallyAlignMid mXAuto">
                        <h5>How I can lead a more self aware life</h5>
                        <div className="verticallyAlignMid">
                            <img alt="" />
                        </div>
                    </div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </p>
                    <p>Ashtanga Yoga</p>
                </div>
                <div>
                    <p>07:33PM, JAN 7TH</p>
                    <div className="verticallyAlignMid mXAuto">
                        <h5>How I can lead a more self aware life</h5>
                        <div className="verticallyAlignMid">
                            <img alt="" />
                        </div>
                    </div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </p>
                    <p>Ashtanga Yoga</p>
                </div>
            </div>
            <button>View All</button>
        </div>
    );
}

export default MentorFlowClientProfile;
