// import './index.css';
import { useEffect, useState } from "react";
import { connect } from "react-redux";

const ButtonTagsCategories = ({
  buttonStatus,
  text,
  parentEvent,
  id,
  dataCompany,
}) => {
  const company = dataCompany.company;

  const [btnStyle, setBtnStyle] = useState([]);

  useEffect(() => {
    if (buttonStatus === "checked") {
      setBtnStyle([
        `${company?.whiteLabel?.color?.buttons?.primaryText}`,
        `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
      ]);
    } else {
      setBtnStyle([
        `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
        `${company?.whiteLabel?.color?.buttons?.secondary}`,
      ]);
    }
  }, [
    buttonStatus,
    company?.whiteLabel?.color?.buttons?.primary?.from,
    company?.whiteLabel?.color?.buttons?.primary?.to,
    company?.whiteLabel?.color?.buttons?.primaryText,
  ]);

  const handleClick = () => {
    parentEvent(id);
  };

  return (
    <div
      onClick={handleClick}
      style={{ color: btnStyle[0], background: btnStyle[1] }}
      className={`borderRadius24 mRight12 mBottom8 cursorPointer`}
    >
      <p className="Pmedium pX6 pY6">{text}</p>
    </div>
  );
};

function getData(state) {
  return {
    dataCompany: state.auth.authData,
  };
}

export default connect(getData)(ButtonTagsCategories);
