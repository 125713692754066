import { AiOutlineClockCircle } from "react-icons/ai";
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faMicrophone, faVideo } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const ViewRescheduleSessionCard = ({session, service, data}) => {

	const company 	= data.company;

	const navigate 	= useNavigate();

	let date 		= new Date(session.date).getTime();
	date 			= Moment(date).format('DD MMM');
	let timeStart 	= Moment(session.date).format('LT');

	let timeEnd = timeStart.split(' ')[0];
	timeEnd 	= timeEnd.split(':');
	timeEnd 	= get12hTime(parseInt(timeEnd[0]) * 60 + parseInt(timeEnd[1]) + service.duration);

	function get12hTime(time) {

		let t1 = Math.floor(time / 60);
		let t2 = time - Math.floor(time / 60) * 60;

		if (t2 === 0) {
			t2 = '00';
		}

        let type;

        if (t1 === 0) {
            t1 = 12;
            type = "AM";
        } else if (t1 < 12) {
            type = "AM"
        } else if (t1 === 12) {
            type = "PM"
        } else {
            t1 -= 12
            type = "PM"
        }

        return (t1 + ":" + t2 + ' ' + type);
	}

	function goToSessionSingleView () {

		let sessionType = "Upcoming";

		if (session.status === "scheduled") {
			sessionType = "Upcoming";
		} else if (session.status === "completed") {
			sessionType = "Past";
		} else {
			sessionType = "Cancelled";
		}

		navigate('/OnboardingMentor11ClientSingleView',
			{
				state : {
					id 		: session._id,
					type 	: sessionType
				}
			}
		)
	}

   return (
      	<div className="backColElementBackgroundGray viewRescheduleSessionCardMain mBottom40 mRight20 borderRadius12 w440">
      		<div className="w90 mXAuto">
				<div className="verticallyAlignMidJustConStart mTop20">
					<div className="mRight20">
						<p className="colLabelGray textAlignLeft">Date</p>
						<p className="P1bold textAlignLeft">{date}</p>
					</div>
					<div>
						<p className="colLabelGray textAlignLeft">Service</p>
						<p className="P1bold textAlignLeft">{service.name}</p>
					</div>
				</div>
				<div className="borderGrayTopInput w100 mY20"></div>
				<div>
					<div className="verticallyAlignMidJustConStart mBottom4">
						<AiOutlineClockCircle size="1.2em" className="mRight8" />
						<p className="Small-LabelCaps textAlignLeft">{timeStart} - {timeEnd} ({service.duration} min)</p>
					</div>
					<div className="verticallyAlignMidJustConStart flexWrap">
						{session.method.map((comm, index) => {
							if (comm === "audio") {
								return <div key={index} className="verticallyAlignMidJustConStart">
									<FontAwesomeIcon icon={faMicrophone} className="mRight5 mLeft5"/>
									<p className="Small-LabelCaps">Audio</p>
								</div>
							} else if (comm === "video") {
								return <div key={index} className="verticallyAlignMidJustConStart">
									<FontAwesomeIcon icon={faVideo} className="mRight5 mLeft5"/>
									<p className="Small-LabelCaps">Video Call</p>
								</div>
							} else if (comm === "chat") {
								return <div key={index} className="verticallyAlignMidJustConStart">
									<FontAwesomeIcon icon={faCommentDots} className="mRight5 mLeft5"/>
									<p className="Small-LabelCaps">Chat</p>
								</div>
							}
							return "";
						})}
					</div>
				</div>
				<div className="borderGrayTopInput w100 mY20"></div>
				<div className="verticallyAlignMidSpaceBetween mBottom20 viewRescheduleSessionCardButtonsMain">
					<button onClick={goToSessionSingleView} className="verticallyAlignMid viewRescheduleSessionCardButtons colLinksAndTitlesBlack w180 borderRadiusForSocialMediaButtons borderNone Pregular backColPlaceHolderGray pY14 mRight20">View</button>

					{ session.status === "scheduled" ?
						<button style={{color : `${company?.whiteLabel?.color?.buttons?.primaryText}`, backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`}} className="verticallyAlignMid viewRescheduleSessionCardButtons w180 borderRadiusForSocialMediaButtons borderNone Pregular colOffWhite pY14">Reschedule</button>
					:
						// <button style={{color : `${company?.whiteLabel?.color?.buttons?.primaryText}`, backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`}} className="verticallyAlignMid viewRescheduleSessionCardButtons w180 borderRadiusForSocialMediaButtons borderNone Pregular colOffWhite pY14">Book Again</button>
						''
					}
				</div>
			</div>
		</div>
   );
}

function getData(state) {
	return {
		data : state.auth.authData
	}
}

export default connect(getData)(ViewRescheduleSessionCard);
