import { connect } from "react-redux";
import { getServices } from "../../api/index.js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddServiceComponent from "../../components/AddServicesComponent/AddServicesComponent.js";
import { Link } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";

function OnboardingMentor13Service(props) {
    const navigate = useNavigate();
    const company = props.company;

    if (!company._id || !props.user._id) {
        navigate("/LogIn");
    }

    const [services, setServices] = useState([]);

    useEffect(() => {
        async function getResults() {
            const queryString = `?mentorID=${props.user._id}`;

            const { data } = await getServices(queryString);

            // console.log(data.data.services)
            setServices(data.data.services);
        }
        getResults();
    }, [props]);

    function goToNextPage(el) {
        navigate("/OnboardingMentor7CreateYourFirstSession", {
            state: {
                service: el,
            },
        });
    }

    function goToCreateSession() {
        localStorage.setItem("typeOfService", "add-new");
        window.location.assign("/OnboardingMentor7CreateYourFirstSession");
    }

    return (
        <div className="OnboardingMentor13Service">
            <div className="mBottom50 mTop70 w80 mXAuto verticallyAlignMidJustConStart OnboardingMentor13ServiceSubWrapper">
                <Link to="/MentorHome2SettingsPanel">
                    <div className="posRel mRight20">
                        <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                            <RiArrowLeftSLine
                                size="2em"
                                className="colPrimaryGreen"
                            />
                        </button>
                    </div>
                </Link>
                <h2 className="H1regular">Services</h2>
                {/* <Link to ="/OnboardingMentor7CreateYourFirstSession" className="float-right"> */}
                <button
                    style={{
                        color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                        backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                    }}
                    className="verticallyAlignMid w180 borderRadius12 borderNone Pregular colOffWhite pY10 mLeftAuto OnboardingMentor13ServiceButton1"
                    onClick={goToCreateSession}
                >
                    Add Service
                </button>
                {/* </Link> */}
            </div>
            <div className="w80 mXAuto mBottom30">
                <button
                    style={{
                        color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                        backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                    }}
                    className="verticallyAlignMid w180 borderRadius12 borderNone Pregular colOffWhite pY10 mLeftAuto OnboardingMentor13ServiceButton2"
                    onClick={goToCreateSession}
                >
                    Add Service
                </button>
            </div>
            <div className="w80 mXAuto mBottom70">
                {services.map((serv, i) => (
                    <AddServiceComponent
                        key={i}
                        service={serv}
                        parentEvent={goToNextPage}
                    />
                ))}
            </div>
        </div>
    );
}

function getUserData(state) {
    return {
        user: state.auth.authData?.data.user || {},
        company: state.auth.authData?.company,
    };
}

export default connect(getUserData)(OnboardingMentor13Service);
