import React from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import { Datepicker, setOptions } from '@mobiscroll/react';
import Moment from 'moment';

setOptions({
    theme: 'ios',
    themeVariant: 'light'
});

function CalendarPopup({marked, parentEventShow, parentEventDate, date, type}) {
    return (
        <>
            <div className="w40 mBottom60 calendarPopUpMain">
                <Datepicker
                    value={date}
                    controls={['calendar']}
                    display="inline"
                    calendarType="month"
                    onChange={(e) => {parentEventDate(e.value); parentEventShow(false)}}
                    min={(!type || type === "Upcoming" || type === "Scheduled") ? Moment().format('MM/DD/YYYY') : ''}
                    max={(type === "Past" || type === "Completed") ? Moment().format('MM/DD/YYYY') : ''}
                    marked={marked}
                />
            </div>
        </>
    );
}

export default CalendarPopup;
