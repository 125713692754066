import { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import MessageContactsComponent from "../../components/MessageContactsComponent/MessageContactsComponent";
import MessagesChatComponent from "../../components/MessagesChatComponent/MessagesChatComponent";
import { connect } from "react-redux";
import { createMessage, getInbox, updateMessages } from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import { socket } from "./../../actions/socket";

function Messages(props, { event }) {
    const [user, setUser] = useState("");
    const [userMessages, setUserMessages] = useState({});
    const [value, setValue] = useState(0);
    // const [showUsers, setShowUsers]			= useState(true);

    const location = useLocation();
    const navigate = useNavigate();

    if (!props.company._id || !props.user._id) {
        navigate("/LogIn");
    }

    useEffect(() => {
        async function getResults() {
            const { data } = await getInbox();

            setUserMessages(data.data.inbox);

            if (
                location?.state?.userID &&
                data.data.inbox.filter(
                    (el) => el._id === location?.state?.userID
                ).length === 0
            ) {
                alert("There are no messages with this user");
            } else {
                if (location?.state?.userID && user === "") {
                    setUser(location?.state?.userID);
                }
            }
        }
        getResults();
    }, [event, value, location?.state?.userID, user]);

    useEffect(() => {
        // console.log(window.innerWidth)

        async function getData() {
            if (user !== "") {
                await updateMessages({ id: user });

                // console.log('user has changed --------->')

                setValue((value) => value + 1);
            }
        }

        getData();
    }, [user]);

    async function handleSendMessage(obj) {
        await createMessage(obj);
        setValue((value) => value + 1);
    }

    socket.on("newAppMessage", () => {
        setValue((value) => value + 1);
    });

    const [windowWidth, setWindowWidth] = useState(0);

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    return (
        // MAIN NAV BAR ORIGINAL
        <div className="messagesMain">
            <div className="mXAuto messagesMainSubb verticallyAlignMidStretchStart h100">
                <div className="messagesMainSubb1 w40">
                    {(!user && windowWidth <= 900) || windowWidth > 900 ? (
                        <>
                            <div className="boxShadowForSearchLanding heightForHeadMessages verticallyAlignMidJustConStart">
                                <p className="H3medium mLeft100 textAlignLeft">
                                    Messages
                                </p>
                            </div>
                            <div className="messagesMainSubb1Subb backColElementBackgroundGray">
                                <div className="w90 mXAuto pY30 messagesMainSubb1SubbScroll">
                                    <form className="verticallyAlignMid w100 mXAuto borderRadius24 backColWhite boxShadowForSearchLanding mBottom30 ">
                                        <button
                                            type="submit"
                                            className="searchButton backColWhite outlineNone borderNone"
                                        >
                                            <RiSearchLine
                                                size="1.4em"
                                                className="searchButtonOjectFit colLabelGray mX10 mY14"
                                            />
                                        </button>
                                        <input
                                            type="search"
                                            placeholder="Search Messages"
                                            className="Pmedium searchField w100 borderNone colLabelGray outlineNone"
                                        />
                                    </form>
                                    <div className="overflowParent">
                                        <div
                                            className={
                                                Object.keys(userMessages)
                                                    .length > 5
                                                    ? "overflowYScroll"
                                                    : ""
                                            }
                                        >
                                            {userMessages &&
                                                userMessages.length > 0 &&
                                                userMessages.map((el, i) => {
                                                    return (
                                                        <MessageContactsComponent
                                                            key={i}
                                                            data={el}
                                                            parentEvent={
                                                                setUser
                                                            }
                                                            user={user}
                                                        />
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <MessagesChatComponent
                            messages={userMessages.filter(
                                (el) => el._id === user
                            )}
                            parentEvent={handleSendMessage}
                            value={value}
                            backToUsers={setUser}
                        />
                    )}
                </div>
                {user && windowWidth > 900 ? (
                    <MessagesChatComponent
                        messages={userMessages.filter((el) => el._id === user)}
                        parentEvent={handleSendMessage}
                        value={value}
                        backToUsers={setUser}
                    />
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}

function getUserData(state) {
    return {
        company: state.auth.authData?.company,
        user: state.auth.authData?.data.user || {},
    };
}

export default connect(getUserData)(Messages);
