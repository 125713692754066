import { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Peer from "peerjs";
import { connect } from "react-redux";
import { socket } from "./../../actions/socket";
import {
    BsCameraVideo,
    BsChatFill,
    BsMicFill,
    BsThreeDots,
    BsFillMicMuteFill,
    BsExclamationLg,
    BsCameraVideoOffFill,
} from "react-icons/bs";
import { FiPhoneOff } from "react-icons/fi";
// import { RiArrowDownSLine } from "react-icons/ri";
import { getNotes, getSession, updateNote } from "../../api/index.js";
// import usePictureInPicture from 'react-use-pip'
import CloseButton from "../../components/buttons/CloseButton/CloseButton";
// import TextareaComponent from "../../components/TextareaComponent/TextareaComponent";
import ThankYouAfterSessionPopUp from "../ThankYouAfterSessionPopUp/ThankYouAfterSessionPopUp";
import YourSessionHasEndedPopUp from "../YourSessionHasEndedPopUp/YourSessionHasEndedPopUp";
import NotesMainPage from "../../components/NotesMainPage/NotesMainPage";
import NewNoteSession from "../../components/NewNoteSession/NewNoteSession";
import { RiArrowLeftSLine } from "react-icons/ri";
import Moment from 'moment';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function InSession(props) {
    const company = props.data.company;
    const navigate = useNavigate();
    const location = useLocation();
    const sessionID = location?.state?.sessionID ?? "";

    if (!sessionID || !props.data.company._id || !props.data.data.user._id) {
        navigate("/LogIn");
    }

    // const videoGridRef      = useRef(null);
    const mainLeftRef = useRef(null);
    const mainRightRef = useRef(null);
    const showTextRef = useRef(null);
    const muteButtonRef = useRef(null);
    const stopVideoRef = useRef(null);
    const textRef = useRef(null);
    const sendRef = useRef(null);
    // const messageRef        = useRef(null);

    const myVideoRef = useRef(null);
    const othersVideoRef = useRef(null);

    let currentCallRef = useRef(null);
    let myNameRef = useRef(null);

    const [session, setSession] = useState({});
    const [sessionChat, setSessionChat] = useState([]);
    const [value, setValue] = useState(0);
    const [enableAudio, setEnableAudio] = useState(true);

    const [enableVideo, setEnableVideo] = useState(true);
    const [peerStreaming, setPeerStreaming] = useState(true);

    const [seeThreeDots, setSeeThreeDots] = useState(false);

    const [askForEndSession, setAskForEndSession] = useState(false);
    const [endSession, setEndSession] = useState(false);
    const [endSessionByUser, setEndSessionByUser] = useState(false);
    const [showNewMessage, setShowNewMessage] = useState(false);
    const [reviewDone, setReviewDone] = useState(false);
    const [seeNotes, setSeeNotes] = useState(false);

    const [newNote, setNewNote] = useState(false);
    const [notes, setNotes] = useState([]);
    const [noteEdit, setNoteEdit] = useState(false);
    const [isTimeRemaining, setIsTimeRemaining] = useState(true);

    const [peerData, setPeerData] = useState({ myPeerID: null, room: null });

    const messagesEndRef = useRef(null);

    // const {
    //     isPictureInPictureActive,
    //     isPictureInPictureAvailable,
    //     togglePictureInPicture,
    // } = usePictureInPicture(myVideoRef)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
        });
    };

    const [windowWidth, setWindowWidth] = useState(0);
    const [firstMount, setFirstMount] = useState(true);

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    // useEffect(() => {
    //     console.log(mainLeftRef.current.style.display);
    //     if(!newNote && !noteEdit && mainLeftRef.current.style.display === "") {
    //         console.log("AAAAAAAAAAA");
    //         mainRightRef.current.style.setProperty("display", "block", "important")
    //     }
    // }, [newNote, noteEdit]);

    function handleBackToVideo() {
        // mobile
        mainRightRef.current.style.setProperty("display", "none", "important");
        mainLeftRef.current.style.setProperty("display", "block", "important");
        mainLeftRef.current.style.width = "100%";
        // if(navigator.vendor != null && navigator.vendor.match(/Apple Computer, Inc./) && navigator.userAgent.match(/iPhone/i) || (navigator.userAgent.match(/iPod/i)))
        // {
        //     window.location.reload();
        // }
    }

    const [timeoutValue, setTimeoutValue] = useState(null);
    const [timeout2MinsValue, setTimeout2MinsValue] = useState(null);

    useEffect(() => {
        (async () => {
            const { data } = await getSession(
                sessionID,
                `?populate=mentorID,serviceID,clientID&_id=${sessionID}`
            );

            if (data.data.session.status !== "scheduled") {
                navigate('/Schedule');
            }

            const currentTimestamp = Moment().unix();
            const sessionStartTimestamp = Moment(data.data.session.date).unix();
            const sessionEndTimestamp = sessionStartTimestamp + data.data.session.serviceID.duration * 60;
            // const sessionEndTimestamp   = sessionStartTimestamp + 47 * 60;
            const systemEndSessionTimer = Math.abs(sessionEndTimestamp - currentTimestamp) * 1000;

            // console.log(data.data.session);
            // console.log(systemEndSessionTimer);

            if (systemEndSessionTimer > 120000) {
                setTimeout2MinsValue(
                    setTimeout(() => {
                        toast.info('Your session will end in 2 minutes...', {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    }, (systemEndSessionTimer - 120000))
                );
            }
            setTimeout(() => {
                setIsTimeRemaining(false);
            }, (systemEndSessionTimer - 3000));
            setTimeoutValue(setTimeout(endSessionSeconds, systemEndSessionTimer));

            setSession(data.data.session);
            setSessionChat(data.data.session.sessionChat);
        })();
    }, []);

    useEffect(() => {
        let myStream = null;
        let myPeer = null;

        let room, myVideo;

        if (Object.keys(session).length === 0) {
            return false;
        }

        room = "session:" + sessionID;
        myVideo = myVideoRef.current;

        // myVideo.controls    = true;
        if (myVideo) {
            myVideo.muted = true;
        }

        const addVideoStream = (video, stream, from) => {
            video.srcObject = stream;
            video.style.display = "block";

            video.autoplay = true;
            video.setAttribute('playsinline', '');
            video.setAttribute('webkit-playsinline', '');
            video.addEventListener("loadedmetadata", () => {
                video.play();
            });
            if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
                console.log('ios device');
                video.addEventListener("pause", () => {
                    video.play();
                });
            }

            if (from === "other") {
                video.style.width = "100%";
                video.style.height = "100%";
            }
        };

        const loadMyStream = async () => {
            if (session?.method?.indexOf("audio") !== -1) {
                let myStream = null;

                let constraints = {
                    video: false,
                    audio: false,
                };

                const devices = await navigator.mediaDevices.enumerateDevices();

                // console.log (devices)

                devices.forEach((device) => {
                    if (device.kind === "audioinput") {
                        if (session?.method?.indexOf("audio") !== -1) {
                            constraints.audio = true;
                        }
                    }

                    if (device.kind === "videoinput") {
                        if (session?.method?.indexOf("video") !== -1) {
                            constraints.video = true;
                        } else {
                            // myVideoRef.current?.style.display = "none";
                            // othersVideoRef.current?.style.display = "none";
                        }
                    }
                });

                /*
                    ako je session videoMORA da su oba audio i video constraints true!
                    ako je session audio mutuj video i sakrij dugme za mute video i mora da je audio true
                */

                // https://www.youtube.com/watch?v=6v0hJMObvgg picture in picture

                try {
                    myStream = await navigator.mediaDevices.getUserMedia(
                        constraints
                    );
                } catch (e) {
                    console.error(e.message);
                } finally {
                    addVideoStream(myVideoRef.current, myStream, "my");
                    return myStream;
                }
            } else {
                myVideoRef.current &&
                    (myVideoRef.current.style.display = "none");
                othersVideoRef.current &&
                    (othersVideoRef.current.style.display = "none");

                return null;
            }
        };

        //TO DO: Fix pluging/unpluging device - this was not working properly on IPhone
        //We should prolly add this check for computers only - not Phones (OR maybe phones can changes speakers as well?!)

        // //  plug/unplug mic/webcam
        // navigator.mediaDevices.ondevicechange = () => {
        //     // window.confirm("Media device(s) changed! Please refresh the page!");
        //     if (
        //         window.confirm(
        //             "Media device(s) changed! Please refresh the page!"
        //         )
        //     ) {
        //         window.location.reload();
        //     } else {
        //         // console.log("reload - false");
        //     }
        // };

        const initPeer = () => {
            let peerConfig = {
                config: {
                    iceServers: [
                        {
                            urls: [
                                "stun:52.56.183.227:3478",
                                // "stun:stun.l.google.com:19302",
                                // 'stun:stun1.l.google.com:19302',
                                // 'stun:stun2.l.google.com:19302',
                                // 'stun:stun3.l.google.com:19302',
                                // 'stun:stun4.l.google.com:19302',

                                // 'stun:stun01.sipphone.com',
                                // 'stun:stun.ekiga.net',
                                // 'stun:stun.fwdnet.net',
                                // 'stun:stun.ideasip.com',
                                // 'stun:stun.iptel.org',
                                // 'stun:stun.rixtelecom.se',
                                // 'stun:stun.schlund.de',
                                // 'stun:stunserver.org',
                                // 'stun:stun.softjoys.com',
                                // 'stun:stun.voiparound.com',
                                // 'stun:stun.voipbuster.com',
                                // 'stun:stun.voipstunt.com',
                                // 'stun:stun.voxgratia.org',
                                // 'stun:stun.xten.com',

                            ],
                        },
                        // UDP/TCP
                        {
                            urls: "turn:52.56.183.227:3478",
                            credential: "mentorxwl1723144224",
                            username: "mentorx",
                        },
                        // TLS
                        {
                            urls: "turn:52.56.183.227:5349",
                            credential: "mentorxwl1723144224",
                            username: "mentorx",
                        },
                    ],
                },
            };

            let myPeer = new Peer(peerConfig);

            //  once the connection to brokering server is established
            myPeer.on("open", (myPeerID) => {
                // console.log(`Joining session room with my peerID: ${myPeerID}`);

                setPeerData({ myPeerID: myPeer.id, room });

                let data = {
                    room,
                    peerID: myPeerID,
                };

                //  check the room for size and duplicates
                socket.emit("querySessionRoom", data, (fullName, roomSize) => {
                    myNameRef.current = fullName ?? "";

                    // console.log(`Room size: ${roomSize}`);

                    if (roomSize > 2) {
                        // console.log("closing socket");
                        socket.close();
                    }
                });
            });

            //  call peer once he joins the room
            socket.on("peerJoinedSessionRoom", (peerID) => {
                // console.log(`User (${peerID}) joined the room!`);
                const call = myPeer.call(peerID, myStream);

                // console.log(call);

                if (call) {
                    currentCallRef.current = call;

                    //  receive peer stream
                    call.on("stream", (peerStream) => {
                        addVideoStream(
                            othersVideoRef.current,
                            peerStream,
                            "other"
                        );
                    });

                    //  call end, remove peer stream
                    call.on("close", () => {
                        // console.log("Call ended. (1)");
                        // console.log(myPeer);
                    });
                }
            });

            //  answer peer calling
            myPeer.on("call", (call) => {
                currentCallRef.current = call;
                call.answer(myStream);

                // console.log(`Answering call from ${call.peer}`);

                //  receive peer stream (console logs twice for each stream track (A/V))
                call.on("stream", (peerStream) => {
                    // console.log("Peer is streaming!");
                    addVideoStream(othersVideoRef.current, peerStream, "other");
                });

                call.on("close", () => {
                    // console.log("Call ended. (2)");
                    // console.log(myPeer);
                });
            });

            return myPeer;
        };

        (async () => {
            myStream = await loadMyStream();
            myPeer = initPeer();
        })();

        //  peer leaves session room
        socket.on("peerLeftSessionRoom", async (peerID, endSession) => {
            // console.log(`Peer (${peerID}) left the room.`);
            // console.log(myVideoRef);
            // console.log(othersVideoRef);

            if (currentCallRef.current) {
                currentCallRef.current.close();
            }

            if (othersVideoRef.current) {
                othersVideoRef.current.style.display = "none";
            }

            //  end stream in a formal way
            if (endSession) {
                if (props.data.data.user.role === "mentor") {
                    setReviewDone(true);
                } else {
                    // setEndSessionByUser(true);
                    // console.log("peerLeftSessionRoom");
                    // console.log(endSessionByUser);
                    setEndSession(true);
                }
            }
        });

        //  mute button and stop video
        const muteButton = muteButtonRef.current;
        const stopVideo = stopVideoRef.current;

        muteButton &&
            muteButton.addEventListener("click", () => {
                const enabled = myStream.getAudioTracks()[0].enabled;

                if (enabled) {
                    myStream.getAudioTracks()[0].enabled = false;
                    muteButton.classList.toggle("background__red");
                } else {
                    myStream.getAudioTracks()[0].enabled = true;
                    muteButton.classList.toggle("background__red");
                }

                setEnableAudio(myStream.getAudioTracks()[0].enabled);
                setValue((value) => value + 1);
            });

        stopVideo &&
            stopVideo.addEventListener("click", () => {
                if (myStream.getVideoTracks()[0]) {
                    let enabled = myStream.getVideoTracks()[0].enabled;

                    if (enabled) {
                        myStream.getVideoTracks()[0].enabled = false;
                        stopVideo.classList.toggle("background__red");

                        //  socket emit user disabled video
                        if (currentCallRef.current) {
                            socket.emit("myVideoMuted", room);
                        }
                    } else {
                        myStream.getVideoTracks()[0].enabled = true;
                        stopVideo.classList.remove("background__red");

                        //  socket emit user disabled video
                        if (currentCallRef.current) {
                            socket.emit("myVideoUnmuted", room);
                        }
                    }

                    setEnableVideo(myStream.getVideoTracks()[0].enabled);
                    setValue((value) => value + 1);
                }
            });

        socket.on("newSessionMessage", (data) => {
            setSessionChat(data);
            setShowNewMessage(true);
        });

        socket.on("peerMutedVideo", (data) => {
            setPeerStreaming(false);
        });

        socket.on("peerUnmutedVideo", (data) => {
            setPeerStreaming(true);
        });

        //  leaving session room, cleanup our stream, peer connection and socket listeners
        return function cleanup() {
            // console.log(myPeer);
            let myPeerID = myPeer.id;

            let data = {
                myPeerID,
                room,
            };

            //  let peer know we are leaving
            socket.emit("leaveSessionRoom", data);

            try {
                if (currentCallRef.current) {
                    currentCallRef.current.close();
                }

                if (myPeer) {
                    myPeer.destroy();
                }
            } catch (e) {
                console.error(e);
            }

            if (myStream) {
                //  stop all stream tracks
                myStream.getTracks().forEach(function (track) {
                    track.stop();
                });
            }

            socket.off("peerJoinedSessionRoom");
            socket.off("peerLeftSessionRoom");
            socket.off("newSessionMessage");
            socket.off("peerMutedVideo");
            socket.off("peerUnmutedVideo");

            clearTimeout(timeout2MinsValue);
            clearTimeout(timeoutValue);
        };
    }, [session, props.data.data.user.role]);

    useEffect(() => {
        async function getNotesData() {
            const notesData = await getNotes(
                `?populate=serviceID,assignedID&userID=${props.data.data.user._id}&sort=-createdAt`
            );

            // console.log(notesData.data.data.notes)
            setNotes(notesData.data.data.notes);
        }

        getNotesData();
    }, [value, props.data.data.user._id]);

    useEffect(() => {
        scrollToBottom();
    }, [sessionChat]);

    function endSessionFirst() {
        setSeeThreeDots(false);
        setAskForEndSession(true);
    }

    function endSessionSeconds() {
        setAskForEndSession(false);

        socket.emit("endSession", sessionID);

        //  let peer know we are leaving
        socket.emit("leaveSessionRoom", peerData, true);

        if (props.data.data.user.role === "mentor") {
            setReviewDone(true);
        } else {
            setEndSessionByUser(true);
            console.log("endSessionSeconds");
            console.log(endSessionByUser);

            setEndSession(true);
        }
    }

    function endSessionThird() {
        setEndSession(false);
        setReviewDone(true);
    }

    // function goToSchedule() {
    //     navigate('/Schedule');
    // }

    // function goHome() {
    //     navigate('/WhatKindOfMentor');
    // }

    // function seeSessionNotes() {
    //     setSeeNotes(true);
    //     mainRightRef.current.style.display = "block";
    //     setSeeThreeDots(false)

    //     showTextRef.current.classList.remove('backColGrayOpacity2')
    //     showTextRef.current.classList.add('backColOffWhite')

    //     showTextRef.current.children[0].classList.remove('colOffWhite')
    //     showTextRef.current.children[0].classList.add('colLinksAndTitlesBlack')
    // }

    function showHideChat() {
        setShowNewMessage(false);
        if (firstMount) {
            setFirstMount(false);
        }

        if (mainRightRef.current.style.display === "block") {
            mainRightRef.current.style.setProperty(
                "display",
                "none",
                "important"
            );
            mainLeftRef.current.style.width = "100%";

            showTextRef.current.classList.remove("backColOffWhite");
            showTextRef.current.classList.add("backColGrayOpacity2");

            showTextRef.current.children[0].classList.remove(
                "colLinksAndTitlesBlack"
            );
            showTextRef.current.children[0].classList.add("colOffWhite");
        } else {
            //Show chat
            // mainRightRef.current.style.display    = "block!important";
            mainRightRef.current.style.setProperty(
                "display",
                "block",
                "important"
            );
            showTextRef.current.classList.remove("backColGrayOpacity2");
            showTextRef.current.classList.add("backColOffWhite");

            showTextRef.current.children[0].classList.remove("colOffWhite");
            showTextRef.current.children[0].classList.add(
                "colLinksAndTitlesBlack"
            );

            //  desktop
            if (windowWidth > 1200) {
                mainRightRef.current.style.width = "30%";
                mainLeftRef.current.style.width = "70%";
            } else {
                // mobile
                // console.log("HIDE VIDEO");

                // mainLeftRef.current.style.display         = "none!important";
                mainLeftRef.current.style.setProperty(
                    "display",
                    "none",
                    "important"
                );
                mainRightRef.current.style.width = "100%";
            }
        }
    }

    const [fullScreen, setFullScreen] = useState(false);

    function goFullScreen() {
        var requestMethod =
            document.getElementById("video_call_main").requestFullScreen ||
            document.getElementById("video_call_main")
                .webkitRequestFullscreen ||
            document.getElementById("video_call_main").mozRequestFullScreen ||
            document.getElementById("video_call_main").msRequestFullScreen;

        if (requestMethod) {
            // Native full screen.
            requestMethod.call(document.getElementById("video_call_main"));
            setSeeThreeDots(false);
            setFullScreen(true);
        }

        // document.getElementById('video_call_main').requestFullscreen();
    }

    function exitFullScreen() {
        setSeeThreeDots(false);
        setFullScreen(false);

        // document.getElementById('video_call_main').exitFullscreen();

        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }

    document.addEventListener(
        "fullscreenchange",
        function () {
            if (!document.fullscreenElement) {
                // console.log("Full screen - EXIT!!!");
                // setFullScreen(false)
            }
        },
        false
    );

    document.addEventListener("click", function (e) {
        if (
            document.getElementsByClassName("threeDotsSessionWrapper")[0] &&
            document.getElementsByClassName("threeDotsSessionClick")[0] &&
            !document
                .getElementsByClassName("threeDotsSessionWrapper")[0]
                .contains(e.target) &&
            !document
                .getElementsByClassName("threeDotsSessionClick")[0]
                .contains(e.target)
        ) {
            setSeeThreeDots(false);
        }
    });

    async function pinNote(id, status) {
        await updateNote(id, { pinned: status });
        setValue((value) => value + 1);
    }

    // let text = textRef.current
    // let send = sendRef.current

    function sendMessage(type) {

        if (type === "btn") {
            if (textRef.current.value.length !== 0) {
                let data = {
                    message: textRef.current.value,
                    room: peerData.room,
                    fullName: props.data.data.user.fullName,
                };
                // console.log("DATA", data);

                socket.emit("sendSessionMessage", data);

                textRef.current.value = "";
            }
        } else {
            let data = {
                message: textRef.current.value,
                room: peerData.room,
                fullName: props.data.data.user.fullName,
            };

            if (type.key === "Enter" && textRef.current.value.length !== 0) {
                type.preventDefault();
                socket.emit("sendSessionMessage", data);
                textRef.current.value = "";
            }
        }
        setShowNewMessage(false);


    }

    function getInitials(name) {
        // console.log(session);
        // console.log(name);
        let words = name.split(" ");

        return (
            words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
        );
    }

    return (
        <>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <div id="video_call_main" className="VideoCallMain">
                {askForEndSession && (
                    <div className="filterMentorPopupWrapper">
                        <div className="NewNotePoUp">
                            <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                                <div className="w90 mXAuto">
                                    <div className="verticallyAlignMid mTop40 aboutToEndSessionSub">
                                        <CloseButton
                                            parentEvent={setAskForEndSession}
                                        />
                                    </div>
                                    <div className="mY30 w100 aboutToEndSessionSub2">
                                        <BsExclamationLg
                                            size="3em"
                                            className="w-100 text-center"
                                        />
                                        <div className="w100 text-center verticallyAlignMid my-4">
                                            <p className="w60 H3medium">
                                                You are about to end the{" "}
                                                {
                                                    company.whiteLabel.texts
                                                        .label4SessionSingle
                                                }{" "}
                                                while there's remaining time.
                                            </p>
                                        </div>
                                        <div className="w100 text-center verticallyAlignMid my-4">
                                            <p className="w40 H3medium">
                                                Are you sure you'd like to
                                                continue?
                                            </p>
                                        </div>
                                        <button
                                            onClick={endSessionSeconds}
                                            className="mXAuto mTop10 verticallyAlignMid ClientNotesSubbButton1 borderRadius12 borderNone Pmedium colOffWhite backColPink pLeft40 pRight40 pY14"
                                        >
                                            <FiPhoneOff size={"1em"} />
                                            &nbsp; End{" "}
                                            {
                                                company.whiteLabel.texts
                                                    .label4SessionSingle
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {endSession && (
                    <div className="filterMentorPopupWrapper">
                        <YourSessionHasEndedPopUp
                            isByUser={endSessionByUser}
                            parentEvent={endSessionThird}
                            session={session}
                            service={session.serviceID}
                            user={props.data.data.user}
                            mentor={session?.mentorID}
                            timeRemaining={isTimeRemaining}
                        />
                    </div>
                )}
                {reviewDone && (
                    <div className="filterMentorPopupWrapper">
                        <ThankYouAfterSessionPopUp
                            user={props.data.data.user}
                        />
                    </div>
                )}
                <div
                    className={`${fullScreen && "w-100"
                        } mXAuto messagesMainSubb verticallyAlignMidStretchStart h-100`}
                >
                    {session?.method?.length === 1 &&
                        session?.method[0] === "chat" ? (
                        ""
                    ) : (
                        <div
                            ref={mainLeftRef}
                            className={`${fullScreen ? "h-100" : "backColInput"
                                } backColElementBackgroundGray VideoCallImageElement posRel`}
                        >
                            {session?.method?.length === 2 &&
                                session?.method?.indexOf("video") < 0 ? (
                                <>
                                    <div className="disabledVideoText">
                                        {session.mentorID.fullName ===
                                            props.data.data.user.fullName ? (
                                            <div className="w100 h100 verticallyAlignMid">
                                                {session.clientID?.photo ? (
                                                    <img alt="" className="w100" src={session.clientID.photo} />
                                                ) : (
                                                    <div className="userInitialsLarge">
                                                        <p>
                                                            {getInitials(session.clientID.fullName)}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="w100 h100 verticallyAlignMid">
                                                {session.mentorID?.photo ? (
                                                    <img
                                                        alt=""
                                                        className="w100"
                                                        src={
                                                            session.mentorID
                                                                .photo
                                                        }
                                                    />
                                                ) : (
                                                    <div className="userInitialsLarge">
                                                        <p>
                                                            {getInitials(session.mentorID.fullName)}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="posAbs mySmallerVideoWrapper">
                                        <video
                                            muted
                                            loop
                                            className="mySmallerVideo"
                                            ref={myVideoRef}
                                        />
                                    </div>
                                    <video
                                        loop
                                        className=""
                                        ref={othersVideoRef}
                                    />
                                    {/* <div className="videos__group">
                                    </div> */}
                                </>
                            ) : (
                                <>
                                    <div className="posAbs mySmallerVideoWrapper">
                                        <video
                                            muted
                                            loop
                                            className="mySmallerVideo"
                                            ref={myVideoRef}
                                        />
                                    </div>

                                    <video
                                        loop
                                        className=""
                                        ref={othersVideoRef}
                                    />

                                    {peerStreaming ? (
                                        ""
                                    ) : (
                                        <div className="disabledVideoText">
                                            {session.mentorID.fullName ===
                                                props.data.data.user.fullName ? (
                                                <div className="w100 h100 verticallyAlignMid">
                                                    {session.clientID?.photo ? (
                                                        <img alt="" className="w100" src={session.clientID.photo} />
                                                    ) : (
                                                        <div className="userInitialsLarge">
                                                            <p>{getInitials(session.clientID.fullName)}</p>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="w100 h100 verticallyAlignMid">
                                                    {session.mentorID?.photo ? (
                                                        <img alt="" className="w100"
                                                            src={session.mentorID.photo}
                                                        />
                                                    ) : (
                                                        <div className="userInitialsLarge">
                                                            <p>{getInitials(session.mentorID.fullName)}</p>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                            <div
                                className={`${session?.method?.length === 2
                                    ? "buttonsForAudioCallOnly "
                                    : ""
                                    }borderRadius18 posAbs buttonsForVideoCall backColGrayOpacity`}
                            >
                                <div className="buttonsForVideoCallSubb mXAuto verticallyAlignMidSpaceBetween pY14">
                                    {session?.method?.length > 2 && (
                                        <div ref={stopVideoRef} className="buttonForVideoCall backColGrayOpacity2 borderRadius50 verticallyAlignMid cursorPointer" >
                                            {enableVideo ? (
                                                <BsCameraVideo className="colOffWhite" size="1.7em" />
                                            ) : (
                                                <BsCameraVideoOffFill className="colOffWhite" size="1.7em" />
                                            )}
                                        </div>
                                    )}
                                    <div
                                        onClick={showHideChat}
                                        ref={showTextRef}
                                        className="buttonForVideoCall backColGrayOpacity2 borderRadius50 verticallyAlignMid cursorPointer"
                                    >
                                        <div className="icon-container">
                                            <BsChatFill className="colOffWhite" size="1.7em" />
                                            {showNewMessage && <span className="notification-dot"></span>}
                                        </div>
                                        {/* <BsChatFill className="colOffWhite" size="1.7em" /> */}
                                    </div>
                                    <div
                                        ref={muteButtonRef}
                                        className={`${enableAudio ? "" : "background__red"
                                            } buttonForVideoCall backColGrayOpacity2 borderRadius50 verticallyAlignMid cursorPointer`}
                                    >
                                        {enableAudio ? (
                                            <BsMicFill className="colOffWhite" size="1.7em" />
                                        ) : (
                                            <BsFillMicMuteFill className="colOffWhite" size="1.7em" />
                                        )}
                                    </div>
                                    <div onClick={() => setSeeThreeDots(!seeThreeDots)}
                                        className="threeDotsSessionClick buttonForVideoCall backColGrayOpacity2 borderRadius50 verticallyAlignMid cursorPointer"
                                    >
                                        <BsThreeDots className="colOffWhite" size="1.7em" />
                                    </div>
                                </div>
                            </div>
                            {seeThreeDots && (
                                <div className="posAbs threeDotsSessionWrapper backColGrayOpacity2 borderRadius24">
                                    <div onClick={endSessionFirst} className="w100 pY10 cursorPointer borderBottomInputGray">
                                        <p className="P1medium colOffWhite">
                                            End Session
                                        </p>
                                    </div>
                                    {/* <div onClick={seeSessionNotes} className="w100 pY10 cursorPointer">
                                    <p className="P1medium colOffWhite">See Notes</p>
                                </div> */}
                                    {fullScreen ? (
                                        <div onClick={exitFullScreen} className="w100 pY10 cursorPointer">
                                            <p className="P1medium colOffWhite">
                                                Exit Full Screen
                                            </p>
                                        </div>
                                    ) : (
                                        <div onClick={goFullScreen} className="w100 pY10 cursorPointer">
                                            <p className="P1medium colOffWhite">
                                                Full Screen
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    <ToastContainer
                        position="bottom-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />

                    {/*OVO JE POP UP ZA EDIT NOTE*/}

                    {(newNote || noteEdit) && (
                        <div className="messagesMainSubb2SubbForEditNote posRel backColOffWhite">
                            <NewNoteSession
                                user={props.data.data.user}
                                parentEvent={setNewNote}
                                parentEventTwo={setNoteEdit}
                                parentSetValue={setValue}
                                edit={noteEdit}
                            />
                        </div>
                    )}

                    {/* OVDE DOLE SU TI ISKOMENTARISANA OBA DIVA KOJA TREBAJU DA SE NALAZE TU NA CLICK*/}

                    {!newNote && !noteEdit && (
                        <div
                            className={`h-100 ${firstMount ? "" : "showChatBack"
                                } VideoCallTextElement ${session?.method?.length === 1 &&
                                    session?.method[0] === "chat"
                                    ? "w100 showChatBack"
                                    : ""
                                }`}
                            ref={mainRightRef}
                        >
                            <div className="backColWhite boxShadowForSearchLanding verticallyAlignMidJustConStart heightForHeadMessages">
                                <button
                                    onClick={() => setSeeNotes(false)}
                                    style={
                                        seeNotes
                                            ? {
                                                color: `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
                                                backgroundImage: `${company?.whiteLabel?.color?.buttons?.secondary}`,
                                            }
                                            : {
                                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                            }
                                    }
                                    className={`${session?.method?.length === 1
                                        ? "w38"
                                        : "w50"
                                        } Pregular h100 borderNone borderRadius12`}
                                >
                                    Chat
                                </button>
                                <button
                                    onClick={() => setSeeNotes(true)}
                                    style={
                                        seeNotes
                                            ? {
                                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                            }
                                            : {
                                                color: `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
                                                backgroundImage: `${company?.whiteLabel?.color?.buttons?.secondary}`,
                                            }
                                    }
                                    className={`${session?.method?.length === 1
                                        ? "w38"
                                        : "w50"
                                        } Pregular h100 borderNone borderRadius12`}
                                >
                                    Notes
                                </button>

                                {windowWidth <= 1200 &&
                                    session?.method?.length !== 1 && (
                                        <div className="posRel mRight20 mLeftAuto messagesMainSubb2Button">
                                            <button onClick={handleBackToVideo} className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                                                <RiArrowLeftSLine size="2em" className="colPrimaryGreen" />
                                            </button>
                                        </div>
                                    )}

                                {session?.method?.length === 1 && (
                                    <button
                                        onClick={endSessionFirst}
                                        className="w24 minW110 mXAuto mTop10 verticallyAlignMid borderRadius12 borderNone Pmedium colOffWhite backColPink pLeft40 pRight40 pY20"
                                    >
                                        <FiPhoneOff size={"1em"} />{" "}
                                        {/*&nbsp; End Session*/}
                                    </button>
                                )}
                            </div>

                            {seeNotes ? (
                                <>
                                    <div className="messagesMainSubb2SubbForNotes posRel backColOffWhite overflowYAuto">
                                        <div className="w90 mXAuto h100">
                                            {/*OVDE FILUJES SA NOTESIMA*/}

                                            {notes &&
                                                notes.map((el, i) => {
                                                    return (
                                                        <NotesMainPage key={i} note={el} parentEvent={pinNote} parentEventEdit={setNoteEdit} />
                                                    );
                                                })}
                                        </div>
                                    </div>
                                    <div className="addNewNoteInSessionButtonMain w100">
                                        <button
                                            onClick={() => setNewNote(true)}
                                            style={{
                                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                            }}
                                            className="verticallyAlignMid borderRadius12 borderNone Pregular colOffWhite pY10 pX30 w90 mXAuto"
                                        >
                                            Add Note
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={`messagesMainSubb2Subb1 posRel backColOffWhite`}>
                                        <div className="bothConversationsChat h100">
                                            {sessionChat &&
                                                sessionChat.map((el, i) => {
                                                    if (
                                                        el.name ===
                                                        props.data.data.user
                                                            .fullName
                                                    ) {
                                                        return (
                                                            <div key={i} className="verticallyAlignMidJustConEnd mRight10">
                                                                <div className="textAlignRight w100">
                                                                    <div className="messagesLeftSubb backColPrimaryGreen borderRadiusForChatOutputRight mY8 chatLineForVideoCall1">
                                                                        <p className="pX10 pY6 Small-Label-cards textAlignLeft breakWord colOffWhite">
                                                                            {
                                                                                el.message
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <div key={i} className="verticallyAlignMidJustConStart mLeft10">
                                                                <div className="textAlignLeft w100">
                                                                    <div className="messagesLeftSubb backColInput borderRadiusForChatOutputLeft chatLineForVideoCall1 mY8">
                                                                        <p className="pX10 pY6 Small-Label-cards breakWord textAlignLeft">
                                                                            {
                                                                                el.message
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                            <div ref={messagesEndRef}> </div>
                                        </div>
                                    </div>
                                    <div className="inputChatBoxMain backColPrimaryLight w100 verticallyAlignMid">
                                        <input
                                            onKeyDown={(e) => sendMessage(e)}
                                            ref={textRef}
                                            placeholder="Type a message"
                                            className="mXAuto w90 Small-Label-cards borderRadius12 boxShadowForSearchLanding borderNone outlineNone pY10 mY20 pX10"
                                        />
                                        <button
                                            onClick={() => sendMessage("btn")}
                                            ref={sendRef}
                                            style={{
                                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                            }}
                                            className="sendMessageButtonInSession fontSize30 verticallyAlignMid borderRadius50 borderNone Pregular colOffWhite mY20 pX14 mXAuto"
                                        >
                                            &gt;
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

function getData(state) {
    return {
        data: state.auth.authData,
    };
}

export default connect(getData)(InSession);
