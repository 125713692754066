import ReviewsTitleStar from "../../components/ReviewsTitleStar/ReviewsTitleStar";
import ReviewsCard from "../../components/ReviewsCard/ReviewsCard";
import ReviewStarsTags from "../../components/ReviewStarsTags/ReviewStarsTags";
import CloseButton from "../../components/buttons/CloseButton/CloseButton";
import { useLocation, useNavigate } from "react-router-dom";
import { getReviews } from "../../api/index.js";
// import Moment from "moment";
import { useEffect, useState } from "react";
import { RiArrowLeftSLine } from "react-icons/ri";
import { connect } from "react-redux";

function Reviews(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const id = props.id;

    if (
        !props.company._id ||
        !props.user._id ||
        (props.user.role === "client" && !location?.state?.id && !id)
    ) {
        navigate("/LogIn");
    }

    const parentEvent = props.parentEvent;

    const [reviews, setReviews] = useState([]);
    // const [avgRating, setAvgRating] = useState(0);
    const [filter, setFilter] = useState(0);

    useEffect(() => {
        async function getResults() {
            let queryString = `?populate=clientID&mentorID=${
                id ? id : location?.state?.mentorID
            }&sort=-createdAt`;

            if (filter > 0) {
                queryString += `&rating=${filter}`;
            }

            const { data } = await getReviews(queryString);
            setReviews(data.data.reviews);
        }

        getResults();
    }, [id, location?.state?.mentorID]);

    // function goHome() {
    // 	navigate('/OnboardingMentor12WelcomePage');
    // }

    return (
        <div className="Reviews">
            <div
                className={
                    location?.state?.fullPage
                        ? "mTop70 mBottom backColOffWhite w100"
                        : "mXAuto mTop70 mBottom50 reviewsMain backColWhite borderRadius24 w900"
                }
            >
                <div
                    className={
                        location?.state?.fullPage
                            ? "w90 mXAuto pY50"
                            : "mXAuto reviewsSubb1 pY50"
                    }
                >
                    <div className="mBottom10">
                        <div className="verticallyAlignMidJustConStart">
                            {location?.state?.fullPage && (
                                <div className="pRight10">
                                    <button
                                        onClick={() => navigate(-1)}
                                        className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding"
                                    >
                                        <RiArrowLeftSLine
                                            size="2em"
                                            className="colPrimaryGreen"
                                        />
                                    </button>
                                </div>
                            )}

                            <h4 className="P1bold textAlignLeft mRight10">
                                Reviews
                            </h4>
                            <div className="fakeForReviewHead1">
                                <ReviewsTitleStar reviews={reviews} />
                            </div>
                            {!location?.state?.fullPage && (
                                <CloseButton parentEvent={parentEvent} />
                            )}
                        </div>
                        <div className="fakeForReviewHead2">
                            <ReviewsTitleStar reviews={reviews} />
                        </div>
                        <ReviewStarsTags
                            selected={filter}
                            parentEvent={setFilter}
                        />
                    </div>
                    <div className="mXAuto reviewsScrollMain">
                        <div className="reviewsScrollSubb">
                            <div className="verticallyAlignMidStretchStart flexWrap">
                                {reviews &&
                                    reviews.map((el, i) => {
                                        if (
                                            filter > 0 &&
                                            el.rating !== filter
                                        ) {
                                            return <></>;
                                        }

                                        return (
                                            <ReviewsCard key={i} review={el} />
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getData(state) {
    return {
        company: state.auth.authData?.company,
        user: state.auth.authData?.data.user || {},
    };
}

export default connect(getData)(Reviews);
