
const ReviewStarsTags = ({selected, parentEvent}) => {

   return (
      	<div className="verticallyAlignMidJustConStart flexWrap">
    		<div onClick={() => parentEvent(0)} className={`${selected === 0 && "backColPrimaryLight"} mRight10 cursorPointer colPrimaryGreen Small-LabelCaps pY6 pX10 borderRadiusForSocialMediaButtons`}>All</div>
    		<div onClick={() => parentEvent(1)} className={`${selected === 1 && "backColPrimaryLight"} mRight10 cursorPointer colPrimaryGreen Small-LabelCaps pY6 pX10 borderRadiusForSocialMediaButtons`}>1 star</div>
  			<div onClick={() => parentEvent(2)} className={`${selected === 2 && "backColPrimaryLight"} mRight10 cursorPointer colPrimaryGreen Small-LabelCaps pY6 pX10 borderRadiusForSocialMediaButtons`}>2 stars</div>
  			<div onClick={() => parentEvent(3)} className={`${selected === 3 && "backColPrimaryLight"} mRight10 cursorPointer colPrimaryGreen Small-LabelCaps pY6 pX10 borderRadiusForSocialMediaButtons`}>3 stars</div>
  			<div onClick={() => parentEvent(4)} className={`${selected === 4 && "backColPrimaryLight"} mRight10 cursorPointer colPrimaryGreen Small-LabelCaps pY6 pX10 borderRadiusForSocialMediaButtons`}>4 stars</div>
  			<div onClick={() => parentEvent(5)} className={`${selected === 5 && "backColPrimaryLight"} mRight10 cursorPointer colPrimaryGreen Small-LabelCaps pY6 pX10 borderRadiusForSocialMediaButtons`}>5 stars</div>
    	</div>
   );
}

export default ReviewStarsTags;
