import { Link } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import { connect } from "react-redux";

function HowMentorXWorks(props) {
    const company = props.data.company;
    const user = props.data?.data?.user;
    // console.log("company", company);

    //-------- Check all cases when the back arrow should be hiden --------//

    return (
        <div className="HowMentorXWorks">
            <div className="verticallyAlignMidJustConStart w80 mXAuto mTop70 mBottom40 HowMentorXWorksSubWrapper">
                {user?.mentorOnboarding?.activeMentor && (
                    <Link to="/MentorHome2SettingsPanel">
                        <div className="posRel mRight20">
                            <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                                <RiArrowLeftSLine
                                    size="2em"
                                    className="colPrimaryGreen"
                                />
                            </button>
                        </div>
                    </Link>
                )}
                <h2 className="textAlignLeft H1regular">
                    How {company.whiteLabel.texts.label1Text} Works
                </h2>
            </div>
            <div className="verticallyAlignMid w80 mXAuto HowMentorXWorksImgMainForRes2">
                <div className="HowMentorXWorksAllText mRightAuto">
                    <h4 className="H2medium textAlignLeft">1. Search</h4>
                    <h6 className="P1regular textAlignLeft">
                        Start by browsing{" "}
                        {company.whiteLabel.texts.label3MentorPlural}. Apply
                        fillters to tailor your search and personalize findings.
                        You can also save your favorites for later.
                    </h6>
                </div>
                <div className="mLeftAuto HowMentorXWorksImgMain overFlowXHidden">
                    <img
                        className="w100"
                        alt=""
                        src={company.whiteLabel.images.mentorsApply}
                    />
                </div>
            </div>
            <div className="verticallyAlignMid w80 mXAuto HowMentorXWorksImgMainForDesk">
                <div className="mRightAuto HowMentorXWorksImgMain overFlowXHidden">
                    <img
                        className="w100"
                        alt=""
                        src={company.whiteLabel.images.mentorsApply}
                    />
                </div>
                <div className="HowMentorXWorksAllText">
                    <h4 className="H2medium textAlignLeft">2. Book</h4>
                    <h6 className="P1regular textAlignLeft">
                        Once you've found the right{" "}
                        {company.whiteLabel.texts.label4SessionSingle} for you,
                        learn about the{" "}
                        {company.whiteLabel.texts.label2MentorSingle}, read past
                        reviews and get details about the{" "}
                        {company.whiteLabel.texts.label4SessionSingle}. When
                        you're ready just book in a couple of clicks.
                    </h6>
                </div>
            </div>
            <div className="verticallyAlignMid w80 mXAuto HowMentorXWorksImgMainForRes3">
                <div className="HowMentorXWorksAllText">
                    <h4 className="H2medium textAlignLeft">3. Done</h4>
                    <h6 className="P1regular textAlignLeft">
                        That's it! Your{" "}
                        {company.whiteLabel.texts.label4SessionSingle} will
                        appear in your schedule. Press view, connect and enjoy
                        your {company.whiteLabel.texts.label4SessionSingle}!{" "}
                    </h6>
                </div>
                <div className="mLeftAuto HowMentorXWorksImgMain overFlowXHidden">
                    <img
                        className="w100"
                        alt=""
                        src={company.whiteLabel.images.mentorsApply}
                    />
                </div>
            </div>
        </div>
    );
}

function getUser(state) {
    return {
        data: state.auth.authData,
    };
}

export default connect(getUser)(HowMentorXWorks);
