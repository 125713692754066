import { Link } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mentorOnboarding } from "../../actions/auth.js";
import AvailabilityCard from "../../components/AvailabilityCard/AvailabilityCard.js";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPen,
    faCheck,
    faCheckCircle,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Moment from "moment";
import { RiArrowLeftSLine } from "react-icons/ri";
import { Datepicker, setOptions } from "@mobiscroll/react";

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update the state to force render
}

function OnboardingMentor8SetAvailability(props) {
    setOptions({
        theme: "ios",
        themeVariant: "light",
    });

    const company = props.company;

    // const [formData, setFormData] = useState('');
    const [update, setUpdate]   = useState(false);
    const [date, setDate]       = useState("");
    const [dates, setDates]     = useState(props.user.availabilityExcludedDates.dates);

    const [excludedSelected, setExcludedSelected] = useState(
        props.user.availabilityExcludedDates.selected
    );

    //translate DB availability to local time zone (days of week might change!!!!)
    let availabilityFromDB = props.user.availability.map((el) => el);

    let availabilityInLocalDays = [
        {
            day: "Monday",
            selected: false,
            time: [],
        },
        {
            day: "Tuesday",
            selected: false,
            time: [],
        },
        {
            day: "Wednesday",
            selected: false,
            time: [],
        },
        {
            day: "Thursday",
            selected: false,
            time: [],
        },
        {
            day: "Friday",
            selected: false,
            time: [],
        },
        {
            day: "Saturday",
            selected: false,
            time: [],
        },
        {
            day: "Sunday",
            selected: false,
            time: [],
        },
    ];

    availabilityFromDB.forEach((dayOfWeek, index) => {
        dayOfWeek.time.forEach((timePeriod) => {
            let startingTimeDayOfWeek   = Moment(timePeriod.from).isoWeekday(); // 1-7
            let endingTimeDayOfWeek     = Moment(timePeriod.to).isoWeekday(); // 1-7
            // console.log(startingTimeDayOfWeek - 1);

            if (
                startingTimeDayOfWeek - 1 === index &&
                endingTimeDayOfWeek - 1 === index
            ) {
                availabilityInLocalDays[index]["time"].push(timePeriod);
                availabilityInLocalDays[index]["selected"] =
                    availabilityFromDB[index]["selected"];
            } else {
                // If it is not Monday, subtract 1
                if (startingTimeDayOfWeek - 1 !== 0) {
                    // If starting time is 'in the day before' (in my time zone)
                    if (startingTimeDayOfWeek === index - 1) {
                        // If ending time is also in the day before
                        if (endingTimeDayOfWeek === index - 1) {
                            availabilityInLocalDays[index - 1]["time"].push(
                                timePeriod
                            );
                            availabilityInLocalDays[index - 1]["selected"] =
                                availabilityFromDB[index - 1]["selected"];
                        } else {
                            //ending time is 'the same day' -> segment 'until' the midnight should be added to previous day and 'after' the midnight in this day
                            let previousDayEndTimeStamp = Moment(
                                timePeriod.from
                            ).endOf("day");
                            let thisDayStartTimeStamp = Moment(
                                timePeriod.to
                            ).startOf("day");

                            availabilityInLocalDays[index - 1]["time"].push({
                                from: timePeriod.from,
                                to: previousDayEndTimeStamp,
                            });
                            availabilityInLocalDays[index - 1]["selected"] =
                                availabilityFromDB[index - 1]["selected"];
                            availabilityInLocalDays[index]["time"].push({
                                from: thisDayStartTimeStamp,
                                to: timePeriod.to,
                            });
                            availabilityInLocalDays[index]["selected"] =
                                availabilityFromDB[index]["selected"];
                        }
                    }
                } else {
                    //Monday
                    // If starting time is 'in the day before' (in my time zone)
                    if (startingTimeDayOfWeek === 6) {
                        // If ending time is also in the day before
                        if (endingTimeDayOfWeek === 6) {
                            availabilityInLocalDays[6]["time"].push(timePeriod);
                            availabilityInLocalDays[6]["selected"] =
                                availabilityFromDB[6]["selected"];
                        } else {
                            //ending time is 'the same day' -> segment 'until' the midnight should be added to previous day and 'after' the midnight in this day
                            let previousDayEndTimeStamp = Moment(
                                timePeriod.from
                            ).endOf("day");
                            let thisDayStartTimeStamp = Moment(
                                timePeriod.to
                            ).startOf("day");

                            availabilityInLocalDays[6]["time"].push({
                                from: timePeriod.from,
                                to: previousDayEndTimeStamp,
                            });
                            availabilityInLocalDays[6]["selected"] =
                                availabilityFromDB[6]["selected"];
                            availabilityInLocalDays[index]["time"].push({
                                from: thisDayStartTimeStamp,
                                to: timePeriod.to,
                            });
                            availabilityInLocalDays[index]["selected"] =
                                availabilityFromDB[index]["selected"];
                        }
                    }
                }

                // If it is not Sunday, add 1
                if (endingTimeDayOfWeek - 1 !== 6) {
                    // If ending time is 'in the day after' (in my time zone)
                    if (endingTimeDayOfWeek === index + 1) {
                        // If starting time is also in the day after
                        if (startingTimeDayOfWeek === index + 1) {
                            availabilityInLocalDays[index + 1]["time"].push(
                                timePeriod
                            );
                            availabilityInLocalDays[index + 1]["selected"] =
                                availabilityFromDB[index + 1]["selected"];
                        } else {
                            //starting time is 'the same day' -> segment 'until' the midnight should be added to this day and 'after' the midnight in next day
                            let thisDayEndTimeStamp = Moment(
                                timePeriod.from
                            ).endOf("day");
                            let nextDayStartTimeStamp = Moment(
                                timePeriod.to
                            ).startOf("day");

                            availabilityInLocalDays[index]["time"].push({
                                from: timePeriod.from,
                                to: thisDayEndTimeStamp,
                            });
                            availabilityInLocalDays[index]["selected"] =
                                availabilityFromDB[index]["selected"];
                            availabilityInLocalDays[index + 1]["time"].push({
                                from: nextDayStartTimeStamp,
                                to: timePeriod.to,
                            });
                            availabilityInLocalDays[index + 1]["selected"] =
                                availabilityFromDB[index + 1]["selected"];
                        }
                    }

                    // If it is Sunday
                } else {
                    // If ending time is 'in the day after' (in my time zone)
                    if (endingTimeDayOfWeek === 1) {
                        // If starting time is also in the day after
                        if (startingTimeDayOfWeek === 1) {
                            availabilityInLocalDays[0]["time"].push(timePeriod);
                            availabilityInLocalDays[0]["selected"] =
                                availabilityFromDB[0]["selected"];
                        } else {
                            //starting time is 'the same day' -> segment 'until' the midnight should be added to this day and 'after' the midnight in next day(Monday)
                            let thisDayEndTimeStamp = Moment(
                                timePeriod.from
                            ).endOf("day");
                            let nextDayStartTimeStamp = Moment(
                                timePeriod.to
                            ).startOf("day");

                            availabilityInLocalDays[index]["time"].push({
                                from: timePeriod.from,
                                to: thisDayEndTimeStamp,
                            });
                            availabilityInLocalDays[index]["selected"] =
                                availabilityFromDB[index]["selected"];
                            availabilityInLocalDays[0]["time"].push({
                                from: nextDayStartTimeStamp,
                                to: timePeriod.to,
                            });
                            availabilityInLocalDays[0]["selected"] =
                                availabilityFromDB[0]["selected"];
                        }
                    }
                }
            }
        });
    });

    const formData = {
        //////////////////////
        setPayment: true,
        //////////////////////
        setAvailability: true,
        type: "onboard",
        availability: availabilityInLocalDays,
        availabilityExcludedDates: props.user.availabilityExcludedDates,
    };

    let backLink = "OnboardingMentor7CreateYourFirstSession";

    if (props.user.mentorOnboarding.activeMentor) {
        backLink = "MentorHome2SettingsPanel";
        formData.type = "profile";
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (!company._id || !props.user._id) {
        navigate("/LogIn");
    }

    function goToNextPage() {
        //const availabilityInUTC = [...formData.availability];

        // let utcDifferenceinMS = Moment().utcOffset() * 60 * 1000;

        const availabilityInUTC = [
            {
                day: "Monday",
                selected: false,
                time: [],
            },
            {
                day: "Tuesday",
                selected: false,
                time: [],
            },
            {
                day: "Wednesday",
                selected: false,
                time: [],
            },
            {
                day: "Thursday",
                selected: false,
                time: [],
            },
            {
                day: "Friday",
                selected: false,
                time: [],
            },
            {
                day: "Saturday",
                selected: false,
                time: [],
            },
            {
                day: "Sunday",
                selected: false,
                time: [],
            },
        ];

        formData.availability.forEach((dayOfWeek, index) => {
            dayOfWeek.time.forEach((timePeriod) => {
                let startingTimeDayOfWeek = Moment.utc(
                    timePeriod.from
                ).isoWeekday(); // 1-7
                let endingTimeDayOfWeek = Moment.utc(
                    timePeriod.to
                ).isoWeekday(); // 1-7
                // console.log(startingTimeDayOfWeek - 1);

                if (
                    startingTimeDayOfWeek - 1 === index &&
                    endingTimeDayOfWeek - 1 === index
                ) {
                    availabilityInUTC[index]["time"].push(timePeriod);
                    availabilityInUTC[index]["selected"] = true;
                } else {
                    // If it is not Monday, subtract 1
                    if (startingTimeDayOfWeek - 1 !== 0) {
                        // If starting time is 'in the day before' (in my time zone)
                        if (startingTimeDayOfWeek === index - 1) {
                            // If ending time is also in the day before
                            if (endingTimeDayOfWeek === index - 1) {
                                availabilityInUTC[index - 1]["time"].push(
                                    timePeriod
                                );
                                availabilityInUTC[index - 1]["selected"] = true;
                            } else {
                                //ending time is 'the same day' -> segment 'until' the midnight should be added to previous day and 'after' the midnight in this day
                                let previousDayEndTimeStamp = Moment.utc(
                                    timePeriod.from
                                ).endOf("day");
                                let thisDayStartTimeStamp = Moment.utc(
                                    timePeriod.to
                                ).startOf("day");

                                availabilityInUTC[index - 1]["time"].push({
                                    from: timePeriod.from,
                                    to: previousDayEndTimeStamp,
                                });
                                availabilityInUTC[index - 1]["selected"] = true;
                                availabilityInUTC[index]["time"].push({
                                    from: thisDayStartTimeStamp,
                                    to: timePeriod.to,
                                });
                                availabilityInUTC[index]["selected"] = true;
                            }
                        }
                    } else {
                        //Monday
                        // If starting time is 'in the day before' (in my time zone)
                        if (startingTimeDayOfWeek === 6) {
                            // If ending time is also in the day before
                            if (endingTimeDayOfWeek === 6) {
                                availabilityInUTC[6]["time"].push(timePeriod);
                                availabilityInUTC[6]["selected"] = true;
                            } else {
                                //ending time is 'the same day' -> segment 'until' the midnight should be added to previous day and 'after' the midnight in this day
                                let previousDayEndTimeStamp = Moment.utc(
                                    timePeriod.from
                                ).endOf("day");
                                let thisDayStartTimeStamp = Moment.utc(
                                    timePeriod.to
                                ).startOf("day");

                                availabilityInUTC[6]["time"].push({
                                    from: timePeriod.from,
                                    to: previousDayEndTimeStamp,
                                });
                                availabilityInUTC[6]["selected"] = true;
                                availabilityInUTC[index]["time"].push({
                                    from: thisDayStartTimeStamp,
                                    to: timePeriod.to,
                                });
                                availabilityInUTC[index]["selected"] = true;
                            }
                        }
                    }

                    // If it is not Sunday, add 1
                    if (endingTimeDayOfWeek - 1 !== 6) {
                        // If ending time is 'in the day after' (in my time zone)
                        if (endingTimeDayOfWeek === index + 1) {
                            // If starting time is also in the day after
                            if (startingTimeDayOfWeek === index + 1) {
                                availabilityInUTC[index + 1]["time"].push(
                                    timePeriod
                                );
                                availabilityInUTC[index + 1]["selected"] = true;
                            } else {
                                //starting time is 'the same day' -> segment 'until' the midnight should be added to this day and 'after' the midnight in next day
                                let thisDayEndTimeStamp = Moment.utc(
                                    timePeriod.from
                                ).endOf("day");
                                let nextDayStartTimeStamp = Moment.utc(
                                    timePeriod.to
                                ).startOf("day");

                                availabilityInUTC[index]["time"].push({
                                    from: timePeriod.from,
                                    to: thisDayEndTimeStamp,
                                });
                                availabilityInUTC[index]["selected"] = true;
                                availabilityInUTC[index + 1]["time"].push({
                                    from: nextDayStartTimeStamp,
                                    to: timePeriod.to,
                                });
                                availabilityInUTC[index + 1]["selected"] = true;
                            }
                        }

                        // If it is Sunday
                    } else {
                        // If ending time is 'in the day after' (in my time zone)
                        if (endingTimeDayOfWeek === 1) {
                            // If starting time is also in the day after
                            if (startingTimeDayOfWeek === 1) {
                                availabilityInUTC[0]["time"].push(timePeriod);
                                availabilityInUTC[0]["selected"] = true;
                            } else {
                                //starting time is 'the same day' -> segment 'until' the midnight should be added to this day and 'after' the midnight in next day(Monday)
                                let thisDayEndTimeStamp = Moment.utc(
                                    timePeriod.from
                                ).endOf("day");
                                let nextDayStartTimeStamp = Moment.utc(
                                    timePeriod.to
                                ).startOf("day");

                                availabilityInUTC[index]["time"].push({
                                    from: timePeriod.from,
                                    to: thisDayEndTimeStamp,
                                });
                                availabilityInUTC[index]["selected"] = true;
                                availabilityInUTC[0]["time"].push({
                                    from: nextDayStartTimeStamp,
                                    to: timePeriod.to,
                                });
                                availabilityInUTC[0]["selected"] = true;
                            }
                        }
                    }
                }
            });
        });

        let excludedDates = [];

        dates.forEach((exDate, i) => {
            excludedDates[i] = {
                from: Moment("00:00:00 " + exDate.date).format("x"),
                date: exDate.date,
            };
        });

        formData.availabilityExcludedDates.dates = excludedDates;

        // console.log(formData)
        // return;

        dispatch(mentorOnboarding(formData, navigate));
    }

    const availabilityChange = (name, arr, selected) => {
        // console.log(name, arr, selected);

        formData.availability.filter((av) => {
            // av.day === name ? {av.time = arr && av.selected = selected} : av
            if (av.day === name) {
                av.time = arr;
                av.selected = selected;
            }
        });
    };

    const forceUpdate = useForceUpdate();

    const convertDate = (d) => {
        return Moment(d).format("DD MMM, YYYY");
    };

    function removeDate(val) {
        const datesObj = dates;
        datesObj.splice(
            datesObj.findIndex((el) => el === val),
            1
        );

        setDates(datesObj);
        forceUpdate();
    }

    function addDate() {
        const datesObj = dates;

        datesObj.push({ date });
        setDates(datesObj);
        setDate("");
        forceUpdate();
    }

    const finishTime = () => {
        setUpdate(false);
    };

    function changeExcluded(e) {
        formData.availabilityExcludedDates.selected = !e.target.checked;
    }

    function goToPrevStep() {
        dispatch(mentorOnboarding({ setAvailabilityFalse: true }, navigate));
    }

    return (
        <>
            <div className="OnboardingMentor8SetAvailability">
                <div className="verticallyAlignMidJustConStart w80 mXAuto mTop70 mBottom40 OnboardingMentor8SetAvailabilitySubWrapper">
                    {props.user.mentorOnboarding.activeMentor ? (
                        <Link to={"/" + backLink}>
                            <div className="posRel mRight20">
                                <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                                    <RiArrowLeftSLine
                                        size="2em"
                                        className="colPrimaryGreen"
                                    />
                                </button>
                            </div>
                        </Link>
                    ) : (
                        <div onClick={goToPrevStep} className="posRel mRight20">
                            <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                                <RiArrowLeftSLine
                                    size="2em"
                                    className="colPrimaryGreen"
                                />
                            </button>
                        </div>
                    )}
                    <h2 className="textAlignLeft H1regular">Availability</h2>
                </div>
                <div className="w80 mXAuto mBottom70 OnboardingMentor8SetAvailabilityMainSubb">
                    <h5 className="textAlignLeft mBottom20 P1medium">
                        Set your daily availability
                    </h5>
                    <div className="backColInput borderRadius24 w820 OnboardingMentor8SetAvailabilitySubb">
                        <h3 className="textAlignLeft mLeft40 pY30 H2regular">
                            Daily Availability
                        </h3>
                        {formData.availability.map((el, i) => {
                            return (
                                <AvailabilityCard
                                    key={i}
                                    props={el}
                                    parentEvent={availabilityChange}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="w80 mXAuto mTop30 mBottom50 OnboardingMentor8SetAvailabilityMainSubb2">
                    {/* <button className="verticallyAlignMid borderRadius12 borderNone mBottom30 P1bold colOffWhite backgroundGradientForButtons pLeft60 pRight60 pTop10 pBottom10 mRightAuto" onClick={goToNextPage}>Update Availability</button> */}
                    <div className="backColInput borderRadius24 w820 OnboardingMentor8SetAvailabilitySubb mTop40">
                        <div className="OnboardingMentor8SetAvailabilitySubb1 verticallyAlignMidJustConStart mLeft40 pY30">
                            <div className="verticallyAlignMidJustConStart mRight40">
                                <div className="onOffSwitchInputOnFilltersMain posRel verticallyAlignMidJustConStart colPlaceHolderGray cursorPointer">
                                    <input
                                        defaultChecked={
                                            !props.user
                                                .availabilityExcludedDates
                                                .selected
                                        }
                                        onChange={(e) => {
                                            changeExcluded(e);
                                            setExcludedSelected(
                                                !e.target.checked
                                            );
                                        }}
                                        className="onOffSwitchInputOnFillters"
                                        type="checkbox"
                                    />
                                    <span className="slider round"></span>
                                </div>
                                <h5 className="P1bold textAlignLeft mLeft8 colLinksAndTitlesBlack">
                                    Exclude Dates
                                </h5>
                            </div>

                            {(() => {
                                if (update === false) {
                                    return (
                                        <>
                                            <div className="verticallyAlignMidJustConStart OnboardingMentor8SetAvailabilitySubbForExclude w100">
                                                <div className="mY8 sessionDurationPMain">
                                                    {dates &&
                                                        dates.map((el, i) => {
                                                            return (
                                                                <p
                                                                    key={i}
                                                                    className="Pmedium sessionDurationP"
                                                                >
                                                                    {convertDate(
                                                                        el.date
                                                                    )}
                                                                </p>
                                                            );
                                                        })}
                                                </div>
                                                {excludedSelected && (
                                                    <div className="mLeftAuto mRight40 pencilIcon">
                                                        <FontAwesomeIcon
                                                            onClick={() =>
                                                                setUpdate(true)
                                                            }
                                                            icon={faPen}
                                                            size="1x"
                                                            className="cursorPointer"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    );
                                } else {
                                    return (
                                        <>
                                            <div className="mY8">
                                                {dates &&
                                                    dates.map((el, i) => {
                                                        return (
                                                            <span
                                                                key={i}
                                                                className="verticallyAlignMidJustConStart"
                                                            >
                                                                <p className="Pmedium">
                                                                    {convertDate(
                                                                        el.date
                                                                    )}
                                                                </p>
                                                                <FontAwesomeIcon
                                                                    color="red"
                                                                    onClick={() =>
                                                                        removeDate(
                                                                            el.date
                                                                        )
                                                                    }
                                                                    icon={
                                                                        faTimes
                                                                    }
                                                                    size="1x"
                                                                    className="mLeft10 cursorPointer"
                                                                />
                                                            </span>
                                                        );
                                                    })}
                                                <div className="verticallyAlignMidJustConStart mRight40 mY1 OnboardingMentor8SetAvailabilitySubbForExcludeDatePickerMain">
                                                    <form className="verticallyAlignMidJustConStart OnboardingMentor8SetAvailabilitySubbForExcludeDatePicker">
                                                        {/* <input className="pY6 outlineNone borderNone borderRadius12 colLabelGray Small-Labelnormal pLeft4" type="date" name="date" onChange={(e) => setDate(e.target.value)}/> */}
                                                        {console.log("promena")}
                                                        <Datepicker
                                                            controls={[
                                                                "calendar",
                                                            ]}
                                                            inputComponent="input"
                                                            className={
                                                                "pY6 outlineNone borderNone borderRadius12 colLabelGray Small-Labelnormal pLeft4"
                                                            }
                                                            min={Moment().format(
                                                                "YYYY/MM/DD"
                                                            )}
                                                            name={"date"}
                                                            onChange={(e) =>
                                                                setDate(
                                                                    Moment(
                                                                        e.valueText
                                                                    ).format(
                                                                        "YYYY/MM/DD"
                                                                    )
                                                                )
                                                            }
                                                            defaultValue={date}
                                                        />
                                                        <div
                                                            color="green"
                                                            onClick={addDate}
                                                            icon={faCheck}
                                                            size="1x"
                                                            style={{
                                                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                                            }}
                                                            className="OnboardingMentor8SetAvailabilitySubbForExcludeButton mLeft10 cursorPointer Small-Labelnormal colOffWhite pY4 pX4 borderRadius12 mRight20"
                                                        >
                                                            Add
                                                        </div>
                                                    </form>
                                                    <FontAwesomeIcon
                                                        className="colPrimaryGreen cursorPointer verticallyAlignMidJustConStart"
                                                        onClick={finishTime}
                                                        icon={faCheckCircle}
                                                        size="2x"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    );
                                }
                            })()}
                        </div>
                    </div>
                    <button
                        style={{
                            color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                            backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                        }}
                        className="OnboardingMentor8SetAvailabilitySubbForExcludeButtonUpdate verticallyAlignMid borderRadius12 borderNone mTop50 P1regular colOffWhite pLeft60 pRight60 pY10 mRightAuto"
                        onClick={goToNextPage}
                    >
                        Update Availability
                    </button>
                </div>
            </div>
        </>
    );
}

function getMentorsStatus(state) {
    return {
        user: state.auth.authData?.data.user || {},
        company: state.auth.authData.company,
    };
}

export default connect(getMentorsStatus)(OnboardingMentor8SetAvailability);
