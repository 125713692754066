import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { RiArrowLeftSLine } from "react-icons/ri";
import { getCompany } from "../../api";
import CloseButton from "../../components/buttons/CloseButton/CloseButton";

function AdminCompany({ id, parentEvent }) {
    const navigate = useNavigate();
    // const location = useLocation();

    const [company, setCompany] = useState({});

    useEffect(() => {
        async function getData() {
            const { data } = await getCompany(id);

            if (!data.data.company) {
                navigate(-1);
            } else {
                // console.log(data.data.company);
                setCompany(data.data.company);
            }
        }

        getData();
    }, [id, navigate]);

    return (
        <div className="companyProfileAdmin w80 mXAuto backColOffWhite">
            <div className="verticallyAlignMidJustConStart w100 mXAuto mTop70 mBottom40 OnboardingMentor8SetAvailabilitySubWrapper">
                <CloseButton parentEvent={parentEvent} />
            </div>
            <div className="verticallyAlignMid w100 mXAuto mTop70 mBottom40 OnboardingMentor8SetAvailabilitySubWrapper">
                {company.active ? (
                    <>
                        <div className="mLeft30">
                            <h1 className="H1bold">{company?.name}</h1>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="mLeft30">
                            <h1 className="H1bold">
                                Company is not active yet...
                            </h1>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default AdminCompany;
