import { useEffect, useState } from "react";
import { BsCameraVideo, BsChatText, BsTelephone } from "react-icons/bs";
import { connect } from "react-redux";
import { getUsers } from "../../api";
import CloseButton from "../buttons/CloseButton/CloseButton";

const MentorSingleView = ({ mentorID, parentEvent, data }) => {
  const [mentor, setMentor] = useState({});

  const company = data.company;

  useEffect(() => {
    async function getData() {
      const { data } = await getUsers(
        `?companyID=${company._id}&email=${mentorID}`
      );
      setMentor(data.data.users[0]);
    }

    getData();
  }, [mentorID, company._id]);

  function getInitials(name) {
    if (!name) {
      return "";
    }

    let words = name.split(" ");

    if (words[0] && words[1]) {
      return (
        words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
      );
    } else {
      return "";
    }
  }

  return (
    <div className="backColElementBackgroundGray borderRadius12 mRight8 w100 mBottom20">
      <div className="w90 mXAuto h100">
        <div className="mTop40 NewNotePoUpSub1Wrapper">
          <CloseButton className="mLeftAuto" parentEvent={parentEvent} />
        </div>
        <div className="verticallyAlignMidJustConStart">
          <div className="pRight1 mRight5 mY10">
            <div className="verticallyAlignMidJustConStart MentorSingleViewImg borderRadius50">
              {mentor?.photo ? (
                <img alt="" className="w100 h100" src={mentor?.photo} />
              ) : (
                <div className="userInitialsSmall">
                  <p>{getInitials(mentor?.fullName)}</p>
                </div>
              )}
            </div>
          </div>
          <h2 className="mRightAuto H3medium">{mentor?.fullName}</h2>
        </div>
        <div className="verticallyAlignMidJustConStart w100 mY20">
          <div className="w50" style={{maxHeight : "500px", overflowY : "auto"}}>
            <div className="w100 pY10">
              <h4 className="textAlignLeft">Biography:</h4>
              <p className="textAlignLeft w80">{mentor.biography}</p>
            </div>
            <div className="w100 pY10">
              <h4 className="textAlignLeft">About sessions:</h4>
              <p className="textAlignLeft w80">
                {mentor?.workExperience?.sessionTextarea}
              </p>
            </div>
            <div className="w100 pY10">
              <h4 className="textAlignLeft">Experience:</h4>
              <p className="textAlignLeft w80">
                {mentor?.workExperience?.experienceTextarea}
              </p>
            </div>
          </div>
          <div className="w50">
            <div className="w100 pY10">
              <div>
                <h5 className="P1medium textAlignCenter mBottom10 w50 mXAuto">
                  Comunication
                </h5>
                <div className="verticallyAlignMid w50 mXAuto">
                  {mentor?.workExperience?.experience &&
                    mentor?.workExperience?.experience.indexOf("video") >
                      -1 && <BsCameraVideo size="2em" className="mXAuto" />}
                  {mentor?.workExperience?.experience &&
                    mentor?.workExperience?.experience.indexOf("audio") >
                      -1 && <BsTelephone size="2em" className="mXAuto" />}
                  {mentor?.workExperience?.experience &&
                    mentor?.workExperience?.experience.indexOf("chat") > -1 && (
                      <BsChatText size="2em" className="mXAuto" />
                    )}
                </div>
              </div>
            </div>
            <div className="w100 pY10">
              <h5 className="P2medium textAlignCenter mXAuto">
                {mentor?.workExperience?.yearsOfPracticing} years of practicing
              </h5>
            </div>
            <div className="w100 pY10">
              <h5 className="P2medium textAlignCente mXAuto">
                {mentor?.workExperience?.hoursAvailable} hours available (per
                week)
              </h5>
            </div>
            <div className="w100 pY10">
              <h5 className="P2medium textAlignCente mXAuto">
                Website:{" "}
                <a target="_blank" href={mentor?.website}>
                  {mentor?.website}
                </a>
              </h5>
            </div>
            <div className="w100 pY10">
              <h5 className="P2medium textAlignCente mXAuto">
                <a download={true} href={mentor?.cv}>
                  Download CV
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function getData(state) {
  return {
    data: state.auth.authData || {},
  };
}

export default connect(getData)(MentorSingleView);
