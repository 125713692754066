import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSessions } from "../../api/index.js";
import MentorHome1 from "../MentorHome1/MentorHome1.js";

function OnboardingMentor12WelcomePage(props) {
    const company = props.data.company;

    const navigate = useNavigate();
    const [sessions, setSessions] = useState([]);
    const [firstLogin, setFirstLogin] = useState(true);

    function goToServices() {
        navigate("/OnboardingMentor13Service");
    }

    useEffect(() => {
        async function getResults() {
            if (!company._id || !props.user._id) {
                navigate("/LogIn");
            }

            let queryString = `?populate=serviceID,clientID&mentorID=${props.user._id}&isPayed=true`;

            const { data } = await getSessions(queryString);

            setFirstLogin(false);
            setSessions(data.data.sessions);
        }
        getResults();
    }, [props, company._id, navigate]);

    return (
        <div className="OnboardingMentor12WelcomePage mY10">
            {firstLogin ? (
                <> </>
            ) : sessions.length === 0 ? (
                <div className="verticallyAlignMid mTop50 mBottom70 w70 mXAuto OnboardingMentor12WelcomePageSubb">
                    <div className="verticallyAlignMid welcomeImg mRightAuto OnboardingMentor12WelcomePageSubb1">
                        {company.whiteLabel.images.noStats !== "" ? (
                            <img
                                className="w100 h100 welcomeImg1"
                                alt=""
                                src={company.whiteLabel.images.noStats}
                            />
                        ) : (
                            <img
                                className="w100 h100 welcomeImg1"
                                alt=""
                                src="https://i.ibb.co/fD1yxzd/img.png"
                            />
                        )}
                    </div>
                    <div className="w600 OnboardingMentor12WelcomePageSubb2">
                        <h2 className="textAlignLeft H1medium mBottom10">
                            Welcome,{" "}
                            <span className="colPrimaryGreen italic">
                                {props.user?.fullName?.split(" ")[0]}
                            </span>
                            !
                        </h2>
                        <h3 className="textAlignLeft H2regular mBottom20">
                            No {company.whiteLabel.texts.label5SessionPlural}{" "}
                            stats yet.
                        </h3>
                        <div className="verticallyAlignMid mRightAuto OnboardingMentor12WelcomePageSubb3">
                            <img
                                className="welcomeImg"
                                alt=""
                                src="https://i.ibb.co/fD1yxzd/img.png"
                            />
                        </div>
                        <h5 className="textAlignLeft P1medium w400 mBottom20">
                            {
                                company.whiteLabel.texts
                                    .label6NoSessionDescription
                            }
                        </h5>
                        {/* <button className="verticallyAlignMid w300 borderRadius12 borderNone Pmedium colOffWhite backgroundGradientForButtons pY20 mRightAuto" onClick={goToServices}>Add a New Service</button> */}
                        <button
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            className="verticallyAlignMid w300 borderRadius12 borderNone Pmedium colOffWhite pY20 mRightAuto"
                            onClick={goToServices}
                        >
                            Add a New Service
                        </button>
                    </div>
                </div>
            ) : (
                <MentorHome1 mentor={props.user} sessions={sessions} />
            )}
        </div>
    );
}

function getUserData(state) {
    return {
        data: state.auth.authData,
        user: state.auth.authData?.data.user || {},
    };
}

export default connect(getUserData)(OnboardingMentor12WelcomePage);
