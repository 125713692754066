import { useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

const CVFileUpload = ({props, parentEvent}) => {

    const [filename, setFilename]               = useState(props.mentorExperience.cv);
    const [borderClassName, setBorderClassName] = useState('');

    const onChange = (e) => {
        if (
            e.target.files[0].type !== 'application/pdf' &&
            e.target.files[0].type !== 'application/msword' &&
            e.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
            return false;
        }

        setFilename(e.target.files[0].name ?? '');
        setBorderClassName('');
        parentEvent(e.target.files[0]);
    }

    const hiddenFileInput = useRef(null);

    const triggerSelect = () => {
        hiddenFileInput.current.click();
    }

    if(props.cv === "") {
        setBorderClassName("dashedBorder2");
    }

    return (
        <div className={`${borderClassName} mBottom50`}>
            {(() => {
                if ( filename === "") {
                    return (
                        <div className="cursorPointer borderDashed" onClick={triggerSelect}>
                            <form className="m-3 pt-2">
                            <div>
                                <input
                                    onChange={(e) => onChange(e)}
                                    type="file"
                                    id="cvFileUpload"
                                    className="customFileInput"
                                    accept={"application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                                    style={{display:"none"}}
                                    ref={hiddenFileInput}
                                />
                                <FontAwesomeIcon icon={faUpload} size="4x" className="mTop15 colPrimaryGreen"/>
                            </div>
                        </form>
                        <div className="pb-5">
                            <div>
                                <img alt="" />
                            </div>
                            <h5 className="cursorPointer P1regular colPrimaryGreen"><span className="P1medium">Browse</span> to upload</h5>
                        </div>
                    </div>
                    )
                } else {
                    return (
                        <div onClick={triggerSelect} className="cursorPointer verticallyAlignMid borderRadius12 mRight30">
                            <p className="Pmedium colPrimaryGreen breakWord">{filename}</p>
                            <input
                                onChange={(e) => onChange(e)}
                                type="file"
                                id="cvFileUpload"
                                className="customFileInput"
                                accept={"application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                                style={{display:"none"}}
                                ref={hiddenFileInput}
                            />
                        </div>
                    )
                }
            })()}
        </div>
    );
}

export default CVFileUpload;
