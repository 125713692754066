import { useState, useEffect } from "react";
// import { Link } from 'react-router-dom';
import { useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mentorOnboarding } from "../../actions/auth.js";
// import { RiArrowLeftSLine } from "react-icons/ri";

function MOProfileDetails(props) {
    const company = props.company;

    const initialState = {
        setProfileDetails: true,
        fullname: "",
        biography: "",
        website: "",
    };
    const [formData, setFormData] = useState(initialState);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!company._id || !props.mentorProfDet._id) {
            navigate("/LogIn");
        }
    }, [company._id, props.mentorProfDet._id, navigate]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(mentorOnboarding(formData, navigate));
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        setFormData({
            setProfileDetails: true,
            fullname: props.mentorProfDet.fullName,
            biography: props.mentorProfDet.biography,
            website: props.mentorProfDet.website,
        });
    }, [props.mentorProfDet]);

    return (
        <div className="OnboardingMentor2">
            <div className="w80 mXAuto mY70 backColPrimaryLight borderRadius24">
                {/*<div className=" mLeft30 mTop30">
					<button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
						<RiArrowLeftSLine size="2em" className="colPrimaryGreen" />
					</button>
				</div>*/}

                <form
                    className="verticallyAlignMid mXAuto w80 mY70 profileDetailsForm"
                    onSubmit={handleSubmit}
                >
                    <div className="w60 profileDetailsForm1">
                        <h3 className="H3bold textAlignLeft mBottom20">
                            Profile Details
                        </h3>
                        {/*<p className="Pmedium textAlignLeft mBottom20">Provide a clear profile picture and display name</p>*/}
                        <div>
                            <div className="backColOffWhite profileDetailsForm4 borderRadius12 mBottom20 pRight10 pLeft30">
                                <p className="Smallest-labelsmall-label textAlignLeft pTop10 colLabelGray">
                                    Full name
                                </p>
                                <input
                                    value={formData.fullname}
                                    name="fullname"
                                    className="Pmedium w100 pLeft4 borderNone backColOffWhite borderRadius12 mBottom10"
                                    type="text"
                                    placeholder="Enter Full Name"
                                    required
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="backColOffWhite profileDetailsForm4 borderRadius12 mBottom20 pRight10 pLeft30">
                                <p className="Smallest-labelsmall-label textAlignLeft pTop10 colLabelGray">
                                    Bio
                                </p>
                                <textarea
                                    value={formData.biography}
                                    name="biography"
                                    className="Pmedium pLeft4 w100 borderNone backColOffWhite borderRadius12 mBottom10"
                                    type="text"
                                    placeholder="Short biography"
                                    required
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                            <div className="backColOffWhite borderRadius12 profileDetailsForm4  mBottom10 pRight10 pLeft30">
                                <p className="Smallest-labelsmall-label textAlignLeft pTop10 colLabelGray">
                                    Website
                                </p>
                                <input
                                    value={formData.website}
                                    name="website"
                                    className="Pmedium pLeft4 w100 borderNone backColOffWhite borderRadius12 mBottom10"
                                    type="text"
                                    placeholder="Link to your website"
                                    onChange={handleChange}
                                />
                            </div>
                            <p className="Pregular textAlignLeft colLabelGray">
                                Optional
                            </p>
                        </div>
                    </div>
                    <div className="w40 profileDetailsForm2">
                        <div>
                            <img alt="" />
                        </div>
                        <button
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            className="borderRadius12 borderNone mBottom30 P1regular colOffWhite pLeft60 pRight60 pTop10 pBottom10"
                        >
                            Next
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function getMentorProfileDetails(state) {
    return {
        mentorProfDet: {
            _id: state.auth.authData?.data.user._id || "",
            fullName: state.auth.authData?.data.user.fullName || "",
            biography: state.auth.authData?.data.user.biography || "",
            website: state.auth.authData?.data.user.website || "",
        },
        company: state.auth.authData.company,
    };
}

export default connect(getMentorProfileDetails)(MOProfileDetails);
