import { AiOutlinePushpin, AiFillPushpin } from "react-icons/ai";
import Moment from "moment";

// za Pedju - on click dobijaju borderRadiusForSocialMediaButtons, backColWhite, boxShadowForSearchLanding i gube colLabelGray
const NotesCard = ({note}) => {

    function dateFormat(date) {
        return Moment(date).format("LT, MMM DD")
    }

   return (
      	<div className="backColElementBackgroundGray borderRadius24 mBottom20 w100">
      		<div className="w80 mXAuto">
				<p className="Small-LabelCaps mTop30 mBottom12 colLabelGray textAlignLeft">{dateFormat(note.createdAt)}</p>
				<div className="verticallyAlignMidStretch">
					<p className="P1bold mBottom20 colSecondaryGreen mRightAuto textAlignLeft">{note.header}</p>
					{ note.pinned ? <AiFillPushpin size="1.4em"/> : <AiOutlinePushpin size="1.4em"/>}
				</div>
				<p className="Pregular mBottom20 textAlignLeft">{note.text}</p>
				<p className="mBottom20 colSecondaryGreen textAlignLeft Small-Label-11">{note.serviceID.name}</p>
			</div>
		</div>
   );
}

export default NotesCard;
