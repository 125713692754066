import { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    mentorCreatesService,
    mentorEditService,
    getService,
} from "../../api/index.js";
// import { mentorDataPatch } from '../../actions/user.js';
import { mentorOnboarding } from "../../actions/auth.js";
import { RiArrowLeftSLine, RiArrowDownSLine } from "react-icons/ri";

function OnboardingMentor7CreateYourFirstSession(props) {
    const company = props.data.company;
    const charityCompany = company.charity;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const audioRef = useRef(null);
    const videoRef = useRef(null);

    if (!company._id || !props.data.data.user._id) {
        navigate("/LogIn");
    }

    // const typeOfService = localStorage.getItem('typeOfService');

    const pageType =
        props.data.data.user.mentorOnboarding.firstServiceCompleted &&
        props.data.data.user.mentorOnboarding.activeMentor
            ? true
            : false;

    const initialState = location.state?.service
        ? location.state?.service
        : {
              // setFirstService : true,
              mentorID: props.data.data.user._id,
              name: "",
              duration: "0",
              rate: charityCompany ? "1" : "0",
              methods: ["chat"],
              // categoryID		: "",
              description: "",
              requirements: "",
          };

    const [formData, setFormData] = useState(initialState);

    if (location.state?.service) {
        getServiceData();
    }

    async function getServiceData() {
        try {
            // const queryString = `?_id=${location.state?.service._id}`;
            await getService(location.state?.service._id);
        } catch (error) {
            // console.log(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.methods.length === 0) {
            alert("You need to select at least one method!");
            return false;
        }

        if (location.state?.service) {
            mentorEditService(location.state?.service._id, formData);
        } else {
            mentorCreatesService(formData);
        }

        // await mentorCreatesService(formData, navigate);

        // const user = {
        // 	mentorOnboarding : {
        // 		firstServiceCompleted : true
        // 	}
        // }

        // const response = await mentorDataPatch(user);

        let initialService = "first";

        if (pageType) {
            initialService = "add";
        }

        let data = {
            setFirstService: true,
            type: initialService,
        };

        dispatch(mentorOnboarding(data, navigate));
    };

    const handleChange = (e) => {
        if (e.target.name === "methods") {
            let array = ["chat"];

            if (e.target.value === "video" && e.target.checked) {
                audioRef.current.checked = true;
            } else if (e.target.value === "audio") {
                if (!e.target.checked) {
                    videoRef.current.checked = false;
                }
            }

            if (audioRef.current.checked) {
                array.push("audio");
            }

            if (videoRef.current.checked) {
                array.push("video");
            }

            setFormData({ ...formData, methods: array });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    // function goBackToServices(type) {

    // 	if (type && formData.type === 'add') {
    // 		if (formData.sessionName === '' || formData.duration === '0' || formData.typeOfServices.length === 0 || formData.rate === 0) {
    // 			alert("You need to fill all input fields");
    // 			return false;
    // 		}
    // 	}

    // 	window.location.assign("/OnboardingMentor13Service");
    // }

    return (
        <div className="OnboardingMentor7CreateYourFirstSession">
            <div className="w80 mXAuto mTop70 mBottom70 OnboardingMentor7CreateYourFirstSessionMainSubb">
                <div className="verticallyAlignMidJustConStart mBottom40">
                    {pageType && (
                        <Link to="/OnboardingMentor13Service">
                            <div className="posRel mRight20">
                                <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                                    <RiArrowLeftSLine
                                        size="2em"
                                        className="colPrimaryGreen"
                                    />
                                </button>
                            </div>
                        </Link>
                    )}
                    {(() => {
                        if (pageType) {
                            if (location.state?.service) {
                                return (
                                    <h2 className="H1regular posRel textAlignLeft">
                                        Edit service
                                    </h2>
                                );
                            } else {
                                return (
                                    <h2 className="H1regular posRel textAlignLeft">
                                        Add new Service
                                    </h2>
                                );
                            }
                        } else {
                            return (
                                <h2 className="H1regular textAlignLeft">
                                    Create your first service
                                </h2>
                            );
                        }
                    })()}
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="verticallyAlignMidStretch OnboardingMentor7CreateYourFirstSessionSubb mXAuto">
                        <div className="w50 mXAuto OnboardingMentor7CreateYourFirstSessionSubb1">
                            <p className="textAlignLeft P1bold mBottom12">
                                Service name
                            </p>
                            <input
                                defaultValue={
                                    location?.state?.service?.name ?? ""
                                }
                                name="name"
                                required
                                className="Pregular pLeft8 verticallyAlignMidJustConStartForText mBottom40 selectForStartFormsDiv colLabelGray borderNone backColInput pY14 borderRadius12"
                                onChange={(e) => handleChange(e)}
                            />

                            <h5 className="textAlignLeft P1bold mTop20 mBottom20">
                                Service Description
                            </h5>
                            <div className="verticallyAlignMidJustConStart">
                                <div className="dotForListItem mLeft4 mRight8 backColLabelGray"></div>
                                <p className="textAlignLeft Small-LabelCaps colLabelGray">
                                    Provide specifics about the service
                                </p>
                            </div>
                            <div className="verticallyAlignMidJustConStart">
                                <div className="dotForListItem mLeft4 mRight8 backColLabelGray"></div>
                                <p className="textAlignLeft Small-LabelCaps colLabelGray">
                                    Describe the process
                                </p>
                            </div>
                            <div className="verticallyAlignMidJustConStart mBottom12">
                                <div className="dotForListItem mLeft4 mRight8 backColLabelGray"></div>
                                <p className="textAlignLeft Small-LabelCaps colLabelGray">
                                    What makes you stand out?
                                </p>
                            </div>
                            <div className="borderRadius12 backColInput textareaForStartFormsDiv mBottom40">
                                {/*<p className="textAlignLeft">Tell your clients what they'll do on your session</p>*/}
                                <textarea
                                    defaultValue={
                                        location?.state?.service?.description ??
                                        ""
                                    }
                                    name="description"
                                    className="Pregular textareaForStartForms w100 pLeft8 textAlignLeft mY4 pRight8 colLabelGray borderNone backColInput pY14 borderRadius12"
                                    type="text"
                                    placeholder="Short description"
                                    required
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                            <h5 className="textAlignLeft P1bold mBottom20">
                                Special requirements
                            </h5>
                            <p className="textAlignLeft mBottom8 Small-Labelnormal">
                                Please bring:
                            </p>
                            <div className="borderRadius12 backColInput textareaForStartFormsDiv">
                                <textarea
                                    defaultValue={
                                        location?.state?.service
                                            ?.requirements ?? ""
                                    }
                                    name="requirements"
                                    className="Pregular pLeft8 textAlignLeft w100 textareaForStartForms mY4 colLabelGray pRight8 borderNone backColInput pY14 borderRadius12"
                                    type="text"
                                    placeholder="Requirements"
                                    required
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                        </div>
                        <div className="w50 OnboardingMentor7CreateYourFirstSessionSubb2 mXAuto">
                            <h5 className="textAlignLeft P1bold mBottom12">
                                How long is your{" "}
                                {
                                    company?.whiteLabel?.texts
                                        ?.label4SessionSingle
                                }
                                ?
                            </h5>
                            <div className="posRel selectForStartFormsDiv">
                                <select
                                    defaultValue={
                                        location?.state?.service?.duration ?? ""
                                    }
                                    name="duration"
                                    required
                                    className="Pregular appearanceNone pLeft8 verticallyAlignMidJustConStartForText w100 selectForStartForms borderNone backColInput colLabelGray pY14 borderRadius12"
                                    onChange={(e) => handleChange(e)}
                                >
                                    <option value="">Please select</option>
                                    {props.data?.company?.sessionDuration
                                        .sort(function (a, b) {
                                            return a - b;
                                        })
                                        .map((duration) => {
                                            return (
                                                <option
                                                    key={duration}
                                                    value={duration}
                                                >
                                                    {duration}
                                                </option>
                                            );
                                        })}
                                </select>
                                <span className="posAbs spanForArrow verticallyAlignMid backColTransparent">
                                    <RiArrowDownSLine
                                        size="1.5em"
                                        className="mRight10 colLabelGray"
                                    />
                                </span>
                            </div>
                            <h5 className="textAlignLeft P1bold mTop50 mBottom20">
                                How will you be giving it?
                            </h5>
                            <label className="verticallyAlignMidJustConStart checkBoxContainer">
                                <input
                                    ref={videoRef}
                                    defaultChecked={
                                        location?.state?.service?.methods.indexOf(
                                            "video"
                                        ) >= 0 ?? true
                                    }
                                    name="methods"
                                    type="checkbox"
                                    defaultValue="video"
                                    onChange={(e) => handleChange(e)}
                                />
                                <label className="Small-LabelCaps colLabelGray">
                                    {" "}
                                    &nbsp;Video
                                </label>
                                <span className="checkmarkCheckBox"></span>
                            </label>
                            <label className="verticallyAlignMidJustConStart checkBoxContainer">
                                <input
                                    ref={audioRef}
                                    defaultChecked={
                                        location?.state?.service?.methods.indexOf(
                                            "audio"
                                        ) >= 0 ?? true
                                    }
                                    name="methods"
                                    type="checkbox"
                                    defaultValue="audio"
                                    onChange={(e) => handleChange(e)}
                                />
                                <label className="Small-LabelCaps colLabelGray">
                                    {" "}
                                    &nbsp;Audio
                                </label>
                                <span className="checkmarkCheckBox"></span>
                            </label>
                            <label className="verticallyAlignMidJustConStart mBottom50 checkBoxContainer">
                                <input
                                    defaultChecked={true}
                                    defaultValue="chat"
                                    name="methods"
                                    required={true}
                                    type="checkbox"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                />
                                <label className="Small-LabelCaps colLabelGray">
                                    {" "}
                                    &nbsp;Chat
                                </label>
                                <span className="checkmarkCheckBox"></span>
                            </label>
                            <h5
                                style={charityCompany ? { opacity: 0.1 } : {}}
                                className="textAlignLeft P1bold mBottom20"
                            >
                                {
                                    company?.whiteLabel?.texts
                                        ?.label4SessionSingle
                                }{" "}
                                Pricing
                            </h5>
                            <h6
                                style={charityCompany ? { opacity: 0.1 } : {}}
                                className="textAlignLeft mBottom12 Pmedium"
                            >
                                Hourly Rate
                            </h6>
                            <div
                                style={charityCompany ? { opacity: 0.1 } : {}}
                                className="verticallyAlignMidJustConStart borderRadius12 backColInput w140 mBottom12"
                            >
                                <label className="P1bold mLeft16 mRight20">
                                    AU$
                                </label>
                                <input
                                    disabled={charityCompany}
                                    required
                                    defaultValue={
                                        charityCompany
                                            ? location?.state?.service?.rate ??
                                              1
                                            : location?.state?.service?.rate ??
                                              ""
                                    }
                                    name="rate"
                                    className="Pregular borderNone pY14 backColInput borderRadius12"
                                    type="number"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <p
                                style={charityCompany ? { opacity: 0 } : {}}
                                className="textAlignLeft Small-Label-11 colLinksAndTitlesBlack mBottom20"
                            >
                                Your estimated earnings:
                                {(
                                    (formData.rate *
                                        (100 -
                                            company.percentage -
                                            company.adminPercentage)) /
                                    100
                                ).toFixed(2)}
                            </p>
                            {(() => {
                                if (pageType) {
                                    if (location.state?.service) {
                                        return (
                                            <button
                                                type="submit"
                                                style={{
                                                    color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                                    backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                                }}
                                                className="borderRadius12 verticallyAlignMid borderNone mBottom30 P1regular mRightAuto colOffWhite w180 pY14"
                                            >
                                                Edit Service
                                            </button>
                                        );
                                    } else {
                                        return (
                                            <button
                                                type="submit"
                                                style={{
                                                    color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                                    backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                                }}
                                                className="borderRadius12 verticallyAlignMid borderNone mBottom30 P1regular mRightAuto colOffWhite w180 pY14"
                                            >
                                                Add Service
                                            </button>
                                        );
                                    }
                                } else {
                                    return (
                                        <button
                                            type="submit"
                                            style={{
                                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                            }}
                                            className="verticallyAlignMid borderRadius12 borderNone mBottom30 P1regular colOffWhite pLeft60 pRight60 pTop10 pBottom10 mLeftAuto"
                                        >
                                            Next
                                        </button>
                                    );
                                }
                            })()}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

function getMentorsStatus(state) {
    return {
        // company : state.auth.authData.company
        data: state.auth.authData || false,
    };
}

export default connect(getMentorsStatus)(
    OnboardingMentor7CreateYourFirstSession
);
