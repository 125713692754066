import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    addCategory,
    getAllAdditionalCategories,
    addAdditionalCategory,
    getAllCategories,
    getAllUsers,
    getCompany,
    updateAdditionalCategories,
    updateCategories,
    // updateCompany,
    getBusinessInitialData,
    getBusinessParameterData,
    getUsers,
    updateUser,
    uploadCompanyLogo,
    signUp,
    uploadCompanyImages,
} from "../../api";
import { updateCompany } from "../../actions/auth";
import MUIDataTable from "mui-datatables";
import AvatarEditor from "react-avatar-editor";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import CloseButton from "../../components/buttons/CloseButton/CloseButton.js";
// import { RiArrowDownSLine } from 'react-icons/ri';
import { HexColorPicker } from "react-colorful";
import MentorSingleView from "../../components/MentorSingleView/MentorSingleView";
import Moment from "moment";
// import axios from 'axios';
import { Datepicker } from "@mobiscroll/react";

function Owner(props) {
    const navigate = useNavigate();
    // const location = useLocation();
    const dispatch = useDispatch();

    if (!props.data?._id) {
        navigate("/LogIn");
    }

    const [table, setTable] = useState("reqInf");
    const [section, setSection] = useState("mentors");
    const [company, setCompany] = useState({});
    const [users, setUsers] = useState([]);
    const [value, setValue] = useState(0);
    const [times, setTimes] = useState([]);
    const [businessInitialData, setBusinessInitialData] = useState({});
    const [revenuePeaks, setRevenuePeaks] = useState([]);

    const [revenueData, setRevenueData] = useState([]);
    const [sessionsData, setSessionsData] = useState([]);
    const [incomeData, setIncomeData] = useState([]);
    const [mentorsData, setMentorsData] = useState([]);
    const [clientsData, setClientsData] = useState([]);

    const [operationsMentors, setOperationsMentors] = useState([]);

    const [businessTab, setBusinessTab] = useState(false);

    const [mentorID, setMentorID] = useState(false);

    const [businessDateType, setBusinessDateType] = useState("today");

    const [categories, setCategories] = useState([]);
    const [additionalCategories, setAdditionalCategories] = useState([]);

    const [companyReady, setCompanyReady] = useState(false);

    const categoryNameRef = useRef(null);
    const categoryTextRef = useRef(null);
    const additionalTextRef = useRef(null);
    const additionalNameRef = useRef(null);
    const timeTextRef = useRef(null);

    //Numbers presentation and rounding
    const COUNT_FORMATS = [
        {
            // 0 - 999
            letter: "",
            limit: 1e3,
        },
        {
            // 1,000 - 999,999
            letter: "K",
            limit: 1e6,
        },
        {
            // 1,000,000 - 999,999,999
            letter: "M",
            limit: 1e9,
        },
        {
            // 1,000,000,000 - 999,999,999,999
            letter: "B",
            limit: 1e12,
        },
        {
            // 1,000,000,000,000 - 999,999,999,999,999
            letter: "T",
            limit: 1e15,
        },
    ];

    // Only on first Load, get data for business Tab
    useEffect(() => {
        async function getData() {
            // console.log("setting initial business data using: ", businessDateType);

            let dateFrom;
            let dateTo = Moment().format("LLLL");
            dateTo = new Date(dateTo).toISOString();

            if (businessDateType === "today") {
                dateFrom = Moment().format("LL");
                dateFrom = new Date(dateFrom).toISOString();
            } else if (businessDateType === "week") {
                dateFrom = Moment().startOf("week").add(1, "days");
                dateFrom = new Date(dateFrom).toISOString();
            } else if (businessDateType === "month") {
                dateFrom = Moment().startOf("month");
                dateFrom = new Date(dateFrom).toISOString();
            } else if (businessDateType === "year") {
                dateFrom = Moment().startOf("year");
                dateFrom = new Date(dateFrom).toISOString();
            } else if (businessDateType === "all") {
                dateFrom = Moment().subtract(10, "years").format("LL");
                dateFrom = new Date(dateFrom).toISOString();
            } else if (businessDateType === "custom") {
                return false;
            } else {
                dateFrom = Moment("2011-07-12").format("LL");
                dateFrom = new Date(dateFrom).toISOString();
            }

            const queryString = `?companyID=${props.data.companyID}&dateFrom=${dateFrom}&dateTo=${dateTo}`;

            const data = await getBusinessInitialData(queryString);
            // console.log(data.data.data);
            setBusinessInitialData(data.data.data);
        }

        getData();
    }, [businessDateType, props.data.companyID]);

    useEffect(() => {
        async function getData() {
            const { data } = await getCompany(props.data.companyID);
            // console.log(data);

            setCompany(data.data.company);
            setCompanyReady(data.data.company.ready);

            setBtnColorFrom(
                data.data.company.whiteLabel.color.buttons.primary.from
            );
            setBtnColorTo(
                data.data.company.whiteLabel.color.buttons.primary.to
            );
            setBtnColorNotActive(
                data.data.company.whiteLabel.color.buttons.secondary
            );
            setBtnTextColorActive(
                data.data.company.whiteLabel.color.buttons.primaryText
            );
            setBtnTextColorNotActive(
                data.data.company.whiteLabel.color.buttons.secondaryText
            );

            setPrimaryTextColor(
                data.data.company.whiteLabel.color.text.primary
            );
            setSecondaryTextColor(
                data.data.company.whiteLabel.color.text.secondary
            );

            const categoriesData = await getAllCategories(
                `?companyID=${props.data.companyID}`
            );

            // console.log(categoriesData);
            setCategories(
                categoriesData.data.data.categories.map((el) => {
                    if (el.active) {
                        el.name = (
                            <p className="textAlignLeft" data-id={el._id}>
                                {el.name}
                            </p>
                        );
                    } else {
                        el.name = (
                            <p
                                className="textAlignLeft"
                                style={{ opacity: 0.3 }}
                                data-id={el._id}
                            >
                                {el.name}
                            </p>
                        );
                    }

                    el.active = (
                        <input
                            type="checkbox"
                            defaultChecked={el.active}
                            onChange={(e) => categoryStatusChanged(e, el)}
                        />
                    );

                    return el;
                })
            );

            const additionalCategoriesData = await getAllAdditionalCategories(
                `?companyID=${props.data.companyID}`
            );

            setAdditionalCategories(
                additionalCategoriesData.data.data.additionalCategories.map(
                    (el) => {
                        if (el.active) {
                            el.name = (
                                <p className="textAlignLeft" data-id={el._id}>
                                    {el.name}
                                </p>
                            );
                        } else {
                            el.name = (
                                <p
                                    className="textAlignLeft"
                                    style={{ opacity: 0.3 }}
                                    data-id={el._id}
                                >
                                    {el.name}
                                </p>
                            );
                        }

                        el.active = (
                            <input
                                type="checkbox"
                                defaultChecked={el.active}
                                onChange={(e) => additionalStatusChanged(e, el)}
                            />
                        );

                        return el;
                    }
                )
            );

            let queryString = "";

            // console.log(props.data.role);

            if (props.data.role === "owner") {
                queryString = `?filter={"$or":[{"role":"mentor"},{"role":"client"},{"role":"director"}]}&companyID=${props.data.companyID}`;
            } else if (props.data.role === "director") {
                queryString = `?filter={"$or":[{"role":"mentor"},{"role":"client"}]}&companyID=${props.data.companyID}`;
            }

            const usersData = await getAllUsers(queryString);

            // console.log(usersData);

            usersData.data.data.users.map((el, i) => {
                // console.log(el.active);
                el.active = (
                    <input
                        key={i}
                        type="checkbox"
                        defaultChecked={el.active}
                        onChange={(e) => userActiveChanged(e, el)}
                    />
                );

                return el;
            });

            setUsers(usersData.data.data.users);
            // hidePopUp();
        }

        getData();
    }, [value]);

    useEffect(() => {
        async function getData() {
            let operationsMentorsData = await getUsers(
                `
					?filter={
						"companyID"							 	: "${props.data.companyID}",
						"role"									: "mentor",
						"mentorOnboarding.workExperienceCompleted" 	: true,
						"mentorOnboarding.acceptedMentor"		: false
					}
				`
            );

            operationsMentorsData =
                operationsMentorsData.data.data.users.filter((el) => {
                    el.activate = (
                        <>
                            <button
                                onClick={() =>
                                    setMentorAccepted(el._id, "accepted")
                                }
                            >
                                Accept Application
                            </button>
                            <button
                                onClick={() =>
                                    setMentorAccepted(el._id, "denied")
                                }
                            >
                                Reject Application
                            </button>
                        </>
                    );
                    el.editMentor = (
                        <>
                            <button onClick={() => setMentorID(el.email)}>
                                View Application
                            </button>
                        </>
                    );
                    return el;
                });

            // console.log("operationsMentorsData", operationsMentorsData);
            setOperationsMentors(operationsMentorsData);
        }

        getData();
    }, [value, props.data.companyID]);

    useEffect(() => {
        async function getData() {
            if (company?.sessionDuration) {
                setTimes(
                    company.sessionDuration
                        .sort(function (a, b) {
                            return a - b;
                        })
                        .map((el) => {
                            el = el + "min";
                            return { duration: el };
                        })
                );
            }
        }

        getData();
    }, [company]);

    async function categoryStatusChanged(el, category) {
        await updateCategories(category._id, { active: el.target.checked });

        const categoriesData = await getAllCategories(
            `?companyID=${props.data.companyID}`
        );
        setCategories(
            categoriesData.data.data.categories.map((el) => {
                if (el.active) {
                    el.name = (
                        <p className="textAlignLeft" data-id={el._id}>
                            {el.name}
                        </p>
                    );
                } else {
                    el.name = (
                        <p
                            className="textAlignLeft"
                            style={{ opacity: 0.3 }}
                            data-id={el._id}
                        >
                            {el.name}
                        </p>
                    );
                }

                el.active = (
                    <input
                        type="checkbox"
                        defaultChecked={el.active}
                        onChange={(e) => categoryStatusChanged(e, el)}
                    />
                );

                return el;
            })
        );

        checkIfCompanyCanBeActive();
    }

    async function additionalStatusChanged(el, category) {
        await updateAdditionalCategories(category._id, {
            active: el.target.checked,
        });

        const additionalCategoriesData = await getAllAdditionalCategories(
            `?companyID=${props.data.companyID}`
        );
        setAdditionalCategories(
            additionalCategoriesData.data.data.additionalCategories.map(
                (el) => {
                    if (el.active) {
                        el.name = (
                            <p className="textAlignLeft" data-id={el._id}>
                                {el.name}
                            </p>
                        );
                    } else {
                        el.name = (
                            <p
                                className="textAlignLeft"
                                style={{ opacity: 0.3 }}
                                data-id={el._id}
                            >
                                {el.name}
                            </p>
                        );
                    }

                    el.active = (
                        <input
                            type="checkbox"
                            defaultChecked={el.active}
                            onChange={(e) => additionalStatusChanged(e, el)}
                        />
                    );

                    return el;
                }
            )
        );

        checkIfCompanyCanBeActive();
    }

    async function userActiveChanged(el, user) {
        // console.log(user);

        await updateUser(user._id, { active: el.target.checked });

        let queryString = "";

        if (props.role === "owner") {
            queryString = `?filter={"$ne":[{"role":"admin"}]}&companyID=${props.data.companyID}`;
        } else {
            queryString = `?filter={"$or":[{"role":"mentor"},{"role":"client"}]}&companyID=${props.data.companyID}`;
        }

        const usersData = await getAllUsers(queryString);

        usersData.data.data.users.map((el) => {
            el.active = (
                <input
                    type="checkbox"
                    defaultChecked={el.active}
                    onChange={(e) => userActiveChanged(e, el)}
                />
            );

            return el;
        });

        setUsers(usersData.data.data.users);
    }

    const dateRangeRef = useRef(null);

    async function getAllBusinessData(parameter) {
        let dateFrom;
        let dateTo = Moment().format("LLLL");
        dateTo = new Date(dateTo).toISOString();

        if (businessDateType === "today") {
            dateFrom = Moment().format("LL");
            dateFrom = new Date(dateFrom).toISOString();
        } else if (businessDateType === "week") {
            dateFrom = Moment().startOf("week").add(1, "days");
            dateFrom = new Date(dateFrom).toISOString();
        } else if (businessDateType === "month") {
            dateFrom = Moment().startOf("month");
            dateFrom = new Date(dateFrom).toISOString();
        } else if (businessDateType === "year") {
            dateFrom = Moment().startOf("year");
            dateFrom = new Date(dateFrom).toISOString();
        } else if (businessDateType === "all") {
            dateFrom = Moment().subtract(10, "years").format("LL");
            dateFrom = new Date(dateFrom).toISOString();
        } else if (businessDateType === "custom") {
            let range = customDate.valueText.split(" - ");

            dateFrom = new Date(range[0]).toISOString();
            dateTo = new Date(range[1]).toISOString();
        } else {
            dateFrom = Moment("2011-07-12").format("LL");
            dateFrom = new Date(dateFrom).toISOString();
        }

        const { data } = await getBusinessParameterData(
            parameter,
            `?companyID=${props.data.companyID}&dateTo=${dateTo}&dateFrom=${dateFrom}`
        );
        // console.log(data);

        setBusinessTab(parameter);

        if (parameter === "revenue") {
            setRevenueData(data.data);
        }

        if (parameter === "sessions") {
            setSessionsData(data.data);
        }

        if (parameter === "income") {
            setIncomeData(data.data);
        }

        if (parameter === "mentors") {
            setMentorsData(data.data);
        }

        if (parameter === "clients") {
            setClientsData(data.data);
        }
    }

    function changeBusinessDate(type) {
        setBusinessDateType(type);
        setBusinessTab(false);
    }

    const [customDate, setCustomDate] = useState(false);

    async function changeCustom(el) {
        let range = el.valueText.split(" - ");

        let dateFrom = new Date(range[0]).toISOString();
        let dateTo = new Date(range[1]).toISOString();

        const queryString = `?companyID=${props.data.companyID}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
        // console.log(queryString);
        // return false

        const data = await getBusinessInitialData(queryString);
        // console.log(data.data.data);
        setBusinessInitialData(data.data.data);
    }

    //////////////////////////////////////
    /////////// TABLE COLUMNS ////////////
    //////////////////////////////////////

    const usersColumns = [
        {
            name: "fullName",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "role",
            label: "Role",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "active",
            label: "Active",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const operationsMentorsColumns = [
        {
            name: "fullName",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "editMentor",
            label: "Application",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "activate",
            label: "Activate",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const categoryColumns = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "active",
            label: "Active",
            options: {
                filter: false,
                sort: true,
            },
        },
    ];

    const timeColumns = [
        {
            name: "duration",
            label: "Duration",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const peaksColumns = [
        {
            name: "peak",
            label: "Peak",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "value",
            label: "Value",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const revenueColumns = [
        {
            name: "date",
            label: "Date",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "revenue",
            label: "Revenue",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "income",
            label: "Income",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "sessions",
            label: "Sessions",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "mentors",
            label: "Mentors",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "clients",
            label: "Clients",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const sessionsBusinessColumns = [
        {
            name: "date",
            label: "Date",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "revenue",
            label: "Revenue",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "income",
            label: "Income",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "duration",
            label: "Duration",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "category",
            label: "Category",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "client",
            label: "Client",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "mentor",
            label: "Mentor",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const mentorsBusinessColumns = [
        {
            name: "mentor",
            label: "Mentor",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "sessions",
            label: "Sessions",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "revenue",
            label: "Revenue",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "income",
            label: "Income",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "payout",
            label: "Payout",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "clients",
            label: "Clients",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const clientsBusinessColumns = [
        {
            name: "client",
            label: "Client",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "sessions",
            label: "Sessions",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "revenue",
            label: "Revenue",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "income",
            label: "Income",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "mentors",
            label: "Mentors",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    //////////////////////////////////////
    /////////// TABLE OPTIONS ////////////
    //////////////////////////////////////

    const usersOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
    };

    if (props.data.role === "owner") {
        usersOptions.customToolbar = () => {
            return (
                <React.Fragment>
                    <Tooltip title={"Add Director"}>
                        <IconButton
                            className=""
                            onClick={() => setPopUp("addDirector")}
                        >
                            <AddIcon className="" />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        };
    }

    const operationsMentorsOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        // onRowClick 		: (el) => {console.log('el',el);setMentorID(el[1])}
    };

    const categoryOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        selectableRows: "none",
        onCellClick: (e) => openCategoryPopUp(e),
        customToolbar: () => {
            return (
                <React.Fragment>
                    <Tooltip title={"Add Category"}>
                        <IconButton
                            className=""
                            onClick={() => setPopUp("category")}
                        >
                            <AddIcon className="" />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        },
    };

    const timeOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        onRowsDelete: async (e) => {
            let array = times.map((el) => {
                let duration = el.duration.split("min")[0];
                return duration;
            });

            let deleted = e.data.map((el) => {
                return array[el.index];
            });

            let newArray = array.filter((x) => !deleted.includes(x));

            let companyID = props.data.companyID;
            // const { data } = await updateCompany(companyID, {sessionDuration: newArray});
            dispatch(updateCompany(companyID, { sessionDuration: newArray }));

            setValue((value) => value + 1);
        },
        customToolbar: () => {
            return (
                <React.Fragment>
                    <Tooltip title={"Add Time"}>
                        <IconButton
                            className=""
                            onClick={() => setPopUp("time")}
                        >
                            <AddIcon className="" />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        },
    };

    const additionalOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        selectableRows: "none",
        onCellClick: (e) => openAdditionalPopUp(e),
        customToolbar: () => {
            return (
                <React.Fragment>
                    <Tooltip title={"Add Additional Category"}>
                        <IconButton
                            className=""
                            onClick={() => setPopUp("additional")}
                        >
                            <AddIcon className="" />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        },
    };

    const peaksOptions = {
        filter: false,
        download: false,
        print: false,
        search: false,
        viewColumns: false,
        selectableRows: "none",
        customFooter: () => null,
    };

    const revenueOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        selectableRows: "none",
    };

    //////////////////////////////
    //////// FORM SUBMIT /////////
    //////////////////////////////

    async function handleSubmitCategory() {
        if (categoryTextRef.current.value !== "") {
            let companyID = props.data.companyID;

            await addCategory({
                name: categoryTextRef.current.value,
                companyID,
            });

            const categoriesData = await getAllCategories(
                `?companyID=${props.data.companyID}`
            );
            setCategories(
                categoriesData.data.data.categories.map((el) => {
                    if (el.active) {
                        el.name = (
                            <p className="textAlignLeft" data-id={el._id}>
                                {el.name}
                            </p>
                        );
                    } else {
                        el.name = (
                            <p
                                className="textAlignLeft"
                                style={{ opacity: 0.3 }}
                                data-id={el._id}
                            >
                                {el.name}
                            </p>
                        );
                    }

                    el.active = (
                        <input
                            type="checkbox"
                            defaultChecked={el.active}
                            onChange={(e) => categoryStatusChanged(e, el)}
                        />
                    );

                    return el;
                })
            );
        }

        setValue((value) => value + 1);
        setPopUp(false);

        showPopUp("success");
        setTimeout(() => {
            hidePopUp();
        }, "1200");
    }

    const [rowName, setRowName] = useState("");

    function openCategoryPopUp(dataForm) {
        // console.log(dataForm);

        if (dataForm.props.type) {
            return false;
        }

        setRowName(dataForm);
        setPopUp("categoryName");
    }

    function openAdditionalPopUp(dataForm) {
        // console.log(dataForm);

        if (dataForm.props.type) {
            return false;
        }

        setRowName(dataForm);
        setPopUp("additionalName");
    }

    async function handleChangeCategory() {
        if (categoryNameRef.current.value !== "") {
            await updateCategories(rowName.props["data-id"], {
                name: categoryNameRef.current.value,
            });

            const categoriesData = await getAllCategories(
                `?companyID=${props.data.companyID}`
            );
            setCategories(
                categoriesData.data.data.categories.map((el) => {
                    if (el.active) {
                        el.name = (
                            <p className="textAlignLeft" data-id={el._id}>
                                {el.name}
                            </p>
                        );
                    } else {
                        el.name = (
                            <p
                                className="textAlignLeft"
                                style={{ opacity: 0.3 }}
                                data-id={el._id}
                            >
                                {el.name}
                            </p>
                        );
                    }

                    el.active = (
                        <input
                            type="checkbox"
                            defaultChecked={el.active}
                            onChange={(e) => categoryStatusChanged(e, el)}
                        />
                    );

                    return el;
                })
            );
        }

        setValue((value) => value + 1);
        setPopUp(false);
    }

    async function handleSubmitAdditional() {
        if (additionalTextRef.current.value !== "") {
            let companyID = props.data.companyID;

            await addAdditionalCategory({
                name: additionalTextRef.current.value,
                companyID,
            });

            const additionalCategoriesData = await getAllAdditionalCategories(
                `?companyID=${props.data.companyID}`
            );
            setAdditionalCategories(
                additionalCategoriesData.data.data.additionalCategories.map(
                    (el) => {
                        if (el.active) {
                            el.name = (
                                <p className="textAlignLeft" data-id={el._id}>
                                    {el.name}
                                </p>
                            );
                        } else {
                            el.name = (
                                <p
                                    className="textAlignLeft"
                                    style={{ opacity: 0.3 }}
                                    data-id={el._id}
                                >
                                    {el.name}
                                </p>
                            );
                        }

                        el.active = (
                            <input
                                type="checkbox"
                                defaultChecked={el.active}
                                onChange={(e) => additionalStatusChanged(e, el)}
                            />
                        );

                        return el;
                    }
                )
            );
        }

        setValue((value) => value + 1);
        setPopUp(false);

        showPopUp("success");
        setTimeout(() => {
            hidePopUp();
        }, "1200");
    }

    async function handleChangeAdditional() {
        if (additionalNameRef.current.value !== "") {
            await updateAdditionalCategories(rowName.props["data-id"], {
                name: additionalNameRef.current.value,
            });

            const additionalCategoriesData = await getAllAdditionalCategories(
                `?companyID=${props.data.companyID}`
            );
            setAdditionalCategories(
                additionalCategoriesData.data.data.additionalCategories.map(
                    (el) => {
                        if (el.active) {
                            el.name = (
                                <p className="textAlignLeft" data-id={el._id}>
                                    {el.name}
                                </p>
                            );
                        } else {
                            el.name = (
                                <p
                                    className="textAlignLeft"
                                    style={{ opacity: 0.3 }}
                                    data-id={el._id}
                                >
                                    {el.name}
                                </p>
                            );
                        }

                        el.active = (
                            <input
                                type="checkbox"
                                defaultChecked={el.active}
                                onChange={(e) => additionalStatusChanged(e, el)}
                            />
                        );

                        return el;
                    }
                )
            );
        }

        setValue((value) => value + 1);
        setPopUp(false);
    }

    const companyNameRef = useRef(0);
    const handleSubmitCompanyName = async (e) => {
        e.preventDefault();
        let companyName = companyNameRef.current.value;
        let companyID = props.data.companyID;

        // const { data } = await updateCompany(companyID, {name: companyName});
        dispatch(updateCompany(companyID, { name: companyName }));
        setShowNameSave(false);

        showPopUp("success");
        setTimeout(() => {
            hidePopUp();
        }, "1200");
    };

    const companyPercRef = useRef(0);
    const handleSubmitPercentage = async (e) => {
        e.preventDefault();
        let companyPerc = companyPercRef.current.value;
        let companyID = props.data.companyID;

        // const { data } = await updateCompany(companyID, {percentage: companyPerc});
        dispatch(updateCompany(companyID, { percentage: companyPerc }));
        setShowPercSave(false);

        showPopUp("success");
        setTimeout(() => {
            hidePopUp();
        }, "1200");
    };

    const companyEmailRef = useRef(0);
    const handleSubmitEmail = async (e) => {
        e.preventDefault();
        let companyEmail = companyEmailRef.current.value;
        let companyID = props.data.companyID;

        // const { data } = await updateCompany(companyID, {email: companyEmail});
        dispatch(updateCompany(companyID, { email: companyEmail }));
        setShowEmailSave(false);

        showPopUp("success");
        setTimeout(() => {
            hidePopUp();
        }, "1200");
    };

    async function handleSubmitTime() {
        if (timeTextRef.current.value > 0) {
            let array = times.map((el) => {
                return el.duration.split("min")[0];
            });

            if (array.indexOf(timeTextRef.current.value) < 0) {
                array.push(timeTextRef.current.value);

                let companyID = props.data.companyID;
                // const { data } = await updateCompany(companyID, {sessionDuration: array});
                await dispatch(
                    updateCompany(companyID, { sessionDuration: array })
                );

                const { data } = await getCompany(props.data.companyID);
                setTimes(
                    data.data.company.sessionDuration
                        .sort(function (a, b) {
                            return a - b;
                        })
                        .map((el) => {
                            el = el + "min";
                            return { duration: el };
                        })
                );
            }
        }

        setValue((value) => value + 1);
        setPopUp(false);

        showPopUp("success");
        setTimeout(() => {
            hidePopUp();
        }, "1200");
    }

    const directorFullNameRef = useRef(0);
    const directorEmailRef = useRef(0);
    const handleSubmitDirector = async (e) => {
        e.preventDefault();

        await signUp({
            fullName: directorFullNameRef.current.value,
            email: directorEmailRef.current.value,
            companyID: props.data.companyID,
            role: "director",
        });

        setPopUp(false);

        // const { data } = await updateCompany(companyID, {email: companyEmail});
    };

    //////////////////////////////
    //////// COMPANY LOGO ////////
    //////////////////////////////

    const [popUp, setPopUp] = useState(false);

    const imageFileRef = useRef(null);
    const imageRef = useRef(null);

    const [image, setImage] = useState(false);
    const [imageScale, setImageScale] = useState(1);

    const onChange = async (e) => {
        // console.log(e.target.files[0]);
        setImage(e.target.files[0]);
    };

    async function saveImage() {
        let file = imageRef.current.getImage().toDataURL();
        await uploadCompanyLogo(props.data.companyID, { image: file });

        setValue((value) => value + 1);
        setImage(false);

        showPopUp("success");
        setTimeout(() => {
            hidePopUp();
        }, "1200");
    }

    function remove() {
        imageFileRef.current.value = null;

        setImage(false);
        setImageScale(1);
    }

    async function setActive(type, e) {
        if (type) {
            // if ( ! company.charity && companyPercRef?.current?.value >= 0) {
            if (false) {
                e.preventDefault();
                alert("You need to set all required fields first!");
            } else if (
                categories.length > 0 &&
                additionalCategories.length > 0 &&
                times.length > 0 &&
                companyNameRef.current.value !== ""
                // &&
                // img
            ) {
                let companyID = props.data.companyID;
                // const { data } = await updateCompany(companyID, {ready: true});
                dispatch(updateCompany(companyID, { ready: true }));
                setCompanyReady(type);
            } else {
                e.preventDefault();
                alert("You need to set all required fields first!");
            }
        } else {
            let companyID = props.data.companyID;
            // const { data } = await updateCompany(companyID, {ready: false});
            dispatch(updateCompany(companyID, { ready: false }));
            // console.log("You set that the company is not ready");
            setCompanyReady(type);
        }
    }

    const [whatKindImage, setWhatKindImage] = useState(false);
    const [noStatsImage, setNoStatsImage] = useState(false);
    const [mentorsApplyImage, setMentorsApplyImage] = useState(false);
    const [acceptedImage, setAcceptedImage] = useState(false);
    const [welcomeImage, setWelcomeImage] = useState(false);

    const whatKindRef = useRef(null);
    const noStatsRef = useRef(null);
    const mentorsApplyRef = useRef(null);
    const acceptedRef = useRef(null);
    const welcomeRef = useRef(null);

    async function saveImages(ref, type, event) {
        let companyID = props.data.companyID;
        let object = new FormData();
        let file = ref.current.files[0];

        object.append("file", file);
        object.append("type", type);
        object.append("whiteLabel", JSON.stringify(company.whiteLabel));

        const data = await uploadCompanyImages(companyID, object);
        // console.log(data);

        event(false);
        setValue((value) => value + 1);

        showPopUp("success");
        setTimeout(() => {
            hidePopUp();
        }, "1200");
    }

    //####################
    //### Business Tab ###
    //####################

    //TO DO: What should this do?

    // async function pickRevenue() {

    // 	// const data = await getBusinessRevenueData(`?date=${businessDateType}`);
    // 	// console.log(data);

    // 	const data = {
    // 		days : [
    // 			{
    // 				date 		: "08.04.2022.",
    // 				revenue 	: 124331,
    // 				income 		: 116745,
    // 				sessions 	: 2319,
    // 				mentors 	: 55,
    // 				clients 	: 34
    // 			},
    // 			{
    // 				date 		: "09.04.2022.",
    // 				revenue 	: 65789,
    // 				income 		: 62456,
    // 				sessions 	: 112,
    // 				mentors 	: 33,
    // 				clients 	: 21
    // 			},
    // 		],
    // 		"peakDate" : "04.04.2022.",
    // 		"peakDay"  : "Tuesday",
    // 		"peakHour" : "11 AM"
    // 	}

    // 	setRevenuePeaks([
    // 		{
    // 			peak 	: "Date",
    // 			value 	: data.peakDate
    // 		},
    // 		{
    // 			peak 	: "Day",
    // 			value 	: data.peakDay
    // 		},
    // 		{
    // 			peak 	: "Hour",
    // 			value 	: data.peakHour
    // 		}
    // 	])

    // 	setRevenueData(data.days);

    // 	setBusinessTab("revenue")
    // }

    // const peaks = [
    // 	{
    // 		peak 	: "Date",
    // 		value 	: "May 22nd"
    // 	},
    // 	{
    // 		peak 	: "Day",
    // 		value 	: "Saturday"
    // 	},
    // 	{
    // 		peak 	: "Hour",
    // 		value 	: "11:00 AM"
    // 	},
    // ]

    // const revenueData = [
    // 	{
    // 		revenue 	: "$57,000",
    // 		income 		: "$2,600",
    // 		sessions 	: 57,
    // 		mentors 	: 21,
    // 		clients 	: 46
    // 	},
    // ]

    async function checkIfCompanyCanBeActive() {
        // console.log(categories)

        let checkCategories = true;

        categories.forEach((el) => {
            if (!el?.props?.defaultChecked) {
                checkCategories = false;
            }
        });

        // console.log(additionalCategories)

        let checkAdditional = true;

        additionalCategories.forEach((el) => {
            if (el?.props?.defaultChecked) {
                checkAdditional = false;
            }
        });

        if (!checkCategories || !checkAdditional) {
            let companyID = props.data.companyID;
            // const { data } = await updateCompany(companyID, {active: false});
            dispatch(updateCompany(companyID, { active: false }));

            setValue((value) => value + 1);
        }
    }

    const durationValues = [15, 30, 45, 60, 75, 90, 120];

    const testRef = useRef(null);

    async function setMentorAccepted(id, status) {
        if (status === "accepted") {
            let mentorOnboarding = testRef.current.props.data.filter((el) => {
                if (el._id === id) {
                    el.mentorOnboarding.acceptedMentor = true;
                    return el;
                }
            })[0];

            mentorOnboarding = mentorOnboarding.mentorOnboarding;

            // console.log(mentorOnboarding);
            // return false;

            const { data } = await updateUser(id, {
                mentorOnboarding,
                thankYouCompleted: true,
                acceptedMentor: true,
            });

            // console.log(data);

            setValue((value) => value + 1);
        } else {
            let mentorOnboarding = testRef.current.props.data.filter((el) => {
                if (el._id === id) {
                    el.mentorOnboarding.acceptedMentor = false;
                    return el;
                }
            })[0];

            mentorOnboarding = mentorOnboarding.mentorOnboarding;

            const { data } = await updateUser(id, {
                mentorOnboarding,
                thankYouCompleted: true,
                acceptedMentor: false,
            });
            setValue((value) => value + 1);

            // console.log(data);
        }
    }

    const [saveProfile, setSaveProfile] = useState(false);

    function changeProfileTexts(text, type) {
        let changes = false;

        if (
            company?.whiteLabel?.texts?.profile?.personalInformationText !==
            personalInformationTextRef.current.value
        ) {
            changes = true;
        }
        if (
            company?.whiteLabel?.texts?.profile?.loginsSecurityText !==
            loginsSecurityTextRef.current.value
        ) {
            changes = true;
        }
        if (
            company?.whiteLabel?.texts?.profile?.howWorksText !==
            howWorksTextRef.current.value
        ) {
            changes = true;
        }
        if (
            company?.whiteLabel?.texts?.profile?.paymentsPayoutsText !==
            paymentsPayoutsTextRef.current.value
        ) {
            changes = true;
        }
        if (
            company?.whiteLabel?.texts?.profile?.availabilityText !==
            availabilityTextRef.current.value
        ) {
            changes = true;
        }
        if (
            company?.whiteLabel?.texts?.profile?.giveUsFeedbackText !==
            giveUsFeedbackTextRef.current.value
        ) {
            changes = true;
        }
        if (
            company?.whiteLabel?.texts?.profile?.manageServicesText !==
            manageServicesTextRef.current.value
        ) {
            changes = true;
        }
        if (
            company?.whiteLabel?.texts?.profile?.notificationsText !==
            notificationsTextRef.current.value
        ) {
            changes = true;
        }
        if (
            company?.whiteLabel?.texts?.profile?.contactsSupportText !==
            contactsSupportTextRef.current.value
        ) {
            changes = true;
        }

        if (changes) {
            setSaveProfile(true);
        } else {
            setSaveProfile(false);
        }
    }

    const personalInformationTextRef = useRef(null);
    const loginsSecurityTextRef = useRef(null);
    const howWorksTextRef = useRef(null);
    const paymentsPayoutsTextRef = useRef(null);
    const availabilityTextRef = useRef(null);
    const giveUsFeedbackTextRef = useRef(null);
    const manageServicesTextRef = useRef(null);
    const notificationsTextRef = useRef(null);
    const contactsSupportTextRef = useRef(null);

    async function saveProfileTexts() {
        let obj = company.whiteLabel ? company.whiteLabel : { texts: {} };
        let companyID = props.data.companyID;

        obj.texts.profile.personalInformationText =
            personalInformationTextRef.current.value;
        obj.texts.profile.loginsSecurityText =
            loginsSecurityTextRef.current.value;
        obj.texts.profile.howWorksText = howWorksTextRef.current.value;
        obj.texts.profile.paymentsPayoutsText =
            paymentsPayoutsTextRef.current.value;
        obj.texts.profile.availabilityText = availabilityTextRef.current.value;
        obj.texts.profile.giveUsFeedbackText =
            giveUsFeedbackTextRef.current.value;
        obj.texts.profile.manageServicesText =
            manageServicesTextRef.current.value;
        obj.texts.profile.notificationsText =
            notificationsTextRef.current.value;
        obj.texts.profile.contactsSupportText =
            contactsSupportTextRef.current.value;

        let object = {
            whiteLabel: obj,
        };

        // const { data } = await updateCompany(companyID, object)
        dispatch(updateCompany(companyID, object));

        setSaveProfile(false);
        setValue((value) => value + 1);

        showPopUp("success");
        setTimeout(() => {
            hidePopUp();
        }, "1200");
    }

    const greenLabelRef = useRef(null);
    const largeHeaderRef = useRef(null);
    const mainHeaderRef = useRef(null);
    const label1Ref = useRef(null);
    const label2Ref = useRef(null);
    const label3Ref = useRef(null);

    const [saveApply, setSaveApply] = useState(false);

    function changeApplyTexts() {
        let changes = false;

        if (
            company?.whiteLabel?.texts?.mentorsApply?.greenLabel !==
            greenLabelRef.current.value
        ) {
            changes = true;
        }
        if (
            company?.whiteLabel?.texts?.mentorsApply?.largeHeader !==
            largeHeaderRef.current.value
        ) {
            changes = true;
        }
        if (
            company?.whiteLabel?.texts?.mentorsApply?.mainHeader !==
            mainHeaderRef.current.value
        ) {
            changes = true;
        }
        if (
            company?.whiteLabel?.texts?.mentorsApply?.label1 !==
            label1Ref.current.value
        ) {
            changes = true;
        }
        if (
            company?.whiteLabel?.texts?.mentorsApply?.label2 !==
            label2Ref.current.value
        ) {
            changes = true;
        }
        if (
            company?.whiteLabel?.texts?.mentorsApply?.label3 !==
            label3Ref.current.value
        ) {
            changes = true;
        }

        if (changes) {
            setSaveApply(true);
        } else {
            setSaveApply(false);
        }
    }

    async function saveApplyTexts() {
        let obj = company.whiteLabel;
        let companyID = props.data.companyID;

        obj.texts.mentorsApply.greenLabel = greenLabelRef.current.value;
        obj.texts.mentorsApply.largeHeader = largeHeaderRef.current.value;
        obj.texts.mentorsApply.mainHeader = mainHeaderRef.current.value;
        obj.texts.mentorsApply.label1 = label1Ref.current.value;
        obj.texts.mentorsApply.label2 = label2Ref.current.value;
        obj.texts.mentorsApply.label3 = label3Ref.current.value;

        let object = {
            whiteLabel: obj,
        };

        // const { data } = await updateCompany(companyID, object)
        dispatch(updateCompany(companyID, object));

        setSaveApply(false);
        setValue((value) => value + 1);

        showPopUp("success");
        setTimeout(() => {
            hidePopUp();
        }, "1200");
    }

    const [label1, setLabel1] = useState(false);
    const [label2MentorSingle, setLabel2MentorSingle] = useState(false);
    const [label3MentorPlural, setLabel3MentorPlural] = useState(false);
    const [label4SessionSingle, setLabel4SessionSingle] = useState(false);
    const [label5SessionPlural, setLabel5SessionPlural] = useState(false);
    const [label6NoSessionDescription, setLabel6NoSessionDescription] =
        useState(false);

    const label1TextRef = useRef(null);
    const label2MentorSingleTextRef = useRef(null);
    const label3MentorPluralTextRef = useRef(null);
    const label4SessionSingleTextRef = useRef(null);
    const label5SessionPluralTextRef = useRef(null);
    const label6NoSessionDescriptionTextRef = useRef(null);

    const [labelColor1Something, setLabelColor1Something] = useState(false);

    function changeLabelText() {
        // console.log(company?.whiteLabel?.texts?.label1Text);
        // console.log(label1TextRef.current.value);
        // console.log(company?.whiteLabel?.texts?.label2MentorSingle);
        // console.log(label2MentorSingleTextRef.current.value);

        if (
            company?.whiteLabel?.texts?.label1Text !==
            label1TextRef.current.value
        ) {
            setLabel1(true);
        } else {
            setLabel1(false);
        }

        if (
            company?.whiteLabel?.texts?.label2MentorSingle !==
            label2MentorSingleTextRef.current.value
        ) {
            setLabel2MentorSingle(true);
        } else {
            setLabel2MentorSingle(false);
        }

        if (
            company?.whiteLabel?.texts?.label3MentorPlural !==
            label3MentorPluralTextRef.current.value
        ) {
            setLabel3MentorPlural(true);
        } else {
            setLabel3MentorPlural(false);
        }

        if (
            company?.whiteLabel?.texts?.label4SessionSingle !==
            label4SessionSingleTextRef.current.value
        ) {
            setLabel4SessionSingle(true);
        } else {
            setLabel4SessionSingle(false);
        }

        if (
            company?.whiteLabel?.texts?.label5SessionPlural !==
            label5SessionPluralTextRef.current.value
        ) {
            setLabel5SessionPlural(true);
        } else {
            setLabel5SessionPlural(false);
        }

        if (
            company?.whiteLabel?.texts?.label6NoSessionDescription !==
            label6NoSessionDescriptionTextRef.current.value
        ) {
            setLabel6NoSessionDescription(true);
        } else {
            setLabel6NoSessionDescription(false);
        }
    }

    async function saveLabelText(event) {
        let obj = company.whiteLabel;
        let companyID = props.data.companyID;

        obj.texts.label1Text = label1TextRef.current.value;
        obj.texts.label2MentorSingle = label2MentorSingleTextRef.current.value;
        obj.texts.label3MentorPlural = label3MentorPluralTextRef.current.value;
        obj.texts.label4SessionSingle =
            label4SessionSingleTextRef.current.value;
        obj.texts.label5SessionPlural =
            label5SessionPluralTextRef.current.value;
        obj.texts.label6NoSessionDescription =
            label6NoSessionDescriptionTextRef.current.value;

        let object = {
            whiteLabel: obj,
        };

        // console.log(object);

        // const { data } = await updateCompany(companyID, object)
        dispatch(updateCompany(companyID, object));

        event(false);

        showPopUp("success");
        setTimeout(() => {
            hidePopUp();
        }, "1200");
    }

    const [btnColorFrom, setBtnColorFrom] = useState("#3e8b9c");
    const [btnColorTo, setBtnColorTo] = useState("#155c6b");
    const [btnColorNotActive, setBtnColorNotActive] = useState("#3e8b9c21");

    const [btnTextColorNotActive, setBtnTextColorNotActive] =
        useState("#262430");
    const [btnTextColorActive, setBtnTextColorActive] = useState("#fff");

    const [primaryTextColor, setPrimaryTextColor] = useState("#000000");
    const [secondaryTextColor, setSecondaryTextColor] = useState("#226b7f");

    const [saveColorBtn, setSaveColorBtn] = useState(false);
    const [saveColorBtn2, setSaveColorBtn2] = useState(false);
    const [saveColorBtn3, setSaveColorBtn3] = useState(false);

    async function saveColors(num) {
        let obj = company.whiteLabel;
        let companyID = props.data.companyID;

        if (num === 1) {
            obj.color.buttons.primary.from = btnColorFrom;
            obj.color.buttons.primary.to = btnColorTo;
            obj.color.buttons.primaryText = btnTextColorActive;
        } else if (num === 2) {
            obj.color.buttons.secondary = btnColorNotActive;
            obj.color.buttons.secondaryText = btnTextColorNotActive;
        } else if (num === 3) {
            obj.color.text.primary = primaryTextColor;
            obj.color.text.secondary = secondaryTextColor;
        } else {
            return false;
        }

        let object = {
            whiteLabel: obj,
        };

        // const { data } = await updateCompany(companyID, object)
        dispatch(updateCompany(companyID, object));

        if (num === 1) {
            setSaveColorBtn(false);
        } else if (num === 2) {
            setSaveColorBtn2(false);
        } else if (num === 3) {
            setSaveColorBtn3(false);
        }

        showPopUp("success");
        setTimeout(() => {
            hidePopUp();
        }, "1200");
    }

    const [showNameSave, setShowNameSave] = useState(false);
    const [showPercSave, setShowPercSave] = useState(false);
    const [showEmailSave, setShowEmailSave] = useState(false);

    function requiredChanges(el, type) {
        if (type === "name") {
            if (el.target.value !== company.name) {
                setShowNameSave(true);
            } else {
                setShowNameSave(false);
            }
        }

        if (type === "perc") {
            if (parseInt(el.target.value) !== company.percentage) {
                setShowPercSave(true);
            } else {
                setShowPercSave(false);
            }
        }

        if (type === "email") {
            if (el.target.value !== company.email) {
                setShowEmailSave(true);
            } else {
                setShowEmailSave(false);
            }
        }
    }

    function showPopUp(type) {
        props.statusPopUp(type);
    }

    function hidePopUp() {
        props.statusPopUp(false);
    }

    return (
        <div className="mBottom30">
            {popUp === "category" && (
                <div className="filterMentorPopupWrapper">
                    <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                        <div className="w90 mXAuto">
                            <div className="verticallyAlignMid mTop40 NewNotePoUpSub1Wrapper">
                                <h2 className="mRightAuto H3medium">
                                    Add Category
                                </h2>
                                <CloseButton parentEvent={setPopUp} />
                            </div>
                            <div className="mTop40 NewNotePoUpSub1Wrapper">
                                <label className="w100 textAlignCenter mY10">
                                    Category:
                                </label>
                                <div className="w100 verticallyAlignMid textAlignCenter">
                                    <span className="mY20"></span>
                                    <input
                                        ref={categoryTextRef}
                                        className="w20 textAlignCenter"
                                        type="text"
                                        placeholder="example"
                                    />
                                </div>
                            </div>
                            <button
                                onClick={handleSubmitCategory}
                                className="verticallyAlignMid w180 borderRadius12 mBottom40 borderNone Pregular colOffWhite backgroundGradientForButtons pY10 mLeftAuto"
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {popUp === "categoryName" && (
                <div className="filterMentorPopupWrapper">
                    <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                        <div className="w90 mXAuto">
                            <div className="verticallyAlignMid mTop40 NewNotePoUpSub1Wrapper">
                                <h2 className="mRightAuto H3medium">
                                    Change Category Name
                                </h2>
                                <CloseButton parentEvent={setPopUp} />
                            </div>
                            <div className="mTop40 NewNotePoUpSub1Wrapper">
                                <label className="w100 textAlignCenter mY10">
                                    Category:
                                </label>
                                <div className="w100 verticallyAlignMid textAlignCenter">
                                    <span className="mY20"></span>
                                    <input
                                        ref={categoryNameRef}
                                        defaultValue={rowName.props.children}
                                        className="w20 textAlignCenter"
                                        type="text"
                                        placeholder="example"
                                    />
                                </div>
                            </div>
                            <button
                                onClick={handleChangeCategory}
                                className="verticallyAlignMid w180 borderRadius12 mBottom40 borderNone Pregular colOffWhite backgroundGradientForButtons pY10 mLeftAuto"
                            >
                                Change
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {popUp === "additionalName" && (
                <div className="filterMentorPopupWrapper">
                    <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                        <div className="w90 mXAuto">
                            <div className="verticallyAlignMid mTop40 NewNotePoUpSub1Wrapper">
                                <h2 className="mRightAuto H3medium">
                                    Change Additional Category Name
                                </h2>
                                <CloseButton parentEvent={setPopUp} />
                            </div>
                            <div className="mTop40 NewNotePoUpSub1Wrapper">
                                <label className="w100 textAlignCenter mY10">
                                    Additional Category:
                                </label>
                                <div className="w100 verticallyAlignMid textAlignCenter">
                                    <span className="mY20"></span>
                                    <input
                                        ref={additionalNameRef}
                                        defaultValue={rowName.props.children}
                                        className="w20 textAlignCenter"
                                        type="text"
                                        placeholder="example"
                                    />
                                </div>
                            </div>
                            <button
                                onClick={handleChangeAdditional}
                                className="verticallyAlignMid w180 borderRadius12 mBottom40 borderNone Pregular colOffWhite backgroundGradientForButtons pY10 mLeftAuto"
                            >
                                Change
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {popUp === "addDirector" && (
                <div className="filterMentorPopupWrapper">
                    <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                        <div className="w90 mXAuto">
                            <div className="verticallyAlignMid mTop40 NewNotePoUpSub1Wrapper">
                                <h2 className="mRightAuto H3medium">
                                    Add Director
                                </h2>
                                <CloseButton parentEvent={setPopUp} />
                            </div>
                            <div className="mTop40 NewNotePoUpSub1Wrapper">
                                <label className="w100 textAlignCenter mY10">
                                    Directors name:
                                </label>
                                <div className="w100 verticallyAlignMid textAlignCenter">
                                    <span className="mY20"></span>
                                    <input
                                        ref={directorFullNameRef}
                                        className="w20 textAlignCenter"
                                        type="text"
                                        placeholder="John Director"
                                    />
                                </div>
                                <label className="w100 textAlignCenter mY10">
                                    Directors email:
                                </label>
                                <div className="w100 verticallyAlignMid textAlignCenter">
                                    <span className="mY20"></span>
                                    <input
                                        ref={directorEmailRef}
                                        className="w20 textAlignCenter"
                                        type="email"
                                        placeholder="example@example.com"
                                    />
                                </div>
                            </div>
                            <button
                                onClick={handleSubmitDirector}
                                className="verticallyAlignMid w180 borderRadius12 mBottom40 borderNone Pregular colOffWhite backgroundGradientForButtons pY10 mLeftAuto"
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {popUp === "additional" && (
                <div className="filterMentorPopupWrapper">
                    <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                        <div className="w90 mXAuto">
                            <div className="verticallyAlignMid mTop40 NewNotePoUpSub1Wrapper">
                                <h2 className="mRightAuto H3medium">
                                    Add Additional Category
                                </h2>
                                <CloseButton parentEvent={setPopUp} />
                            </div>
                            <div className="mTop40 NewNotePoUpSub1Wrapper">
                                <label className="w100 textAlignCenter mY10">
                                    Additional Category:
                                </label>
                                <div className="w100 verticallyAlignMid textAlignCenter">
                                    <span className="mY20"></span>
                                    <input
                                        ref={additionalTextRef}
                                        className="w20 textAlignCenter"
                                        type="text"
                                        placeholder="example"
                                    />
                                </div>
                            </div>
                            <button
                                onClick={handleSubmitAdditional}
                                className="verticallyAlignMid w180 borderRadius12 mBottom40 borderNone Pregular colOffWhite backgroundGradientForButtons pY10 mLeftAuto"
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {popUp === "time" && (
                <div className="filterMentorPopupWrapper">
                    <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                        <div className="w90 mXAuto">
                            <div className="verticallyAlignMid mTop40 NewNotePoUpSub1Wrapper">
                                <h2 className="mRightAuto H3medium">
                                    Add Duration
                                </h2>
                                <CloseButton parentEvent={setPopUp} />
                            </div>
                            <div className="mTop40 NewNotePoUpSub1Wrapper">
                                <label className="w100 textAlignCenter mY10">
                                    Duration:
                                </label>
                                <div className="w100 verticallyAlignMid textAlignCenter">
                                    <span className="mY20"></span>
                                    {/* <input ref={timeTextRef} className="w20 textAlignCenter" type="number" placeholder="example" /> */}
                                    <select
                                        ref={timeTextRef}
                                        className="w20 textAlignCenter"
                                    >
                                        <option value="0">
                                            Select Duration
                                        </option>
                                        {durationValues.map((el, i) => {
                                            if (
                                                company?.sessionDuration.indexOf(
                                                    el
                                                ) < 0
                                            ) {
                                                return (
                                                    <option key={i} value={el}>
                                                        {el} min
                                                    </option>
                                                );
                                            } else {
                                                return <></>;
                                            }
                                        })}
                                    </select>
                                </div>
                            </div>
                            <button
                                onClick={handleSubmitTime}
                                className="verticallyAlignMid w180 borderRadius12 mBottom40 borderNone Pregular colOffWhite backgroundGradientForButtons pY10 mLeftAuto"
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {(popUp === "whatKind" ||
                popUp === "noStats" ||
                popUp === "mentorsApply" ||
                popUp === "accepted" ||
                popUp === "welcome") && (
                <div className="filterMentorPopupWrapper">
                    <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                        <div className="w90 mXAuto mBottom10">
                            <div className="verticallyAlignMid mTop40 NewNotePoUpSub1Wrapper">
                                <h2 className="mRightAuto H3medium">
                                    {popUp === "whatKind" &&
                                        "What kind of mentor Image"}
                                    {popUp === "noStats" && "No stats Image"}
                                    {popUp === "mentorsApply" &&
                                        "Mentors apply Image"}
                                    {popUp === "accepted" &&
                                        "Accepted mentor Image"}
                                    {popUp === "welcome" &&
                                        "Mentor welcome Image"}
                                </h2>
                                <CloseButton parentEvent={setPopUp} />
                            </div>
                            {popUp === "whatKind" && (
                                <img
                                    width="100%"
                                    alt={popUp}
                                    src={company.whiteLabel.images.whatKind}
                                />
                            )}
                            {popUp === "noStats" && (
                                <img
                                    width="100%"
                                    alt={popUp}
                                    src={company.whiteLabel.images.noStats}
                                />
                            )}
                            {popUp === "mentorsApply" && (
                                <img
                                    width="100%"
                                    alt={popUp}
                                    src={company.whiteLabel.images.mentorsApply}
                                />
                            )}
                            {popUp === "accepted" && (
                                <img
                                    width="100%"
                                    alt={popUp}
                                    src={company.whiteLabel.images.accepted}
                                />
                            )}
                            {popUp === "welcome" && (
                                <img
                                    width="100%"
                                    alt={popUp}
                                    src={company.whiteLabel.images.welcome}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}

            {mentorID && (
                <div className="filterMentorPopupWrapper">
                    <div className="mentorsApplicationWrapper mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                        <MentorSingleView
                            mentorID={mentorID}
                            parentEvent={setMentorID}
                        />
                    </div>
                </div>
            )}
            <div className="w100 mXAuto mTop70 mBottom40 OnboardingMentor8SetAvailabilitySubWrapper">
                <div className="w100 verticallyAlignMid">
                    <div className="mX10 w20">
                        <button
                            onClick={() => setTable("reqInf")}
                            className={`w60 pY10 Pregular h100 borderNone borderRadius12`}
                            style={
                                table === "reqInf"
                                    ? {
                                          color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                          backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                      }
                                    : {
                                          color: `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
                                          background: `${company?.whiteLabel?.color?.buttons?.secondary}`,
                                      }
                            }
                        >
                            Required
                        </button>
                    </div>
                    <div className="mX10 w20">
                        <button
                            onClick={() => setTable("whitelabel")}
                            className={`w60 pY10 Pregular h100 borderNone borderRadius12`}
                            style={
                                table === "whitelabel"
                                    ? {
                                          color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                          backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                      }
                                    : {
                                          color: `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
                                          background: `${company?.whiteLabel?.color?.buttons?.secondary}`,
                                      }
                            }
                        >
                            Whitelabel
                        </button>
                    </div>
                    <div className="mX10 w20">
                        <button
                            onClick={() => setTable("business")}
                            className={`w60 pY10 Pregular h100 borderNone borderRadius12`}
                            style={
                                table === "business"
                                    ? {
                                          color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                          backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                      }
                                    : {
                                          color: `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
                                          background: `${company?.whiteLabel?.color?.buttons?.secondary}`,
                                      }
                            }
                        >
                            Business
                        </button>
                    </div>
                    <div className="mX10 w20">
                        <button
                            onClick={() => setTable("users")}
                            className={`w60 pY10 Pregular h100 borderNone borderRadius12`}
                            style={
                                table === "users"
                                    ? {
                                          color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                          backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                      }
                                    : {
                                          color: `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
                                          background: `${company?.whiteLabel?.color?.buttons?.secondary}`,
                                      }
                            }
                        >
                            Users
                        </button>
                    </div>
                    <div className="mX10 w20">
                        <button
                            onClick={() => setTable("operations")}
                            className={`w60 pY10 Pregular h100 borderNone borderRadius12`}
                            style={
                                table === "operations"
                                    ? {
                                          color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                          backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                      }
                                    : {
                                          color: `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
                                          background: `${company?.whiteLabel?.color?.buttons?.secondary}`,
                                      }
                            }
                        >
                            Operations
                        </button>
                    </div>
                </div>

                {table === "reqInf" && (
                    <div className="w100 mx-auto mTop30 verticallyAlignMid">
                        <div className="w80 verticallyAlignMid">
                            <div className="w100 mTop30 verticallyAlignMidStretchStart">
                                <div className="w50">
                                    {!companyReady && (
                                        <div className="w100 verticallyAlignMid mBottom20">
                                            <div className="w20">
                                                <h3 className="H3Bold">
                                                    Ready
                                                </h3>
                                                <div className="w100 verticallyAlignMid">
                                                    <p>No</p>
                                                    <input
                                                        onClick={(e) =>
                                                            setActive(false, e)
                                                        }
                                                        defaultChecked={
                                                            !company?.ready
                                                        }
                                                        type="radio"
                                                        name="active"
                                                        className="mLeft5"
                                                    />
                                                    <p className="mLeft10">
                                                        Yes
                                                    </p>
                                                    <input
                                                        onClick={(e) =>
                                                            setActive(true, e)
                                                        }
                                                        defaultChecked={
                                                            company?.ready
                                                        }
                                                        type="radio"
                                                        name="active"
                                                        className="mLeft5"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <form
                                        onSubmit={handleSubmitCompanyName}
                                        className="verticallyAlignMidJustConStart"
                                    >
                                        <div className="textAlignLeft pRight5">
                                            <label className="Pbold">
                                                Company name:
                                            </label>
                                        </div>
                                        <div className="textAlignLeft pRight5">
                                            <input
                                                defaultValue={
                                                    company?.name ?? ""
                                                }
                                                onChange={(e) =>
                                                    requiredChanges(e, "name")
                                                }
                                                className="borderLightGray pY4 pLeft5 Pregular w300"
                                                ref={companyNameRef}
                                                type="text"
                                                placeholder="Type here"
                                            />
                                        </div>
                                        {showNameSave && (
                                            <button
                                                type="submit"
                                                className="textAlignLeft pX10 verticallyAlignMid borderRadius12 borderNone colOffWhite backgroundGradientForButtons mLeftAuto"
                                            >
                                                Save
                                            </button>
                                        )}
                                    </form>
                                    {!company?.charity && (
                                        <form
                                            onSubmit={handleSubmitPercentage}
                                            className="verticallyAlignMidJustConStart mY20"
                                        >
                                            <div className="textAlignLeft pRight5">
                                                <label className="Pbold">
                                                    Company Percentage:
                                                </label>
                                            </div>
                                            {company?.charity ? (
                                                <p className="pLeft5 Pbold">
                                                    0%
                                                </p>
                                            ) : (
                                                <div className="textAlignLeft pRight5 verticallyAlignMidJustConStart">
                                                    <input
                                                        defaultValue={
                                                            company?.percentage &&
                                                            (company?.percentage ??
                                                                0)
                                                        }
                                                        onChange={(e) =>
                                                            requiredChanges(
                                                                e,
                                                                "perc"
                                                            )
                                                        }
                                                        className="borderLightGray pY4 pLeft5 Pregular w180"
                                                        ref={companyPercRef}
                                                        type="number"
                                                        placeholder="Percentage"
                                                    />
                                                    <p className="pLeft5 Pbold">
                                                        %
                                                    </p>
                                                </div>
                                            )}
                                            {showPercSave && (
                                                <button
                                                    type="submit"
                                                    className="textAlignLeft pX10 verticallyAlignMid borderRadius12 borderNone colOffWhite backgroundGradientForButtons mLeftAuto"
                                                >
                                                    Save
                                                </button>
                                            )}
                                        </form>
                                    )}
                                    <form
                                        onSubmit={handleSubmitEmail}
                                        className="verticallyAlignMidJustConStart mY20"
                                    >
                                        <div className="textAlignLeft pRight5">
                                            <label className="Pbold">
                                                Email Address:
                                            </label>
                                        </div>
                                        <div className="textAlignLeft pRight5">
                                            <input
                                                defaultValue={
                                                    company?.email ?? ""
                                                }
                                                onChange={(e) =>
                                                    requiredChanges(e, "email")
                                                }
                                                className="borderLightGray pY4 pLeft5 Pregular w340"
                                                ref={companyEmailRef}
                                                type="email"
                                                placeholder="Company Email"
                                            />
                                        </div>
                                        {showEmailSave && (
                                            <button
                                                type="submit"
                                                className="textAlignLeft pX10 verticallyAlignMid borderRadius12 borderNone colOffWhite backgroundGradientForButtons mLeftAuto"
                                            >
                                                Save
                                            </button>
                                        )}
                                    </form>
                                    {/*<form onSubmit={handleSubmitCompanyName} className="verticallyAlignMid">
	                                    <div className="textAlignLeft pRight5">*/}
                                    {/*<label>mobile number:</label>*/}
                                    {/*</div>
	                                    <div className="w60 textAlignLeft pRight5">
	                                        <input defaultValue={company?.name ?? ""} className="w100" ref={companyNameRef} type="text" placeholder="Type here" />
	                                    </div>
	                                    <button type="submit" className="textAlignLeft w10 pX6 verticallyAlignMid borderRadius12 borderNone colOffWhite backgroundGradientForButtons">Save</button>
                                    </form>*/}
                                    <div className="w50 mTop30">
                                        <input
                                            onChange={(e) => onChange(e)}
                                            type="file"
                                            className="customFileInput"
                                            ref={imageFileRef}
                                        />

                                        {image && (
                                            <button
                                                onClick={saveImage}
                                                className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold"
                                            >
                                                Save
                                            </button>
                                        )}

                                        {image && (
                                            <button
                                                onClick={remove}
                                                className="mLeftAuto borderNone backColWhite borderBottomForButtons colErrorRed Pbold mX10"
                                            >
                                                Remove
                                            </button>
                                        )}

                                        {image ? (
                                            <>
                                                <AvatarEditor
                                                    image={image}
                                                    width={250}
                                                    height={250}
                                                    border={50}
                                                    color={[255, 255, 255, 0.6]} // RGBA
                                                    scale={imageScale}
                                                    rotate={0}
                                                    ref={imageRef}
                                                />

                                                <input
                                                    type="range"
                                                    name="scale"
                                                    min="1"
                                                    max="5"
                                                    step="0.2"
                                                    defaultValue={1}
                                                    onChange={(e) =>
                                                        setImageScale(
                                                            parseFloat(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <div className="w50 mTop10">
                                                <img
                                                    alt=""
                                                    src={
                                                        company.logo !== ""
                                                            ? company.logo
                                                            : "https://gigr.com/images/png/default_logo.png"
                                                    }
                                                    width="250px"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="w50">
                                    <div className="w100">
                                        <MUIDataTable
                                            title={"Categories"}
                                            data={categories}
                                            columns={categoryColumns}
                                            options={categoryOptions}
                                            className={"w90 mXAuto"}
                                        />
                                    </div>
                                    <div className="w100 mTop10">
                                        <MUIDataTable
                                            title={"Additional Categories"}
                                            data={additionalCategories}
                                            columns={categoryColumns}
                                            options={additionalOptions}
                                            className={"w90 mXAuto"}
                                        />
                                    </div>
                                    <div className="w100 mTop10">
                                        <MUIDataTable
                                            title={"Session Time Table"}
                                            data={times}
                                            columns={timeColumns}
                                            options={timeOptions}
                                            className={"w90 mXAuto"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {table === "whitelabel" && (
                    <div className="w100 mx-auto mTop30 verticallyAlignMidSpace">
                        <div className="w100 mTop20 mLeft20">
                            <h2 className="H3bold"> Text options </h2>
                            <div className="mTop40">
                                <h4 className="P1bold"> Company Labels </h4>
                                <p className="mY20 w50 mLeftAuto mRightAuto Pregular">
                                    {" "}
                                    Some of the words and phrases in the
                                    application can be adjusted specifically for
                                    your company using the whitelabel feature.
                                    The text that you write in the input field
                                    will replace the word(s) that are underlined
                                    in screenshots taken from the app.{" "}
                                </p>

                                <div className="w100 verticallyAlignMid mY30">
                                    <div className="w30">
                                        <h5 className="mY14 w100">
                                            Company Label 1
                                        </h5>
                                        <input
                                            className="borderLightGray pY4 pLeft5 Pregular w300"
                                            onChange={changeLabelText}
                                            ref={label1TextRef}
                                            type="text"
                                            defaultValue={
                                                company?.whiteLabel?.texts
                                                    ?.label1Text
                                            }
                                        />
                                    </div>
                                    <div className="w30">
                                        <img
                                            alt=""
                                            src="https://i.ibb.co/WtjfWbX/test2.png"
                                            width="100%"
                                        />
                                    </div>
                                </div>

                                {label1 && (
                                    <div className="w100 verticallyAlignMid mY30">
                                        <button
                                            onClick={() =>
                                                saveLabelText(setLabel1)
                                            }
                                            className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                        >
                                            Save Label
                                        </button>
                                    </div>
                                )}

                                <div className="whitelabelSmallSeparator">
                                    {" "}
                                </div>

                                <div className="w100 verticallyAlignMid mY30">
                                    <div className="w30">
                                        <h5 className="mY14 w100">
                                            Company Label 2 (mentor) single
                                        </h5>
                                        <input
                                            className="borderLightGray pY4 pLeft5 Pregular w300"
                                            onChange={changeLabelText}
                                            ref={label2MentorSingleTextRef}
                                            type="text"
                                            defaultValue={
                                                company?.whiteLabel?.texts
                                                    ?.label2MentorSingle
                                            }
                                        />
                                    </div>
                                    <div className="w30">
                                        <img
                                            alt=""
                                            src="https://i.ibb.co/nB6FQVK/test3.png"
                                            width="100%"
                                        />
                                    </div>
                                </div>

                                {label2MentorSingle && (
                                    <div className="w100 verticallyAlignMid mY30">
                                        <button
                                            onClick={() =>
                                                saveLabelText(
                                                    setLabel2MentorSingle
                                                )
                                            }
                                            className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                        >
                                            Save Label
                                        </button>
                                    </div>
                                )}

                                <div className="whitelabelSmallSeparator">
                                    {" "}
                                </div>

                                <div className="w100 verticallyAlignMid mY30">
                                    <div className="w30">
                                        <h5 className="mY14 w100">
                                            Company Label 3 (mentor) plural
                                        </h5>
                                        <input
                                            className="borderLightGray pY4 pLeft5 Pregular w300"
                                            onChange={changeLabelText}
                                            ref={label3MentorPluralTextRef}
                                            type="text"
                                            defaultValue={
                                                company?.whiteLabel?.texts
                                                    ?.label3MentorPlural
                                            }
                                        />
                                    </div>
                                    <div className="w30">
                                        <img
                                            alt=""
                                            src="https://i.ibb.co/C86GDhw/mentors.png"
                                            width="100%"
                                        />
                                    </div>
                                </div>

                                {label3MentorPlural && (
                                    <div className="w100 verticallyAlignMid mY30">
                                        <button
                                            onClick={() =>
                                                saveLabelText(
                                                    setLabel3MentorPlural
                                                )
                                            }
                                            className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                        >
                                            Save Label
                                        </button>
                                    </div>
                                )}

                                <div className="whitelabelSmallSeparator">
                                    {" "}
                                </div>

                                <div className="w100 verticallyAlignMid mY30">
                                    <div className="w30">
                                        <h5 className="mY14 w100">
                                            Company Label 4 (session) single
                                        </h5>
                                        <input
                                            className="borderLightGray pY4 pLeft5 Pregular w300"
                                            onChange={changeLabelText}
                                            ref={label4SessionSingleTextRef}
                                            type="text"
                                            defaultValue={
                                                company?.whiteLabel?.texts
                                                    ?.label4SessionSingle
                                            }
                                        />
                                    </div>
                                    <div className="w30">
                                        <img
                                            alt=""
                                            src="https://i.ibb.co/XsbZNRp/test4.png"
                                            width="100%"
                                        />
                                    </div>
                                </div>

                                {label4SessionSingle && (
                                    <div className="w100 verticallyAlignMid mY30">
                                        <button
                                            onClick={() =>
                                                saveLabelText(
                                                    setLabel4SessionSingle
                                                )
                                            }
                                            className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                        >
                                            Save Label
                                        </button>
                                    </div>
                                )}

                                <div className="whitelabelSmallSeparator">
                                    {" "}
                                </div>

                                <div className="w100 verticallyAlignMid mY30">
                                    <div className="w30">
                                        <h5 className="mY14 w100">
                                            Company Label 5 (sessions) plural
                                        </h5>
                                        <input
                                            className="borderLightGray pY4 pLeft5 Pregular w300"
                                            onChange={changeLabelText}
                                            ref={label5SessionPluralTextRef}
                                            type="text"
                                            defaultValue={
                                                company?.whiteLabel?.texts
                                                    ?.label5SessionPlural
                                            }
                                        />
                                    </div>
                                    <div className="w30">
                                        <img
                                            alt=""
                                            src="https://i.ibb.co/XsbZNRp/test4.png"
                                            width="100%"
                                        />
                                    </div>
                                </div>

                                {label5SessionPlural && (
                                    <div className="w100 verticallyAlignMid mY30">
                                        <button
                                            onClick={() =>
                                                saveLabelText(
                                                    setLabel5SessionPlural
                                                )
                                            }
                                            className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                        >
                                            Save Label
                                        </button>
                                    </div>
                                )}

                                <div className="whitelabelSmallSeparator">
                                    {" "}
                                </div>

                                <div className="w100 verticallyAlignMid mY30">
                                    <div className="w30">
                                        <h5 className="mY14 w100">
                                            Company Label 6
                                        </h5>
                                        <textarea
                                            className="borderLightGray pLeft5 Pregular w300"
                                            rows="5"
                                            onChange={changeLabelText}
                                            ref={
                                                label6NoSessionDescriptionTextRef
                                            }
                                            defaultValue={
                                                company?.whiteLabel?.texts
                                                    ?.label6NoSessionDescription
                                            }
                                        ></textarea>
                                    </div>
                                    <div className="w30">
                                        <img
                                            alt=""
                                            src="https://i.ibb.co/6vXvt49/test5.png"
                                            width="100%"
                                        />
                                    </div>
                                </div>

                                {label6NoSessionDescription && (
                                    <div className="w100 verticallyAlignMid mY30">
                                        <button
                                            onClick={() =>
                                                saveLabelText(
                                                    setLabel6NoSessionDescription
                                                )
                                            }
                                            className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                        >
                                            Save Label
                                        </button>
                                    </div>
                                )}

                                <div className="whitelabelSmallSeparator"></div>

                                <h4> Profile Pages </h4>
                                <p className="mY10">
                                    {" "}
                                    Here, you can edit a short text that will
                                    appear below each of the links for Profile
                                    Pages{" "}
                                </p>

                                <div className="row w100 verticallyAlignMid mY20">
                                    <div className="w30">
                                        <div className="w100 mTop20">
                                            <h5 className="mY14 w100">
                                                Personal Information
                                            </h5>
                                            <input
                                                className="borderLightGray pY4 pLeft5 Pregular w300"
                                                onChange={changeProfileTexts}
                                                ref={personalInformationTextRef}
                                                defaultValue={
                                                    company?.whiteLabel?.texts
                                                        ?.profile
                                                        ?.personalInformationText ||
                                                    "Some Text Here"
                                                }
                                            />
                                        </div>
                                        <div className="w100 mTop20">
                                            <h5 className="mY14">
                                                Logins &amp; Security
                                            </h5>
                                            <input
                                                className="borderLightGray pY4 pLeft5 Pregular w300"
                                                onChange={changeProfileTexts}
                                                ref={loginsSecurityTextRef}
                                                defaultValue={
                                                    company?.whiteLabel?.texts
                                                        ?.profile
                                                        ?.loginsSecurityText ||
                                                    "settings..."
                                                }
                                            />
                                        </div>
                                        <div className="w100 mTop20">
                                            <h5 className="mY14">
                                                How 'MentorX' Works
                                            </h5>
                                            <input
                                                className="borderLightGray pY4 pLeft5 Pregular w300"
                                                onChange={changeProfileTexts}
                                                ref={howWorksTextRef}
                                                defaultValue={
                                                    company?.whiteLabel?.texts
                                                        ?.profile
                                                        ?.howWorksText ||
                                                    "Mentors are helping clients"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="w40">
                                        <img
                                            alt=""
                                            src="https://i.ibb.co/BTb1GKW/test.png"
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>
                                    <div className="w30">
                                        <div className="w100 mTop20">
                                            <h5 className="mY14 w100">
                                                Payments &amp; Payouts
                                            </h5>
                                            <input
                                                className="borderLightGray pY4 pLeft5 Pregular w300"
                                                onChange={changeProfileTexts}
                                                ref={paymentsPayoutsTextRef}
                                                defaultValue={
                                                    company?.whiteLabel?.texts
                                                        ?.profile
                                                        ?.paymentsPayoutsText ||
                                                    "Set payment method"
                                                }
                                            />
                                        </div>
                                        <div className="w100 mTop20">
                                            <h5 className="mY14">
                                                Availability
                                            </h5>
                                            <input
                                                className="borderLightGray pY4 pLeft5 Pregular w300"
                                                onChange={changeProfileTexts}
                                                ref={availabilityTextRef}
                                                defaultValue={
                                                    company?.whiteLabel?.texts
                                                        ?.profile
                                                        ?.availabilityText ||
                                                    "Set your availability by day here"
                                                }
                                            />
                                        </div>
                                        <div className="w100 mTop20">
                                            <h5 className="mY14">
                                                Give us feedback
                                            </h5>
                                            <input
                                                className="borderLightGray pY4 pLeft5 Pregular w300"
                                                onChange={changeProfileTexts}
                                                ref={giveUsFeedbackTextRef}
                                                defaultValue={
                                                    company?.whiteLabel?.texts
                                                        ?.profile
                                                        ?.giveUsFeedbackText ||
                                                    "We appreciate your oppinion"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="w80 verticallyAlignMidSpaceBetween mY20">
                                        <div className="">
                                            <h5 className="mY14 w100">
                                                Manage Services
                                            </h5>
                                            <input
                                                className="borderLightGray pY4 pLeft5 Pregular w300"
                                                onChange={changeProfileTexts}
                                                ref={manageServicesTextRef}
                                                defaultValue={
                                                    company?.whiteLabel?.texts
                                                        ?.profile
                                                        ?.manageServicesText ||
                                                    "Add new or edit you Services"
                                                }
                                            />
                                        </div>
                                        <div className="">
                                            <h5 className="mY14 w100">
                                                Notifications
                                            </h5>
                                            <input
                                                className="borderLightGray pY4 pLeft5 Pregular w300"
                                                onChange={changeProfileTexts}
                                                ref={notificationsTextRef}
                                                defaultValue={
                                                    company?.whiteLabel?.texts
                                                        ?.profile
                                                        ?.notificationsText ||
                                                    "Notification settings"
                                                }
                                            />
                                        </div>
                                        <div className="">
                                            <h5 className="mY14 w100">
                                                Contact Support
                                            </h5>
                                            <input
                                                className="borderLightGray pY4 pLeft5 Pregular w300"
                                                onChange={changeProfileTexts}
                                                ref={contactsSupportTextRef}
                                                defaultValue={
                                                    company?.whiteLabel?.texts
                                                        ?.profile
                                                        ?.contactsSupportText ||
                                                    "Need help?"
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                {saveProfile && (
                                    <div className="w100 verticallyAlignMid mY20">
                                        <div className="w20">
                                            <button
                                                onClick={saveProfileTexts}
                                                className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                            >
                                                Save Texts
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="whitelabelSmallSeparator"></div>

                            <h4>Mentors Apply</h4>
                            <div className="row w100 verticallyAlignMid mY20">
                                <div className="w60">
                                    <div className="w100 verticallyAlignMid">
                                        <div className="w40 mRightAuto">
                                            <div className="w100 mTop20">
                                                <h5 className="mY14 w100">
                                                    Green word
                                                </h5>
                                                <input
                                                    className="borderLightGray pY4 pLeft5 Pregular w300"
                                                    onChange={changeApplyTexts}
                                                    ref={greenLabelRef}
                                                    defaultValue={
                                                        company?.whiteLabel
                                                            ?.texts
                                                            ?.mentorsApply
                                                            ?.greenLabel ||
                                                        "Mentors"
                                                    }
                                                />
                                            </div>
                                            <div className="w100 mTop20">
                                                <h5 className="mY14 w100">
                                                    Large Header
                                                </h5>
                                                <input
                                                    className="borderLightGray pY4 pLeft5 Pregular w300"
                                                    onChange={changeApplyTexts}
                                                    ref={largeHeaderRef}
                                                    defaultValue={
                                                        company?.whiteLabel
                                                            ?.texts
                                                            ?.mentorsApply
                                                            ?.largeHeader ||
                                                        "with technical ability & expertise on the highest level"
                                                    }
                                                />
                                            </div>
                                            <div className="w100 mTop20">
                                                <h5 className="mY14 w100">
                                                    Main Label
                                                </h5>
                                                <input
                                                    className="borderLightGray pY4 pLeft5 Pregular w300"
                                                    onChange={changeApplyTexts}
                                                    ref={mainHeaderRef}
                                                    defaultValue={
                                                        company?.whiteLabel
                                                            ?.texts
                                                            ?.mentorsApply
                                                            ?.mainHeader ||
                                                        "Technical quality is of utmost importance as for example:"
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="w40">
                                            <div className="w100 mTop20">
                                                <h5 className="mY14 w100">
                                                    Label 1
                                                </h5>
                                                <input
                                                    className="borderLightGray pY4 pLeft5 Pregular w300"
                                                    onChange={changeApplyTexts}
                                                    ref={label1Ref}
                                                    defaultValue={
                                                        company?.whiteLabel
                                                            ?.texts
                                                            ?.mentorsApply
                                                            ?.label1 ||
                                                        "Lighning that helps user view you"
                                                    }
                                                />
                                            </div>
                                            <div className="w100 mTop20">
                                                <h5 className="mY14 w100">
                                                    Label 2
                                                </h5>
                                                <input
                                                    className="borderLightGray pY4 pLeft5 Pregular w300"
                                                    onChange={changeApplyTexts}
                                                    ref={label2Ref}
                                                    defaultValue={
                                                        company?.whiteLabel
                                                            ?.texts
                                                            ?.mentorsApply
                                                            ?.Label2 ||
                                                        "Fast internet connection"
                                                    }
                                                />
                                            </div>
                                            <div className="w100 mTop20">
                                                <h5 className="mY14 w100">
                                                    Label 3
                                                </h5>
                                                <input
                                                    className="borderLightGray pY4 pLeft5 Pregular w300"
                                                    onChange={changeApplyTexts}
                                                    ref={label3Ref}
                                                    defaultValue={
                                                        company?.whiteLabel
                                                            ?.texts
                                                            ?.mentorsApply
                                                            ?.label3 ||
                                                        "Clear sound"
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {saveApply && (
                                        <div className="w100 verticallyAlignMid mY20">
                                            <div className="w20">
                                                <button
                                                    onClick={saveApplyTexts}
                                                    className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                                >
                                                    Save Texts
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row w100 verticallyAlignMid mY20">
                                        <div className="w50">
                                            <h5 className="mY14 w100">
                                                Image 1{" "}
                                                {company.whiteLabel.images
                                                    .mentorsApply !== "" ? (
                                                    <span
                                                        onClick={() =>
                                                            setPopUp(
                                                                "mentorsApply"
                                                            )
                                                        }
                                                        className="mX10 previewImage"
                                                    >
                                                        preview
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </h5>
                                            <input
                                                className="w60"
                                                type="file"
                                                ref={mentorsApplyRef}
                                                onChange={() =>
                                                    setMentorsApplyImage(true)
                                                }
                                            />
                                        </div>
                                    </div>
                                    {mentorsApplyImage && (
                                        <div className="w100 verticallyAlignMid mY20">
                                            <div className="w20">
                                                <button
                                                    onClick={() =>
                                                        saveImages(
                                                            mentorsApplyRef,
                                                            "mentorsApply",
                                                            setMentorsApplyImage
                                                        )
                                                    }
                                                    className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                                >
                                                    Save Image
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="w40">
                                    <img
                                        alt=""
                                        src="https://i.ibb.co/fFBpHvf/test6.png"
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                            </div>

                            <div className="w100 verticallyAlignMid">
                                <div className="w80 borderSecundaryGreen"></div>
                            </div>

                            <h2 className="mTop30"> Images </h2>
                            <div className="mTop40">
                                <div className="w100 verticallyAlignMid mY30">
                                    <div className="w30">
                                        <h5 className="mY14 w100">
                                            Image 1{" "}
                                            {company.whiteLabel.images
                                                .whatKind !== "" ? (
                                                <span
                                                    onClick={() =>
                                                        setPopUp("whatKind")
                                                    }
                                                    className="mX10 previewImage"
                                                >
                                                    preview
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </h5>

                                        <input
                                            className="w60"
                                            type="file"
                                            ref={whatKindRef}
                                            onChange={() =>
                                                setWhatKindImage(true)
                                            }
                                        />
                                    </div>
                                    <div className="w30">
                                        <img
                                            alt=""
                                            src="https://i.ibb.co/vmHbVN5/whatkindofmentoor.png"
                                            width="100%"
                                        />
                                    </div>
                                </div>

                                {whatKindImage && (
                                    <div className="w100 verticallyAlignMid mY20">
                                        <div className="w20">
                                            <button
                                                onClick={() =>
                                                    saveImages(
                                                        whatKindRef,
                                                        "whatKind",
                                                        setWhatKindImage
                                                    )
                                                }
                                                className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                            >
                                                Save Image
                                            </button>
                                        </div>
                                    </div>
                                )}

                                <div className="whitelabelSmallSeparator">
                                    {" "}
                                </div>

                                <div className="w100 verticallyAlignMid mY30">
                                    <div className="w30">
                                        <h5 className="mY14 w100">
                                            Image 2{" "}
                                            {company.whiteLabel.images
                                                .noStats !== "" ? (
                                                <span
                                                    onClick={() =>
                                                        setPopUp("noStats")
                                                    }
                                                    className="mX10 previewImage"
                                                >
                                                    preview
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </h5>
                                        <input
                                            className="w60"
                                            type="file"
                                            ref={noStatsRef}
                                            onChange={() =>
                                                setNoStatsImage(true)
                                            }
                                        />
                                    </div>
                                    <div className="w30">
                                        <img
                                            alt=""
                                            src="https://i.ibb.co/LCTLx4V/nosstatsyet.png"
                                            width="100%"
                                        />
                                    </div>
                                </div>

                                {noStatsImage && (
                                    <div className="w100 verticallyAlignMid mY20">
                                        <div className="w20">
                                            <button
                                                onClick={() =>
                                                    saveImages(
                                                        noStatsRef,
                                                        "noStats",
                                                        setNoStatsImage
                                                    )
                                                }
                                                className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                            >
                                                Save Image
                                            </button>
                                        </div>
                                    </div>
                                )}

                                <div className="whitelabelSmallSeparator">
                                    {" "}
                                </div>

                                <div className="w100 verticallyAlignMid mY30">
                                    <div className="w30">
                                        <h5 className="mY14 w100">
                                            Image 3{" "}
                                            {company.whiteLabel.images
                                                ?.accepted &&
                                            company.whiteLabel.images
                                                .accepted !== "" ? (
                                                <span
                                                    onClick={() =>
                                                        setPopUp("accepted")
                                                    }
                                                    className="mX10 previewImage"
                                                >
                                                    preview
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </h5>
                                        <input
                                            className="w60"
                                            type="file"
                                            ref={acceptedRef}
                                            onChange={() =>
                                                setAcceptedImage(true)
                                            }
                                        />
                                    </div>
                                    <div className="w30">
                                        <img
                                            src="https://i.ibb.co/LRJ0jzh/accepted-Img.png"
                                            alt="welcomeImg"
                                            width="100%"
                                        />
                                    </div>
                                </div>

                                {acceptedImage && (
                                    <div className="w100 verticallyAlignMid mY20">
                                        <div className="w20">
                                            <button
                                                onClick={() =>
                                                    saveImages(
                                                        acceptedRef,
                                                        "accepted",
                                                        setAcceptedImage
                                                    )
                                                }
                                                className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                            >
                                                Save Image
                                            </button>
                                        </div>
                                    </div>
                                )}

                                <div className="whitelabelSmallSeparator">
                                    {" "}
                                </div>

                                <div className="w100 verticallyAlignMid mY30">
                                    <div className="w30">
                                        <h5 className="mY14 w100">
                                            Image 4{" "}
                                            {company.whiteLabel.images
                                                ?.welcome &&
                                            company.whiteLabel.images
                                                .welcome !== "" ? (
                                                <span
                                                    onClick={() =>
                                                        setPopUp("welcome")
                                                    }
                                                    className="mX10 previewImage"
                                                >
                                                    preview
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </h5>
                                        <input
                                            className="w60"
                                            type="file"
                                            ref={welcomeRef}
                                            onChange={() =>
                                                setWelcomeImage(true)
                                            }
                                        />
                                    </div>
                                    <div className="w30">
                                        <img
                                            src="https://i.ibb.co/GdsdQML/welcome.png"
                                            alt="welcome"
                                            width="100%"
                                        />
                                    </div>
                                </div>

                                {welcomeImage && (
                                    <div className="w100 verticallyAlignMid mY20">
                                        <div className="w20">
                                            <button
                                                onClick={() =>
                                                    saveImages(
                                                        welcomeRef,
                                                        "welcome",
                                                        setWelcomeImage
                                                    )
                                                }
                                                className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                            >
                                                Save Image
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="w100 verticallyAlignMid">
                                <div className="w80 borderSecundaryGreen"></div>
                            </div>

                            <h2 className="mTop30"> Colours </h2>

                            <div className="w100 mY30">
                                <div className="w100 verticallyAlignMid">
                                    <h5 className="mY14 w100">Buttons</h5>
                                </div>
                                <div className="w100 verticallyAlignMid">
                                    <div className="w30">
                                        <h5 className="mY14 w100">
                                            This button is a linear gradient
                                            from bottom-left to right-top
                                        </h5>
                                        <button
                                            style={{
                                                backgroundImage: `linear-gradient(to right top, ${btnColorFrom}, ${btnColorTo})`,
                                                color: `${btnTextColorActive}`,
                                            }}
                                            className="w60 pY10 Pregular h100 borderNone borderRadius12"
                                        >
                                            Button
                                        </button>
                                    </div>
                                </div>
                                <div className="w100 verticallyAlignMid mY20">
                                    <div className="w20">
                                        <h5 className="mY14 w100">
                                            Colour from
                                        </h5>
                                        <div className="w100">
                                            <HexColorPicker
                                                className="mXAuto"
                                                color={btnColorFrom}
                                                onChange={(e) => {
                                                    setBtnColorFrom(e);
                                                    setSaveColorBtn(true);
                                                }}
                                            />
                                            <div className="w100 verticallyAlignMid">
                                                <div
                                                    className="mTop10"
                                                    style={{
                                                        borderLeft:
                                                            "24px solid " +
                                                            btnColorFrom,
                                                    }}
                                                >
                                                    Current colour is{" "}
                                                    {btnColorFrom}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w20">
                                        <h5 className="mY14 w100">Colour to</h5>
                                        <div className="w100">
                                            <HexColorPicker
                                                className="mXAuto"
                                                color={btnColorTo}
                                                onChange={(e) => {
                                                    setBtnColorTo(e);
                                                    setSaveColorBtn(true);
                                                }}
                                            />
                                            <div className="w100 verticallyAlignMid">
                                                <div
                                                    className="mTop10"
                                                    style={{
                                                        borderLeft:
                                                            "24px solid " +
                                                            btnColorTo,
                                                    }}
                                                >
                                                    Current colour is{" "}
                                                    {btnColorTo}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w20">
                                        <h5 className="mY14 w100">
                                            Text Colour
                                        </h5>
                                        <div className="w100 mY20">
                                            <HexColorPicker
                                                className="mXAuto"
                                                color={btnTextColorActive}
                                                onChange={(e) => {
                                                    setBtnTextColorActive(e);
                                                    setSaveColorBtn(true);
                                                }}
                                            />
                                            <div className="w100 verticallyAlignMid">
                                                <div
                                                    className="mTop10"
                                                    style={{
                                                        borderLeft:
                                                            "24px solid " +
                                                            btnTextColorActive,
                                                    }}
                                                >
                                                    Current colour is{" "}
                                                    {btnTextColorActive}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {labelColor1Something && (
                                        <div className="w100 verticallyAlignMid mY30">
                                            <button
                                                onClick={() =>
                                                    saveLabelText(
                                                        setLabelColor1Something
                                                    )
                                                }
                                                className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                            >
                                                Save Label
                                            </button>
                                        </div>
                                    )}
                                </div>
                                {saveColorBtn && (
                                    <div className="w100 verticallyAlignMid mY30">
                                        <button
                                            onClick={() => saveColors(1)}
                                            className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                        >
                                            Save Colours
                                        </button>
                                    </div>
                                )}
                                <div className="w100 verticallyAlignMid mY20">
                                    <div className="w30">
                                        <h5 className="mY14 w100">
                                            Colour for not active buttons
                                        </h5>
                                        <button
                                            style={{
                                                backgroundColor: `${btnColorNotActive}`,
                                                color: `${btnTextColorNotActive}`,
                                            }}
                                            className="w60 pY10 Pregular h100 borderNone borderRadius12"
                                        >
                                            Button
                                        </button>
                                        <div className="w100 verticallyAlignMid">
                                            <div className="w50">
                                                <div className="w100 mY20">
                                                    <HexColorPicker
                                                        className="mXAuto"
                                                        color={
                                                            btnColorNotActive
                                                        }
                                                        onChange={(e) => {
                                                            setBtnColorNotActive(
                                                                e
                                                            );
                                                            setSaveColorBtn2(
                                                                true
                                                            );
                                                        }}
                                                    />
                                                    <div className="w100 verticallyAlignMid">
                                                        <div
                                                            className="mTop10"
                                                            style={{
                                                                borderLeft:
                                                                    "24px solid " +
                                                                    btnColorNotActive,
                                                            }}
                                                        >
                                                            Current colour is{" "}
                                                            {btnColorNotActive}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w50">
                                                {/* <button style={{backgroundColor: `${btnColorNotActive}`, color : `${btnTextColorNotActive}`}} className="w60 pY10 Pregular h100 borderNone borderRadius12">Button</button> */}
                                                <div className="w100 mY20">
                                                    <HexColorPicker
                                                        className="mXAuto"
                                                        color={
                                                            btnTextColorNotActive
                                                        }
                                                        onChange={(e) => {
                                                            setBtnTextColorNotActive(
                                                                e
                                                            );
                                                            setSaveColorBtn2(
                                                                true
                                                            );
                                                        }}
                                                    />
                                                    <div className="w100 verticallyAlignMid">
                                                        <div
                                                            className="mTop10"
                                                            style={{
                                                                borderLeft:
                                                                    "24px solid " +
                                                                    btnTextColorNotActive,
                                                            }}
                                                        >
                                                            Current colour is{" "}
                                                            {
                                                                btnTextColorNotActive
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {saveColorBtn2 && (
                                            <div className="w100 verticallyAlignMid mY30">
                                                <button
                                                    onClick={() =>
                                                        saveColors(2)
                                                    }
                                                    className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                                >
                                                    Save Colours
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="w100 verticallyAlignMid mY20">
                                    <div className="w30">
                                        <div className="w100 verticallyAlignMid mY20">
                                            <div className="w50">
                                                <h5
                                                    style={{
                                                        color: `${primaryTextColor}`,
                                                    }}
                                                    className="mY14 w100 font-weight-bold"
                                                >
                                                    Primary Text Colour
                                                </h5>
                                                <div className="w100 mY20">
                                                    <HexColorPicker
                                                        className="mXAuto"
                                                        color={primaryTextColor}
                                                        onChange={(e) => {
                                                            setPrimaryTextColor(
                                                                e
                                                            );
                                                            setSaveColorBtn3(
                                                                true
                                                            );
                                                        }}
                                                    />
                                                    <div className="w100 verticallyAlignMid">
                                                        <div
                                                            className="mTop10"
                                                            style={{
                                                                borderLeft:
                                                                    "24px solid " +
                                                                    primaryTextColor,
                                                            }}
                                                        >
                                                            Current colour is{" "}
                                                            {primaryTextColor}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w30">
                                        <div className="w100 verticallyAlignMid mY20">
                                            <div className="w50">
                                                <h5
                                                    style={{
                                                        color: `${secondaryTextColor}`,
                                                    }}
                                                    className="mY14 w100 font-weight-bold"
                                                >
                                                    Secondary Text Color
                                                </h5>
                                                <div className="w100 mY20">
                                                    <HexColorPicker
                                                        className="mXAuto"
                                                        color={
                                                            secondaryTextColor
                                                        }
                                                        onChange={(e) => {
                                                            setSecondaryTextColor(
                                                                e
                                                            );
                                                            setSaveColorBtn3(
                                                                true
                                                            );
                                                        }}
                                                    />
                                                    <div className="w100 verticallyAlignMid">
                                                        <div
                                                            className="mTop10"
                                                            style={{
                                                                borderLeft:
                                                                    "24px solid " +
                                                                    secondaryTextColor,
                                                            }}
                                                        >
                                                            Current colour is{" "}
                                                            {secondaryTextColor}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {saveColorBtn3 && (
                                    <div className="w100 verticallyAlignMid mY30">
                                        <button
                                            onClick={() => saveColors(3)}
                                            className="w60 pY10 Pregular colOffWhite backgroundGradientForButtons h100 borderNone borderRadius12"
                                        >
                                            Save Colours
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {table === "business" && (
                    <>
                        <div className="w100 mx-auto mTop30 verticallyAlignMid">
                            <div
                                className="btn-group borderSecundaryGreen"
                                role="group"
                                aria-label="Basic example"
                            >
                                <button
                                    onClick={() => changeBusinessDate("today")}
                                    type="button"
                                    className={
                                        businessDateType === "today"
                                            ? "btn backColSecondaryGreen colOffWhite"
                                            : "btn colSecondaryGreen"
                                    }
                                >
                                    Today
                                </button>
                                <button
                                    onClick={() => changeBusinessDate("week")}
                                    type="button"
                                    className={
                                        businessDateType === "week"
                                            ? "btn backColSecondaryGreen colOffWhite"
                                            : "btn colSecondaryGreen"
                                    }
                                >
                                    This Week
                                </button>
                                <button
                                    onClick={() => changeBusinessDate("month")}
                                    type="button"
                                    className={
                                        businessDateType === "month"
                                            ? "btn backColSecondaryGreen colOffWhite"
                                            : "btn colSecondaryGreen"
                                    }
                                >
                                    This Month
                                </button>
                                <button
                                    onClick={() => changeBusinessDate("year")}
                                    type="button"
                                    className={
                                        businessDateType === "year"
                                            ? "btn backColSecondaryGreen colOffWhite"
                                            : "btn colSecondaryGreen"
                                    }
                                >
                                    This Year
                                </button>
                                <button
                                    onClick={() => changeBusinessDate("all")}
                                    type="button"
                                    className={
                                        businessDateType === "all"
                                            ? "btn backColSecondaryGreen colOffWhite"
                                            : "btn colSecondaryGreen"
                                    }
                                >
                                    All Time
                                </button>
                                <button
                                    onClick={() => changeBusinessDate("custom")}
                                    type="button"
                                    className={
                                        businessDateType === "custom"
                                            ? "btn backColSecondaryGreen colOffWhite"
                                            : "btn colSecondaryGreen"
                                    }
                                >
                                    Custom
                                </button>
                                {businessDateType === "custom" && (
                                    <Datepicker
                                        controls={["calendar"]}
                                        select="range"
                                        touchUi={true}
                                        inputComponent="input"
                                        ref={dateRangeRef}
                                        inputProps={{
                                            placeholder: "Select custom range",
                                        }}
                                        onChange={(e) => {
                                            changeCustom(e);
                                            setCustomDate(e);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="w100 mx-auto mTop30">
                            <div className="w100 mTop15 verticallyAlignMid">
                                <div
                                    className="w20"
                                    onClick={() =>
                                        getAllBusinessData("revenue")
                                    }
                                >
                                    <div className="w100 mY5 verticallyAlignMid">
                                        <h3 className="H3bold">Revenue</h3>
                                    </div>
                                    <div className="verticallyAlignMid MentorSingleViewImg cursorPointer">
                                        <div className="userInitialsBig">
                                            <p>{businessInitialData.revenue}</p>
                                            {/* <p>{formatCount(543156.22)}</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="w20"
                                    onClick={() => getAllBusinessData("income")}
                                >
                                    <div className="w100 mY5 verticallyAlignMid">
                                        <h3 className="H3bold">Income</h3>
                                    </div>
                                    <div className="verticallyAlignMid MentorSingleViewImg cursorPointer">
                                        <div className="userInitialsBig">
                                            <p>{businessInitialData.income}</p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="w20"
                                    onClick={() =>
                                        getAllBusinessData("sessions")
                                    }
                                >
                                    <div className="w100 mY5 verticallyAlignMid">
                                        <h3 className="H3bold">Sessions</h3>
                                    </div>
                                    <div className="verticallyAlignMid MentorSingleViewImg cursorPointer">
                                        <div className="userInitialsBig">
                                            <p>
                                                {businessInitialData.sessions}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="w20"
                                    onClick={() =>
                                        getAllBusinessData("mentors")
                                    }
                                >
                                    <div className="w100 mY5 verticallyAlignMid">
                                        <h3 className="H3bold">Mentors</h3>
                                    </div>
                                    <div className="verticallyAlignMid MentorSingleViewImg cursorPointer">
                                        <div className="userInitialsBig">
                                            <p>{businessInitialData.mentors}</p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="w20"
                                    onClick={() =>
                                        getAllBusinessData("clients")
                                    }
                                >
                                    <div className="w100 mY5 verticallyAlignMid">
                                        <h3 className="H3bold">Clients</h3>
                                    </div>
                                    <div className="verticallyAlignMid MentorSingleViewImg cursorPointer">
                                        <div className="userInitialsBig">
                                            <p>{businessInitialData.clients}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {businessTab === "revenue" && (
                                <div className="w100 mTop30">
                                    <h2 className="H2bold mTop30 w100">
                                        Revenue Overview
                                    </h2>
                                    <div className="w90 mXAuto verticallyAlignMid">
                                        <div className="w30 pX20">
                                            <div className="w90 mXAuto mTop20 dataTableHead">
                                                <MUIDataTable
                                                    data={revenuePeaks}
                                                    columns={peaksColumns}
                                                    options={peaksOptions}
                                                    className={"w100 mXAuto"}
                                                />
                                            </div>
                                        </div>
                                        <div className="w70 pX20">
                                            <div className="w90 mXAuto mTop20">
                                                <MUIDataTable
                                                    title={"Revenue Table"}
                                                    data={revenueData}
                                                    columns={revenueColumns}
                                                    options={revenueOptions}
                                                    className={"w100 mXAuto"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {businessTab === "income" && (
                                <div className="w100 mTop30">
                                    <h2 className="H2bold mTop30 w100">
                                        Income Overview
                                    </h2>
                                    <div className="w90 mXAuto verticallyAlignMid">
                                        <div className="w30 pX20">
                                            <div className="w90 mXAuto mTop20 dataTableHead">
                                                {/* <MUIDataTable
												data={peaks}
												columns={peaksColumns}
												options={peaksOptions}
												className={"w100 mXAuto"}
											/> */}
                                            </div>
                                        </div>
                                        <div className="w70 pX20">
                                            <div className="w90 mXAuto mTop20">
                                                <MUIDataTable
                                                    title={"Income Table"}
                                                    data={incomeData}
                                                    columns={revenueColumns}
                                                    options={revenueOptions}
                                                    className={"w100 mXAuto"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {businessTab === "sessions" && (
                                <div className="w100 mTop30">
                                    <h2 className="H2bold mTop30 w100">
                                        Sessions Overview
                                    </h2>
                                    <div className="w90 mXAuto verticallyAlignMid">
                                        <div className="w30 pX20">
                                            <div className="w90 mXAuto mTop20 dataTableHead">
                                                {/* <MUIDataTable
												data={peaks}
												columns={peaksColumns}
												options={peaksOptions}
												className={"w100 mXAuto"}
											/> */}
                                            </div>
                                        </div>
                                        <div className="w70 pX20">
                                            <div className="w90 mXAuto mTop20">
                                                <MUIDataTable
                                                    title={"Sessions Table"}
                                                    data={sessionsData}
                                                    columns={
                                                        sessionsBusinessColumns
                                                    }
                                                    options={revenueOptions}
                                                    className={"w100 mXAuto"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {businessTab === "mentors" && (
                                <div className="w100 mTop30">
                                    <h2 className="H2bold mTop30 w100">
                                        Mentors Overview
                                    </h2>
                                    <div className="w90 mXAuto verticallyAlignMid">
                                        <div className="w30 pX20">
                                            <div className="w90 mXAuto mTop20 dataTableHead">
                                                {/* <MUIDataTable
												data={peaks}
												columns={peaksColumns}
												options={peaksOptions}
												className={"w100 mXAuto"}
											/> */}
                                            </div>
                                        </div>
                                        <div className="w70 pX20">
                                            <div className="w90 mXAuto mTop20">
                                                <MUIDataTable
                                                    title={"Mentors Table"}
                                                    data={mentorsData}
                                                    columns={
                                                        mentorsBusinessColumns
                                                    }
                                                    options={revenueOptions}
                                                    className={"w100 mXAuto"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {businessTab === "clients" && (
                                <div className="w100 mTop30">
                                    <h2 className="H2bold mTop30 w100">
                                        Clients
                                    </h2>
                                    <div className="w90 mXAuto verticallyAlignMid">
                                        <div className="w30 pX20">
                                            <div className="w90 mXAuto mTop20 dataTableHead">
                                                {/* <MUIDataTable
												data={peaks}
												columns={peaksColumns}
												options={peaksOptions}
												className={"w100 mXAuto"}
											/> */}
                                            </div>
                                        </div>
                                        <div className="w70 pX20">
                                            <div className="w90 mXAuto mTop20">
                                                <MUIDataTable
                                                    title={"Clients Table"}
                                                    data={clientsData}
                                                    columns={
                                                        clientsBusinessColumns
                                                    }
                                                    options={revenueOptions}
                                                    className={"w100 mXAuto"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}

                {table === "users" && (
                    <div className="w100 mx-auto mTop30 verticallyAlignMidSpace">
                        <div className="w100 verticallyAlignMid mTop20">
                            <div className="w80 mTop20">
                                <MUIDataTable
                                    title={"Users Table"}
                                    data={users}
                                    columns={usersColumns}
                                    options={usersOptions}
                                    className={"w90 mXAuto"}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {table === "operations" && (
                    <div className="w100 mx-auto mTop30 verticallyAlignMidSpace">
                        <div className="w20">
                            <div className="borderRadius12 borderLightGray container pTop5">
                                <h3 className="w100 backgroundGradientForButtons borderRadius12 colOffWhite pY10">
                                    Sections
                                </h3>
                                <div
                                    onClick={() => setSection("mentors")}
                                    className={`flex-grow-1 mY8 cursorPointer borderRadius4 ${
                                        section === "mentors"
                                            ? "backColLightBlue"
                                            : ""
                                    }`}
                                >
                                    <div className="verticallyAlignMidJustConStart">
                                        <i className="fas fa-wrench mLeft5"></i>
                                        <p className="mLeft5 pY10">Mentors</p>
                                    </div>
                                </div>
                                <div
                                    onClick={() => setSection("tickets")}
                                    className={`flex-grow-1 mY8 cursorPointer borderRadius4 ${
                                        section === "tickets"
                                            ? "backColLightBlue"
                                            : ""
                                    }`}
                                >
                                    <div className="verticallyAlignMidJustConStart">
                                        <i className="fas fa-wrench mLeft5"></i>
                                        <p className="mLeft5 pY10">Tickets</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w60">
                            {section === "mentors" && (
                                <div className="">
                                    <div
                                        className="w100" /*onClick={() => setMentorID("6241c3b52978c011d083415a")}*/
                                    >
                                        <MUIDataTable
                                            title={"Mentors Table"}
                                            data={operationsMentors}
                                            columns={operationsMentorsColumns}
                                            options={operationsMentorsOptions}
                                            className={"w90 mXAuto"}
                                            ref={testRef}
                                        />
                                    </div>
                                </div>
                            )}

                            {section === "tickets" && (
                                <div className="">
                                    <div className="w100">
                                        <MUIDataTable
                                            title={"Tickets Table"}
                                            data={users}
                                            columns={usersColumns}
                                            options={usersOptions}
                                            className={"w90 mXAuto"}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

function getUser(state) {
    return {
        // mentorStatus : state.auth.authData?.data.user.mentorOnboarding.firstServiceCompleted || false
        data: state.auth.authData.data.user || false,
    };
}

export default connect(getUser)(Owner);
