import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mentorOnboarding } from "../../actions/auth.js";
import { RiArrowLeftSLine } from "react-icons/ri";

function MOWorkExperience(props) {
    const initialState = {
        setWorkExperience: true,
        yearsOfPracticing: "",
        hoursAvailable: "",
        experience: [],
        sessionTextarea: "",
        experienceTextarea: "",
    };

    const company = props.company;

    const [formData, setFormData] = useState(initialState);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (!company._id || !props.userID) {
        navigate("/LogIn");
    }

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(mentorOnboarding(formData, navigate));
    }

    const handleChange = (e) => {
        if (e.target.name === "experience") {
            const array = formData.experience;

            if (e.target.checked) {
                array.push(e.target.value);
            } else {
                const index = array.indexOf(e.target.value);
                if (index > -1) {
                    array.splice(index, 1);
                }
            }
            // setExperience(array);

            setFormData({ ...formData, [e.target.name]: array });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    useEffect(() => {
        // console.log("eff", props.workExperience);
        setFormData({
            setWorkExperience: true,
            yearsOfPracticing: props.workExperience.yearsOfPracticing,
            hoursAvailable: props.workExperience.hoursAvailable,
            experience: props.workExperience.experience,
            sessionTextarea: props.workExperience.sessionTextarea,
            experienceTextarea: props.workExperience.experienceTextarea,
        });
    }, [props.workExperience]);

    function goToPrevStep() {
        dispatch(mentorOnboarding({ setYourExperienceFalse: true }, navigate));
    }

    return (
        <div className="OnboardingMentor4">
            <div className="w80 mx-auto mY70 backColPrimaryLight borderRadius24">
                <div className=" mLeft30 mTop30">
                    <button
                        onClick={goToPrevStep}
                        className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding"
                    >
                        <RiArrowLeftSLine
                            size="2em"
                            className="colPrimaryGreen"
                        />
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="verticallyAlignMidStretch mY70 w80 mXAuto OnboardingMentor4Subb">
                        <div className="OnboardingMentor4Subb1">
                            <p className="Pmedium textAlignLeft mBottom12 mRight20">
                                How many years of experience do you have
                                practicing?
                            </p>
                            <div className="mBottom20">
                                <label className="verticallyAlignMidJustConStart mainForRadioButtons mBottom12">
                                    <input
                                        required
                                        checked={
                                            formData.yearsOfPracticing === "1-5"
                                        }
                                        id="years_1_5"
                                        type="radio"
                                        name="yearsOfPracticing"
                                        value="1-5"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <span
                                        htmlFor="years_1_5"
                                        className="mLeft10 Pregular"
                                    >
                                        1-5 years
                                    </span>
                                    <span className="checkmark"></span>
                                </label>
                                <label className="verticallyAlignMidJustConStart mainForRadioButtons mBottom12">
                                    <input
                                        required
                                        checked={
                                            formData.yearsOfPracticing ===
                                            "5-10"
                                        }
                                        id="years_5_10"
                                        type="radio"
                                        name="yearsOfPracticing"
                                        value="5-10"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <span
                                        htmlFor="years_5_10"
                                        className="mLeft10 Pregular"
                                    >
                                        5-10 years
                                    </span>
                                    <span className="checkmark"></span>
                                </label>
                                <label className="verticallyAlignMidJustConStart mainForRadioButtons mBottom12">
                                    <input
                                        required
                                        checked={
                                            formData.yearsOfPracticing ===
                                            "10-15"
                                        }
                                        id="years_10_15"
                                        type="radio"
                                        name="yearsOfPracticing"
                                        value="10-15"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <span
                                        htmlFor="years_10_15"
                                        className="mLeft10 Pregular"
                                    >
                                        10-15 years
                                    </span>
                                    <span className="checkmark"></span>
                                </label>
                                <label className="verticallyAlignMidJustConStart mainForRadioButtons">
                                    <input
                                        required
                                        checked={
                                            formData.yearsOfPracticing === "15+"
                                        }
                                        id="years_15_plus"
                                        type="radio"
                                        name="yearsOfPracticing"
                                        value="15+"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <span
                                        htmlFor="years_15_plus"
                                        className="mLeft10 Pregular"
                                    >
                                        15+ years
                                    </span>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <p className="Pmedium textAlignLeft mBottom12 mRight20">
                                How many hours a week will you be available?
                            </p>
                            <div>
                                <label className="verticallyAlignMidJustConStart mainForRadioButtons mBottom12">
                                    <input
                                        required
                                        checked={
                                            formData.hoursAvailable === "1-10"
                                        }
                                        id="hours_1_10"
                                        type="radio"
                                        name="hoursAvailable"
                                        value="1-10"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <span
                                        htmlFor="hours_1_10"
                                        className="mLeft10 Pregular"
                                    >
                                        1-10 hours
                                    </span>
                                    <span className="checkmark"></span>
                                </label>
                                <label className="verticallyAlignMidJustConStart mainForRadioButtons mBottom12">
                                    <input
                                        required
                                        checked={
                                            formData.hoursAvailable === "10-20"
                                        }
                                        id="hours_10_20"
                                        type="radio"
                                        name="hoursAvailable"
                                        value="10-20"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <span
                                        htmlFor="hours_10_20"
                                        className="mLeft10 Pregular"
                                    >
                                        10-20 hours
                                    </span>
                                    <span className="checkmark"></span>
                                </label>
                                <label className="verticallyAlignMidJustConStart mainForRadioButtons">
                                    <input
                                        required
                                        checked={
                                            formData.hoursAvailable === "20-40"
                                        }
                                        id="hours_20_40"
                                        type="radio"
                                        name="hoursAvailable"
                                        value="20-40"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <span
                                        htmlFor="hours_20_40"
                                        className="mLeft10 Pregular"
                                    >
                                        20-40 hours
                                    </span>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div>
                            <p className="Pmedium textAlignLeft mBottom12">
                                What type of experience of providing mentorship
                                do you have?
                            </p>
                            <div className="verticallyAlignMidJustConStart flexWrap mBottom20">
                                <label className="verticallyAlignMidJustConStart checkBoxContainer mRight20">
                                    <input
                                        checked={formData.experience.includes(
                                            "audio"
                                        )}
                                        id="experience_audio"
                                        type="checkbox"
                                        name="experience"
                                        value="audio"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <span htmlFor="experience_audio">
                                        Audio
                                    </span>
                                    <span className="checkmarkCheckBox"></span>
                                </label>
                                <label className="verticallyAlignMidJustConStart checkBoxContainer mRight20">
                                    <input
                                        checked={formData.experience.includes(
                                            "video"
                                        )}
                                        id="experience_video"
                                        type="checkbox"
                                        name="experience"
                                        value="video"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <span htmlFor="experience_video">
                                        Video
                                    </span>
                                    <span className="checkmarkCheckBox"></span>
                                </label>
                                <label className="verticallyAlignMidJustConStart checkBoxContainer">
                                    <input
                                        checked={formData.experience.includes(
                                            "chat"
                                        )}
                                        id="experience_chat"
                                        type="checkbox"
                                        name="experience"
                                        value="chat"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <span htmlFor="experience_chat">Chat</span>
                                    <span className="checkmarkCheckBox"></span>
                                </label>
                            </div>
                            <p className="Pmedium textAlignLeft mBottom8">
                                Tell us more about you{" "}
                                {company.whiteLabel.texts.label5SessionPlural}
                            </p>
                            <div className="verticallyAlignMidJustConStart mBottom20 yearsOfExpTextarea">
                                <textarea
                                    required
                                    value={formData.sessionTextarea}
                                    rows="3"
                                    name="sessionTextarea"
                                    onChange={(e) => handleChange(e)}
                                    className="borderNone Pregular borderRadius12 pY10 pX6"
                                ></textarea>
                            </div>
                            <p className="Pmedium textAlignLeft mBottom8">
                                Describe your work experience in a couple of
                                sentences.
                            </p>
                            <div className="verticallyAlignMidJustConStart mBottom40 yearsOfExpTextarea">
                                <textarea
                                    required
                                    value={formData.experienceTextarea}
                                    rows="3"
                                    name="experienceTextarea"
                                    onChange={(e) => handleChange(e)}
                                    className="borderNone Pregular borderRadius12 pY10 pX6"
                                ></textarea>
                            </div>
                            <button
                                style={{
                                    color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                    backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                }}
                                type="submit"
                                className="verticallyAlignMid borderRadius12 borderNone mBottom30 P1regular colOffWhite w180 pY10 mLeftAuto"
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

function getMentorWorkExperience(state) {
    return {
        workExperience: {
            yearsOfPracticing:
                state.auth.authData?.data.user.workExperience
                    .yearsOfPracticing || "",
            hoursAvailable:
                state.auth.authData?.data.user.workExperience.hoursAvailable ||
                "",
            experience:
                state.auth.authData?.data.user.workExperience.experience || [],
            sessionTextarea:
                state.auth.authData?.data.user.workExperience.sessionTextarea ||
                "",
            experienceTextarea:
                state.auth.authData?.data.user.workExperience
                    .experienceTextarea || "",
        },
        userID: state.auth.authData?.data.user._id,
        company: state.auth.authData?.company,
    };
}

export default connect(getMentorWorkExperience)(MOWorkExperience);
