import Moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { setNotificationRead } from "../../api/index.js";

const NotificationsComponent = ({ notification }) => {
  const navigate = useNavigate();

  let date = notification.createdAt;
  let dateToday = new Date();
  let dateTodayFormat = Moment(dateToday).format("YYYY/MM/DD");

  const [hover, setHover] = useState(false);

  let time = "";

  if (Moment(date).format("YYYY/MM/DD") === dateTodayFormat) {
    var duration = Moment.duration(Moment(dateToday).diff(Moment(date)));
    var minutes = duration.asMinutes();

    // console.log(minutes) // 70.63
    // console.log(date) // 2022-01-31T02:03:34.790Z
    // console.log(dateToday) // Mon Jan 31 2022 04:14:12 GMT+0100

    let text = "";

    if (minutes < 1) {
      text = "just now";
    } else if (minutes / 60 < 1) {
      text = Math.floor(minutes) + " min/s ago";
    } else {
      text = Math.floor(minutes / 60) + " hour/s ago";
    }

    time = text;
  } else {
    date = Moment(date).format("HH:mm");
    time = date;
  }

  let className = "";

  if (notification.read) {
    className = "colPlaceHolderGray";
  }

  async function handleClick() {
    // TO CHECK: What was this for?
    // let data = await setNotificationRead(notification.id);

    if (notification.type === "Session") {
      navigate("/OnboardingMentor11ClientSingleView", {
        state: {
          id    : notification.typeID._id,
          type  : notification.typeID.status,
        },
      });
    }

    if (notification.type === "Message") {
      let userID;

      if (notification.userID === notification.typeID.fromID) {
        userID = notification.typeID.toID;
      } else {
        userID = notification.typeID.fromID;
      }

      navigate("/Messages", {
        state: {
          userID,
        },
      });
    }

    if (notification.type === "Review") {
      navigate("/Reviews", {
        state: {
          mentorID: notification.userID,
          fullPage: true,
        },
      });
    }
  }

  function getInitials(name) {
    let words = name.split(" ");
    return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase();
  }

  return (
    <>
      <div
        onClick={handleClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={`${
          hover ? "backColPrimaryGreen borderRadius4" : ""
        } verticallyAlignMidJustConStart notificationComponentMain mBottom5 pY10 mTop5 cursorPointer`}
      >
        <div className="mLeft8 pRight8">
          <div className="verticallyAlignMidJustConStart reviewsCardImg borderRadius50">
            <div className="w100 h100 verticallyAlignMid">
              {notification?.fromID?.photo ? (
                <img
                  alt=""
                  className="w100 h100"
                  src={notification?.fromID.photo}
                />
              ) : (
                <div className="userInitialsMedium">
                  <p>{getInitials(notification?.fromID?.fullName)}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="verticallyAlignMidJustConStart mRight8 mLeft12 w100">
          <p
            className={`${
              hover ? "colOffWhite" : ""
            } ${className} Pbold w80 mRightAuto textAlignLeft mRightAuto`}
          >
            {notification.text}
          </p>
          <h6
            className={`${
              hover ? "colOffWhite" : "colLabelGray"
            } Small-LabelCaps`}
          >
            {time}
          </h6>
        </div>
      </div>
      <div className="borderGrayTopInput w100"></div>
    </>
  );
};

export default NotificationsComponent;
