import { Link, useNavigate } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import { connect } from "react-redux";

function ClientSettingsPanelContactSupport(props) {
    const company = props.data.company;
    const navigate = useNavigate();

    if (!company._id || !props.data.data.user._id) {
        navigate("/LogIn");
    }

    return (
        <div className="ClientSettingsPanelContactSupport">
            <div className="verticallyAlignMidJustConStart w80 mXAuto mTop70 mBottom40 mTopForSmallerCreens ClientSettingsPanelContactSupportSubb3">
                <Link to="/MentorHome2SettingsPanel">
                    <div className="posRel mRight20">
                        <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                            <RiArrowLeftSLine
                                size="2em"
                                className="colPrimaryGreen"
                            />
                        </button>
                    </div>
                </Link>
                <div>
                    <h2 className="textAlignLeft H1regular">Contact Support</h2>
                    <p className="textAlignLeft Pmedium ">
                        We are available every week day 7am-6pm.
                    </p>
                </div>
            </div>
            <div className="w80 mXAuto mBottom70 ClientSettingsPanelContactSupportSubb2">
                <div className="w50 ClientSettingsPanelContactSupportSubb1">
                    <div className="verticallyAlignMidJustConStart ClientSettingsPanelContactSupportSubb4">
                        <div className="ClientSettingsPanelContactSupportSubb5 mRight20">
                            <h5 className="P1bold textAlignLeft mBottom8 ClientSettingsPanelContactSupportSubb5H5">
                                Regarding
                            </h5>
                            <select className="Pregular smallSelectCss pLeft8 textAlignLeft ClientSettingsPanelContactSupportSubb5H5 verticallyAlignMidJustConStartForText borderNone backColInput pY14 borderRadius12">
                                <option>Select</option>
                            </select>
                        </div>
                        <div>
                            <h5 className="P1bold textAlignLeft mBottom8 ClientSettingsPanelContactSupportSubb5H5">
                                Session
                            </h5>
                            <select className="Pregular smallSelectCss ClientSettingsPanelContactSupportSubb5H5 pLeft8 textAlignLeft verticallyAlignMidJustConStartForText borderNone backColInput pY14 borderRadius12">
                                <option>Select</option>
                            </select>
                        </div>
                    </div>
                    <h5 className="P1bold textAlignLeft pTop30 mBottom8">
                        Note
                    </h5>
                    <textarea
                        rows="8"
                        className="w100 Pregular borderNone pY14 backColInput borderRadius12 mBottom20"
                    ></textarea>
                </div>
                <button
                    style={{
                        color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                        backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                    }}
                    className="borderRadius12 borderNone verticallyAlignMidJustConStart mBottom30 Pmedium colOffWhite pLeft30 pRight30 pTop10 pBottom10"
                >
                    Send Ticket
                </button>
            </div>
        </div>
    );
}

function getData(state) {
    return {
        data: state.auth.authData,
    };
}

export default connect(getData)(ClientSettingsPanelContactSupport);
