// import './index.css';
import { useEffect, useState } from "react";
import { connect } from "react-redux";

const ButtonTags = (props) => {
  const company = props.data.company;

  const [btnClass, setBtnClass] = useState("backColOffWhite colPrimaryGreen");
  const [btnStyle, setBtnStyle] = useState([
    `${company?.whiteLabel?.color?.buttons?.primaryText}`,
    `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
  ]);

  useEffect(() => {
    if (props.buttonStatus === "checked") {
      setBtnClass("");
      setBtnStyle([
        `${company?.whiteLabel?.color?.buttons?.primaryText}`,
        `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
      ]);
    } else {
      setBtnClass("backColOffWhite colPrimaryGreen");
      setBtnStyle("");
    }
  }, [
    props.buttonStatus,
    company?.whiteLabel?.color?.buttons?.primary?.from,
    company?.whiteLabel?.color?.buttons?.primary?.to,
    company?.whiteLabel?.color?.buttons?.primaryText,
  ]);

  const handleClick = () => {
    if (props.service) {
      props.parentEvent(props.service);
    } else {
      props.parentEvent(props.id);
    }
  };

  return (
    <div
      onClick={handleClick}
      style={{ color: btnStyle[0], backgroundImage: btnStyle[1] }}
      className={`borderRadius24 mRight4 mBottom8 cursorPointer ${btnClass}`}
    >
      <p className="Small-LabelCaps pX10 pY6">{props.text}</p>
    </div>
  );
};

function getData(state) {
  return {
    data: state.auth.authData,
  };
}

export default connect(getData)(ButtonTags);
