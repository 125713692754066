import { RiStarFill } from "react-icons/ri";
import { BsFillSuitHeartFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { getReviews } from "../../api";

const LandingRecommendedMentorCards = ({
  favorite,
  user,
  props,
  parentEvent,
  parentEventFavourite,
  available,
}) => {
  // const [checked, setChecked] = useState(false)
  const [checked, setChecked] = useState(false);
  const [rating, setRating] = useState(0);

  function handleClick(e) {
    if (e.target.tagName === "path") {
      parentEventFavourite(props._id);
    } else {
      parentEvent(props._id);
    }
  }

  useEffect(() => {
    async function getData() {
      const queryString = `?mentorID=${props._id}`;
      const { data } = await getReviews(queryString);

      let count = 0;

      if (data.data.reviews.length > 0) {
        data.data.reviews.forEach((el) => {
          count += parseInt(el.rating);
        });

        setRating(Math.round((count / data.data.reviews.length) * 10) / 10);
      }
    }

    getData();
  }, [props._id]);

  useEffect(() => {
    let value = favorite.filter((el) => el?.mentorID?._id === props?._id);

    if (value.length > 0) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [favorite, props._id]);

  function getInitials() {
    let words = props?.fullName.split(" ");
    let empty = "";

    if (words && words.length > 1) {
      return (
        words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
      );
    } else {
      return empty;
    }
  }

  return (
    <div
      className="mRight20 mLeft10 mBottom20  cursorPointer landingCardsMain"
      onClick={(e) => handleClick(e)}
    >
      <div className={`posRel borderRadius12 mBottom12 recommendedMentors ${!props?.photo && 'backColPlaceHolderGray'}`}>
        {props?.photo ? (
          <img alt="" className="w100 h100" src={props.photo} />
        ) : (
          <div className="w100 h100 verticallyAlignMid">
            <div className="userInitialsLarge">
              <p>{getInitials()}</p>
            </div>
          </div>
        )}
        {available && (
          <div className="posAbs borderRadius24 cursorPointer colOffWhite backColGreen onlineOffline Small-Label-11 pX20">
            Available Now
          </div>
        )}
        <BsFillSuitHeartFill
          onClick={() => setChecked(!checked)}
          size="1.5em"
          className={`posAbs cursorPointer likeDislike ${
            checked ? "colGoogleRed" : "colDarkFray"
          }`}
        />
        <div className="posAbs borderRadius24 cursorPointer backColOffWhite colPrimaryGreen professionExpert Small-Label-11 pX10">
          {props?.expertise?.name}
        </div>
      </div>
      <div className="landingCardsMainBio">
        <div className="verticallyAlignMidStretch forNoteStarMain">
          <h5 className="P1medium nameRecommendedMentor textAlignLeft mRightAuto heightForBio2">
            {props?.fullName}
          </h5>
          <div className="verticallyAlignMidStretch forNoteStarMainSubb">
            <RiStarFill
              size="1.5em"
              className="cursorPointer colPrimaryGreen mRight4"
            />
            <h5 className="P1medium">{rating}</h5>
          </div>
        </div>
        <p className="Small-LabelCaps textAlignLeft heightForBio w100">
          {props?.biography}
        </p>
        {props?.rateFrom && props?.rateFrom.length > 0 && (
          <h6 className="Pbold textAlignLeft">
            {/* From <span>${props?.rateFrom[0]?.rate}/hour</span> */}
            From <span>${Math.round(props?.rateFrom[0]?.rate / 60 * 100) / 100}/minute</span>
          </h6>
        )}
      </div>
    </div>
  );
};

export default LandingRecommendedMentorCards;
