import * as actionType from "../constants/actionTypes";

const authReducer = (state = { authData: null }, action) => {
    switch (action.type) {
        case actionType.AUTH:
            let profile = JSON.parse(localStorage.getItem("profile"));

            action.data.company = profile?.company;
            if (!action.data.token) {
                action.data.token = profile?.token;
            }

            localStorage.setItem(
                "profile",
                JSON.stringify({ ...action?.data })
            );
            return {
                ...state,
                authData: action.data,
                loading: false,
                errors: null,
            };

        case actionType.LOGOUT:
            let localStorLogout = JSON.parse(localStorage.getItem("profile"));
            let newLocalStore = {
                company: localStorLogout.company,
            };
            localStorage.setItem("profile", JSON.stringify(newLocalStore));

            return {
                ...state,
                authData: newLocalStore,
                loading: false,
                errors: null,
            };

        case actionType.COMPANY:
            let localStor = localStorage.getItem("profile");

            if (localStor) {
                localStor = JSON.parse(localStor);
                localStor.company = action?.data;
            } else {
                localStor = { company: action?.data };
            }

            localStorage.setItem("profile", JSON.stringify(localStor));
            return {
                ...state,
                authData: localStor,
                loading: false,
                errors: null,
            };

        default:
            return state;
    }
};

export default authReducer;
