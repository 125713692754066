import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function ClientSettingsPanelPaymentAndPayouts5(props) {

	const company 	= props.company;
	const navigate 	= useNavigate();

	if (!company._id || !props.userID) {
		navigate('LogIn');
	}

   	return (
      	<div className="ClientSettingsPanelPaymentAndPayouts5">
      		<div className="verticallyAlignMidJustConStart w80 mXAuto mTop70 mBottom40">
		        <Link to="/ClientSettingsPanelPaymentAndPayouts4">
		        	<button>Back Button</button>
				</Link>
		        <h2 className="textAlignLeft H1regular">Payment and Payouts</h2>
		    </div>
		    <div className="w80 mXAuto">
			    <div className="w50">
				    <h4 className="textAlignLeft mBottom20 H2medium">Please fill in your bank details</h4>
				    <h5 className="textAlignLeft mBottom20 P1medium">Bank account type</h5>
				    <div className="verticallyAlignMidJustConStart mBottom20">
				    	<input type="radio" name="bank_account_type" />
				    	<h5 className="textAlignLeft P1medium">Checking</h5>
				    </div>
				    <div className="verticallyAlignMidJustConStart mBottom40">
			    		<input type="radio" name="bank_account_type" />
			    		<h5 className="textAlignLeft P1medium">Savings</h5>
				    </div>
				    <h5 className="textAlignLeft P1medium mBottom20">Account holder legal name</h5>
				    <input className="Pregular pLeft8 textAlignLeft w100 verticallyAlignMidJustConStart borderGrayInput pY14 borderRadius12 mBottom30" type="text" />
				    <h5 className="textAlignLeft P1medium mBottom20">Bank name</h5>
				    <input className="Pregular pLeft8 textAlignLeft w100 verticallyAlignMidJustConStart borderGrayInput pY14 borderRadius12 mBottom30" type="text" />
				    <h5 className="textAlignLeft P1medium mBottom20">Sort code</h5>
				    <input className="Pregular pLeft8 textAlignLeft w100 verticallyAlignMidJustConStart borderGrayInput pY14 borderRadius12 mBottom30" type="text" />
				    <h5 className="textAlignLeft P1medium mBottom20">Account number</h5>
				    <input className="Pregular pLeft8 textAlignLeft w100 verticallyAlignMidJustConStart borderGrayInput pY14 borderRadius12 mBottom30" type="text" />
				    <h5 className="textAlignLeft P1medium mBottom20">Confirm account number</h5>
				    <input className="Pregular pLeft8 textAlignLeft w100 verticallyAlignMidJustConStart borderGrayInput pY14 borderRadius12 mBottom60" type="text" />
				    <div className="borderGrayTopInput mY20"></div>
				    <button style={{color : `${company?.whiteLabel?.color?.buttons?.primaryText}`, backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`}} className="borderRadius12 borderNone verticallyAlignMidJustConStart mBottom30 Pmedium colOffWhite pLeft30 pRight30 pTop10 pBottom10">Save</button>
				</div>
			</div>
      	</div>
   	);
}

function getData(state) {
	return {
		company : state.auth.authData.company,
		userID 	: state.auth.authData.data.user._id
	}
}

export default connect(getData)(ClientSettingsPanelPaymentAndPayouts5);
