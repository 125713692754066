import { Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

function RestrictedClientSelectCategories(props) {

	// return props.userData.user.clientOnboarding.selectCategoriesCompleted ? <Navigate to="/LogInClientSetYourPaymentDetails" /> : <Outlet />;

	if(!props.userData.user.clientOnboarding.selectCategoriesCompleted) {
		return <Outlet />;
	} else if (props.company?.charity) {
		return <Navigate to="/WhatKindOfMentor" />;
	// } else if(!props.userData.user.clientOnboarding.paymentMethodCompleted) {
	// 	return <Navigate to="/LogInClientSetYourPaymentDetails" />;
	} else {
		return <Navigate to="/WhatKindOfMentor" />;
	}
};

function getUser(state) {
	return {
		userData: {
			user : state.auth.authData?.data.user
		},
		company : state.auth.authData?.company
	}
}

export default connect(getUser)(RestrictedClientSelectCategories);
