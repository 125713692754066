import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { AUTH } from '../../constants/actionTypes';
// import { signup, userGoogleLogin } from "../../actions/auth.js";
import { signup } from "../../actions/auth.js";
// import ButtonsType1 from '../../components/buttons/ButtonsType1/ButtonsType1';
// import Input from '../../components/input/Input.js';
import { RiFacebookBoxFill } from "react-icons/ri";
// import { GoogleLogin } from 'react-google-login';
// import FacebookLogin from "react-facebook-login";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import jwt_decode from "jwt-decode";
import CloseButton from "../../components/buttons/CloseButton/CloseButton.js";

function Signup(props) {
    const [formData, setFormData] = useState({
        email: "",
        role: "",
        firstName: "",
        lastName: "",
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [errorText, setErrorText] = useState(false);

    const company = props.company.company;

    const handleSubmit = (e) => {
        e.preventDefault();

        // if (formData.email === "" || formData.role === "") {
        //     alert("You need to fill all inputs")
        //     return false;
        // }

        let preparedFormData = {
            email: formData.email,
            role: formData.role,
            fullName: `${formData.firstName} ${formData.lastName}`,
            companyID: props.company.company._id,
        };

        dispatch(signup(preparedFormData, navigate)).then((response) => {
            if (response) {
                // console.log(JSON.stringify(response, null, 4))

                if (response.response.data.message) {
                    setErrorText(response.response.data.message);
                } else {
                    setErrorText("Something went wrong!");
                }
            }
        });
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const [userData, setUserData] = useState({});
    const [socialRole, setSocialRole] = useState(false);

    const facebookSuccess = async (response) => {
        console.log(response);

        setUserData({
            social      : true,
            email       : response.email,
            companyID   : company._id,
            name        : response.name,
            type        : "facebookID",
            id          : response.id,
        });

        setSocialRole("facebook");
    };

    const facebookError = (response) => {
        // console.log('error', response)
    };

    useEffect(() => {
        const google = window.google;

        google.accounts.id.initialize({
            // client_id: "567198460390-oo360r7n7jsiruap9nilpe4ooohqkhfs.apps.googleusercontent.com",
            // client_id: "567198460390-eqfp1i18tf1lbphu7h50a9c7h547s1rs.apps.googleusercontent.com",
            //client_id: "241251241244-6cmsret0k7g8aovg1unrauu1gvk4dfhc.apps.googleusercontent.com",
            client_id: "241251241244-4sckjn6mdd2n969nscj4e0ahhqurg38r.apps.googleusercontent.com",
            callback: handleGoogleResponse,
        });

        google.accounts.id.renderButton(document.getElementById("signInDiv"), {
            theme: "outline",
            size: "large",
        });

        google.accounts.id.prompt();
    }, []);

    async function handleGoogleResponse(response) {
        if (response.credential) {
            const user = jwt_decode(response.credential);
            // console.log(user);

            if (user.email_verified) {
                setUserData({
                    social: true,
                    email: user.email,
                    companyID: company._id,
                    name: user.name,
                    type: "googleID",
                    id: user.sub, //
                });

                setSocialRole("facebook");
            }
        }
    }

    async function finishSocial() {
        let preparedFormData = {
            social: true,
            email: userData.email,
            role: formData.role,
            fullName: userData.name,
            companyID: company._id,
            type: userData.type,
            id: userData.id,
        };

        // console.log("preparedFormData", preparedFormData);
        // return;

        dispatch(signup(preparedFormData, navigate)).then((response) => {
            if (response) {
                if (response.response.data.message) {
                    setErrorText(response.response.data.message);
                } else {
                    setErrorText("Something went wrong!");
                }
            }
        });

        setSocialRole(false);
    }

    return (
        <div className="Signup">
            {socialRole && (
                <div className="filterMentorPopupWrapper">
                    <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                        <div className="w90 mXAuto">
                            <div className="verticallyAlignMid mTop40 NewNotePoUpSub1Wrapper">
                                <h2 className="mRightAuto H3medium">
                                    Set Role
                                </h2>
                                <CloseButton parentEvent={setSocialRole} />
                            </div>
                            <div className="mTop40 NewNotePoUpSub1Wrapper">
                                <div className="backColOffWhite borderRadius12 signupMainSubb4 mBottom20 pRight10 pLeft30 w340 mXAuto">
                                    <p
                                        htmlFor="role_for_login"
                                        className="Small-Labelnormal textAlignLeft pTop10"
                                    >
                                        {
                                            company.whiteLabel.texts
                                                .label2MentorSingle
                                        }{" "}
                                        or Client
                                    </p>
                                    <select
                                        tabIndex="4"
                                        id="role_for_login"
                                        name="role"
                                        onChange={handleChange}
                                        className="pLeft0 textAlignLeft Pmedium w100 borderNone backColOffWhite colLabelGray borderRadius12 mBottom10"
                                    >
                                        <option value="">Select Role</option>
                                        <option value="mentor">
                                            {
                                                company.whiteLabel.texts
                                                    .label2MentorSingle
                                            }
                                        </option>
                                        <option value="client">Client</option>
                                    </select>
                                </div>
                            </div>
                            <button
                                onClick={finishSocial}
                                className="verticallyAlignMid w180 borderRadius12 mBottom40 borderNone Pregular colOffWhite backgroundGradientForButtons pY10 mLeftAuto"
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className="backColPrimaryLight signupMainSubb1 borderRadius24 mTop50 mBottom70 w80 mXAuto">
                <div className="verticallyAlignMid">
                    <div className="signupMainSubb2 mXAuto mTop70 pBottom70">
                        <h2 className="textAlignCenter H1medium mBottom8">
                            Sign up
                        </h2>
                        <form
                            onSubmit={handleSubmit}
                            className="submitMainForm"
                        >
                            <div>
                                <div className="verticallyAlignMid signupMainSubb3">
                                    <div className="backColOffWhite signupMainSubb4 borderRadius12 mBottom20 pRight10 pLeft30 w340 mXAuto mRight20">
                                        <p
                                            htmlFor="input_text_for_first_name"
                                            className="Small-Labelnormal textAlignLeft pTop10"
                                        >
                                            FIRST NAME
                                        </p>
                                        <input
                                            tabIndex="1"
                                            id="input_text_for_first_name"
                                            label="Enter first name"
                                            name="firstName"
                                            placeholder="Enter first name"
                                            onChange={handleChange}
                                            type="text"
                                            className="Pmedium w100 borderNone backColOffWhite borderRadius12 mBottom10"
                                        />
                                    </div>
                                    <div className="backColOffWhite borderRadius12 signupMainSubb4 mBottom20 pRight10 pLeft30 w340 mXAuto">
                                        <p
                                            htmlFor="input_text_for_last_name"
                                            className="Small-Labelnormal textAlignLeft pTop10"
                                        >
                                            LAST NAME
                                        </p>
                                        <input
                                            tabIndex="2"
                                            id="input_text_for_last_name"
                                            label="Enter last name"
                                            name="lastName"
                                            placeholder="Enter last name"
                                            onChange={handleChange}
                                            type="text"
                                            className="Pmedium w100 borderNone backColOffWhite borderRadius12 mBottom10"
                                        />
                                    </div>
                                </div>
                                <div className="verticallyAlignMid signupMainSubb3">
                                    <div className="backColOffWhite borderRadius12 signupMainSubb4 mBottom20 pRight10 pLeft30 w340 mXAuto mRight20">
                                        <p
                                            htmlFor="input_email_for_login"
                                            className="Small-Labelnormal textAlignLeft pTop10"
                                        >
                                            EMAIL
                                        </p>
                                        <input
                                            tabIndex="3"
                                            id="input_email_for_login"
                                            name="email"
                                            label="Email Address"
                                            onChange={handleChange}
                                            placeholder="Enter email"
                                            type="email"
                                            className="Pmedium w100 borderNone backColOffWhite borderRadius12 mBottom10"
                                        />
                                    </div>
                                    <div className="backColOffWhite borderRadius12 signupMainSubb4 mBottom20 pRight10 pLeft30 w340 mXAuto">
                                        <p
                                            htmlFor="role_for_login"
                                            className="Small-Labelnormal textAlignLeft pTop10"
                                        >
                                            {
                                                company.whiteLabel.texts
                                                    .label2MentorSingle
                                            }{" "}
                                            or Client
                                        </p>
                                        <select
                                            tabIndex="4"
                                            id="role_for_login"
                                            name="role"
                                            onChange={handleChange}
                                            className="pLeft0 textAlignLeft Pmedium w100 borderNone backColOffWhite colLabelGray borderRadius12 mBottom10"
                                        >
                                            <option value="">
                                                Select Role
                                            </option>
                                            <option value="mentor">
                                                {
                                                    company.whiteLabel.texts
                                                        .label2MentorSingle
                                                }
                                            </option>
                                            <option value="client">
                                                Client
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <p className="textAlignCenter Pmedium mBottom20">
                                Or sign up using
                            </p>
                            <div className="verticallyAlignMid mXAuto mBottom20">
                                {/* <button className="borderRadiusForSocialMediaButtons backColGoogleRed borderNone pY14 w104 verticallyAlignMid mRight8 colOffWhite">
                    <RiGoogleFill size="1.5em" />
                </button> */}

                                <div id="signInDiv"></div>

                                <FacebookLogin
                                    appId="356156892738915"
                                    // autoLoad={true}
                                    fields="email,name"
                                    callback={facebookSuccess}
                                    onFailure={facebookError}
                                    render={(renderProps) => (
                                        <button
                                        onClick={renderProps.onClick}
                                        className="borderRadiusForSocialMediaButtons backColFacebookBlue borderNone pY14 w104 verticallyAlignMid colOffWhite"
                                        >
                                        <RiFacebookBoxFill size="1.5em" />
                                        </button>
                                    )}
                                />
                            </div>

                            {errorText && (
                                <div className="disFlex verticallyAlignMid">
                                    <p className="P1bold colErrorRed">
                                        {errorText}
                                    </p>
                                </div>
                            )}

                            <div className="w100 textAlignCentertextAlignCenter">
                                <p className="Pmedium w90 mXAuto">
                                    {" "}
                                    Already have an account?
                                    <a href="/LogIn"> Log In! </a>
                                </p>
                            </div>
                            <button
                                type="submit"
                                style={{
                                    color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                    backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                }}
                                className="submitMainFormButton verticallyAlignMid borderRadius12 mBottom20 w340 borderNone mTop20 Pmedium colOffWhite pX80 pY14 mXAuto"
                            >
                                Sign Up
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getData(state) {
    return {
        company: state.auth?.authData || {},
    };
}

export default connect(getData)(Signup);
