// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { RiSearchLine, RiPushpinFill } from "react-icons/ri";
// import { RiSearchLine } from "react-icons/ri";
import NotesMainPage from "../../components/NotesMainPage/NotesMainPage";
import { useNavigate } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { getNotes, updateNote } from "../../api";
import NewNotePoUp from "../NewNotePoUp/NewNotePoUp";

function ClientNotes(props) {
    const navigate = useNavigate();
    const company = props.company;

    if (!company._id || !props.user._id) {
        navigate("/LogIn");
    }

    const [notes, setNotes] = useState([]);
    const [value, setValue] = useState(0);
    const [newNote, setNewNote] = useState(false);
    const [editNotePopup, setEditNotePopup] = useState(false);
    const [search, setSearch] = useState("");

    useEffect(() => {
        async function getResult() {
            // const { data } = await getNotes(`?populate=serviceID,assignedID&userID=${props.user._id}&sort=-createdAt`);
            const { data } = await getNotes(
                `?filter={"$or":[{"header": {"$regex" : "${search}", "$options" : "i"}},{"text": {"$regex" : "${search}", "$options" : "i"}}]}&populate=serviceID,assignedID&userID=${props.user._id}&sort=-createdAt`
            );

            // console.log(data.data.notes);
            setNotes(data.data.notes);
        }

        getResult();
    }, [props, value, search]);

    async function pinNote(id, status) {
        // console.log(status);

        await updateNote(id, { pinned: status });
        setValue((value) => value + 1);
    }

    function finishPopup() {
        setValue((value) => value + 1);
    }

    return (
        <div className="ClientNotes">
            {(newNote || editNotePopup) && (
                <div className="filterMentorPopupWrapper">
                    <NewNotePoUp
                        user={props.user}
                        parentEvent={setNewNote}
                        parentEventTwo={setEditNotePopup}
                        parentSetValue={finishPopup}
                        edit={editNotePopup}
                    />
                </div>
            )}

            <div className="mTop70 mBottom70 w80 mXAuto ClientNotesSubb3">
                <div className="verticallyAlignMidJustConStart ClientNotesSubb1 mBottom30">
                    <div className="verticallyAlignMidJustConStart">
                        <div className="pRight10">
                            <button
                                onClick={() => navigate(-1)}
                                className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding"
                            >
                                <RiArrowLeftSLine
                                    size="2em"
                                    className="colPrimaryGreen"
                                />
                            </button>
                        </div>
                        <h2 className="H2medium">Your Notes</h2>
                    </div>
                    <div className="verticallyAlignMidJustConStart ClientNotesSubb2 mLeftAuto">
                        <div className="verticallyAlignMid borderRadius24 ClientNotesSubb4 w300 boxShadowForSearchLanding mRight10">
                            {/*<button type="submit" className="searchButton backColTransparent outlineNone borderNone pRight10">
                                <RiSearchLine size="1.4em" className="searchButtonOjectFit mY14"/>
                            </button>*/}
                            <input
                                onChange={(e) => setSearch(e.target.value)}
                                type="search"
                                placeholder="Search"
                                className="Pmedium searchField mY14 w80 borderNone outlineNone"
                            />
                            {/* <FontAwesomeIcon size="1x" className="cursorPointer"/> */}
                        </div>
                        <button
                            onClick={() => setNewNote(true)}
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            className="verticallyAlignMid ClientNotesSubbButton1 borderRadius12 borderNone Pmedium colOffWhite pLeft40 pRight40 pY14"
                        >
                            + New Note
                        </button>
                    </div>
                </div>

                {notes &&
                    notes.length > 0 &&
                    notes.map((el, i) => {
                        return (
                            <NotesMainPage
                                key={i}
                                note={el}
                                parentEvent={pinNote}
                                parentEventEdit={setEditNotePopup}
                            />
                        );
                    })}
            </div>
        </div>
    );
}

function getUserData(state) {
    return {
        user: state.auth?.authData.data.user ?? {},
        company: state.auth.authData.company,
    };
}

export default connect(getUserData)(ClientNotes);
