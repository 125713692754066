import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mentorOnboarding, uploadCV } from "../../actions/auth.js";
import ButtonTags from "../../components/buttons/ButtonTags/ButtonTags";
import CVUploadFile from "../../components/CVFileUpload";
import {
    getAllAdditionalCategories,
    getAllCategories,
} from "../../api/index.js";
import { RiArrowLeftSLine, RiArrowDownSLine } from "react-icons/ri";

function MOYourExperience(props) {
    const [categories, setCategories] = useState([]);
    const [additionalCategories, setAdditionalCategories] = useState([]);
    const [file, setFile] = useState({});

    const company = props.company;

    async function getCategories() {
        try {
            const { data } = await getAllCategories(
                `?companyID=${props.companyID}&active=true`
            );
            setCategories(data.data.categories);
        } catch (error) {
            // console.log(error);
        }
    }

    async function getAdditionalCategories() {
        try {
            const { data } = await getAllAdditionalCategories(
                `?companyID=${props.companyID}&active=true`
            );
            setAdditionalCategories(data.data.additionalCategories);
        } catch (error) {
            // console.log(error);
        }
    }

    useEffect(() => {
        getCategories();
        getAdditionalCategories();
    }, []);

    const initialState = {
        setYourExperience: true,
        expertise: "",
        cv: "",
        categories: [],
    };
    const [formData, setFormData] = useState(initialState);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (!company._id || !props.companyID || !props.mentorExperience.id) {
        navigate("/LogIn");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        /*if (formData.cv === "") {
			alert("You need to upload CV!");
			return false;
		}*/

        let mentorOnboarding = props.onboarding;
        mentorOnboarding.setYourExperience = true;

        let form = new FormData();
        form.append("mentorOnboarding", mentorOnboarding);
        form.append("expertise", formData.expertise);
        form.append("categories", formData.categories);
        form.append("file", file);

        dispatch(uploadCV(props.mentorExperience.id, form, navigate));
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        setFormData({
            setYourExperience: true,
            expertise: props.mentorExperience.expertise,
            cv: props.mentorExperience.cv,
            categories: props.mentorExperience.categories,
        });
    }, [
        props.mentorExperience.expertise,
        props.mentorExperience.cv,
        props.mentorExperience.categories,
    ]);

    function handleButtonClick(id) {
        let additionalCategories = formData.categories;

        if (additionalCategories.includes(id)) {
            additionalCategories = additionalCategories.filter(
                (el) => el !== id
            );
        } else {
            additionalCategories.push(id);
        }

        setFormData({ ...formData, categories: additionalCategories });
    }

    const fileChanged = (f) => {
        // console.log(f);
        setFile(f);
    };

    function goToPrevStep() {
        dispatch(
            mentorOnboarding(
                { setProfileDetailsCompletedFalse: true },
                navigate
            )
        );
    }

    // I think that each of the folowing divs should be a component
    // so we can change state on click...
    return (
        <div className="OnboardingMentor2 ">
            <div className="w80 mXAuto mY70 backColPrimaryLight borderRadius24 OnboardingMentor2MainSubb">
                <div className="mX20 mY20">
                    <button
                        onClick={goToPrevStep}
                        className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding"
                    >
                        <RiArrowLeftSLine
                            size="2em"
                            className="colPrimaryGreen"
                        />
                    </button>
                </div>

                <div className="verticallyAlignMidStretch mY70 w80 mXAuto onboardingMentor2Subb">
                    <div className="mRightAuto">
                        <h3 className="H3bold textAlignLeft">
                            Your Experience
                        </h3>
                        <p className="Pmedium textAlignLeft mBottom50">
                            Please fill in the application details below
                        </p>
                        {/* <h5 className="P1bold textAlignLeft">Upload your CV</h5> */}
                        <CVUploadFile props={props} parentEvent={fileChanged} />
                    </div>
                    <form
                        className="w50 onboardingMentor2Subb2"
                        onSubmit={handleSubmit}
                    >
                        <h5 className="P1bold textAlignLeft mBottom8">
                            What's your expertise?
                        </h5>
                        <div className="posRel w340 selectUploadCV">
                            <select
                                value={formData.expertise}
                                onChange={handleChange}
                                name="expertise"
                                className="Pregular appearanceNone pLeft8 textAlignLeft w100 verticallyAlignMidJustConStartForText borderNone pY14 borderRadius12 mBottom30"
                            >
                                <option>Select Expertise</option>

                                {categories.map((cat, i) => {
                                    return (
                                        <option key={i} value={cat.id}>
                                            {cat.name}
                                        </option>
                                    );
                                })}
                            </select>
                            <span className="posAbs spanForArrow verticallyAlignMid">
                                <RiArrowDownSLine
                                    size="1.5em"
                                    className="mRight10 colLabelGray"
                                />
                            </span>
                        </div>
                        <p className="Pmedium textAlignLeft mBottom20">
                            Add additional categories most fitting to your work:
                        </p>
                        <div className="verticallyAlignMidJustConStart flexWrap">
                            {additionalCategories.map((item, i) => {
                                let itemStatus = "";

                                if (
                                    formData.categories.indexOf(item["_id"]) !==
                                    -1
                                ) {
                                    itemStatus = "checked";
                                }

                                return (
                                    <ButtonTags
                                        key={i}
                                        buttonStatus={itemStatus}
                                        text={item.name}
                                        id={item["_id"]}
                                        parentEvent={handleButtonClick}
                                    />
                                );
                            })}

                            {/* <ButtonTags buttonStatus="" text="Tag name 1"/> */}
                        </div>
                        <button
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            className="verticallyAlignMid borderRadius12 borderNone mY30 P1regular colOffWhite w180 pTop10 pBottom10 mLeftAuto"
                        >
                            Next
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

function getMentorExperience(state) {
    return {
        companyID: state.auth.authData?.data.user.companyID || "",
        mentorExperience: {
            id: state.auth.authData?.data.user["_id"] || 0,
            expertise: state.auth.authData?.data.user.expertise || "",
            cv: state.auth.authData?.data.user.cv || "",
            categories: state.auth.authData?.data.user.categories || [],
        },
        onboarding: state.auth.authData?.data.user.mentorOnboarding || {},
        company: state.auth.authData?.company,
    };
}

export default connect(getMentorExperience)(MOYourExperience);
