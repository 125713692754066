import { BsEye, BsCameraVideo, BsMic, BsChatText } from "react-icons/bs";
import { connect } from "react-redux";

function AddServiceComponent(props) {

    const company = props?.data?.company;
    const charityCompany = company?.charity;

    return (
        <div className="borderRadius18 boxShadowForSettingPanelCards mBottom30">
            <div className="w90 mXAuto">
                <h6 className="textAlignLeft mTop50 colPrimaryGreen Pbold">Active</h6>
                <div className="verticallyAlignMidJustConStart addServiceComponentSubb1">
                    <h4 className="H2bold textAlignLeft mBottom8 breakWord w80">{props?.service?.name}</h4>
                    <button style={{color : `${company?.whiteLabel?.color?.buttons?.primaryText}`, backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`}} className="verticallyAlignMid w140 borderRadius12 borderNone Pregular pY6 mLeftAuto" value="active" onClick={() => {props.parentEvent(props.service)}}>
                    <BsEye size="1.2em" className="colOffWhite mRight8" />
                    Preview
                    </button>
                </div>
                <p className="P1regular textAlignLeft mBottom50 w600 AddServiceComponentP breakWord">{props.service.description}</p>
                <div className="verticallyAlignMidStretchStart addServiceComponentSubb2 mBottom40">
                    <div className="mRight100 addServiceComponentSubb2AllCom">
                        <p className="Small-Labelnormal colPrimaryGreen textAlignLeft">Cost</p>
                        <h5 className="Pmedium textAlignLeft">${charityCompany ? "0" : props.service.rate}/h</h5>
                    </div>
                    <div className="mRight100 addServiceComponentSubb2AllCom ">
                        <p className="Small-Labelnormal colPrimaryGreen textAlignLeft">Communication</p>
                        <div className="verticallyAlignMidJustConStart">

                            {props.service.methods.map((comm, index) => {
                                if (comm === "audio") {
                                    return <div key={index}>
                                        <BsMic size="1.2em" className="mRight10"/>
                                    </div>
                                } else if (comm === "video") {
                                    return <div key={index}>
                                        <BsCameraVideo size="1.2em" className=""/>
                                    </div>
                                } else if (comm === "chat") {
                                    return <div key={index}>
                                        <BsChatText  className="mRight10"/>
                                    </div>
                                }
                                return "";
                            })}
                        </div>
                    </div>
                    <div className="addServiceComponentSubb2AllCom">
                        <p className="Small-Labelnormal colPrimaryGreen textAlignLeft">Duration</p>
                        <div className="borderRadiusForSocialMediaButtons backColInput addServiceComponentSubb2Dur">
                            <p className="pX20 pY4 Small-Labelnormal textAlignLeft">{props.service.duration}min</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


function getData(state) {
	return {
		data : state.auth.authData
	}
}

export default connect(getData)(AddServiceComponent);