import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function ClientSettingsPanelPaymentAndPayouts2(props) {
    const company = props.company;
    const navigate = useNavigate();

    if (!company._id || !props.userID) {
        navigate("LogIn");
    }

    return (
        <div className="ClientSettingsPanelPaymentAndPayouts1">
            <div className="verticallyAlignMidJustConStart w80 mXAuto mTop70 mBottom40">
                <Link to="/ClientSettingsPanelPaymentAndPayouts1">
                    <button>Back Button</button>
                </Link>
                <h2 className="textAlignLeft H1regular">Payment and Payouts</h2>
            </div>
            <div className="w80 mXAuto">
                <div className="w50">
                    <div className="verticallyAlignMidJustConStart borderBottomForButtonsGray">
                        <div className="pY14 pX80 borderBottomForButtons">
                            <h5 className="P1medium colPrimaryGreen">
                                Payments
                            </h5>
                        </div>
                        <div className="pY14 pX80 borderBottomForButtons">
                            <h5 className="P1medium colPrimaryGreen">
                                Payouts
                            </h5>
                        </div>
                    </div>
                    <h4 className="textAlignLeft H2medium mBottom50 mTop50">
                        Payment methods
                    </h4>
                    <p className="P1regular textAlignLeft">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                    </p>
                    <button
                        style={{
                            color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                            backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                        }}
                        className="borderRadius12 borderNone verticallyAlignMidJustConStart mBottom30 Pmedium colOffWhite pLeft30 pRight30 pTop10 pBottom10"
                    >
                        Add payout method
                    </button>
                </div>
            </div>
        </div>
    );
}

function getData(state) {
    return {
        company: state.auth.authData.company,
        userID: state.auth.authData.data.user._id,
    };
}

export default connect(getData)(ClientSettingsPanelPaymentAndPayouts2);
