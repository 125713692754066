import { Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

function RestrictedActiveMentorsRoute(props) {
	if(props.userData.user.mentorOnboarding.activeMentor) {
		return <Outlet />;
	} else {
		return <Navigate to="/OnboardingMentor6" />;
	}
}

function getUser(state) {
	// console.log('RestrictedActiveMentorsRoute', state.auth.authData?.data.user)
	return {
		userData: {
			user : state.auth.authData?.data.user		
		}
	}
}

export default connect(getUser)(RestrictedActiveMentorsRoute);