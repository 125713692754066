import { Link, useNavigate } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import { connect } from "react-redux";

function ClientSettingsPanelPaymentAndPayouts1(props) {
    const company = props.company;
    const navigate = useNavigate();

    if (!company._id || !props.userID) {
        navigate("/LogIn");
    }

    return (
        <div className="ClientSettingsPanelPaymentAndPayouts1">
            <div className="verticallyAlignMidJustConStart w80 mXAuto mTop70 mBottom40">
                <Link to="/MentorHome2SettingsPanel">
                    <div className="posRel mRight20">
                        <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                            <RiArrowLeftSLine
                                size="2em"
                                className="colPrimaryGreen"
                            />
                        </button>
                    </div>
                </Link>
                <h2 className="textAlignLeft H1regular">Payment and Payouts</h2>
            </div>
            <div className="w80 mXAuto">
                <div className="verticallyAlignMidJustConStart w50 borderBottomForButtonsGray">
                    <div className="pY14 pX80 borderBottomForButtons">
                        <h5 className="P1medium colPrimaryGreen">Payments</h5>
                    </div>
                    <div className="pY14 pX80 borderBottomForButtons">
                        <h5 className="P1medium colPrimaryGreen">Payouts</h5>
                    </div>
                </div>
                <div className="w50">
                    <h4 className="textAlignLeft H2medium mBottom50 mTop50">
                        Payment methods
                    </h4>
                    <div className="verticallyAlignMidJustConStart mLeft8 mBottom30">
                        <div>
                            <h5 className="textAlignLeft">Visa</h5>
                            <p className="Pbold textAlignLeft">Expiration</p>
                        </div>
                        <button className="mLeftAuto">Elipsis Button</button>
                    </div>
                    <div className="borderGrayTopInput mY20"></div>
                    <div className="verticallyAlignMidJustConStart mLeft8 mTop30">
                        <div>
                            <h5 className="textAlignLeft">Stripe</h5>
                            <p className="Pbold textAlignLeft">
                            info@mentrx.io
                            </p>
                        </div>
                        <button className="mLeftAuto">Elipsis Button</button>
                    </div>
                    <div className="borderGrayTopInput mY20"></div>
                    <button
                        style={{
                            color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                            backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                        }}
                        className="borderRadius12 borderNone verticallyAlignMidJustConStart mBottom30 Pmedium colOffWhite pLeft30 pRight30 pTop10 pBottom10"
                    >
                        Add payment method
                    </button>
                    <h4 className="textAlignLeft H2medium mBottom50 mTop50">
                        Coupons
                    </h4>
                    <div className="verticallyAlignMidJustConStart">
                        <p className="Pbold textAlignLeft">Active coupons</p>
                        <input className="mLeftAuto" />
                    </div>
                    <div className="borderGrayTopInput mY20"></div>
                    <button
                        style={{
                            color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                            backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                        }}
                        className="borderRadius12 borderNone verticallyAlignMidJustConStart mBottom30 Pmedium colOffWhite pLeft30 pRight30 pTop10 pBottom10"
                    >
                        Add coupon
                    </button>
                    <div className="verticallyAlignMidJustConStart">
                        <div>
                            <h5 className="textAlignLeft">Address</h5>
                            <p className="Pbold textAlignLeft">Not Provided</p>
                        </div>
                        <button>Edit</button>
                    </div>
                    <h4 className="textAlignLeft H2medium mBottom50 mTop50">
                        Account
                    </h4>
                    <div className="verticallyAlignMidJustConStart">
                        <p className="Pbold textAlignLeft">
                            Deactivate your account
                        </p>
                        <button>Deactivate</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getData(state) {
    return {
        company: state.auth.authData.company || {},
        userID: state.auth.authData.data.user._id,
    };
}

export default connect(getData)(ClientSettingsPanelPaymentAndPayouts1);
