import { useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clientOnboarding } from "../../actions/auth.js";
import { RiArrowLeftSLine } from "react-icons/ri";

function LogInClientSetYourPaymentDetails(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (!props.company._id || !props.userID) {
        navigate("/LogIn");
    }

    const [formData, setFormData] = useState({
        setPaymentDetails: true,
        paymentMethod: "",
    });

    function onChange(e) {
        setFormData({ ...formData, paymentMethod: e.target.value });
    }

    function goToNextPage(e) {
        e.preventDefault();

        if (formData.paymentMethod === "") {
            alert("You need to select payment details!");
            return false;
        }

        dispatch(clientOnboarding(formData, navigate));
    }

    return (
        <div className="LogInClientSetYourPaymentDetails">
            <div className="backColPrimaryLight borderRadius24 mTop50 mBottom70 w80 mXAuto">
                <Link to="/ClientSelectCategories">
                    <div className="posRel mX20 mY20">
                        <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                            <RiArrowLeftSLine
                                size="2em"
                                className="colPrimaryGreen"
                            />
                        </button>
                    </div>
                </Link>
                <div className="w50 mXAuto mTop70 pBottom70 LogInClientSetYourPaymentDetailsSubb1">
                    <h4 className="textAlignCenter H2medium mBottom20">
                        Set your payment details
                    </h4>
                    <p className="textAlignCenter Pmedium">
                        You won't be charged until your trial expiers.
                    </p>
                    <p className="textAlignCenter Pmedium mBottom10">
                        You can cancel anytime in your profile settings.
                    </p>
                    <div className="w340 mXAuto LogInClientSetYourPaymentDetailsSubb2">
                        <h5 className="textAlignLeft mBottom20 P1medium">
                            Pay With
                        </h5>
                        <select
                            onChange={(e) => onChange(e)}
                            className="Pregular pLeft8 mXAuto textAlignLeft verticallyAlignMid borderNone pY14 borderRadius12 mBottom30"
                        >
                            <option value="">Select Payment Method</option>
                            <option value="1">Payment Method 1</option>
                        </select>
                        <p className="textAlignLeft Pbold mBottom10">
                            Promo code
                        </p>
                    </div>
                    <button
                        onClick={goToNextPage}
                        className="verticallyAlignMid borderRadius12 w300 borderNone mTop20 Pmedium colOffWhite backColLabelGray pX80 pY14 mXAuto"
                    >
                        Start Trial
                    </button>
                </div>
            </div>
        </div>
    );
}

function getData(state) {
    return {
        company: state.auth.authData.company,
        userID: state.auth.authData?.data.user._id,
    };
}

export default connect(getData)(LogInClientSetYourPaymentDetails);
