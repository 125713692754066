import { Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

function RestrictedClientPaymentDetails(props) {

	if(!props.userData.user.clientOnboarding.selectCategoriesCompleted) {
		return <Navigate to="/ClientSelectCategories" />;
	} else if(!props.userData.user.clientOnboarding.paymentMethodCompleted) {
		return <Outlet />;
	} else {
		return <Navigate to="/WhatKindOfMentor" />;
	}
};

function getUser(state) {
	return {
		userData: {
			user : state.auth.authData?.data.user
		}
	}
}

export default connect(getUser)(RestrictedClientPaymentDetails);