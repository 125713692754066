import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
// import {io} from 'socket.io-client';
// import { useDispatch, connect } from 'react-redux'
import { socket } from "./actions/socket";

import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";

import NavBar from "./components/NavBar/NavBar";
import NotificationBottomPopup from "./components/NotificationBottomPopup/NotificationBottomPopup";
import Views from "./Views";
import Footer from "./components/Footer/Footer";
import NewNotePoUp from "./pages/NewNotePoUp/NewNotePoUp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function App(props) {
    // console.log(props)

    const [redDotNotification, setRedDotNotification] = useState(false);

    useEffect(() => {
        //console.log('trying to connect');

        socket.on("connect", () => {
            // console.log(`Client with socket ID ${socket.id} connected!`);
        });

        socket.on("connect_error", (err) => {
            // console.log(`Socket connection error due to ${err.message}`);
            setTimeout(() => socket.connect(), 10000);
        });

        socket.on("disconnect", () => {
            // console.log("server disconnected");
        });

        socket.io.on("reconnect", () => {
            // console.log("reauthorizing");
            socket.emit("authorize", props?.data?.data?.user);
        });

        if (props.data?.token) {
            // console.log("authorizing socket connection");
            socket.emit("authorize", props.data.data.user);

            if (!socket.hasListeners("notification")) {
                // console.log("creating socket notification listener");
                socket.on("notification", (data) =>
                    setRedDotNotification(true)
                );
                // socket.on("newSessionMessage", (data) =>
                //     setRedDotNotification(true)
                // );
                socket.on("newAppMessage", (data) => setRedDotNotification(true)




            );
            } else {
                // console.log("already listening for socket notifications");
            }
        } else {
            // console.log("user not connected")
            // if(socket && socket.connected) {
            // 	console.log("will DC socket")
            // 	socket.disconnect();
            // }
        }

        return function cleanup() {
            socket.off("connect");
            socket.off("connect_error");
            socket.off("disconnect");
            socket.io.off("reconnect");
        };
    }, [props]);

    // console.log(socket)
    // console.log(time)

    const [addNote, setAddNote] = useState(false);
    const [popUpStatus, setPopUpStatus] = useState(false);

    return (
        <Router>
            <div className="contentWrapper">
                {addNote && (
                    <div className="filterMentorPopupWrapper">
                        <NewNotePoUp
                            user={props.data.data.user}
                            parentEvent={setAddNote}
                        />
                    </div>
                )}

                {popUpStatus && (
                    <div className="filterMentorPopupWrapper">
                        <div className="popUpStart verticallyAlignMid">
                            {popUpStatus === "error" && (
                                <h2 className="pLeft10">Error</h2>
                            )}
                            {popUpStatus === "success" && (
                                <>
                                    <FontAwesomeIcon icon={faCheck} size="2x" />
                                    <h2 className="pLeft10">Success</h2>
                                </>
                            )}
                            {popUpStatus === "processing" && (
                                <>
                                    <h2 className="pLeft10">Processing...</h2>
                                </>
                            )}
                            {popUpStatus === "sessionUnavailable" && (
                                <>
                                    <h2 className="pLeft10">Unavailable</h2>
                                </>
                            )}
                        </div>
                    </div>
                )}

                <NavBar
                    redDot={redDotNotification}
                    parentEvent={setRedDotNotification}
                    addNote={addNote}
                    addNoteEvent={setAddNote}
                />

                <div className="viewsWrapper">
                    <Views
                        user={props.data?.data?.user}
                        redDot={redDotNotification}
                        parentEvent={setRedDotNotification}
                        statusPopUp={setPopUpStatus}
                    />
                </div>

                <NotificationBottomPopup />
                <Footer />
            </div>
        </Router>
    );
}

function getCurrentUser(state) {
    return {
        data: state.auth?.authData,
    };
}

export default connect(getCurrentUser)(App);
