import ConifrmSessionOrderSummaryComponent from "../../components/ConifrmSessionOrderSummaryComponent/ConifrmSessionOrderSummaryComponent";
import { BsCheckCircleFill } from "react-icons/bs";
// import WhatToBringTags from '../../components/WhatToBringTags/WhatToBringTags';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getSession } from "../../api/index.js";
import Moment from "moment";
import { connect } from "react-redux";

function ClientConfirmSessionThankYou(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const company = props.company;

    const [session, setSession] = useState({});

    function dateFormat(date) {
        // return Moment(date).format('MMM DD, YYYY');
        return Moment(date).format("MMM DD");
    }

    function timeFormat(time) {
        return Moment(time).format("hh:mm A");
    }

    function goToSchedule() {
        navigate("/Schedule");
    }

    function goHome() {
        navigate("/WhatKindOfMentor");
    }

    // console.log(location.state);
    // console.log(props);

    useEffect(() => {
        async function getResults() {
            // let queryString = `?populate=mentorID,serviceID&_id=${location.state.id}`;
            let queryString = `?populate=mentorID,serviceID`;

            const { data } = await getSession(location.state.id, queryString);

            // console.log(data);
            setSession(data.data.session);
        }

        getResults();
    }, [location.state.id]);

    return (
        <div className="ClientConfirmSessionThankYou">
            <div className="verticallyAlignMidJustConStart ClientConfirmSessionThankYouMain mTopForSmallerCreens mTop70 mBottom70 w80 mXAuto">
                <div className="w50 ClientConfirmSessionThankYouSubb1">
                    <div className="verticallyAlignMidJustConStart mBottom40">
                        <BsCheckCircleFill
                            size="5em"
                            className="colPrimaryGreen checkThankYou mRight20"
                        />
                        <h1 className="H1medium textAlignLeft">Thank you!</h1>
                    </div>
                    <h5 className="textAlignLeft H3bold mBottom30">
                        Your session with{" "}
                        {session?.mentorID &&
                            session.mentorID.fullName.split(" ")[0]}{" "}
                        is confirmed!
                    </h5>
                    {/* <p className="textAlignLeft Pregular">We sent an email to <strong>{props.user.email}</strong> with sesion confirmation and bill.</p> */}
                    <div className="borderGrayTopInput w100 mTop20 mBottom40"></div>
                    <div>
                        <h4 className="textAlignLeft H3bold mBottom30">
                            Order details
                        </h4>
                        <div className="verticallyAlignMidJustConStart">
                            <div className="mRight40">
                                <h5 className="textAlignLeft colLabelGray Pmedium">
                                    Date
                                </h5>
                                <h5 className="textAlignLeft Pmedium">
                                    {session?.date && dateFormat(session.date)}
                                </h5>
                            </div>
                            <div>
                                <h5 className="textAlignLeft colLabelGray Pmedium">
                                    Time
                                </h5>
                                <h5 className="textAlignLeft Pmedium">
                                    {session?.date && timeFormat(session.date)}
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="borderGrayTopInput w100 mY50"></div>
                    <h4 className="textAlignLeft H3bold ">What to bring</h4>
                    <div className="verticallyAlignMidJustConStart mY20 flexWrap">
                        <h5 className="P1medium textAlignLeft">
                            {session?.serviceID &&
                                session.serviceID?.requirements}
                        </h5>
                    </div>
                    <div className="verticallyAlignMidJustConStart ClientConfirmSessionThankYouButtonsMain mTop50 mBottom70">
                        <button
                            onClick={goToSchedule}
                            className="verticallyAlignMid borderRadius12 ClientConfirmSessionThankYouButtons Pbold colSecondaryGreen mY20 backColOffWhite w180 pY10 mRight20 borderPrimaryGreen"
                        >
                            View Shedule
                        </button>
                        <button
                            onClick={goHome}
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            className="verticallyAlignMid borderRadius12 borderNone Pmedium colOffWhite mY20 w180 pY10"
                        >
                            Go Home
                        </button>
                    </div>
                </div>
                <div className="w50 ClientConfirmSessionThankYouSubb2">
                    {session?.mentorID && (
                        <ConifrmSessionOrderSummaryComponent
                            mentor={session.mentorID}
                            session={session}
                            service={session.serviceID}
                            client={props.user}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

function getUserData(state) {
    return {
        user: state.auth.authData?.data.user ?? {},
        company: state.auth.authData.company,
    };
}

export default connect(getUserData)(ClientConfirmSessionThankYou);
