import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    getFavoriteMentors,
    addFavoriteMentors,
    deleteFavoriteMentors,
} from "../../api/index.js";
// import Moment from 'moment';
import { useNavigate } from "react-router-dom";
import LandingRecommendedMentorCards from "../../components/LandingRecommendedMentorCards/LandingRecommendedMentorCards";
import { RiArrowLeftSLine } from "react-icons/ri";
import { socket } from "../../actions/socket";

function FavoriteMentors(props) {

    const navigate = useNavigate();

    if (!props.user._id || !props.company._id) {
        navigate("/LogIn");
    }

    const [mentors, setMentors]                     = useState([]);
    const [value, setValue]                         = useState(0);
    const [availableMentors, setAvailableMentors]   = useState([]);

    const company = props.company;

    // useEffect(() => {
    // 	function getData() {
    // 		socket.emit('getAvailableMentors', null, (array) => {
    // 			console.log(array);
    // 			return setAvailableMentors(array);
    // 		});
    // 	}

    // 	getData();
    // },[])

    useEffect(() => {
        async function getResults() {
            const queryString = `?populate=mentorID&clientID=${props.user._id}&sort=-createdAt`;

            const { data } = await getFavoriteMentors(queryString);

            // console.log(data.data.mentors)
            setMentors(data.data.mentors);

            socket.emit("getAvailableMentors", null, (array) => {
                // console.log(array);
                return setAvailableMentors(array);
            });
        }
        getResults();
    }, [props, value]);

    function setValueReRender(id) {
        let array = mentors.filter((el) => el.mentorID._id === id);

        if (array.length > 0) {
            deleteFavoriteMentors(array[0]._id);
        } else {
            addFavoriteMentors({
                clientID: props.user._id,
                mentorID: id,
            });
        }

        setValue((value) => value + 1);
    }

    const goToMentorProfile = (id) => {
        navigate("/MentorProfileUserSide", {
            state: {
                id,
            },
        });
    };

    return (
        <div className="FavoriteMentors">
            <div className="mTop70 mBottom70 w80 mXAuto FavoriteMentorsSubb">
                <div className="verticallyAlignMidJustConStart FavoriteMentorsSubbHead mBottom70">
                    {/* <button>Back Button</button> */}
                    <div
                        onClick={() => navigate(-1)}
                        className="posRel mRight20"
                    >
                        <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                            <RiArrowLeftSLine
                                size="2em"
                                className="colPrimaryGreen"
                            />
                        </button>
                    </div>
                    {/* <h2 className="H2medium textAlignLeft">Favorite Mentors</h2> */}
                    <h2 className="H2medium textAlignLeft">Favorite {company.whiteLabel.texts.label3MentorPlural}</h2>
                </div>
                <div className="verticallyAlignMidJustConStart flexWrap w100">
                    {mentors.length > 0 &&
                        mentors.map((mentor, i) => {
                            if (!mentor.mentorID) {
                                return false;
                            }

                            let available = false;

                            if (
                                availableMentors.indexOf(
                                    mentor.mentorID?._id
                                ) >= 0
                            ) {
                                available = true;
                            }

                            return (
                                <div className="" key={i}>
                                    <LandingRecommendedMentorCards
                                        user={props.user}
                                        favorite={mentors}
                                        parentEventFavourite={setValueReRender}
                                        props={mentor.mentorID}
                                        parentEvent={goToMentorProfile}
                                        available={available}
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}

function getUserData(state) {
    return {
        user    : state.auth.authData?.data.user,
        company : state.auth.authData?.company,
    };
}

export default connect(getUserData)(FavoriteMentors);
