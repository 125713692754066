// import { RiStarFill, RiCheckboxCircleLine } from "react-icons/ri";
// import { RiStarFill } from "react-icons/ri";
import ButtonTags from "../../components/buttons/ButtonTags/ButtonTags";
// import WhatToBringTags from '../../components/WhatToBringTags/WhatToBringTags';
import BigScheduleCalendar from "../../components/BigScheduleCalendar/BigScheduleCalendar";
import ReviewsCard from "../../components/ReviewsCard/ReviewsCard";
import LandingRecommendedMentorCards from "../../components/LandingRecommendedMentorCards/LandingRecommendedMentorCards";
import UpcomingPastCancelledNav from "../../components/UpcomingPastCancelledNav/UpcomingPastCancelledNav";
import ViewRescheduleSessionCard from "../../components/ViewRescheduleSessionCard/ViewRescheduleSessionCard";
import NotesCard from "../../components/NotesCard/NotesCard";
import ReviewsTitleStar from "../../components/ReviewsTitleStar/ReviewsTitleStar";
import { useLocation, useNavigate } from "react-router-dom";
import {
    getUser,
    getServices,
    getReviews,
    getRecomendedMentors,
    getSessions,
    getNotes,
    // getNote,
    // updateUser,
    getFavoriteMentors,
    addFavoriteMentors,
    deleteFavoriteMentors,
    createMessage,
} from "../../api/index.js";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Reviews from "../Reviews/Reviews";
import CloseButton from "../../components/buttons/CloseButton/CloseButton";
import TextareaComponent from "../../components/TextareaComponent/TextareaComponent";
// import { RiContactsBookLine } from 'react-icons/ri';
import { socket } from "../../actions/socket.js";

function MentorProfileUserSide(props) {
    const company = props.data.company;

    const location = useLocation();
    const navigate = useNavigate();

    if (!company._id || !props.user._id || !location.state.id) {
        navigate("/LogIn");
    }

    const [availableMentors, setAvailableMentors] = useState([]);

    useEffect(() => {
        function getData() {
            socket.emit("getAvailableMentors", null, (array) => {
                return setAvailableMentors(array);
            });
        }

        getData();
    }, []);

    const initialMentorAndService = {
        mentor: {
            availability: [],
            biography: "",
            fullName: "",
            role: "",
            _id: "",
        },
        services: [
            {
                id: "",
                method: [],
                requirements: [],
                status: "",
                mentorID: "",
                name: "",
                duration: 0,
                description: "",
                rate: 0,
            },
        ],
        service: {
            id: "",
            method: [],
            requirements: [],
            status: "",
            mentorID: "",
            name: "",
            duration: 0,
            description: "",
            rate: 0,
        },
        reviews: [],
        recommendedMentors: [],
    };

    const initialSessions = {
        Upcoming: [],
        Past: [],
        Cancelled: [],
    };

    const [mentorAndService, setMentorAndService] = useState(
        initialMentorAndService
    );
    const [sessions, setSessions] = useState(initialSessions);
    const [sessionType, setSessionType] = useState("Upcoming");
    const [reviewView, setReviewView] = useState(false);
    const [notes, setNotes] = useState([]);
    const [favoriteMentors, setFavoriteMentors] = useState([]);
    const [contactMentor, setContactMentor] = useState(false);
    const [text, setText] = useState("");

    const [service, setService] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);

        async function getResults() {
            const { data } = await getUser(location.state.id);

            let queryStringForServices = `?mentorID=${location.state.id}`;
            const services = await getServices(queryStringForServices);

            let queryStringForReviews = `
				?populate=clientID
				&mentorID=${location.state.id}
				&clientID=${props.user._id}
			`;

            if (service !== "") {
                queryStringForReviews += `&serviceID=${mentorAndService.service._id}`;
            } else {
                queryStringForReviews += `&serviceID=${services.data.data.services[0]?._id}`;
            }

            const reviews = await getReviews(queryStringForReviews);

            let queryStringRecommendedMentors = `?companyID=${props.user.companyID}&role=mentor&active=true&_id!=${location.state.id}&filter={"mentorOnboarding.activeMentor":true}&populate=expertise&limit=10`;
            const recommendedMentors = await getRecomendedMentors(
                queryStringRecommendedMentors
            );

            // console.log("recommendedMentors", recommendedMentors);

            let queryStringForSessions = `?mentorID=${location.state.id}`;

            if (service !== "") {
                queryStringForSessions += `&serviceID=${mentorAndService.service._id}`;
            } else {
                queryStringForSessions += `&serviceID=${services.data.data.services[0]._id}`;
            }

            const sessionsData = await getSessions(queryStringForSessions);

            checkJointSessions(sessionsData.data.data.sessions);

            setMentorAndService({
                mentor: data.data.user,
                services: services.data.data.services,
                service:
                    service === ""
                        ? services.data.data.services[0]
                        : mentorAndService.service,
                reviews: reviews.data.data.reviews,
                recommendedMentors: recommendedMentors.data.data.users,
            });

            let queryString = `
				?populate=assignedID,serviceID,userID
				&userID=${props.user._id}
				&assignedID=${location.state.id}
				&limit=2
				&sort=-createdAt
			`;

            if (service !== "") {
                queryString += `&serviceID=${mentorAndService.service._id}`;
            } else {
                queryString += `&serviceID=${services.data.data.services[0]._id}`;
            }

            const notesData = await getNotes(queryString);
            setNotes(notesData.data.data.notes);

            const favoriteMentorsData = await getFavoriteMentors(
                `?populate=mentorID&clientID=${data.data.user._id}`
            );
            setFavoriteMentors(favoriteMentorsData.data.data.mentors);

            function checkJointSessions(data) {
                let sessionObj = initialSessions;

                if (data.length > 0) {
                    data.forEach((el) => {
                        if (el.status === "cancelled") {
                            if (sessionObj["Cancelled"].length < 3) {
                                sessionObj["Cancelled"].push(el);
                            }
                        } else if (el.status === "completed") {
                            if (sessionObj["Past"].length < 3) {
                                sessionObj["Past"].push(el);
                            }
                        } else {
                            if (sessionObj["Upcoming"].length < 3) {
                                sessionObj["Upcoming"].push(el);
                            }
                        }
                    });
                }

                setSessions(sessionObj);
            }
        }
        getResults();
    }, [location.state.id, service]);

    function changeService(el) {
        if (el._id !== mentorAndService.service._id) {
            setMentorAndService((prevState) => ({ ...prevState, service: el }));
        }

        setService(el.name);
    }

    const goToMentorProfile = (id) => {
        // console.log("id", id);
        navigate("/MentorProfileUserSide", {
            state: {
                id,
            },
        });
    };

    // function goToMessages() {

    //     let userID = location.state.id;

    //     navigate("/Messages", {
    //         state : {
    //             userID
    //         }
    //     })
    // }

    function differentFromInitialSession() {
        let res = false;

        if (
            sessions.Upcoming.length === 0 &&
            sessions.Past.length === 0 &&
            sessions.Cancelled.length === 0
        ) {
            res = false;
        } else {
            res = true;
        }

        return res;
    }

    function goToNotes() {
        navigate("/ClientNotes");
    }

    function setValueReRender(id) {
        let array = favoriteMentors.filter((el) => el.mentorID._id === id);

        if (array.length > 0) {
            deleteFavoriteMentors(array[0]._id);
        } else {
            addFavoriteMentors({
                clientID: props.user._id,
                mentorID: id,
            });
        }
    }

    function sendMessageToMentor() {
        createMessage({
            fromID: props.user._id,
            toID: mentorAndService.mentor._id,
            text,
            type: "Message",
        });

        // console.log({
        //   fromID: props.user._id,
        //   toID: mentorAndService.mentor._id,
        //   text,
        //   type: "Message",
        // });

        setContactMentor(false);
    }

    function getInitials(name) {
        let words = name.split(" ");
        return (
            words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
        );
    }

    return (
        <div className="MentorProfileUserSide">
            {reviewView && (
                <div className="filterMentorPopupWrapper">
                    <Reviews
                        id={mentorAndService.mentor?._id}
                        parentEvent={setReviewView}
                    />
                </div>
            )}

            {contactMentor && (
                <div className="filterMentorPopupWrapper">
                    <div className="ReschedulePopUp">
                        <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                            <div className="w90 mXAuto">
                                <div className="verticallyAlignMidJustConStart mTop40">
                                    <CloseButton
                                        parentEvent={setContactMentor}
                                    />
                                </div>
                                <div className="verticallyAlignMidJustConStart mBottom30">
                                    <div className="verticallyAlignMidJustConStart mRight50">
                                        <div className="verticallyAlignMidJustConStart borderRadius50 mRight12 mY20">
                                            <img
                                                alt=""
                                                className="reviewsCardImg2"
                                                src="https://i.ibb.co/fD1yxzd/img.png"
                                            />
                                        </div>
                                        <div>
                                            <div className="P1bold textAlignLeft">
                                                {props.user.fullName}
                                            </div>
                                            <p className="textAlignLeft Small-Labelnormal colLabelGray">
                                                {mentorAndService.service.name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className="Pmedium textAlignLeft mBottom12">
                                        Send{" "}
                                        {
                                            mentorAndService.mentor.fullName.split(
                                                " "
                                            )[0]
                                        }{" "}
                                        a message.
                                    </p>
                                    <TextareaComponent parentEvent={setText} />
                                </div>
                                <button
                                    onClick={sendMessageToMentor}
                                    style={{
                                        color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                        backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                    }}
                                    className="verticallyAlignMid w180 borderRadius12 mBottom40 borderNone Pregular colOffWhite pY10 mLeftAuto"
                                >
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="mTop50 mBottom70 w80 mXAuto MentorProfileUserSideSubb1 mTopForSmallerCreens">
                <div className="verticallyAlignMidStretch MentorProfileUserSideSubb1Subb mBottom70">
                    <div className="w50 mRightAuto MentorProfileUserSideSubb1Left">
                        <h3 className="H2medium textAlignLeft mBottom8">
                            {mentorAndService.mentor?.fullName}
                        </h3>
                        <div className="mBottom50">
                            <ReviewsTitleStar
                                reviews={mentorAndService.reviews}
                            />
                        </div>
                        <div>
                            <h5 className="P1bold textAlignLeft">Services</h5>
                            <div className="verticallyAlignMidJustConStart flexWrap pTop10">
                                {mentorAndService.services.map((serv, i) => {
                                    let status = "";

                                    if (
                                        mentorAndService.service._id ===
                                        serv._id
                                    ) {
                                        status = "checked";
                                    }

                                    return (
                                        <ButtonTags
                                            key={i}
                                            buttonStatus={status}
                                            service={serv}
                                            text={serv.name}
                                            parentEvent={changeService}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div className="borderGrayTopInput w100 mY30"></div>
                        <div>
                            <h5 className="H3bold textAlignLeft mBottom8">
                                {mentorAndService.service.name}
                            </h5>
                            <p className="textAlignLeft Pregular mBottom10 w90">
                                {mentorAndService.service.description}
                            </p>
                            {/*<p className="textAlignLeft Pmedium cursorPointer">Read more</p>*/}
                        </div>
                        <div className="borderGrayTopInput w100 mY30"></div>
                        <div>
                            <h4 className="H3bold textAlignLeft mBottom8">
                                What to bring
                            </h4>
                            <div className="verticallyAlignMidJustConStartForText Pregular w90">
                                {/*<WhatToBringTags />
	        					<WhatToBringTags />
	        					<WhatToBringTags />*/}
                                {mentorAndService.service.requirements}
                            </div>
                        </div>
                        <div className="borderGrayTopInput w100 mY30"></div>
                        <div>
                            <h4 className="H3bold textAlignLeft mBottom8">
                                Meet your{" "}
                                {company.whiteLabel.texts.label2MentorSingle}
                            </h4>
                            <p className="textAlignLeft Pregular w90">
                                {mentorAndService.mentor?.biography}
                            </p>
                            <button
                                onClick={() => setContactMentor(true)}
                                style={{
                                    color: `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
                                    backgroundImage: `${company?.whiteLabel?.color?.buttons?.secondary}`,
                                }}
                                className="verticallyAlignMid borderRadius12 borderNone mY30 P1bold colPrimaryGreen pLeft60 pRight60 pY14"
                            >
                                Contact{" "}
                                {mentorAndService.mentor.fullName.split(" ")[0]}
                            </button>
                        </div>
                        <div className="borderGrayTopInput w100 mY30"></div>
                    </div>
                    <div className="w40 MentorProfileUserSideSubb1Right">
                        <div className="borderRadius12 MentorProfileUserSideSubb1RightSubb w400 mBottom50">
                            {/* <img alt="" className="w100" src="https://i.ibb.co/fD1yxzd/img.png" /> */}
                            <div
                                className={`w100 h100 verticallyAlignMid ${
                                    !mentorAndService.mentor?.photo &&
                                    "backColPlaceHolderGray"
                                } pY50`}
                            >
                                {mentorAndService.mentor?.photo ? (
                                    <img
                                        alt=""
                                        className="w100 h100"
                                        src={mentorAndService.mentor.photo}
                                    />
                                ) : (
                                    <div className="userInitialsLarge">
                                        <p>
                                            {mentorAndService.mentor
                                                ?.fullName &&
                                                getInitials(
                                                    mentorAndService.mentor
                                                        ?.fullName
                                                )}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        {
                            <BigScheduleCalendar
                                mentor={mentorAndService.mentor}
                                service={mentorAndService.service}
                            />
                        }
                    </div>
                </div>

                {mentorAndService.reviews &&
                    mentorAndService.reviews.length > 0 && (
                        <>
                            <div className="verticallyAlignMidJustConStart mBottom40">
                                <h4 className="P1bold textAlignLeft mRight10">
                                    Reviews
                                </h4>
                                <ReviewsTitleStar
                                    reviews={mentorAndService.reviews}
                                />
                            </div>
                            <div className="verticallyAlignMidJustConStart flexWrap">
                                {mentorAndService.reviews.map((rev, i) => {
                                    if (i > 2) {
                                        return <></>;
                                    }

                                    return (
                                        <div
                                            key={i}
                                            className="mRight20 reviewsCardMainSubb"
                                        >
                                            <ReviewsCard review={rev} />
                                        </div>
                                    );
                                })}
                            </div>

                            <button
                                onClick={() => setReviewView(true)}
                                style={{
                                    color: `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
                                    backgroundImage: `${company?.whiteLabel?.color?.buttons?.secondary}`,
                                }}
                                className="verticallyAlignMid borderRadius12 borderNone mTop50 mBottom70 P1bold colPrimaryGreen pLeft60 pRight60 pY14"
                            >
                                View All
                            </button>
                        </>
                    )}

                {(() => {
                    const e = differentFromInitialSession();

                    if (e) {
                        return (
                            <>
                                <div className="borderGrayTopInput w100 mY30"></div>
                                <UpcomingPastCancelledNav
                                    type={sessionType}
                                    parentEvent={setSessionType}
                                />

                                <div className="verticallyAlignMidJustConStart flexWrap">
                                    {sessions[sessionType].map((el, i) => {
                                        return (
                                            <ViewRescheduleSessionCard
                                                key={el._id}
                                                session={el}
                                                service={
                                                    mentorAndService.service
                                                }
                                            />
                                        );
                                    })}
                                </div>
                                <div className="borderGrayTopInput w100 mY30"></div>
                            </>
                        );
                    }
                })()}

                {notes && notes.length > 0 && (
                    <>
                        <h4 className="H3bold textAlignLeft mBottom12">
                            Notes
                        </h4>
                        <div className="verticallyAlignMidJustConStart flexWrap">
                            {notes.map((el, i) => {
                                if (i % 2 === 0) {
                                    return (
                                        <div
                                            key={i}
                                            className="mRight20 w440 notesCardMainSubb"
                                        >
                                            <NotesCard note={el} />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            key={i}
                                            className="w440 notesCardMainSubb"
                                        >
                                            <NotesCard note={el} />
                                        </div>
                                    );
                                }
                            })}
                        </div>
                        <button
                            onClick={goToNotes}
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.secondaryText}`,
                                backgroundImage: `${company?.whiteLabel?.color?.buttons?.secondary}`,
                            }}
                            className="verticallyAlignMid borderRadius12 borderNone mLeftAuto mBottom70 P1bold colPrimaryGreen pLeft60 pRight60 pY14"
                        >
                            View All
                        </button>
                    </>
                )}
                <h4 className="H3bold textAlignLeft mBottom50">
                    More {company.whiteLabel.texts.label3MentorPlural}
                </h4>
                <div
                    className={
                        "verticallyAlignMidJustConStart w100 forRecommendedMentors " +
                        (mentorAndService.recommendedMentors &&
                        mentorAndService.recommendedMentors.length > 4
                            ? "xScrollWrapper"
                            : "")
                    }
                >
                    {mentorAndService.recommendedMentors &&
                        mentorAndService.recommendedMentors.map((mentor, i) => {
                            let available = false;

                            if (
                                availableMentors.length > 0 &&
                                availableMentors.indexOf(mentor._id) >= 0
                            ) {
                                available = true;
                            }

                            return (
                                <LandingRecommendedMentorCards
                                    user={props.user}
                                    key={i}
                                    props={mentor}
                                    favorite={favoriteMentors}
                                    parentEvent={goToMentorProfile}
                                    parentEventFavourite={setValueReRender}
                                    available={available}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
}

function getData(state) {
    return {
        data: state.auth.authData,
        user: state.auth.authData?.data.user,
    };
}

export default connect(getData)(MentorProfileUserSide);
