import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mentorOnboarding } from "../../actions/auth.js";

function OnboardingMentor10(props) {
    const company = props.company;
    const formData = { setWelcome: true };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (!company._id || !props.user._id) {
        navigate("/LogIn");
    }

    function goToNextPage(e) {
        e.preventDefault();
        dispatch(mentorOnboarding(formData, navigate));
    }

    return (
        <div className="OnboardingMentor10">
            <div className="w80 mXAuto mY70 backColPrimaryLight borderRadius24">
                <div className="verticallyAlignMidJustConStart OnboardingMentor5Subb mY70 w80 mXAuto">
                    <div className="w50 OnboardingMentor5Subb1">
                        <h3 className="H2medium textAlignLeft mBottom20">
                            You are all set!
                        </h3>
                        <div className="mY20 loginStartingPagesImg2">
                            <img
                                alt="Welcome"
                                className="welcomeImg"
                                src="https://i.ibb.co/fD1yxzd/img.png"
                            />
                        </div>
                        {/*<p className="textAlignLeft Pmedium mBottom30 mRight20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>*/}
                        <div className="verticallyAlignMidJustConStart mRightAuto">
                            <button
                                onClick={goToNextPage}
                                style={{
                                    color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                    backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                }}
                                className="borderRadius12 borderNone mBottom30 P1regular colOffWhite w180 pY10"
                            >
                                Get started
                            </button>
                        </div>
                    </div>
                    <div className="w50 mY50 loginStartingPagesImg">
                        {company.whiteLabel.images?.welcome ? (
                            <img
                                alt="Welcome"
                                className="welcomeImg"
                                src={company.whiteLabel.images?.welcome}
                            />
                        ) : (
                            <img
                                alt="Welcome"
                                className="welcomeImg"
                                src="https://i.ibb.co/fD1yxzd/img.png"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

function getData(state) {
    return {
        company: state.auth.authData.company,
        user: state.auth.authData.data.user,
    };
}

export default connect(getData)(OnboardingMentor10);
