// import { getMessages } from '../../api';
// import { useLocation } from "react-router-dom";
import {
    BsCameraVideo,
    BsChatFill,
    BsMicFill,
    BsThreeDots,
} from "react-icons/bs";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

function VideoCall(props) {
    const navigate = useNavigate();
    const company = props.company;

    if (!props.company._id || !props.user.id) {
        navigate("/LogIn");
    }

    return (
        // MAIN NAV BAR ORIGINAL
        <div className="VideoCallMain">
            <div className="mXAuto messagesMainSubb verticallyAlignMidStretchStart">
                <div className="VideoCallImageElement backColInput posRel">
                    <div className="borderRadius18 posAbs buttonsForVideoCall backColGrayOpacity">
                        <div className="buttonsForVideoCallSubb mXAuto verticallyAlignMidSpaceBetween pY14">
                            <div className="buttonForVideoCall backColGrayOpacity2 borderRadius50 verticallyAlignMid cursorPointer">
                                <BsCameraVideo
                                    className="colOffWhite"
                                    size="1.7em"
                                />
                            </div>
                            <div className="buttonForVideoCall backColOffWhite borderRadius50 verticallyAlignMid cursorPointer">
                                <BsChatFill
                                    className="colLinksAndTitlesBlack"
                                    size="1.7em"
                                />
                            </div>
                            <div className="buttonForVideoCall backColGrayOpacity2 borderRadius50 verticallyAlignMid cursorPointer">
                                <BsMicFill
                                    className="colOffWhite"
                                    size="1.7em"
                                />
                            </div>
                            <div className="buttonForVideoCall backColGrayOpacity2 borderRadius50 verticallyAlignMid cursorPointer">
                                <BsThreeDots
                                    className="colOffWhite"
                                    size="1.7em"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="VideoCallTextElement">
                    <div className="boxShadowForSearchLanding verticallyAlignMidJustConStart heightForHeadMessages">
                        <div className="verticallyAlignMidJustConStart borderRadius50 mRight12 mLeft20">
                            <img
                                alt=""
                                className="messageImg"
                                src="https://i.ibb.co/fD1yxzd/img.png"
                            />
                        </div>
                        <p className="P1medium"></p>
                        {/* <button className="verticallyAlignMid mRight40 w180 borderRadius12 borderNone Pregular colOffWhite backgroundGradientForButtons pY10 mLeftAuto">Send Offer</button> */}
                    </div>
                    <div className="messagesMainSubb2Subb1 posRel backColOffWhite pY20">
                        <div className="verticallyAlignMidJustConEnd mRight10">
                            <div className="textAlignRight">
                                <div className="messagesLeftSubb backColPrimaryGreen borderRadiusForChatOutputRight mBottom10">
                                    <p className="pX10 pY6 Small-Label-cards textAlignRight colOffWhite">
                                        Something
                                    </p>
                                </div>
                            </div>
                            <div className="verticallyAlignMidJustConStart reviewsCardImgMainChat borderRadius50 mLeft12">
                                <img
                                    alt=""
                                    className="reviewsCardImg"
                                    src="https://i.ibb.co/fD1yxzd/img.png"
                                />
                            </div>
                        </div>
                        <div className="verticallyAlignMidJustConStart mLeft10">
                            <div className="verticallyAlignMidJustConStart reviewsCardImgMainChat borderRadius50 mRight12">
                                <img
                                    alt=""
                                    className="reviewsCardImg"
                                    src="https://i.ibb.co/fD1yxzd/img.png"
                                />
                            </div>
                            <div className="textAlignLeft">
                                <div className="messagesLeftSubb backColInput borderRadiusForChatOutputLeft mBottom10">
                                    <p className="pX10 pY6 Small-Label-cards textAlignLeft">
                                        Something
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="inputChatBoxMain backColPrimaryLight posAbs w100">
                            <input
                                placeholder="Type a message"
                                className="inputChatBoxMainInput Small-Label-cards borderRadius12 boxShadowForSearchLanding borderNone outlineNone pY10 mY20 pX10"
                            />
                            <button
                                style={{
                                    color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                    backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                }}
                                className="verticallyAlignMid borderRadius12 borderNone Pregular colOffWhite mBottom20 pY10 pX30 w90 mXAuto"
                            >
                                Send Message
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getData(state) {
    return {
        company: state.auth.authData.company,
        user: state.auth.authData.data.user,
    };
}

export default connect(getData)(VideoCall);
