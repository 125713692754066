import UpcomingPastCancelledNav from "../../components/UpcomingPastCancelledNav/UpcomingPastCancelledNav";
import ViewRescheduleSessionCardType2 from "../../components/ViewRescheduleSessionCardType2/ViewRescheduleSessionCardType2";
import { RiSearchLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getSessions } from "../../api/index.js";
import { connect } from "react-redux";
import Moment from "moment";
// import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import { Datepicker } from "@mobiscroll/react";
import { FaRegCalendarMinus } from "react-icons/fa";
import CalendarPopup from "../../components/CalendarPopup/CalendarPopup";
import "./../../index.scss";

function Schedule(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const company = props.company;

    if (!company._id || !props.user._id) {
        navigate("/LogIn");
    }

    const [allDates, setAllDates]           = useState(true);
    const [type, setType]                   = useState(location?.state?.type || "");
    const [showCalendar, setShowCalendar]   = useState(false);
    const [selectedDate, setSelectedDate]   = useState("");

    const [sessions, setSessions]           = useState({});
    const [marked, setMarked]               = useState([]);

    useEffect(() => {
        const initialState = {
            today       : [],
            yesterday   : [],
            other       : {},
        };

        async function getResults() {
            let status  = "scheduled";
            let sort    = "date";

            if (!type || type === "Upcoming" || type === "Scheduled") {
                status  = "scheduled";
                sort    = "date";
            } else if (type === "Cancelled") {
                status  = "cancelled";
                sort    = "-date";
            } else if (type === "Past" || type === "Completed") {
                status  = "completed";
                sort    = "-date";
            }

            let queryString = "";

            if (props.user.role === "mentor") {
                queryString = `?populate=serviceID,clientID&mentorID=${props.user._id}&status=${status}&sort=${sort}&isPayed=true`;
                // queryString = `?populate=serviceID,clientID&mentorID=${props.user._id}&status=${status}&sort=${sort}`;
            } else {
                queryString = `?populate=serviceID,mentorID&clientID=${props.user._id}&status=${status}&sort=${sort}&isPayed=true`;
                // queryString = `?populate=serviceID,mentorID&clientID=${props.user._id}&status=${status}&sort=${sort}`;
            }

            const { data } = await getSessions(queryString);
            let obj = initialState;

            console.log(data)

            let dateToday       = Moment(new Date()).format("YYYY/MM/DD");
            let dateYesterday   = Moment(new Date())
                .subtract(1, "day")
                .format("YYYY/MM/DD");

            let array = [];

            data.data.sessions &&
                data.data.sessions.forEach((el) => {

                    let today       = Moment().utc().format();
                    let difference  = Moment(today).diff(Moment(el.date))

                    // if the difference between dates is greater than 0, that means that session is in the past
                    // console.log(difference)

                    if (!type || type === "Upcoming" || type === "Scheduled") {
                        if (difference > 0) {
                            return;
                        }
                    }

                    let date = Moment(el.date).format("LLL");

                    array.push({
                        date: new Date(
                            Moment(date).format("YYYY"),
                            Moment(date).format("M") - 1,
                            Moment(date).format("D")
                        ),
                        color: "#155c6b",
                    });

                    date = Moment(date).format("YYYY/MM/DD");

                    if (selectedDate !== "") {
                        let localDate =
                            Moment(selectedDate).format("YYYY/MM/DD");

                        if (date !== localDate) {
                            return;
                        }
                    }

                    if (date === dateToday) {
                        obj.today.push(el);
                    } else if (date === dateYesterday) {
                        obj.yesterday.push(el);
                    } else {
                        date = Moment(date).format("DD MMM");

                        if (obj.other[date]) {
                            obj.other[date].push(el);
                        } else {
                            obj.other[date] = [];
                            obj.other[date].push(el);
                        }
                    }
                });

            setMarked(array);

            setSessions((prevState) => obj);
            console.log(obj)
            // setSessions(prevState => ({...prevState, obj}));
        }
        getResults();
    }, [props, type, selectedDate, allDates]);

    function goToSessionSingleView(id, sessionType) {
        navigate("/OnboardingMentor11ClientSingleView", {
            state: {
                id,
                type: sessionType,
            },
        });
    }

    function changeBackground(e, mouse) {
        if (mouse === "out") {
            e.target.style.background = "none";
        } else if (mouse === "over") {
            e.target.style.background = "#3e8b9c";
        }
    }

    function closeBigCalendar(e) {
        if (
            e.target.classList.length > 0 &&
            e.target.classList[0] === "filterMentorPopupWrapper"
        ) {
            setShowCalendar(false);
        } else {
            return false;
        }
    }

    return (
        <div className="Schedule">
            {showCalendar && (
                <div
                    onClick={closeBigCalendar}
                    className="filterMentorPopupWrapper verticallyAlignMid"
                >
                    <CalendarPopup
                        parentEventShow={setShowCalendar}
                        parentEventDate={setSelectedDate}
                        date={selectedDate}
                        marked={marked}
                        type={type}
                        className=""
                    />
                </div>
            )}
            <div className="mTop70 mBottom70 w80 mXAuto scheduleSubbMain">
                <h1 className="H1regular textAlignLeft mBottom20 h1Schedule">
                    Schedule
                </h1>
                <div className="w40 mLeftAuto posRel calendarMain1">
                    {allDates ? (
                        <button
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            className="posAbs calendarButton right0 py10 pX20 borderRadius4 Pregular borderNone outlineNone"
                            onClick={() => {
                                setAllDates(false);
                                setSelectedDate(new Date());
                            }}
                        >
                            Calendar
                        </button>
                    ) : (
                        <>
                            <button
                                style={{
                                    color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                    backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                }}
                                className="posAbs right15 py10 pX20 borderRadius4 Pregular borderNone outlineNone"
                                onClick={() => {
                                    setAllDates(true);
                                    setSelectedDate("");
                                }}
                            >
                                All Dates
                            </button>
                            <div
                                onMouseLeave={(e) => changeBackground(e, "out")}
                                onMouseEnter={(e) =>
                                    changeBackground(e, "over")
                                }
                                className="calendarIconHover verticallyAlignMid textAlignCenter cursorPointer mBottom10 mLeftAuto w10 pTop10 pBottom10 borderRadius24 borderPrimaryGreen"
                            >
                                <FaRegCalendarMinus
                                    onClick={(e) => setShowCalendar(true)}
                                    size="1.2em"
                                    className="colLinksAndTitlesBlack"
                                />
                            </div>
                            <Datepicker
                                onChange={(e) => setSelectedDate(e.value)}
                                value={selectedDate}
                                controls={["calendar"]}
                                theme={"ios"}
                                display="inline"
                                calendarType="week"
                                calendarSize={1}
                                className="w100"
                                // showControls={false}
                                min={
                                    !type ||
                                    type === "Upcoming" ||
                                    type === "Scheduled"
                                        ? Moment().format("MM/DD/YYYY")
                                        : ""
                                }
                                max={
                                    type === "Past" || type === "Completed"
                                        ? Moment().format("MM/DD/YYYY")
                                        : ""
                                }
                                marked={marked}
                            />
                        </>
                    )}
                </div>

                <form className="scheduleMainSearch verticallyAlignMid w400 borderRadius24 backColElementBackgroundGray mBottom20">
                    <button
                        type="submit"
                        className="searchButton backColTransparent outlineNone borderNone"
                    >
                        <RiSearchLine
                            size="1.4em"
                            className="searchButtonOjectFit colPrimaryGreen mY14 mX4"
                        />
                    </button>
                    <input
                        type="search"
                        placeholder="Search"
                        className="Pmedium searchField mY14 w100 borderNone scheduleSearchForm outlineNone backColElementBackgroundGray"
                    />
                </form>
                <div className="verticallyAlignMidSpaceBetween w100 mBottom10">
                    <UpcomingPastCancelledNav
                        type={type}
                        parentEvent={setType}
                    />
                </div>

                {sessions.today && sessions.today.length > 0 && (
                    <h5 className="Pmedium textAlignLeft pTop10 w100">TODAY</h5>
                )}

                <div className="verticallyAlignMidJustConStart flexWrap">
                    {sessions.today &&
                        sessions.today.length > 0 &&
                        sessions.today.map((el) => {
                            return (
                                <ViewRescheduleSessionCardType2
                                    user={props.user}
                                    key={el._id}
                                    session={el}
                                    type={type}
                                    parentEvent={goToSessionSingleView}
                                />
                            );
                        })}
                </div>

                {sessions.yesterday && sessions.yesterday.length > 0 && (
                    <h5 className="Pmedium textAlignLeft pTop10">YESTERDAY</h5>
                )}

                <div className="verticallyAlignMidJustConStart flexWrap">
                    {sessions.yesterday &&
                        sessions.yesterday.length > 0 &&
                        sessions.yesterday.map((el) => {
                            return (
                                <ViewRescheduleSessionCardType2
                                    user={props.user}
                                    key={el._id}
                                    session={el}
                                    type={type}
                                    parentEvent={goToSessionSingleView}
                                />
                            );
                        })}
                </div>

                {sessions.other &&
                    Object.keys(sessions.other).length > 0 &&
                    Object.keys(sessions?.other).map((otherDate, i) => {
                        let html = sessions.other[otherDate].map((el) => (
                            <ViewRescheduleSessionCardType2
                                user={props.user}
                                key={el._id}
                                session={el}
                                type={type}
                                parentEvent={goToSessionSingleView}
                            />
                        ));

                        return (
                            <React.Fragment key={i}>
                                <h5 className="Pmedium textAlignLeft pTop10">
                                    {otherDate}
                                </h5>
                                <div
                                    className="verticallyAlignMidJustConStart flexWrap"
                                    key={i}
                                >
                                    {html}
                                </div>
                            </React.Fragment>
                        );
                    })}
                {/* </div> */}
            </div>
        </div>
    );
}

function getUserData(state) {
    return {
        user: state.auth.authData?.data.user || {},
        company: state.auth.authData?.company,
    };
}

export default connect(getUserData)(Schedule);
