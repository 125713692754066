// import CloseButton from '../../components/buttons/CloseButton/CloseButton';
// import TextareaComponent from '../../components/TextareaComponent/TextareaComponent';
import { BsCalendar2Minus, BsHouse } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

function ThankYouAfterSessionPopUp(props, { user }) {
    const company = props.data.company;

    const navigate = useNavigate();

    function goToSchedule() {
        navigate("/Schedule");
    }

    function goToProfile() {
        navigate("/WhatKindOfMentor");
    }

    return (
        <div className="thankYouAfterSessionPopUp">
            <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                <div className="w90 mXAuto">
                    <h2 className="textAlignCenter H2medium mTop70 mBottom40">
                        Thank you,{" "}
                        <span>{props.user.fullName.split(" ")[0]}!</span>
                    </h2>
                    <p className="P1medium textAlignCenter">
                        Hope you had a satisfactory{" "}
                        {company.whiteLabel.texts.label4SessionSingle}.
                    </p>
                    <p className="P1medium textAlignCenter mBottom40">
                        What would you like to do next?
                    </p>
                    <div className="verticallyAlignMid mBottom70 mXAuto w440 hopeYouHadSatisfactorySub">
                        <button
                            onClick={goToSchedule}
                            className="verticallyAlignMid borderRadius12 w240 borderPrimaryGreen Pmedium colPrimaryGreen backColOffWhite mRight20 pY10"
                        >
                            <BsCalendar2Minus
                                size="1.4em"
                                className="colPrimaryGreen mRight10"
                            />
                            View Schedule
                        </button>
                        <button
                            onClick={goToProfile}
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            className="verticallyAlignMid borderRadius12 w240 borderNone Pregular colOffWhite pY10"
                        >
                            <BsHouse
                                size="1.4em"
                                className="colOffWhite mRight10"
                            />
                            Go Home
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getUser(state) {
    return {
        data: state.auth.authData,
    };
}

export default connect(getUser)(ThankYouAfterSessionPopUp);
