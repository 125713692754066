import CloseButton from "./../buttons/CloseButton/CloseButton";
import TextareaComponent from "./../TextareaComponent/TextareaComponent";
import { RiArrowDownSLine } from "react-icons/ri";
import { useEffect, useRef, useState } from "react";
import { getSessions, updateNote } from "../../api";
import { createNote } from "../../api";
import { connect } from "react-redux";

function NewNoteSession({
  user,
  parentEvent,
  parentEventTwo,
  parentSetValue,
  edit,
  companyData,
}) {
  const [text, setText] = useState(edit ? edit.text : "");
  const [header, setHeader] = useState(edit ? edit.header : "");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(
    edit?.assignedID ? edit.assignedID._id : ""
  );
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(
    edit?.serviceID ? edit.serviceID._id : ""
  );

  const company = companyData.company;

  const userRef = useRef(null);
  const serviceRef = useRef(null);

  useEffect(() => {
    async function getResult() {
      let queryString = "?populate=mentorID,clientID,serviceID";

      if (user.role === "mentor") {
        queryString += `&mentorID=${user._id}`;
      } else {
        queryString += `&clientID=${user._id}`;
      }

      const { data } = await getSessions(queryString);

      let objUsers = {};
      let objServices = {};

      data.data.sessions.length > 0 &&
        data.data.sessions.forEach((el) => {
          if (user.role === "mentor") {
            if (!objUsers[el.clientID._id]) {
              objUsers[el.clientID._id] = {};
              objUsers[el.clientID._id] = el.clientID;
            }
          } else {
            if (!objUsers[el.mentorID._id]) {
              objUsers[el.mentorID._id] = {};
              objUsers[el.mentorID._id] = el.mentorID;
            }
          }

          if (!objServices[el.serviceID._id]) {
            objServices[el.serviceID._id] = {};
            objServices[el.serviceID._id] = el.serviceID;
          }
        });

      setUsers(objUsers);
      setServices(objServices);
    }

    getResult();
  }, [user._id, user.role]);

  async function handleSubmit() {
    let obj = {
      userID: user._id,
      header,
      text,
    };

    if (selectedUser !== "") {
      obj["assignedID"] = selectedUser;
    } else {
      obj["assignedID"] = null;
    }

    if (selectedService !== "") {
      obj["serviceID"] = selectedService;
    } else {
      obj["serviceID"] = null;
    }

    if (edit) {
      await updateNote(edit._id, obj);
      parentEventTwo(false);
    } else {
      await createNote(obj);
      parentEvent(false);
    }

    parentSetValue((value) => value + 1);
  }

  return (
    <div className="w90 mXAuto">
      <div className="verticallyAlignMid mTop40">
        <h2 className="mRightAuto H3medium">New Note</h2>
        <CloseButton parentEvent={edit ? parentEventTwo : parentEvent} />
      </div>
      <div className="mY30">
        <p className="P1medium textAlignLeft mBottom12">Header</p>
        <input
          type="text"
          defaultValue={edit ? edit.header : ""}
          className="borderNone Pregular pX4 pY6 w100 borderRadius12 boxShadowForSearchLanding verticallyAlignMidJustConStart"
          onChange={(e) => setHeader(e.target.value)}
        />
      </div>
      <div className="mY30">
        <p className="P1medium textAlignLeft mBottom12">Note</p>
        <TextareaComponent
          defValue={edit ? edit.text : ""}
          parentEvent={setText}
        />
      </div>
      <div className="mBottom20">
        <div className="mBottom20">
          <h5 className="P1bold textAlignLeft mBottom8">Assign Client</h5>
          <div className="posRel verticallyAlignMidJustConStart">
            <select
              ref={userRef}
              onChange={(e) => setSelectedUser(e.target.value)}
              className="Pregular w100 pLeft8 textAlignLeft appearanceNone borderNone backColInput pY14 borderRadius12"
            >
              {user.role === "mentor" ? (
                <option value="">Select Client</option>
              ) : (
                <option value="">Select Mentor</option>
              )}

              {users &&
                Object.keys(users).map((el, i) => {
                  return (
                    <option key={i} value={el}>
                      {users[el].fullName}
                    </option>
                  );
                })}
            </select>

            {(() => {
              if (userRef.current) {
                userRef.current.value = selectedUser;
              }
            })()}

            <span className="posAbs spanForArrow verticallyAlignMid backColInput borderRadiusSpanSelect">
              <RiArrowDownSLine
                size="1.5em"
                className="mRight10 colLabelGray"
              />
            </span>
          </div>
        </div>
        <div className="selectForNewNotesPopUpMainSubb2">
          <h5 className="P1bold textAlignLeft mBottom8">Session Type</h5>
          <div className="posRel selectForNewNotesPopUpMainSubb1">
            <select
              ref={serviceRef}
              value={selectedService}
              disabled={selectedUser === "" ? true : false}
              onChange={(e) => setSelectedService(e.target.value)}
              className="Pregular w100 pLeft8 appearanceNone textAlignLeft borderNone backColInput pY14 borderRadius12"
            >
              <option value="">Select Service</option>
              {services &&
                Object.keys(services).forEach((el, i) => {
                  if (services[el].mentorID !== selectedUser) {
                    return;
                  }
                  return (
                    <option key={i} value={el}>
                      {services[el].name}
                    </option>
                  );
                })}
            </select>

            {(() => {
              if (serviceRef.current) {
                serviceRef.current.value = selectedService;
              }
            })()}

            <span className="posAbs spanForArrow verticallyAlignMid backColInput borderRadiusSpanSelect">
              <RiArrowDownSLine
                size="1.5em"
                className="mRight10 colLabelGray"
              />
            </span>
          </div>
        </div>
      </div>
      {edit ? (
        <button
          style={{
            color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
            backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
          }}
          onClick={handleSubmit}
          className="verticallyAlignMid w180 borderRadius12 mBottom40 borderNone Pregular colOffWhite pY10 mLeftAuto"
        >
          Update Note
        </button>
      ) : (
        <button
          style={{
            color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
            backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
          }}
          onClick={handleSubmit}
          className="verticallyAlignMid w180 borderRadius12 mBottom40 borderNone Pregular colOffWhite pY10 mLeftAuto"
        >
          Add Note
        </button>
      )}
    </div>
  );
}

function getData(state) {
  return {
    companyData: state.auth.authData,
  };
}

export default connect(getData)(NewNoteSession);
