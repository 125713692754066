import { GrClose } from "react-icons/gr";

const CloseButton = ({parentEvent}) => {
   return (
      	<div onClick={() => parentEvent(false)} className="mLeftAuto borderRadius50 backColElementBackgroundGray verticallyAlignMid forCloseButton cursorPointer">
			<GrClose size="1em" />
		</div>
   );
}

export default CloseButton;
