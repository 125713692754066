import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMessages } from "../../api";
import moment from 'moment';

import { RiArrowLeftSLine } from "react-icons/ri";

function MessageChatComponent(props) {
  const company = props.companyData.company;

  const [userMessages, setUserMessages] = useState([]);
  const [sessionName, setSessionName] = useState("");

  const messagesEndRef = useRef(null);
  // const scrollToBottom = (ref) => window.scrollTo(0, messagesEndRef.current.offsetTop)
  // const scrollToBottom = (ref) => messagesEndRef.current.scrollTo(0, messagesEndRef.current.scrollTop)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };
  // useEffect(() => {
  //     scrollToBottom()
  // }, [userMessages]);

  useEffect(() => {
    async function getResults() {
      // let queryString = `?fromID=${props.user._id}&fromID=${props.messages.id}&toID=${props.user._id}&toID=${props.messages.id}`;
      let queryString = `?filter={"$or":[{"fromID":"${props.user._id}","toID":"${props.messages[0]._id}"},{"toID":"${props.user._id}","fromID":"${props.messages[0]._id}"}]}`;
      queryString += `&populate=fromID,toID,typeID&sort=createdAt`; // props.user._id

      const { data } = await getMessages(queryString);

      let arrayOfMessages = {};
      let index = 0;

      for (let i = 0; i < data.data.messages.length; i++) {
        if (
          data.data.messages[i - 1]?.fromID?._id ||
          data.data.messages[i - 1]?.toID?._id
        ) {
          if (
            data.data.messages[i - 1].fromID._id ===
            data.data.messages[i].fromID._id
          ) {
            // Isti user je poslao poruku
            arrayOfMessages[index].push(data.data.messages[i]);
          } else {
            // drugi user je poslao potuku
            arrayOfMessages[index + 1] = [];
            arrayOfMessages[index + 1].push(data.data.messages[i]);

            index++;
          }
        } else {
          arrayOfMessages[index] = [];
          arrayOfMessages[index].push(data.data.messages[i]);
        }
      }

      setUserMessages(arrayOfMessages);
      scrollToBottom();
      console.log(company);
      setSessionName(company.whiteLabel.texts.label4SessionSingle);
    }
    getResults();
  }, [props]);

  const messageRef = useRef("");

  function handleEnter(e) {
    if (e.key === "Enter") {
      e.preventDefault();

      if (messageRef.current.value === "") {
        return false;
      }

      props.parentEvent({
        fromID: props.user._id,
        toID: props.messages[0]._id,
        text: messageRef.current.value,
        type: "Message",
      });
      messageRef.current.value = "";
    }
  }

  function handleBackToUsers() {
    props.backToUsers(false);
  }

  const navigate = useNavigate();

  function goToSessionPage(id, type = "") {
    navigate("/OnboardingMentor11ClientSingleView", {
      state: {
        id,
        // type
      },
    });
  }

  function getInitials(name) {
    let words = name.split(" ");
    return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase();
  }

  const [windowWidth, setWindowWidth] = useState(0);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return (
    <div className="messagesMainSubb2 flexDirectionColumn w60 posRel">
      <div className="boxShadowForSearchLanding verticallyAlignMidJustConStart heightForHeadMessages">
        <div className="pRight1 mRight12 mLeft20">
          <div className="verticallyAlignMidJustConStart messageImg borderRadius50">
            {props.messages[0]?.photo ? (
              <img alt="" className="w100 h100" src={props.messages[0].photo} />
            ) : (
              <div className="userInitialsMedium">
                <p>{getInitials(props.messages[0].fullName)}</p>
              </div>
            )}
          </div>
        </div>
        <p className="P1medium">{props.messages[0].fullName}</p>
        {windowWidth <= 900 &&
          <div className="posRel mRight20 mLeftAuto messagesMainSubb2Button verticallyAlignMid">
            <button
              onClick={handleBackToUsers}
              className="borderRadius50 backButtonForMobileMessages verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding"
              >
              <RiArrowLeftSLine size="2em" className="colPrimaryGreen" />
            </button>
          </div>
        }
      </div>
      <div className="messagesMainSubb2Subb1 backColOffWhite pTop20">
        <div className="bothConversationsChat">
          {Object.keys(userMessages).length > 0 &&
            Object.keys(userMessages).map((el, i) => {
              if (userMessages[el][0].fromID._id === props.user._id) {
                let html = userMessages[el].map((mess) => {
                  if (mess.type === "Session") {
                    let text = "";

                    if (mess.subType === "cancelled") {
                      text = "You have cancelled your "+ sessionName + " with " + mess.toID.fullName;
                    }

                    if (mess.subType === "booked") {
                      text = "You have booked your "+ sessionName + " with " + mess.toID.fullName;
                    }

                    if (mess.subType === "rescheduled") {
                      text = "You have rescheduled your "+ sessionName + " with " + mess.toID.fullName;
                    }

                    return (
                      <div
                        key={mess._id}
                        className="mLeftAuto textAlignRight sessionMessageWrapper borderRadius12 backColPrimaryLight mBottom10 pBottom10"
                      >
                        <div className="w90 mXAuto">
                          <div className="verticallyAlignMidJustConStart pY10 headOfMessageCard">
                            <div className="pRight1 mLeft8 mRight8">
                              <div className="verticallyAlignMidJustConStart reviewsCardImgMainChat borderRadius50">
                                {userMessages[el][0].fromID?.photo ? (
                                  <img
                                    alt=""
                                    className="w100 h100"
                                    src={userMessages[el][0].fromID.photo}
                                  />
                                ) : (
                                  <div className="userInitialsSmall">
                                    <p>
                                      {getInitials(
                                        userMessages[el][0].fromID.fullName
                                      )}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <h3 className="textAlignLeft">{text}</h3>
                          </div>
                          <div className="verticallyAlignMidJustConStart flexWrap">
                            <div className="mRight12">
                              <p className="textAlignLeft colLabelGray Small-Labelnormal">
                                Service
                              </p>
                              <p className="Pbold textAlignLeft">Session</p>
                            </div>
                            <div className="mRight12">
                              <p className="textAlignLeft colLabelGray Small-Labelnormal">
                                Date
                              </p>
                              <p className="Pbold textAlignLeft">{moment(mess.createdAt).format("MMM Do YY")}</p>
                            </div>
                            <div className="">
                              <p className="textAlignLeft colLabelGray Small-Labelnormal">
                                Time
                              </p>
                              <p className="Pbold textAlignLeft">{moment(mess.createdAt).format("hh:mm A")}</p>
                            </div>
                          </div>
                          <div className="textAlignCenter pY10">
                            <p className="Pbold textAlignLeft">{mess.text}</p>
                          </div>
                          <button
                            style={{
                              color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                              backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            onClick={() => goToSessionPage(mess.typeID._id)}
                            className="verticallyAlignMid borderRadius12 w100 Pmedium borderNone colOffWhite pY10"
                          >
                            View Session
                          </button>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={mess._id} className="textAlignRight">
                        <div className="messagesLeftSubb backColPrimaryGreen borderRadiusForChatOutputRight chatBoxClientMain mBottom10">
                          <p className="pX10 pY6 Small-Label-cards textAlignLeft colOffWhite breakWord">
                            {mess.text}
                          </p>
                        </div>
                      </div>
                    );
                  }
                });

                return (
                  <div key={i} className="messagesMainSubb2Subb1Right">
                    <div className="messagesMainSubb2LeftAndRight verticallyAlignMidStretchEnd">
                      <div className="messagesLeftMain">{html}</div>
                      <div className="pLeft1 mLeft8 mRight8">
                        <div className="verticallyAlignMidJustConStart reviewsCardImgMainChat borderRadius50">
                          {userMessages[el][0].fromID?.photo ? (
                            <img
                              alt=""
                              className="w100 h100"
                              src={userMessages[el][0].fromID.photo}
                            />
                          ) : (
                            <div className="userInitialsSmall">
                              <p>
                                {getInitials(
                                  userMessages[el][0].fromID.fullName
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                let html = userMessages[el].map((mess) => {
                  if (mess.type === "Session") {
                    let text = "";
                    if (mess.subType === "cancelled") {
                      text = "User has cancelled your " + sessionName;
                    }

                    if (mess.subType === "booked") {
                      text = "User has booked "+ sessionName + " with you";
                    }

                    if (mess.subType === "rescheduled") {
                      text = "User has rescheduled your " + sessionName;
                    }

                    // console.log(mess);

                    return (
                      <div
                        key={mess._id}
                        className="textAlignRight sessionMessageWrapper borderRadius12 backColPrimaryLight mBottom10 pBottom10"
                      >
                        <div className="w90 mXAuto">
                          <div className="verticallyAlignMidJustConStart pY10 headOfMessageCard">
                            <div className="pRight1 mLeft8 mRight8">
                              <div className="verticallyAlignMidJustConStart reviewsCardImgMainChat borderRadius50">
                                {props.messages[0]?.photo ? (
                                  <img
                                    alt=""
                                    className="w100 h100"
                                    src={props.messages[0].photo}
                                  />
                                ) : (
                                  <div className="userInitialsSmall">
                                    <p>
                                      {/* {(mess.fromID._id === props.user._id ?
                                                                        (getInitials(mess.fromID.fullName))
                                                                    :
                                                                        (getInitials(mess.toID.fullName))
                                                                    )} */}
                                      {getInitials(props.messages[0].fullName)}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <h3 className="textAlignLeft">
                              {text}
                            </h3>
                          </div>
                          <div className="verticallyAlignMidJustConStart flexWrap">
                            <div className="mRight12">
                              <p className="textAlignLeft colLabelGray Small-Labelnormal">
                                Service
                              </p>
                              <p className="Pbold textAlignLeft">Session</p>
                            </div>
                            <div className="mRight12">
                              <p className="textAlignLeft colLabelGray Small-Labelnormal">
                                Date
                              </p>
                              <p className="Pbold textAlignLeft">{moment(mess.createdAt).format("MMM Do YY")}</p>
                            </div>
                            <div className="">
                              <p className="textAlignLeft colLabelGray Small-Labelnormal">
                                Time
                              </p>
                              <p className="Pbold textAlignLeft">{moment(mess.createdAt).format("hh:mm A")}</p>
                            </div>
                          </div>
                          <div className="textAlignCenter pY10">
                            <p className="Pbold textAlignLeft">{mess.text}</p>
                          </div>
                          <button
                            style={{
                              color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                              backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            onClick={() => goToSessionPage(mess.typeID._id)}
                            className="verticallyAlignMid borderRadius12 w100 Pmedium borderNone colOffWhite pY10"
                          >
                            View Session
                          </button>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={mess._id} className="textAlignLeft">
                        <div className="messagesLeftSubb backColInput borderRadiusForChatOutputLeft mBottom10">
                          <p className="pX10 pY6 Small-Label-cards textAlignLeft breakWord">
                            {mess.text}
                          </p>
                        </div>
                      </div>
                    );
                  }
                });

                return (
                  <div
                    key={i}
                    className="messagesMainSubb2LeftAndRight mLeft0 mRightAuto"
                  >
                    <div className="messagesMainSubb2Subb1Left1 verticallyAlignMidStretchStart">
                      <div className="pRight1 mLeft8 mRight8">
                        <div className="verticallyAlignMidJustConStart reviewsCardImgMainChat borderRadius50">
                          {props.messages[0]?.photo ? (
                            <img
                              alt=""
                              className="w100 h100"
                              src={props.messages[0].photo}
                            />
                          ) : (
                            <div className="userInitialsSmall">
                              <p>{getInitials(props.messages[0].fullName)}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="messagesLeftMain">{html}</div>
                    </div>
                  </div>
                );
              }
            })}
          <div ref={messagesEndRef}> </div>
        </div>
      </div>
      <div className="inputChatBoxMain backColPrimaryLight verticallyAlignMid w100">
        {/* <input onKeyDown={handleEnter} ref={messageRef} placeholder="Type a message" className="inputChatBoxMainInput Small-Label-cards borderRadius12 boxShadowForSearchLanding borderNone outlineNone pY10 mY20 pX10" /> */}
        <textarea
          onKeyDown={handleEnter}
          ref={messageRef}
          placeholder="Type a message"
          className="inputChatBoxMainInput Small-Label-cards borderRadius12 boxShadowForSearchLanding borderNone outlineNone pY10 mY20 pX10"
        ></textarea>
      </div>
    </div>
  );
}

function getUserData(state) {
  return {
    user: state.auth?.authData.data.user || {},
    companyData: state.auth?.authData,
  };
}

export default connect(getUserData)(MessageChatComponent);
