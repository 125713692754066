import { AUTH, COMPANY } from "../constants/actionTypes";
import * as api from "../api/index.js";
// import { RiContactsBookLine } from 'react-icons/ri';

export const signin = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.signIn(formData);

    // console.log(data);

    dispatch({ type: AUTH, data });

    const user = data.data.user;

    // console.log(user.role);

    if (user.role === "mentor") {
      if (user.mentorOnboarding.acceptedMentor) {
        if (user.mentorOnboarding.goToMentorAcceptancePage) {
          navigate("/OnboardingMentor6");
        } else if (!user.mentorOnboarding.firstServiceCompleted) {
          navigate("/OnboardingMentor7CreateYourFirstSession");
        } else if (!user.mentorOnboarding.availabilityCompleted) {
          navigate("/OnboardingMentor8SetAvailability");
        } else if (!user.mentorOnboarding.paymentDetailsCompleted) {
          navigate("/OnboardingMentor9PayoutMethod");
        } else {
          navigate("/MentorHome2SettingsPanel", {
            state: {
              logIn: true,
            },
          });
        }
      } else {
        if (user.mentorOnboarding.onboardingCompleted) {
          //Where should be this user navigated (until he is accepted?)
          navigate("/HowMentorXWorks");
        } else {
          navigate("/MOMentorsApply");
        }
      }
      navigate("/MOMentorsApply");
    } else if (user.role === "client") {
      // console.log('first navigate')

      navigate("/ClientSelectCategories", {
        state: {
          logIn: true,
        },
      });
    } else if (user.role === "admin") {
      // console.log("Admin loged in");
      navigate("/Admin");
    } else if (user.role === "owner" || user.role === "director") {
      navigate("/Owner");
    }
  } catch (error) {
    // console.log(error);
    return error;
  }
};

export const signup = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.signUp(formData);

    if (data.social) {
      try {
        const userData = await api.signIn(formData);

        dispatch({ type: AUTH, data: userData.data });

        const user = userData.data.data.user;

        if (user.role === "mentor") {
          if (user.mentorOnboarding.acceptedMentor) {
            if (user.mentorOnboarding.goToMentorAcceptancePage) {
              navigate("/OnboardingMentor6");
            } else if (!user.mentorOnboarding.firstServiceCompleted) {
              navigate("/OnboardingMentor7CreateYourFirstSession");
            } else if (!user.mentorOnboarding.availabilityCompleted) {
              navigate("/OnboardingMentor8SetAvailability");
            } else if (!user.mentorOnboarding.paymentDetailsCompleted) {
              navigate("/OnboardingMentor9PayoutMethod");
            } else {
              navigate("/MentorHome2SettingsPanel", {
                state: {
                  logIn: true,
                },
              });
            }
          } else {
            if (user.mentorOnboarding.onboardingCompleted) {
              //Where should be this user navigated (until he is accepted?)
              navigate("/HowMentorXWorks");
            } else {
              navigate("/MOMentorsApply");
            }
          }
          navigate("/MOMentorsApply");
        } else if (user.role === "client") {
          // console.log('first navigate')

          navigate("/ClientSelectCategories", {
            state: {
              logIn: true,
            },
          });
        } else if (user.role === "admin") {
          // console.log("Admin loged in");
          navigate("/Admin");
        } else if (user.role === "owner" || user.role === "director") {
          navigate("/Owner");
        }
      } catch (error) {
        // console.log(error);
        return error;
      }
    } else {
      dispatch({ type: AUTH, data });
      navigate("/LogInEmailConfirmation");
    }
  } catch (error) {
    return error;
  }
};

export const setpassword = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.setPassword(formData);

    dispatch({ type: AUTH, data });

    if (formData.role === "mentor") {
      navigate("/MOMentorsApply");
    } else if (formData.role === "client") {
      navigate("/ClientSelectCategories");
    } else {
      navigate("/Role");
    }
  } catch (error) {
    return error;
  }
};

export const updatePassword = (formData) => async (dispatch) => {
  try {
    const { data } = await api.updatePassword(formData);
    dispatch({ type: AUTH, data });
  } catch (error) {
    return error;
  }
};

export const saveResetedPassword = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.saveResetedPassword(formData);

    dispatch({ type: AUTH, data });

    if (formData.role === "mentor") {
      navigate("/MOMentorsApply");
    } else if (formData.role === "client") {
      navigate("/ClientSelectCategories");
    } else {
      navigate("/Role");
    }
  } catch (error) {
    // console.log(error);
    // console.log(error.response.status);
  }
};

export const uploadCV = (id, formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.uploadCV(id, formData);

    dispatch({ type: AUTH, data });
    navigate("/MOWorkExperience");
  } catch (error) {
    // console.log(error);
  }
};

export const mentorOnboarding = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.mentorOnboarding(formData);

    dispatch({ type: AUTH, data });

    // console.log(data.data);
    // console.log(formData);

    if (formData.setUserRole) {
      if (formData.role === "mentor") {
        navigate("/MOMentorsApply");
      } else {
        navigate("/ClientSelectCategories");
      }
    } else if (formData.setMentorsApply) {
      navigate("/MOProfileDetails");
    } else if (formData.setProfileDetails) {
      navigate("/MOYourExperience");
    } else if (formData.setYourExperience) {
      navigate("/MOWorkExperience");
    } else if (formData.setWorkExperience) {
      navigate("/OnboardingMentor5");
    } else if (formData.setThankYouForApplying) {
      navigate("/HowMentorXWorks");
    } else if (formData.setOnboardingComplete) {
      navigate("/OnboardingMentor7CreateYourFirstSession");
    } else if (formData.setFirstService) {
      if (formData.type === "first") {
        navigate("/OnboardingMentor8SetAvailability");
      } else {
        navigate("/OnboardingMentor13Service");
      }
    } else if (formData.setAvailability) {
      if (formData.type === "onboard") {
        // navigate("/OnboardingMentor9PayoutMethod");
        navigate("/OnboardingMentor10"); ///////////////////////////////////
      } else {
        navigate("/MentorHome2SettingsPanel");
      }
    } else if (formData.setPayment) {
      navigate("/OnboardingMentor10");
    } else if (formData.setWelcome) {
      navigate("/MentorHome2SettingsPanel");
    } else if (formData.setProfileDetailsCompletedFalse) {
      navigate("/MOProfileDetails");
    } else if (formData.setYourExperienceFalse) {
      navigate("/MOYourExperience");
    } else if (formData.setAvailabilityFalse) {
      navigate("/OnboardingMentor7CreateYourFirstSession");
    } else {
      navigate("/error!!!!!");
    }
  } catch (error) {
    // console.log(error);
  }
};

export const clientOnboarding = (formData, navigate) => async (dispatch) => {
  try {
    if (formData.charity) {
      formData.setPaymentDetails = true;
    }

    const { data } = await api.clientOnboarding(formData);

    dispatch({ type: AUTH, data });

    // console.log(data);

    if (formData.setSelectCategories) {
      // if (formData.charity) {
      navigate("/WhatKindOfMentor");
      // } else {
      //   navigate("/LogInClientSetYourPaymentDetails");
      // }
    } else if (formData.paymentMethod) {
      navigate("/WhatKindOfMentor");
    } else {
      navigate("/ClientSelectCategories");
    }
  } catch (error) {
    // console.log(error);
  }
};

export const clientBookSession = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.clientBookSession(formData);

    // dispatch({ type: AUTH, data });

    // console.log("client book session", data);

    navigate("/ClientConfirmSessionThankYou", {
      state: {
        id: data._id,
      },
    });
  } catch (error) {
    // console.log(error);
    // console.log(error.response.status);
  }
};

export const forgotPassword = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.sendForgotPasswordEmail(formData);

    // dispatch({ type: AUTH, data });

    navigate("/LogInEmailConfirmation", {
      state: {
        user: data.user,
      },
    });
  } catch (error) {
    // console.log(error);
    // console.log(error.response.status);
    return error;
  }
};

export const userGoogleLogin = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.userGoogleLogin(formData);

    dispatch({ type: AUTH, data: data.data });

    // console.log(data);

    if (data.data.type === "logIn") {
      const user = data.data.data.user;

      if (user.role === "mentor") {
        if (user.mentorOnboarding.acceptedMentor) {
          if (user.mentorOnboarding.goToMentorAcceptancePage) {
            navigate("/OnboardingMentor6");
          } else if (!user.mentorOnboarding.firstServiceCompleted) {
            navigate("/OnboardingMentor7CreateYourFirstSession");
          } else if (!user.mentorOnboarding.availabilityCompleted) {
            navigate("/OnboardingMentor8SetAvailability");
          } else if (!user.mentorOnboarding.paymentDetailsCompleted) {
            navigate("/OnboardingMentor9PayoutMethod");
          } else {
            navigate("/MentorHome2SettingsPanel");
          }
        } else {
          if (user.mentorOnboarding.onboardingCompleted) {
            //Where should be this user navigated (until he is accepted?)
            navigate("/OnboardingMentor6");
          } else {
            navigate("/MOMentorsApply");
          }
        }
        navigate("/MOMentorsApply");
      } else if (user.role === "client") {
        navigate("/ClientSelectCategories");
      }
    } else if (data.data.type === "update") {
      navigate("/Success");
    } else {
      navigate("/Role");
    }
  } catch (error) {
    // console.log(error);
    // console.log(error.response.status);
  }
};

export const companyData = (name) => async (dispatch) => {
  try {
    const { data } = await api.getAllCompanies(`?subdomain=${name}`);

    // console.log(data)

    dispatch({ type: COMPANY, data: data.data.companies[0] });
  } catch (error) {
    // console.log(error);
    // console.log(error.response.status);
  }
};

export const uploadProfilePhoto = (id, obj) => async (dispatch) => {
  try {
    const { data } = await api.uploadProfilePhoto(id, obj);

    dispatch({ type: AUTH, data });
  } catch (error) {
    // console.log(error);
    // console.log(error.response.status);
  }
};

export const updateCompany = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.updateCompany(id, formData);

    // console.log(data);

    dispatch({ type: COMPANY, data: data.data.company });
  } catch (error) {
    // console.log(error);
    // console.log(error.response.status);
  }
};

export const connectSocial = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.connectSocial(id, formData);

    dispatch({ type: AUTH, data });
  } catch (error) {
    // console.log(error);
    // console.log(error.response.status);
    return error;
  }
};
