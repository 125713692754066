import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
// import { sendForgotPasswordEmail } from '../../api/index.js';

function LogInEmailConfirmation(props) {
    // const [sendAgain, setSendAgain] = useState(false);
    const [email, setEmail] = useState("");

    const location = useLocation();
    const user = location?.state && location?.state?.user;

    useEffect(() => {
        if (user?.email) {
            setEmail(user.email);
        } else {
            setEmail(props.email);
        }
    }, [props.email, user?.email]);

    // async function sendAgainFunction(e) {
    //    e.preventDefault();

    //    const { data } = sendForgotPasswordEmail(props.email);

    //    if(data) {
    //       setSendAgain(true);
    //    }
    //    // console.log('click');
    // }

    return (
        <div className="LogInEmailConfirmation mTop50 mBottom70">
            <div className="backColPrimaryLight borderRadius24 mTop50 mBottom70 LogInEmailConfirmationSubb1 w80 mXAuto">
                <div className="w50 mXAuto mTop70 pBottom70 LogInEmailConfirmationSubb2">
                    {user && !user?.signupToken ? (
                        <h2 className="textAlignCenter H2medium mBottom20">
                            Thank you - please continue your password reset
                            through your email.
                        </h2>
                    ) : (
                        <h2 className="textAlignCenter H2medium mBottom20">
                            Thank you - please confirm your registration through
                            your email.
                        </h2>
                    )}
                    <p className="textAlignCenter Pmedium mBottom4">
                        We have sent an email to{" "}
                        <span>
                            <b>{email}</b>
                        </span>{" "}
                        as given.
                    </p>
                    {/*<p className="textAlignCenter Pmedium mBottom4">If the email doesn't arrive within 5 minutes, please <a onClick={sendAgainFunction} className="colPrimaryGreen cursorPointer"><b>click here</b></a> and we'll send it another one.</p>*/}
                    {/* { sendAgain && <p className="textAlignCenter Pmedium mBottom4 mTop20">Email was sent again</p>} */}
                    {/* <button className="verticallyAlignMid borderRadius12 w340 borderNone mTop20 Pmedium colOffWhite backgroundGradientForButtons pX80 pY14 mXAuto">Done</button>*/}
                </div>
            </div>
        </div>
    );
}

function getUserData(state) {
    return {
        email: state.auth.authData?.email ?? "",
    };
}

export default connect(getUserData)(LogInEmailConfirmation);
