import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

function LogInClientSetYourPaymentDetails2(props) {
    const company = props.company;
    const navigate = useNavigate();

    if (!company._id || !props.userID) {
        navigate("/LogIn");
    }

    return (
        <div className="LogInClientSetYourPaymentDetails2">
            <div className="backColPrimaryLight borderRadius24 mTop50 mBottom70 w80 mXAuto">
                <button>Back Button</button>
                <div className="w50 mXAuto mTop70 pBottom70">
                    <h4 className="textAlignCenter H2medium mBottom20">
                        Set your payment details
                    </h4>
                    <p className="textAlignCenter Pmedium">
                        You won't be charged until your trial expiers.
                    </p>
                    <p className="textAlignCenter Pmedium mBottom10">
                        You can cancel anytime in your profile settings.
                    </p>
                    <div className="w340 mXAuto">
                        <h5 className="textAlignLeft mBottom20 P1medium">
                            Pay With
                        </h5>
                        <select className="Pregular pLeft8 mXAuto textAlignLeft verticallyAlignMid borderNone pY14 borderRadius12 mBottom30">
                            <option>Mastercard</option>
                        </select>
                        <p className="textAlignLeft Pbold mBottom10">
                            Promo code
                        </p>
                    </div>
                    <button
                        style={{
                            color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                            backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                        }}
                        className="verticallyAlignMid borderRadius12 w300 borderNone mTop20 Pmedium colOffWhite pX80 pY14 mXAuto"
                    >
                        Start Trial
                    </button>
                </div>
            </div>
        </div>
    );
}

function getData(state) {
    return {
        company: state.auth.authData.company,
        userID: state.auth.authData.data.user._id,
    };
}

export default connect(getData)(LogInClientSetYourPaymentDetails2);
