import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React from "react"
import PaymentForm from "./../PaymentForm/PaymentForm"

const stripeTestPromise = loadStripe(process.env.NODE_ENV === 'production' ? process.env.REACT_APP_STRIPE_PUBLIC : process.env.REACT_APP_STRIPE_PUBLIC_TEST)

function StripeContainer({parentEvent, check, dataForm, setParentSessionID}) {
	return (
		<Elements stripe={stripeTestPromise}>
			<PaymentForm parentEvent={parentEvent} check={check} dataForm={dataForm} setParentSessionID={setParentSessionID} />
		</Elements>
	)
}

export default StripeContainer;
