// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser, faBookOpen, faCreditCard, faSignInAlt, faBell, faCalendarMinus, faMobileAlt, faClipboard, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from "react-router-dom";
import { FiUser, FiBookOpen } from "react-icons/fi";
import { BsCreditCard2Back, BsCalendar4, BsClipboard } from "react-icons/bs";
import { VscSignIn, VscDeviceMobile } from "react-icons/vsc";
import { connect } from "react-redux";
// import { useState, useEffect } from 'react';

function MentorHome2SettingsPanel(props) {
    function getInitials(name) {
        let words = name.split(" ");
        return (
            words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
        );
    }

    const company = props.data.company;
    const charityCompany = company.charity;
    const navigate = useNavigate();

    if (!props.data.data.user._id || !company._id) {
        navigate("/LogIn");
    }

    // console.log("company", company);

    return (
        <div className="MentorHome2SettingsPanel">
            <div className="mTop70 mBottom70 w80 mXAuto MentorHome2SettingsPanelSubWrapper">
                <h2 className="H2medium textAlignLeft mBottom20">Profile</h2>
                <div className="verticallyAlignMidJustConStart MentorHome2SettingsPanelSubb mBottom50">
                    <div className="borderRadius50 profileSettingsPanelImg">
                        <div className="w100 h100 verticallyAlignMid">
                            {props.data.data.user?.photo ? (
                                <img
                                    alt=""
                                    className="w100 h100"
                                    src={props.data.data.user.photo}
                                />
                            ) : (
                                <div className="userInitialsBig">
                                    <p>
                                        {getInitials(
                                            props.data.data.user.fullName
                                        )}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mLeft20 MentorHome2SettingsPanelSubb1">
                        <h6 className="P1bold">
                            {props.data.data.user.fullName},{" "}
                            <mark className="Pregular backColWhite">
                                {props?.data.data.user?.email}
                            </mark>
                        </h6>
                        {/*<p className="colPrimaryGreen Pmedium textAlignLeft">Edit Profile</p>*/}
                    </div>
                </div>
                <div className="verticallyAlignMidJustConStart mBottom30 flexWrap">
                    <Link
                        to="/ClientSettingsPanelPersonalInfo"
                        className="profileSmallBoxes borderRadius18 boxShadowForSettingPanelCards mRight20 mBottom30"
                    >
                        <div className="w90 mXAuto mY30">
                            <FiUser
                                size="3em"
                                className="colPrimaryGreen disFlex mRightAuto mBottom8"
                            />
                            <h5 className="pTop10 textAlignLeft P1bold colLinksAndTitlesBlack">
                                Personal Information
                            </h5>
                            <p className="Pmedium colLinksAndTitlesBlack textAlignLeft pTop15 pRight5 colLinksAndTitlesBlack">
                                {
                                    company.whiteLabel.texts.profile
                                        .personalInformationText
                                }
                            </p>
                        </div>
                    </Link>
                    {props?.data.data.user.role === "mentor" && (
                        <Link
                            to="/OnboardingMentor13Service"
                            className="mBottom30 profileSmallBoxes borderRadius18 boxShadowForSettingPanelCards mRight20"
                        >
                            <div className="w90 mXAuto mY30">
                                <FiBookOpen
                                    size="3em"
                                    className="colPrimaryGreen disFlex mRightAuto mBottom8"
                                />
                                <h5 className="pTop10 textAlignLeft P1bold colLinksAndTitlesBlack">
                                    Manage Services
                                </h5>
                                <p className="Pmedium colLinksAndTitlesBlack textAlignLeft pTop15 pRight5">
                                    {
                                        company.whiteLabel.texts.profile
                                            .manageServicesText
                                    }
                                </p>
                            </div>
                        </Link>
                    )}
                    {/* {!charityCompany ? (
            <Link
              to="/ClientSettingsPanelPaymentAndPayouts1"
              className="mBottom30 profileSmallBoxes paymentsAndPayoutsProfile borderRadius18 boxShadowForSettingPanelCards mRight20"
            >
              <div className="w90 mXAuto mY30">
                <BsCreditCard2Back
                  size="3em"
                  className="colPrimaryGreen disFlex mRightAuto mBottom8"
                />
                <h5 className="pTop10 textAlignLeft P1bold colLinksAndTitlesBlack">
                  Payment &#38; Payouts
                </h5>
                <p className="Pmedium colLinksAndTitlesBlack textAlignLeft pTop15 pRight5">
                  {company.whiteLabel.texts.profile.paymentsPayoutsText}
                </p>
              </div>
            </Link>
          ) : (
            <></>
          )} */}
                    <Link
                        to="/ClientSettingsPanelLoginAndSecurity"
                        className="mBottom30 profileSmallBoxes boxShadowForSettingPanelCards borderRadius18 mRight20"
                    >
                        <div className="w90 mXAuto mY30">
                            <VscSignIn
                                size="3em"
                                className="colPrimaryGreen disFlex mRightAuto mBottom8"
                            />
                            <h5 className="pTop10 textAlignLeft P1bold colLinksAndTitlesBlack">
                                Logins &#38; Security
                            </h5>
                            <p className="Pmedium colLinksAndTitlesBlack textAlignLeft pTop15 pRight5">
                                {
                                    company.whiteLabel.texts.profile
                                        .loginsSecurityText
                                }
                            </p>
                        </div>
                    </Link>
                    {/*<Link to="/MentorHome3Notifications" className="mBottom30 profileSmallBoxes paymentsAndPayoutsProfile boxShadowForSettingPanelCards borderRadius18 mRight20">
						<div className="w90 mXAuto mY30">
							<BsBell size="3em" className="colPrimaryGreen disFlex mRightAuto mBottom8"/>
							<h5 className="pTop10 textAlignLeft P1bold colLinksAndTitlesBlack">Notifications</h5>
							<p className="Pmedium colLinksAndTitlesBlack textAlignLeft pTop15 pRight5">{company.whiteLabel.texts.profile.notificationsText}</p>
						</div>
					</Link>*/}
                    {props?.data.data.user.role === "mentor" && (
                        <Link
                            to="/OnboardingMentor8SetAvailability"
                            className="mBottom30 profileSmallBoxes boxShadowForSettingPanelCards borderRadius18 mRight20"
                        >
                            <div className="w90 mXAuto mY30">
                                <BsCalendar4
                                    size="3em"
                                    className="colPrimaryGreen disFlex mRightAuto mBottom8"
                                />
                                <h5 className="pTop10 textAlignLeft P1bold colLinksAndTitlesBlack">
                                    Availability
                                </h5>
                                <p className="Pmedium colLinksAndTitlesBlack textAlignLeft pTop15 pRight5">
                                    {
                                        company.whiteLabel.texts.profile
                                            .availabilityText
                                    }
                                </p>
                            </div>
                        </Link>
                    )}
                    <Link
                        to="/HowMentorXWorks"
                        className="mBottom30 profileSmallBoxes boxShadowForSettingPanelCards borderRadius18 mRight20"
                    >
                        <div className="w90 mXAuto mY30">
                            <VscDeviceMobile
                                size="3em"
                                className="colPrimaryGreen disFlex mRightAuto mBottom8"
                            />
                            <h5 className="pTop10 textAlignLeft P1bold colLinksAndTitlesBlack">
                                How {company.whiteLabel.texts.label1Text} Works
                            </h5>
                            <p className="Pmedium colLinksAndTitlesBlack textAlignLeft pTop15 pRight5">
                                {company.whiteLabel.texts.profile.howWorksText}
                            </p>
                        </div>
                    </Link>
                    <Link
                        to="/ClientSettingsPanelContactSupport"
                        className="mBottom30 profileSmallBoxes paymentsAndPayoutsProfile boxShadowForSettingPanelCards borderRadius18 mRight20"
                    >
                        <div className="w90 mXAuto mY30">
                            <BsClipboard
                                size="3em"
                                className="colPrimaryGreen disFlex mRightAuto mBottom8"
                            />
                            <h5 className="pTop10 textAlignLeft P1bold colLinksAndTitlesBlack">
                                Contact Support
                            </h5>
                            <p className="Pmedium colLinksAndTitlesBlack textAlignLeft pTop15 pRight5">
                                {
                                    company.whiteLabel.texts.profile
                                        .contactsSupportText
                                }
                            </p>
                        </div>
                    </Link>
                    {/*<Link to="/???????????????" className="mBottom30 profileSmallBoxes giveUsFeedBack boxShadowForSettingPanelCards borderRadius18 mRight20">
						<div className="w90 mXAuto mY30">
							<FiShield  size="3em" className="colPrimaryGreen disFlex mRightAuto mBottom8"/>
							<h5 className="pTop10 textAlignLeft P1bold colLinksAndTitlesBlack">Give us feedback</h5>
							<p className="Pmedium colLinksAndTitlesBlack textAlignLeft pTop15 pRight5">{company.whiteLabel.texts.profile.giveUsFeedbackText}</p>
						</div>
					</Link>*/}
                </div>
            </div>
        </div>
    );
}

function getUser(state) {
    return {
        data: state.auth.authData,
    };
}

export default connect(getUser)(MentorHome2SettingsPanel);
