import { Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

function RestrictedMentorNotYouAreAllSetCompletedRoute(props) {
	if(!props.userData.user.mentorOnboarding.welcomeCompleted) {
		return <Navigate to="/OnboardingMentor6" />;
	} else if(!props.userData.user.mentorOnboarding.firstServiceCompleted) {
		return <Navigate to="/OnboardingMentor7CreateYourFirstSession" />;
	} else if(!props.userData.user.mentorOnboarding.availabilityCompleted) {
		return <Navigate to="/OnboardingMentor8SetAvailability" />;
	} else if(!props.userData.user.mentorOnboarding.paymentDetailsCompleted) {
		return <Navigate to="/OnboardingMentor9PayoutMethod" />;
	// } else if(!props.userData.user.mentorOnboarding.paymentDetails2Completed) {
	// 	return <Navigate to="/OnboardingMentor9PayoutMethod2" />;
	} else if(!props.userData.user.mentorOnboarding.activeMentor) {
		return <Outlet />;
	} else {
		return <Navigate to="/OnboardingMentor12WelcomePage" />;
	}
};

function getUser(state) {
	// console.log('RestrictedMentorNotYouAreAllSetCompletedRoute', state.auth.authData?.data.user)
	return {
		userData: {
			user : state.auth.authData?.data.user
		}
	}
}

export default connect(getUser)(RestrictedMentorNotYouAreAllSetCompletedRoute);
