import { Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

function RestrictedMentorMOProfileDetailsRoute(props) {
	if(!props.userData.user.mentorOnboarding.mentorApplyCompleted) {
		return <Navigate to="/MOMentorsApply" />;
	} else if(!props.userData.user.mentorOnboarding.profileDetailsCompleted) {
		return <Outlet />;
	} else {
		return <Navigate to="/MOYourExperience" />;
	}
};

function getUser(state) {
	// console.log('RestrictedMentorMOProfileDetailsRoute', state.auth.authData?.data.user)
	return {
		userData: {
			user : state.auth.authData?.data.user			
		}
	}
}

export default connect(getUser)(RestrictedMentorMOProfileDetailsRoute);