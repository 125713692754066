import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
// import { logger } from 'redux-logger';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import { reducers } from "./reducers";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "./index.css";
import "./style.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';

// const middlewares = [thunk, logger];
const middlewares = [thunk];

const persistConfig = {
    key: "root",
    storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);

// const store = createStore(reducers, compose(applyMiddleware(thunk)));
const store = createStore(
    persistedReducer,
    compose(applyMiddleware(...middlewares))
);

const persistor = persistStore(store);

// const unsubscribe = store.subscribe(() => {
//     console.log("Store changed!", store.getState());
// })

// unsubscribe();

store.subscribe(() => {
    // console.log("Store changed!", store.getState());
});

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
