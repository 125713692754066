import { useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mentorOnboarding } from "../../actions/auth.js";
import { RiArrowLeftSLine } from "react-icons/ri";

function OnboardingMentor9PayoutMethod(props) {
    const initialState = {
        setPayment: true,
        billingCountry: "",
        type: "",
        profileDetails: {
            address: "",
            aptSuiteBuilding: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
        },
    };

    const company = props.company;

    const [formData, setFormData] = useState(initialState);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (!props.company._id || !props.user._id) {
        navigate("/LogIn");
    }

    function goToNextPage() {
        dispatch(mentorOnboarding(formData, navigate));
    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangeProfile = (e) => {
        setFormData({
            ...formData,
            profileDetails: {
                ...formData.profileDetails,
                [e.target.name]: e.target.value,
            },
        });
    };

    return (
        <div className="OnboardingMentor9PayoutMethod">
            <div className="verticallyAlignMidJustConStart w80 mXAuto mTop70 mBottom40">
                <div className="posRel mRight20">
                    <Link to="/OnboardingMentor8SetAvailability">
                        <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                            <RiArrowLeftSLine
                                size="2em"
                                className="colPrimaryGreen"
                            />
                        </button>
                        {/* <button>Back Button</button> */}
                    </Link>
                </div>
                <div>
                    <h2 className="textAlignLeft H1regular">Payout</h2>
                    <h5 className="textAlignLeft mBottom20 P1medium">
                        Last, set your preferred payout method
                    </h5>
                </div>
            </div>
            <div className="verticallyAlignMidStretch w80 mXAuto">
                <div className="w50 mXAuto">
                    <h4 className="H3bold textAlignLeft mBottom12">
                        Billing country
                    </h4>
                    <select
                        name="billingCountry"
                        onChange={(e) => handleChange(e)}
                        className="Pregular pLeft8 textAlignLeft w60 verticallyAlignMidJustConStart borderGrayInput pY14 borderRadius12 mBottom30"
                    >
                        <option>United Kingdom</option>
                        <option>United States</option>
                    </select>
                    <div className="verticallyAlignMidJustConStart mBottom12 w60">
                        <h5 className="textAlignLeft P1medium">
                            Stripe in AU$
                        </h5>
                        <input
                            className="mLeftAuto"
                            type="radio"
                            name="type"
                            value="gbt"
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div className="verticallyAlignMidJustConStart mBottom12">
                        <div className="dotForListItem mLeft4 mRight8"></div>
                        <p className="textAlignLeft Small-LabelCaps">
                            Payment in 4-6 hours
                        </p>
                    </div>
                    <div className="verticallyAlignMidJustConStart mBottom12">
                        <div className="dotForListItem mLeft4 mRight8"></div>
                        <p className="textAlignLeft Small-LabelCaps">
                            Connect with your stripe account
                        </p>
                    </div>
                    <div className="verticallyAlignMidJustConStart mBottom12">
                        <div className="dotForListItem mLeft4 mRight8"></div>
                        <p className="textAlignLeft Small-LabelCaps">
                            Fees may occur
                        </p>
                    </div>
                    <div className="borderGrayTopInput w70 mY30"></div>
                    {/*       <label className="containerForCheckBox">
                        <input type="radio" checked="checked" name="radio" />
                        <span className="checkmark"></span>
                    </label>*/}
                    <div className="verticallyAlignMidJustConStart mBottom12 w60">
                        <h5 className="textAlignLeft P1medium">
                            Bank Transfer
                        </h5>
                        <input
                            className="mLeftAuto"
                            type="radio"
                            name="type"
                            value="bank"
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div className="verticallyAlignMidJustConStart mBottom12">
                        <div className="dotForListItem mLeft4 mRight8"></div>
                        <p className="textAlignLeft Small-LabelCaps">
                            Payment in 1-2 days
                        </p>
                    </div>
                    <div className="verticallyAlignMidJustConStart mBottom12">
                        <div className="dotForListItem mLeft4 mRight8"></div>
                        <p className="textAlignLeft Small-LabelCaps">No Fees</p>
                    </div>
                </div>
                {formData.type === "bank" && (
                    <div className="w50 mXAuto textAlignLeft">
                        <h4 className="H3bold textAlignLeft mBottom4">
                            Please fill in your bank address
                        </h4>
                        <label className="textAlignLeft Pmedium">
                            Street address
                        </label>
                        <input
                            name="address"
                            onChange={(e) => {
                                handleChangeProfile(e);
                            }}
                            className="Pregular pLeft8 textAlignLeft w100 verticallyAlignMidJustConStart borderGrayInput pY14 borderRadius12 mBottom12"
                        />
                        <label className="textAlignLeft Pmedium">
                            Apt, suite, building. (optional)
                        </label>
                        <input
                            name="aptSuiteBuilding"
                            onChange={(e) => {
                                handleChangeProfile(e);
                            }}
                            className="Pregular pLeft8 textAlignLeft w100 verticallyAlignMidJustConStart borderGrayInput pY14 borderRadius12 mBottom12"
                        />
                        <div className="verticallyAlignMidJustConStart mBottom12 w100">
                            <div className="textAlignLeft w50 mRight20">
                                <label className="Pmedium">City</label>
                                <input
                                    name="city"
                                    onChange={(e) => {
                                        handleChangeProfile(e);
                                    }}
                                    className="Pregular pLeft8 textAlignLeft w100 verticallyAlignMidJustConStart borderGrayInput pY14 borderRadius12"
                                />
                            </div>
                            <div className="textAlignLeft w50">
                                <label className="Pmedium">
                                    State / Province
                                </label>
                                <input
                                    name="state"
                                    onChange={(e) => {
                                        handleChangeProfile(e);
                                    }}
                                    className="Pregular pLeft8 textAlignLeft w100 verticallyAlignMidJustConStart borderGrayInput pY14 borderRadius12"
                                />
                            </div>
                        </div>
                        <label className="Pmedium">Zip / Postal code</label>
                        <input
                            name="zipCode"
                            onChange={(e) => {
                                handleChangeProfile(e);
                            }}
                            className="Pregular pLeft8 textAlignLeft w100 verticallyAlignMidJustConStart borderGrayInput pY14 borderRadius12 mBottom12"
                        />
                        <label className="Pmedium">Country / Region</label>
                        <input
                            name="country"
                            onChange={(e) => {
                                handleChangeProfile(e);
                            }}
                            className="Pregular pLeft8 textAlignLeft w100 verticallyAlignMidJustConStart borderGrayInput pY14 borderRadius12 mBottom12"
                        />
                    </div>
                )}
                {formData.type === "gbt" && (
                    <div className="w50 mXAuto">
                        <h4 className="H3bold textAlignLeft mBottom4">
                            Stripe in AU$
                        </h4>
                        <p className="textAlignLeft Small-LabelCaps mBottom50">
                            Connect your Stripe account
                        </p>
                        <p className="textAlignLeft Small-LabelCaps mLeft8">
                            Payment in 4-6 hours
                        </p>
                        <div className="borderGrayTopInput w70 mY20"></div>
                        <p className="textAlignLeft Small-LabelCaps mLeft8">
                            May include fees
                        </p>
                        <div className="borderGrayTopInput w70 mY20"></div>
                        <p className="textAlignLeft Small-LabelCaps w70">
                            After clicking next you will be redirected to an
                            external website where you will securely enter your
                            payout details.
                        </p>
                        <p className="textAlignLeft Small-LabelCaps mBottom70 w70">
                            After completing the process you will be redirected
                            back to MentorX.
                        </p>
                        <div className="borderGrayTopInput w70 mY20"></div>
                        <button
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            className="borderRadius12 borderNone mBottom30 P1bold colOffWhite pLeft60 pRight60 pTop10 pBottom10"
                        >
                            Login With Stripe
                        </button>
                    </div>
                )}
            </div>
            <div className="w80 mXAuto mTop50">
                <button
                    style={{
                        color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                        backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                    }}
                    className="verticallyAlignMid mLeftAuto borderRadius12 borderNone mBottom30 P1bold colOffWhite pLeft60 pRight60 pTop10 pBottom10"
                    onClick={goToNextPage}
                >
                    Next
                </button>
            </div>
        </div>
    );
}

function getData(state) {
    return {
        company: state.auth.authData.company,
        user: state.auth.authData.data.user,
    };
}

export default connect(getData)(OnboardingMentor9PayoutMethod);
