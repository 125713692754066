import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import FacebookLogin from "react-facebook-login";
// import { AUTH } from '../../constants/actionTypes';
import { signin } from "../../actions/auth.js";
// import Input from '../../components/input/Input.js';
import { useEffect } from "react";
import { RiFacebookBoxFill } from "react-icons/ri";
import jwt_decode from "jwt-decode";

function LogIn(props) {
    const company = props.company;

    const initialState = { companyID : company._id, email : "", password : "" };

    //console.log(process.env.NODE_ENV);
    //console.log(process.env.REACT_APP_GOOGLE_CLIENT_ID);

    // const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData]           = useState(initialState);
    const [errorText, setErrorText]         = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();

        let password = formData.password;

        if (password.length < 8) {
            setErrorPassword(true);
            return false;
        } else {
            setErrorPassword(false);
        }

        // dispatch(signin(formData, navigate));
        const el = await dispatch(signin(formData, navigate));

        if (el?.response.status === 401) {
            setErrorText(true);
        }
    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // useEffect(() => {
    //     setFormData({ ...formData, companyID: company._id });
    // }, [props]);

    const responseFacebook = async (response) => {
        // console.log(response);

        const userData = {
            social      : true,
            email       : response.email,
            companyID   : company._id,
            id          : response.id,
            type        : 'facebookID'
        };

        const data = await dispatch(signin(userData, navigate));
        // console.log(data);
    };

    const host = window.location.host;
    const subdomain = host.split(".")[0];

    async function handleGoogleResponse(response) {
        if (response.credential) {
            const user = jwt_decode(response.credential);
            // console.log(user);

            if (user.email_verified) {
                const userData = {
                    social      : true,
                    email       : user.email,
                    companyID   : company._id,
                    id          : user.sub,
                    type        : 'googleID'
                };

                const data = await dispatch(signin(userData, navigate));
                // console.log(data);
            }
        }
    }

    useEffect(() => {
        const google = window.google;

        google.accounts.id.initialize({
            // client_id: "567198460390-oo360r7n7jsiruap9nilpe4ooohqkhfs.apps.googleusercontent.com",
            //client_id   : "567198460390-eqfp1i18tf1lbphu7h50a9c7h547s1rs.apps.googleusercontent.com",
            //client_id: "241251241244-6cmsret0k7g8aovg1unrauu1gvk4dfhc.apps.googleusercontent.com",
            client_id: "241251241244-4sckjn6mdd2n969nscj4e0ahhqurg38r.apps.googleusercontent.com",
            callback    : handleGoogleResponse,
        });

        google.accounts.id.renderButton(document.getElementById("signInDiv"), {
            theme   : "outline",
            size    : "large",
        });

        google.accounts.id.prompt();
    }, []);

    return (
        <div className="LogIn">
            <div className="backColPrimaryLight borderRadius24 LogInSubbMain1 mTop50 mBottom70 w80 mXAuto">
                <div className="verticallyAlignMid">
                    <div className="w50 mXAuto mTop70 pBottom70 LogInSubbMain2">
                        <h2 className="textAlignCenter H1medium mBottom8">
                            Sign in
                        </h2>
                        <form onSubmit={handleSubmit} className="formLoginMain">
                            <div className="backColOffWhite borderRadius12 mBottom20 pRight10 pLeft30 w340 LogInSubbMain3 mXAuto">
                                <p
                                    htmlFor="input_email_for_login"
                                    className="Small-Labelnormal textAlignLeft pTop10"
                                >
                                    {" "}
                                    E-MAIL &nbsp;&nbsp;&nbsp;&nbsp;
                                </p>
                                <input
                                    id="input_email_for_login"
                                    name="email"
                                    label="Email Address"
                                    onChange={handleChange}
                                    type="email"
                                    className="Pmedium w100 borderNone backColOffWhite borderRadius12 mBottom10"
                                />
                            </div>
                            <div className="backColOffWhite borderRadius12 mBottom20 pRight10 pLeft30 LogInSubbMain3 w340 mXAuto">
                                <p
                                    htmlFor="input_password_for_login"
                                    className="Small-Labelnormal textAlignLeft pTop10"
                                >
                                    PASSWORD
                                </p>
                                <input
                                    id="input_password_for_login"
                                    name="password"
                                    label="Password"
                                    onChange={handleChange}
                                    type="password"
                                    className="Pmedium w100 borderNone backColOffWhite borderRadius12 mBottom10"
                                />
                            </div>
                            {errorText && (
                                <div className="disFlex verticallyAlignMid">
                                    <p className="P1bold colErrorRed">
                                        Wrong email or password!
                                    </p>
                                </div>
                            )}
                            {errorPassword && (
                                <div className="disFlex verticallyAlignMid">
                                    <p className="Pbold colErrorRed">
                                        Password must be at least 8 characters
                                        with any mix of upper and lower case
                                        letters, numbers, or special characters.
                                    </p>
                                </div>
                            )}
                            <div className="disFlex">
                                {subdomain === "admin" ? (
                                    <button
                                        type="submit"
                                        className="verticallyAlignMid colOffWhite backgroundGradientForButtons borderRadius12 mBottom20 w340 borderNone mTop20 Pmedium colOffWhite pX80 pY14 mXAuto"
                                    >
                                        Sign In
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        style={{
                                            color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                            backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                        }}
                                        className="verticallyAlignMid borderRadius12 mBottom20 w340 borderNone mTop20 Pmedium colOffWhite pX80 pY14 mXAuto"
                                    >
                                        Sign In
                                    </button>
                                )}
                            </div>
                        </form>
                        <p className="textAlignCenter Pmedium mBottom20">
                            Or sign up using
                        </p>
                        <div className="verticallyAlignMid mXAuto mBottom20">
                            <div id="signInDiv"></div>
                        </div>
                        {/* <button className="borderRadiusForSocialMediaButtons backColGoogleRed borderNone pY14 w104 verticallyAlignMid mRight8 colOffWhite">
                            <RiGoogleFill size="1.5em" />
                        </button> */}

                        {/* <FacebookLogin
                            appId="356156892738915"
                            // autoLoad={true}
                            fields="email"
                            callback={responseFacebook}
                            render={(renderProps) => (
                                <button
                                onClick={renderProps.onClick}
                                className="borderRadiusForSocialMediaButtons backColFacebookBlue borderNone pY14 w104 colOffWhite"
                                >
                                <RiFacebookBoxFill size="1.5em" />
                                </button>
                            )}
                        /> */}
                        <p className="Pmedium">
                            {" "}
                            <a href="/LogInForgotYourPassword">
                                {" "}
                                Forgot Password?{" "}
                            </a>{" "}
                        </p>
                        <p className="Pmedium">
                            {" "}
                            Don't have an account?
                            <a href="/Signup"> Sign up! </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getData(state) {
    return {
        company: state.auth.authData?.company || {},
    };
}

export default connect(getData)(LogIn);
