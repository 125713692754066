// import ScheduleCard1 from '../../components/schedule/ScheduleCard1/ScheduleCard1';
// import ScheduleCard2 from '../../components/schedule/ScheduleCard2/ScheduleCard2';
// import ScheduleCard3 from '../../components/schedule/ScheduleCard3/ScheduleCard3';
import CloseButton from "../../components/buttons/CloseButton/CloseButton";
import TextareaComponent from "../../components/TextareaComponent/TextareaComponent";
import Moment from "moment";
import { useState } from "react";
import {
    finishSession,
    createNotification,
    createMessage,
} from "../../api/index.js";
import { connect } from "react-redux";

function ReschedulePopUp(props) {
    function dateFormat(date) {
        let resDate = Moment(date).format("LLLL");
        return Moment(resDate).format("ddd, MMM DD");
    }

    const company = props.company;

    function timeFormat(time) {
        return (
            Moment(time).format("LT") +
            " - " +
            Moment(time).add(props.service.duration, "minutes").format("LT")
        );
    }

    const [text, setText] = useState("");

    async function cancelSessionClick() {
        finishSession(props.session._id, { status: "cancelled" });

        let userID, fromID, toID;

        if (props.user.role === "mentor") {
            userID = props.session.clientID._id;
            toID = props.session.clientID._id;
            fromID = props.session.mentorID._id;
        } else {
            userID = props.session.mentorID._id;
            toID = props.session.mentorID._id;
            fromID = props.session.clientID._id;
        }

        createNotification({
            userID,
            type: "Session",
            typeID: props.session._id,
            text: `${props.user.fullName} cancelled a session`,
            fromID: props.user._id,
        });

        if (text !== "") {
            const { data } = await createMessage({
                fromID,
                toID,
                text,
                typeID: props.session._id,
                type: "Session",
                subType: "cancelled",
            });

            createNotification({
                userID,
                type: "Message",
                typeID: data.data.message._id,
                text: `${props.user.fullName} send you a message`,
                fromID: props.user._id,
            });
        }

        props.parentEvent("popup" + props.type);
    }

    async function rescheduleSessionClick() {
        if (props.session.rescheduling && props.user.role === "client") {
            props.parentEventView(true);
        } else {
            finishSession(props.session._id, { status: "reschedule" });

            let userID, fromID, toID;

            if (props.user.role === "mentor") {
                userID = props.session.clientID._id;
                toID = props.session.clientID._id;
                fromID = props.session.mentorID._id;
            } else {
                userID = props.session.mentorID._id;
                toID = props.session.mentorID._id;
                fromID = props.session.clientID._id;
            }

            createNotification({
                userID,
                type: "Session",
                typeID: props.session._id,
                text: `${props.user.fullName} cancelled a session`,
                fromID: props.user._id,
            });

            if (text !== "") {
                const { data } = await createMessage({
                    fromID,
                    toID,
                    text,
                    typeID: props.session._id,
                    type: "Session",
                    subType: "rescheduled",
                });

                createNotification({
                    userID,
                    type: "Message",
                    typeID: data.data.message._id,
                    text: `${props.user.fullName} send you a message`,
                    fromID: props.user._id,
                });
            }

            props.parentEvent("popup" + props.type);
        }
    }

    function getInitials(name) {
        let words = name.split(" ");
        return (
            words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
        );
    }

    return (
        <div className="ReschedulePopUp">
            <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                <div className="w90 mXAuto">
                    <div className="verticallyAlignMidJustConStart mTop40">
                        <h3 className="mRightAuto H3medium">
                            {props.type} Session
                        </h3>
                        <CloseButton parentEvent={props.parentEvent} />
                    </div>
                    <div className="verticallyAlignMidJustConStart ReschedulePopUpSubbDateTime mY30">
                        <div className="verticallyAlignMidJustConStart mRight50 ReschedulePopUpSubbDateTime3">
                            <div className="pRight1 mRight12 mY20">
                                <div className="verticallyAlignMidJustConStart reviewsCardImg2 borderRadius50">
                                    {props.user.role === "mentor" ? (
                                        <div className="w100 h100 verticallyAlignMid">
                                            {props.session.clientID?.photo ? (
                                                <img
                                                    alt=""
                                                    className="w100 h100"
                                                    src={
                                                        props.session.clientID
                                                            .photo
                                                    }
                                                />
                                            ) : (
                                                <div className="userInitialsMedium">
                                                    <p>
                                                        {getInitials(
                                                            props.session
                                                                .clientID
                                                                .fullName
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="w100 h100 verticallyAlignMid">
                                            {props.session.mentorID?.photo ? (
                                                <img
                                                    alt=""
                                                    className="w100 h100"
                                                    src={
                                                        props.session.mentorID
                                                            .photo
                                                    }
                                                />
                                            ) : (
                                                <div className="userInitialsMedium">
                                                    <p>
                                                        {getInitials(
                                                            props.session
                                                                .mentorID
                                                                .fullName
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div>
                                <div className="P1bold textAlignLeft">
                                    {props.session.clientID.fullName ===
                                    props.user.fullName
                                        ? props.session.mentorID.fullName
                                        : props.session.clientID.fullName}
                                </div>
                                <p className="textAlignLeft Small-Labelnormal colLabelGray w100 breakWord">
                                    {props.service.name}
                                </p>
                            </div>
                        </div>
                        <div className="verticallyAlignMidJustConStart ReschedulePopUpSubbDateTime2">
                            <div className="mRight50">
                                <p className="textAlignLeft colLabelGray Small-Labelnormal">
                                    DATE
                                </p>
                                <h3 className="textAlignLeft P1medium">
                                    {dateFormat(props.session.date)}
                                </h3>
                            </div>
                            <div>
                                <p className="textAlignLeft colLabelGray Small-Labelnormal">
                                    TIME
                                </p>
                                <h3 className="textAlignLeft P1medium">
                                    {timeFormat(props.session.date)}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="Pmedium textAlignLeft mBottom12">
                            Send{" "}
                            {props.client.fullName === props.user.fullName
                                ? props.session.mentorID.fullName.split(" ")[0]
                                : props.session.clientID.fullName.split(
                                      " "
                                  )[0]}{" "}
                            a message.
                        </p>
                        <TextareaComponent parentEvent={setText} />
                    </div>
                    {props.type === "Cancel" && (
                        <button
                            onClick={cancelSessionClick}
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            className="verticallyAlignMid w180 borderRadius12 mBottom40 borderNone Pregular colOffWhite pY10 mLeftAuto"
                        >
                            Cancel Session
                        </button>
                    )}
                    {props.type === "Reschedule" && (
                        <button
                            onClick={rescheduleSessionClick}
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            className="verticallyAlignMid w180 borderRadius12 mBottom40 borderNone Pregular colOffWhite pY10 mLeftAuto"
                        >
                            Send Availability
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

function getUserData(state) {
    return {
        user: state.auth?.authData.data.user ?? {},
        company: state.auth?.authData.company,
    };
}

export default connect(getUserData)(ReschedulePopUp);
