import ConifrmSessionPaymentComponent from "../../components/ConifrmSessionPaymentComponent/ConifrmSessionPaymentComponent";
import ConifrmSessionMessageYourMentorComponent from "../../components/ConifrmSessionMessageYourMentorComponent/ConifrmSessionMessageYourMentorComponent";
import ConifrmSessionOrderSummaryComponent from "../../components/ConifrmSessionOrderSummaryComponent/ConifrmSessionOrderSummaryComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { clientBookSession } from "../../api/index.js";
import { createMessage } from "../../api";
import { RiArrowLeftSLine } from "react-icons/ri";
import Moment from "moment";

function ClientConfirmSession(props) {
    function showPopUp(type) {
        props.statusPopUp(type);
    }

    function hidePopUp() {
        props.statusPopUp(false);
    }

    const company = props.data.company;
    const charityCompany = company.charity;

    const location = useLocation();
    const navigate = useNavigate();

    if (
        !company._id ||
        !location.state.service._id ||
        !props.data.data.user._id ||
        !location.state.mentor._id ||
        !location.state.session
    ) {
        navigate("/LogIn");
    }

    const session = location.state.session;

    // const [editDate, setEditDate] = useState(false);
    const [message, setMessage] = useState("");

    const [successPayment, setSuccessPayment] = useState(false);
    const [check, setCheck] = useState(false);

    const [formData, setFormData] = useState({});

    const [sessionID, setSessionID] = useState("");

    async function changeFormData() {

        showPopUp("processing");

        let date = Moment(session.date).format("x");

        let rate        = parseInt(location.state.service.rate);
        let duration    = parseInt(location.state.service.duration);
        let total       = Math.round((rate / 60) * duration * 100) / 100;

        setFormData({
            session: {
                mentorID        : location.state.mentor._id,
                clientID        : props.data.data.user._id,
                userID          : props.data.data.user._id,
                serviceID       : location.state.service._id,
                date            : date,
                status          : "scheduled",
                method          : location.state.service.methods,
                paymentMethod   : "stripe",
                isPayed         : false,
                actualPrice     : total,
                companyPerc     : props.data.company.percentage,
                adminPercentage : props.data.company.adminPercentage,
                charity         : charityCompany ? true : false,
            },
            service: {
                name        : location.state.service.name,
                description : location.state.service.description,
            },
            message,
        });

        if (charityCompany) {
            setSuccessPayment(true);
        } else {
            setCheck(true);
        }
    }

    useEffect(() => {
        if (successPayment) {
            bookSession();
            setCheck(false);
        }
    }, [successPayment]);

    async function bookSession() {

        console.log(successPayment)
        // return false;

        if (successPayment !== true) {
            hidePopUp();
            return false;
        }

        setSuccessPayment(false);

        let data;
        if (charityCompany) {
            data = await clientBookSession(formData);
        }

        showPopUp("success");

        setTimeout(() => {
            hidePopUp();
        }, "2000");

        if (message !== "") {
            createMessage({
                fromID  : props.data.data.user.id,
                toID    : location.state.mentor._id,
                text    : message,
                typeID  : charityCompany ? data.data.data.session.id : sessionID,
                type    : "Session",
                subType : "booked"
            });
        }

        navigate("/ClientConfirmSessionThankYou", {
            state: {
                id: charityCompany ? data.data.data.session.id : sessionID,
            },
        });
    }

    return (
        <div className="ClientConfirmSession">
            <div className="mTop50 mBottom70 w80 mXAuto mTopForSmallerCreens">
                <div className="verticallyAlignMidJustConStart">
                    <div
                        onClick={() => navigate(-1)}
                        className="posRel mRight20"
                    >
                        <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                            <RiArrowLeftSLine
                                size="2em"
                                className="colPrimaryGreen"
                            />
                        </button>
                    </div>

                    <h2 className="H1regular textAlignLeft">
                        Confirm {company.whiteLabel.texts.label4SessionSingle}
                    </h2>
                </div>

                <div className="borderGrayTopInput w100 mY50"></div>
                <div className="verticallyAlignMidStretch ClientConfirmSessionSubb1">
                    <div className="w50 ClientConfirmSessionSubb2">
                        {!charityCompany ? (
                            <ConifrmSessionPaymentComponent
                                check={check}
                                parentEvent={setSuccessPayment}
                                dataForm={formData}
                                setParentSessionID={setSessionID}
                            />
                        ) : (
                            <></>
                        )}
                        { successPayment?.message &&
                            <div className="disFlex verticallyAlignMid">
                                <p className="P1bold colErrorRed">
                                    {successPayment?.message}
                                </p>
                            </div>
                        }
                        <ConifrmSessionMessageYourMentorComponent
                            parentEvent={setMessage}
                        />
                        <button
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            onClick={changeFormData}
                            className="verticallyAlignMidJustConStart borderRadius12 borderNone mTop20 H3medium colOffWhite pX80 pY14"
                        >
                            Book {company.whiteLabel.texts.label4SessionSingle}
                        </button>
                    </div>
                    <div className="w50 ClientConfirmSessionSubb3">
                        <ConifrmSessionOrderSummaryComponent
                            service={location.state.service}
                            mentor={location.state.mentor}
                            session={session}
                            client={props.data.data.user}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function getUsetData(state) {
    return {
        data: state.auth.authData,
    };
}

export default connect(getUsetData)(ClientConfirmSession);
