import { Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";

import Admin from "./pages/Admin/Admin";
import AdminCompany from "./pages/AdminCompany/AdminCompany";
import Owner from "./pages/Owner/Owner";
import Page404 from "./pages/Page404/Page404";

import Signup from "./pages/Signup/Signup";
import LogIn from "./pages/LogIn/LogIn";
import LogInEmailConfirmation from "./pages/LogInEmailConfirmation/LogInEmailConfirmation";
import LogInForgotYourPassword from "./pages/LogInForgotYourPassword/LogInForgotYourPassword";
import LogInEnterNewPassword from "./pages/LogInEnterNewPassword/LogInEnterNewPassword";
import LogInClientSetYourPaymentDetails from "./pages/LogInClientSetYourPaymentDetails/LogInClientSetYourPaymentDetails";
import LogInClientSetYourPaymentDetails2 from "./pages/LogInClientSetYourPaymentDetails2/LogInClientSetYourPaymentDetails2";
import LogInClientSetYourPaymentMethod from "./pages/LogInClientSetYourPaymentMethod/LogInClientSetYourPaymentMethod";
import Schedule from "./pages/Schedule/Schedule";
// import UserProfile from './pages/UserProfile/UserProfile';
import WhatKindOfMentor from "./pages/WhatKindOfMentor/WhatKindOfMentor";
import MentorProfileUserSide from "./pages/MentorProfileUserSide/MentorProfileUserSide";
import MOMentorsApply from "./pages/MOMentorsApply/MOMentorsApply";
import MOProfileDetails from "./pages/MOProfileDetails/MOProfileDetails";
import MOYourExperience from "./pages/MOYourExperience/MOYourExperience";
import MOWorkExperience from "./pages/MOWorkExperience/MOWorkExperience";
import OnboardingMentor5 from "./pages/OnboardingMentor5/OnboardingMentor5";
import OnboardingMentor6 from "./pages/OnboardingMentor6/OnboardingMentor6";
import OnboardingMentor7CreateYourFirstSession from "./pages/OnboardingMentor7CreateYourFirstSession/OnboardingMentor7CreateYourFirstSession";
import OnboardingMentor8SetAvailability from "./pages/OnboardingMentor8SetAvailability/OnboardingMentor8SetAvailability";
import OnboardingMentor9PayoutMethod from "./pages/OnboardingMentor9PayoutMethod/OnboardingMentor9PayoutMethod";
// import OnboardingMentor9PayoutMethod2 from './pages/OnboardingMentor9PayoutMethod2/OnboardingMentor9PayoutMethod2';
import OnboardingMentor10 from "./pages/OnboardingMentor10/OnboardingMentor10";
import OnboardingMentor11ClientSingleView from "./pages/OnboardingMentor11ClientSingleView/OnboardingMentor11ClientSingleView";
import OnboardingMentor12WelcomePage from "./pages/OnboardingMentor12WelcomePage/OnboardingMentor12WelcomePage";
import OnboardingMentor13Service from "./pages/OnboardingMentor13Service/OnboardingMentor13Service";
import MentorHome2SettingsPanel from "./pages/MentorHome2SettingsPanel/MentorHome2SettingsPanel";
import MentorHome3Notifications from "./pages/MentorHome3Notifications/MentorHome3Notifications";
import ClientSettingsPanelLoginAndSecurity from "./pages/ClientSettingsPanelLoginAndSecurity/ClientSettingsPanelLoginAndSecurity";
import ClientSettingsPanelPersonalInfo from "./pages/ClientSettingsPanelPersonalInfo/ClientSettingsPanelPersonalInfo";
import ClientSettingsPanelPaymentAndPayouts1 from "./pages/ClientSettingsPanelPaymentAndPayouts1/ClientSettingsPanelPaymentAndPayouts1";
import ClientSettingsPanelPaymentAndPayouts2 from "./pages/ClientSettingsPanelPaymentAndPayouts2/ClientSettingsPanelPaymentAndPayouts2";
import ClientSettingsPanelPaymentAndPayouts3 from "./pages/ClientSettingsPanelPaymentAndPayouts3/ClientSettingsPanelPaymentAndPayouts3";
import ClientSettingsPanelPaymentAndPayouts4 from "./pages/ClientSettingsPanelPaymentAndPayouts4/ClientSettingsPanelPaymentAndPayouts4";
import ClientSettingsPanelPaymentAndPayouts5 from "./pages/ClientSettingsPanelPaymentAndPayouts5/ClientSettingsPanelPaymentAndPayouts5";
import ClientSettingsPanelContactSupport from "./pages/ClientSettingsPanelContactSupport/ClientSettingsPanelContactSupport";
import HowMentorXWorks from "./pages/HowMentorXWorks/HowMentorXWorks";
import ClientNotes from "./pages/ClientNotes/ClientNotes";
import Notifications from "./pages/Notifications/Notifications";
// import NewNotePoUp from './pages/NewNotePoUp/NewNotePoUp';
import ClientSelectCategories from "./pages/ClientSelectCategories/ClientSelectCategories";
// import ReschedulePopUp from './pages/ReschedulePopUp/ReschedulePopUp';
// import SuccessPopUpMentorFlow from './pages/SuccessPopUpMentorFlow/SuccessPopUpMentorFlow';
// import ThanksPopUpMentorFlow from './pages/ThanksPopUpMentorFlow/ThanksPopUpMentorFlow';
// import ApplyFilters from './pages/ApplyFilters/ApplyFilters';
import MentorFlowClientProfile from "./pages/MentorFlowClientProfile/MentorFlowClientProfile";
import ClientBookOffer from "./pages/ClientBookOffer/ClientBookOffer";
import ClientConfirmSession from "./pages/ClientConfirmSession/ClientConfirmSession";
import Reviews from "./pages/Reviews/Reviews";
// import YourSessionHasEndedPopUp from './pages/YourSessionHasEndedPopUp/YourSessionHasEndedPopUp';
// import ThankYouAfterSessionPopUp from './pages/ThankYouAfterSessionPopUp/ThankYouAfterSessionPopUp';
import ClientConfirmSessionThankYou from "./pages/ClientConfirmSessionThankYou/ClientConfirmSessionThankYou";
import Messages from "./pages/Messages/Messages";
// import Role from './pages/Role/Role';
import VideoCall from "./pages/VideoCall/VideoCall";

//Logged in
import RestrictedLoggedInRoute from "./components/restrictedRoutes/RestrictedLoggedInRoute/RestrictedLoggedInRoute";

//NOT Logged in
import RestrictedNOTLoggedInRoute from "./components/restrictedRoutes/RestrictedNOTLoggedInRoute/RestrictedNOTLoggedInRoute";

//Active Company
import RestrictedActiveCompanyRoute from "./components/restrictedRoutes/RestrictedActiveCompanyRoute/RestrictedActiveCompanyRoute";

//Mentors
import RestrictedMentorsRoute from "./components/restrictedRoutes/RestrictedMentorsRoute/RestrictedMentorsRoute";
import RestrictedNotAcceptedMentorsRoute from "./components/restrictedRoutes/RestrictedNotAcceptedMentorsRoute/RestrictedNotAcceptedMentorsRoute";

//Mentors applying
import RestrictedMentorApplyRoute from "./components/restrictedRoutes/RestrictedMentorApplyRoute/RestrictedMentorApplyRoute";
import RestrictedMentorMOProfileDetailsRoute from "./components/restrictedRoutes/RestrictedMentorMOProfileDetailsRoute/RestrictedMentorMOProfileDetailsRoute";
import RestrictedMentorMOYourExperienceRoute from "./components/restrictedRoutes/RestrictedMentorMOYourExperienceRoute/RestrictedMentorMOYourExperienceRoute";
import RestrictedMentorMOWorkExperienceRoute from "./components/restrictedRoutes/RestrictedMentorMOWorkExperienceRoute/RestrictedMentorMOWorkExperienceRoute";
import RestrictedMentorNotThankYouCompletedRoute from "./components/restrictedRoutes/RestrictedMentorNotThankYouCompletedRoute/RestrictedMentorNotThankYouCompletedRoute";

//Accepted mentors
import RestrictedAcceptedMentorsRoute from "./components/restrictedRoutes/RestrictedAcceptedMentorsRoute/RestrictedAcceptedMentorsRoute";

import RestrictedMentorNotYouAreAcceptedCompletedRoute from "./components/restrictedRoutes/RestrictedMentorNotYouAreAcceptedCompletedRoute/RestrictedMentorNotYouAreAcceptedCompletedRoute";
import RestrictedMentorNotCreateYourFirstSessionCompletedRoute from "./components/restrictedRoutes/RestrictedMentorNotCreateYourFirstSessionCompletedRoute/RestrictedMentorNotCreateYourFirstSessionCompletedRoute";
// import RestrictedMentorNotSetAvailabilityCompletedRoute from "./components/restrictedRoutes/RestrictedMentorNotSetAvailabilityCompletedRoute/RestrictedMentorNotSetAvailabilityCompletedRoute"
import RestrictedMentorNotPayoutMethodCompletedRoute from "./components/restrictedRoutes/RestrictedMentorNotPayoutMethodCompletedRoute/RestrictedMentorNotPayoutMethodCompletedRoute";
// import RestrictedMentorNotPayoutMethod2CompletedRoute from "./components/restrictedRoutes/RestrictedMentorNotPayoutMethod2CompletedRoute/RestrictedMentorNotPayoutMethod2CompletedRoute"
import RestrictedMentorNotYouAreAllSetCompletedRoute from "./components/restrictedRoutes/RestrictedMentorNotYouAreAllSetCompletedRoute/RestrictedMentorNotYouAreAllSetCompletedRoute";

//Active mentors
import RestrictedActiveMentorsRoute from "./components/restrictedRoutes/RestrictedActiveMentorsRoute/RestrictedActiveMentorsRoute";

import RestrictedWhatKindOfMentor from "./components/restrictedRoutes/RestrictedWhatKindOfMentor/RestrictedWhatKindOfMentor";

import RestrictedClientsRoute from "./components/restrictedRoutes/RestrictedClientsRoute/RestrictedClientsRoute";

import RestrictedClientSelectCategories from "./components/restrictedRoutes/RestrictedClientSelectCategories/RestrictedClientSelectCategories";
import RestrictedClientPaymentDetails from "./components/restrictedRoutes/RestrictedClientPaymentDetails/RestrictedClientPaymentDetails";
import RestrictedAdminsRoute from "./components/restrictedRoutes/RestrictedAdminsRoute/RestrictedAdminsRoute";
import RestrictedOwnersRoute from "./components/restrictedRoutes/RestrictedOwnersRoute/RestrictedOwnersRoute";

// import NewCalendarTest from './pages/NewCalendarTest/NewCalendarTest';
import InSession from "./pages/InSession/InSession";
import FavoriteMentors from "./pages/FavoriteMentors/FavoriteMentors";

import { getAllCompanies } from "./api/index.js";
import { companyData } from "./actions/auth";
import { useDispatch } from "react-redux";

const Views = (props) => {
    //console.log(props)

    const [firstLoad, setFirstLoad] = useState(true);
    const [errorPage, setErrorPage] = useState(true);
    // const [activeCompany, setactiveCompany] 	= useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        async function getSubdomains() {
            const queryString = `?fields=subdomain,id,active`;
            const { data } = await getAllCompanies(queryString);

            //console.log('data', data.data.companies)

            const sites = data.data.companies;

            // If someone wants to have a '.' (dot) in the url(name), example -> 'web.site.mentorx'
            // We need to cut the domain (mentorx) with the last dot from the url (host variable)
            const host = window.location.host;
            // const urlWithoutDots 	= host.split('.');
            // const domain 			= urlWithoutDots[urlWithoutDots.length - 1];
            const domainString =
                process.env.NODE_ENV !== "development"
                    ? ".mentrx"
                    //: ".mentrx";
                    : ".localhost";
            const subdomain = host.substring(0, host.indexOf(domainString));

            //	accessing admin page
            if (subdomain === "admin") {
                setErrorPage(false);
                setFirstLoad(false);
                return false;
            }

            // console.log(sites);

            sites.map((site) => {
                if (site.subdomain === subdomain) {
                    setErrorPage(false);
                    setFirstLoad(false);
                    //TO CHECK: What was this for?
                    // if(site.active) {
                    // 	setactiveCompany(true);
                    // }

                    dispatch(companyData(`${site.subdomain}`));
                    // console.log("happens");
                    return false;
                }

                setFirstLoad(false);
                return false;
            });
        }

        getSubdomains();
    }, [dispatch]);

    return (
        <>
            {/*console.log(errorPage)*/}
            {firstLoad ? (
                <></>
            ) : errorPage ? (
                <Page404 />
            ) : (
                <Routes>
                    {/*<Route path="/test" element={<NewCalendarTest/>} />*/}

                    {/*NOT Logged in*/}
                    <Route element={<RestrictedNOTLoggedInRoute />}>
                        <Route path="/LogIn" element={<LogIn />} />
                        <Route
                            path="/LogInEmailConfirmation"
                            element={<LogInEmailConfirmation />}
                        />
                        <Route
                            path="/LogInEnterNewPassword"
                            element={<LogInEnterNewPassword />}
                        />
                        <Route
                            path="/LogInForgotYourPassword"
                            element={<LogInForgotYourPassword />}
                        />
                        <Route element={<RestrictedActiveCompanyRoute />}>
                            <Route path="/Signup" element={<Signup />} />
                        </Route>
                    </Route>

                    {/*Logged in*/}
                    <Route element={<RestrictedLoggedInRoute />}>
                        {/*Active Company*/}
                        <Route element={<RestrictedActiveCompanyRoute />}>
                            {/*Mentor*/}
                            <Route element={<RestrictedMentorsRoute />}>
                                {/*Mentors not accepted*/}
                                <Route
                                    element={
                                        <RestrictedNotAcceptedMentorsRoute />
                                    }
                                >
                                    <Route
                                        element={<RestrictedMentorApplyRoute />}
                                    >
                                        <Route
                                            path="/MOMentorsApply"
                                            element={<MOMentorsApply />}
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <RestrictedMentorMOProfileDetailsRoute />
                                        }
                                    >
                                        <Route
                                            path="/MOProfileDetails"
                                            element={<MOProfileDetails />}
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <RestrictedMentorMOYourExperienceRoute />
                                        }
                                    >
                                        <Route
                                            path="/MOYourExperience"
                                            element={<MOYourExperience />}
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <RestrictedMentorMOWorkExperienceRoute />
                                        }
                                    >
                                        <Route
                                            path="/MOWorkExperience"
                                            element={<MOWorkExperience />}
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <RestrictedMentorNotThankYouCompletedRoute />
                                        }
                                    >
                                        <Route
                                            path="/OnboardingMentor5"
                                            element={<OnboardingMentor5 />}
                                        />
                                    </Route>
                                </Route>

                                {/*Mentors accepted*/}
                                <Route
                                    element={<RestrictedAcceptedMentorsRoute />}
                                >
                                    <Route
                                        element={
                                            <RestrictedMentorNotYouAreAcceptedCompletedRoute />
                                        }
                                    >
                                        <Route
                                            path="/OnboardingMentor6"
                                            element={<OnboardingMentor6 />}
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <RestrictedMentorNotPayoutMethodCompletedRoute />
                                        }
                                    >
                                        <Route
                                            path="/OnboardingMentor9PayoutMethod"
                                            element={
                                                <OnboardingMentor9PayoutMethod />
                                            }
                                        />
                                    </Route>

                                    {/* <Route element={<RestrictedMentorNotPayoutMethod2CompletedRoute />}>
										<Route path="/OnboardingMentor9PayoutMethod2"  element={<OnboardingMentor9PayoutMethod2 />}/>
									</Route> */}

                                    <Route
                                        element={
                                            <RestrictedMentorNotYouAreAllSetCompletedRoute />
                                        }
                                    >
                                        <Route
                                            path="/OnboardingMentor10"
                                            element={<OnboardingMentor10 />}
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <RestrictedMentorNotCreateYourFirstSessionCompletedRoute />
                                        }
                                    >
                                        <Route
                                            path="/OnboardingMentor7CreateYourFirstSession"
                                            element={
                                                <OnboardingMentor7CreateYourFirstSession />
                                            }
                                        />
                                    </Route>

                                    {/*<Route element={<RestrictedMentorNotSetAvailabilityCompletedRoute />}>*/}
                                    <Route
                                        path="/OnboardingMentor8SetAvailability"
                                        element={
                                            <OnboardingMentor8SetAvailability />
                                        }
                                    />
                                    {/*</Route>*/}

                                    {/*Mentors active*/}
                                    <Route
                                        element={
                                            <RestrictedActiveMentorsRoute />
                                        }
                                    >
                                        <Route
                                            path="/OnboardingMentor12WelcomePage"
                                            element={
                                                <OnboardingMentor12WelcomePage />
                                            }
                                        />
                                        <Route
                                            path="/OnboardingMentor13Service"
                                            element={
                                                <OnboardingMentor13Service />
                                            }
                                        />

                                        <Route
                                            path="/MentorFlowClientProfile"
                                            element={
                                                <MentorFlowClientProfile />
                                            }
                                        />
                                    </Route>
                                </Route>
                            </Route>

                            {/*Client*/}
                            <Route element={<RestrictedClientsRoute />}>
                                <Route
                                    element={
                                        <RestrictedClientSelectCategories />
                                    }
                                >
                                    <Route
                                        path="/ClientSelectCategories"
                                        element={<ClientSelectCategories />}
                                    />
                                </Route>

                                <Route
                                    element={<RestrictedClientPaymentDetails />}
                                >
                                    <Route
                                        path="/LogInClientSetYourPaymentDetails"
                                        element={
                                            <LogInClientSetYourPaymentDetails />
                                        }
                                    />
                                </Route>

                                <Route element={<RestrictedWhatKindOfMentor />}>
                                    <Route
                                        path="/WhatKindOfMentor"
                                        element={<WhatKindOfMentor />}
                                    />
                                </Route>

                                <Route
                                    path="/MentorProfileUserSide"
                                    element={<MentorProfileUserSide />}
                                />

                                <Route
                                    path="/ClientSettingsPanelPaymentAndPayouts1"
                                    element={
                                        <ClientSettingsPanelPaymentAndPayouts1 />
                                    }
                                />
                                <Route
                                    path="/ClientSettingsPanelPaymentAndPayouts2"
                                    element={
                                        <ClientSettingsPanelPaymentAndPayouts2 />
                                    }
                                />
                                <Route
                                    path="/ClientSettingsPanelPaymentAndPayouts3"
                                    element={
                                        <ClientSettingsPanelPaymentAndPayouts3 />
                                    }
                                />
                                <Route
                                    path="/ClientSettingsPanelPaymentAndPayouts4"
                                    element={
                                        <ClientSettingsPanelPaymentAndPayouts4 />
                                    }
                                />
                                <Route
                                    path="/ClientSettingsPanelPaymentAndPayouts5"
                                    element={
                                        <ClientSettingsPanelPaymentAndPayouts5 />
                                    }
                                />

                                <Route
                                    path="/LogInClientSetYourPaymentMethod"
                                    element={
                                        <LogInClientSetYourPaymentMethod />
                                    }
                                />
                                <Route
                                    path="/LogInClientSetYourPaymentDetails2"
                                    element={
                                        <LogInClientSetYourPaymentDetails2 />
                                    }
                                />

                                <Route
                                    path="/ClientBookOffer"
                                    element={<ClientBookOffer />}
                                />

                                <Route
                                    path="/ClientConfirmSessionThankYou"
                                    element={<ClientConfirmSessionThankYou />}
                                />
                                <Route
                                    path="/ClientConfirmSession"
                                    element={
                                        <ClientConfirmSession
                                            statusPopUp={props.statusPopUp}
                                        />
                                    }
                                />
                                <Route
                                    path="/FavoriteMentors"
                                    element={<FavoriteMentors />}
                                />
                            </Route>
                        </Route>

                        {/*Admin*/}
                        <Route element={<RestrictedAdminsRoute />}>
                            <Route
                                path="/Admin"
                                element={
                                    <Admin statusPopUp={props.statusPopUp} />
                                }
                            />
                            <Route
                                path="/AdminCompany"
                                element={<AdminCompany />}
                            />
                        </Route>

                        {/*Owner/Director*/}
                        <Route element={<RestrictedOwnersRoute />}>
                            <Route
                                path="/Owner"
                                element={
                                    <Owner statusPopUp={props.statusPopUp} />
                                }
                            />
                        </Route>

                        {/*profile settings*/}
                        <Route
                            path="/MentorHome2SettingsPanel"
                            element={<MentorHome2SettingsPanel />}
                        />
                        <Route
                            path="/ClientSettingsPanelPersonalInfo"
                            element={<ClientSettingsPanelPersonalInfo />}
                        />
                        <Route
                            path="/ClientSettingsPanelLoginAndSecurity"
                            element={<ClientSettingsPanelLoginAndSecurity />}
                        />
                        <Route
                            path="/MentorHome3Notifications"
                            element={<MentorHome3Notifications />}
                        />
                        <Route
                            path="/ClientSettingsPanelContactSupport"
                            element={<ClientSettingsPanelContactSupport />}
                        />
                        <Route path="/Messages" element={<Messages />} />

                        <Route
                            path="/HowMentorXWorks"
                            element={<HowMentorXWorks />}
                        />

                        {/*All logged in (Not Admin, not owner)*/}

                        <Route
                            path="/OnboardingMentor11ClientSingleView"
                            element={
                                <OnboardingMentor11ClientSingleView
                                    statusPopUp={props.statusPopUp}
                                />
                            }
                        />
                        <Route path="/Reviews" element={<Reviews />} />
                        <Route path="/ClientNotes" element={<ClientNotes />} />
                        <Route
                            path="/Notifications"
                            element={
                                <Notifications
                                    redDot={props.redDot}
                                    parentEvent={props.parentEvent}
                                />
                            }
                        />
                        <Route path="/Schedule" element={<Schedule />} />

                        <Route path="/VideoCall" element={<VideoCall />} />
                        <Route path="/InSession" element={<InSession />} />
                    </Route>

                    {/*Should go to popups (components)*/}
                    {/*<Route path="/NewNotePoUp"  element={<NewNotePoUp />}/>
					<Route path="/YourSessionHasEndedPopUp"  element={<YourSessionHasEndedPopUp />}/>
					<Route path="/ThankYouAfterSessionPopUp"  element={<ThankYouAfterSessionPopUp />}/>
					<Route path="/ReschedulePopUp"  element={<ReschedulePopUp />}/>
					<Route path="/SuccessPopUpMentorFlow"  element={<SuccessPopUpMentorFlow />}/>
					<Route path="/ThanksPopUpMentorFlow"  element={<ThanksPopUpMentorFlow />}/>
					<Route path="/ApplyFilters"  element={<ApplyFilters />}/>*/}

                    {/*NOT SURE*/}
                    {/*<Route path="/UserProfile"  element={<UserProfile />}/>
					<Route path="/Role"  element={<Role />}/>*/}

                    {/*404*/}
                    <Route path="*" element={<Page404 />} />
                </Routes>
            )}
        </>
    );
};

export default Views;
