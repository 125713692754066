import StripeContainer from "../StripeContainer/StripeContainer";

const ConifrmSessionPaymentComponent = ({
  parentEvent,
  check,
  dataForm,
  setParentSessionID,
}) => {
  return (
    <div>
      <h2 className="H2regular textAlignLeft mBottom12">Payment</h2>
      {/* <p className="Pmedium textAlignLeft mBottom12">Some more info</p>
            <h5 className="P1bold textAlignLeft mBottom12">Pay With</h5>
            <select className="boxShadowForSearchLanding w100 conifrmSessionPaymentComponentSelect Small-Labelregular pLeft8 textAlignLeft verticallyAlignMid borderNone pY14 mBottom30">
                <option>Select Payment Method</option>
            </select> */}

      <StripeContainer
        parentEvent={parentEvent}
        check={check}
        dataForm={dataForm}
        setParentSessionID={setParentSessionID}
      />

      {/* <p className="Pbold textAlignLeft mBottom12">Promo code</p> */}
    </div>
  );
};

export default ConifrmSessionPaymentComponent;
