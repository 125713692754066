// import { Link } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mentorOnboarding } from "../../actions/auth.js";

function MOMentorsApply(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const company = props.company;

    if (!company._id) {
        navigate("/LogIn");
    }

    const formData = { setMentorsApply: true };

    function goToNextPage(e) {
        // e.preventDefault();
        dispatch(mentorOnboarding(formData, navigate));
    }

    return (
        <div className="OnboardingMentor1">
            <div className="w80 mx-auto mY70 backColPrimaryLight borderRadius24">
                <div className="verticallyAlignMid w80 mx-auto mY70 onboardingMentor1Sub">
                    <div className="w50 mTop50 mBottom50 loginStartingPages mRightAuto">
                        {/* <p className="P1regular textAlignLeft mBottom20">We're looking for</p> */}
                        <h2 className="H2medium textAlignLeft mBottom20">
                            <span className="colPrimaryGreen">
                                {
                                    company?.whiteLabel?.texts?.mentorsApply
                                        ?.greenLabel
                                }
                            </span>{" "}
                            {
                                company?.whiteLabel?.texts?.mentorsApply
                                    ?.largeHeader
                            }
                        </h2>
                        <div className="mY20 loginStartingPagesImg2">
                            <img
                                alt="Welcome"
                                className="welcomeImg"
                                src={
                                    company.whiteLabel.images.mentorsApply !==
                                    ""
                                        ? company.whiteLabel.images.mentorsApply
                                        : "https://i.ibb.co/fD1yxzd/img.png"
                                }
                            />
                        </div>
                        <p className="textAlignLeft Pmedium mBottom30 w90">
                            {
                                company?.whiteLabel?.texts?.mentorsApply
                                    ?.mainHeader
                            }
                        </p>
                        {company?.whiteLabel?.texts?.mentorsApply?.label1 &&
                            company?.whiteLabel?.texts?.mentorsApply?.label1 !==
                                "" && (
                                <div className="verticallyAlignMidJustConStart listItemMain">
                                    <div className="dotForListItem mLeft4 mRight4"></div>
                                    <p className="Pmedium textAlignLeft">
                                        {
                                            company?.whiteLabel?.texts
                                                ?.mentorsApply?.label1
                                        }
                                    </p>
                                </div>
                            )}
                        {company?.whiteLabel?.texts?.mentorsApply?.label2 &&
                            company?.whiteLabel?.texts?.mentorsApply?.label2 !==
                                "" && (
                                <div className="verticallyAlignMidJustConStart listItemMain">
                                    <div className="dotForListItem mLeft4 mRight4"></div>
                                    <p className="Pmedium">
                                        {
                                            company?.whiteLabel?.texts
                                                ?.mentorsApply?.label2
                                        }
                                    </p>
                                </div>
                            )}
                        {company?.whiteLabel?.texts?.mentorsApply?.label3 &&
                            company?.whiteLabel?.texts?.mentorsApply?.label3 !==
                                "" && (
                                <div className="verticallyAlignMidJustConStart mBottom30 listItemMain">
                                    <div className="dotForListItem mLeft4 mRight4"></div>
                                    <p className="Pmedium">
                                        {
                                            company?.whiteLabel?.texts
                                                ?.mentorsApply?.label3
                                        }
                                    </p>
                                </div>
                            )}
                        <button
                            type="input"
                            onClick={goToNextPage}
                            style={{
                                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                            }}
                            className="borderRadius12 borderNone verticallyAlignMid mBottom30 P1regular colOffWhite w180 pY10"
                        >
                            Apply
                        </button>
                    </div>
                    <div className="w50 mY50 loginStartingPagesImg">
                        <img
                            alt="Welcome"
                            className="welcomeImg"
                            src={
                                company.whiteLabel.images.mentorsApply !== ""
                                    ? company.whiteLabel.images.mentorsApply
                                    : "https://i.ibb.co/fD1yxzd/img.png"
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function getData(state) {
    return {
        company: state.auth?.authData?.company || {},
    };
}

export default connect(getData)(MOMentorsApply);
