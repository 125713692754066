import Moment from "moment";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { updateUser, updatePassword } from "../../api/index.js";
import { connectSocial } from "../../actions/auth.js";
import { RiArrowLeftSLine } from "react-icons/ri";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import jwt_decode from "jwt-decode";

function ClientSettingsPanelLoginAndSecurity(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    if (!props.company._id || !props.user._id) {
        navigate("/LogIn");
    }

    const company = props.company;
    const user    = props.user;

    console.log(user);

    // const [email, setEmail] 		= useState(false);
    const [password, setPassword]         = useState(false);
    const [active, setActive]             = useState(props.user?.active);
    const [passwordData, setPasswordData] = useState({ old: "", new: "" , repeat: ""});

    async function updateUserLogin(type) {
        // const queryString = `?_id=${props.user?._id}`;

        if (type === "email") {
            // setEmail(false)
            // updateUser(props.user?._id, {email});
        } else if (type === "password") {

            // user.passwordChangedAt -> because we cant read users password, we use passwordChangedAt

            // if (
            //   passwordData.repeat === "" ||
            //   passwordData.new === "" ||
            //   (passwordData.old === "" && user.passwordChangedAt)
            //   || passwordData.new !== passwordData.repeat
            // ) {
            //   setErrorText("You need to fill all inputs");
            //   return false;
            // }

            const formData = {
                password        : passwordData.new,
                passwordConfirm : passwordData.repeat,
                passwordCurrent : passwordData.old
            }

            if (!user.passwordChangedAt) {
                // password doesn't exists
                formData.social = true;
            }

            setPassword(false);
            const data = await dispatch(updatePassword(formData));
        } else if (type === "active") {
            setActive(!active);
            updateUser(props.user?._id, { active });
        }
    }

    function getPasswordTime(time) {
        let diffMinutes = Moment(new Date()).diff(Moment(time), "minutes");
        if (diffMinutes > 59) {
            let diffHours = Moment(new Date()).diff(Moment(time), "hours");
            if (diffHours > 23) {
                let diffDays = Moment(new Date()).diff(Moment(time), "days");
                if (diffDays > 6) {
                    let diffWeeks = Moment(new Date()).diff(Moment(time), "weeks");
                    if (diffWeeks > 4) {
                        let diffMonth = Moment(new Date()).diff(Moment(time), "months");
                        return diffMonth + " month/s ago";
                    } else {
                        return diffWeeks + " week/s ago";
                    }
                } else {
                    return diffDays + " day/s ago";
                }
            } else {
                return diffHours + " hour/s ago";
            }
        } else {
            return diffMinutes + " minute/s ago";
        }
    }

    const [errorText, setErrorText] = useState(false);

    function setNewPassword() {
        if (passwordData.new === passwordData.old) {
            setErrorText("Old and new passwords are the same");
        } else if (passwordData.repeat !== passwordData.new) {
            setErrorText("Passwords are not the same");
        // } else if (passwordData.new === "" || passwordData.repeat === "") {
        //   setErrorText("You need to fill all the inputs");
        } else {
            setErrorText(false);
        }
    }

    // useEffect(() => {
    // 	setEmail(false);
    // 	setActive(props.user?.active)
    // 	setPassword(false)
    // },[props])

    const responseFacebook = async (response) => {
        const userData = {
            connection  : true,
            type        : 'facebookID',
            id          : response.id,
        };

        const data = await dispatch(connectSocial(user._id, userData));
        console.log(data);
    };

    async function socialConnection(type) {
        const userData = {
            connection : false,
            type,
        };

        const data = await dispatch(connectSocial(user._id, userData));
        console.log(data);
    }

    async function handleGoogleResponse(response) {
        if (response.credential) {
            const user = jwt_decode(response.credential);
            // console.log(user);

            if (user.email_verified) {
                const userData = {
                    connection  : true,
                    type        : 'googleID',
                    id          : user.sub,
                };

                const data = await dispatch(connectSocial(user._id, userData));
                console.log(data);
            }
        }
    }

    useEffect(() => {
        const google = window.google;

        google.accounts.id.initialize({
                // client_id: "567198460390-oo360r7n7jsiruap9nilpe4ooohqkhfs.apps.googleusercontent.com",
                //client_id   : "567198460390-eqfp1i18tf1lbphu7h50a9c7h547s1rs.apps.googleusercontent.com",
                //client_id: "241251241244-6cmsret0k7g8aovg1unrauu1gvk4dfhc.apps.googleusercontent.com",
                client_id: "241251241244-4sckjn6mdd2n969nscj4e0ahhqurg38r.apps.googleusercontent.com",
                callback    : handleGoogleResponse,
        });

        google.accounts.id.renderButton(document.getElementById("signInDiv"), {
                theme   : "outline",
                size    : "large",
        });

        // google.accounts.id.prompt();
    }, []);

    return (
        <div className="ClientSettingsPanelLoginAndSecurity">
            <div className="verticallyAlignMidJustConStart mTopForSmallerCreens w80 mXAuto mTop70 mBottom40">
                <Link to="/MentorHome2SettingsPanel">
                    <div className="posRel mRight20">
                        <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                            <RiArrowLeftSLine size="2em" className="colPrimaryGreen" />
                        </button>
                    </div>
                </Link>
                <h2 className="textAlignLeft H1regular">Login and Security</h2>
            </div>
            <div className="w80 mXAuto">
                <div className="w50 ClientSettingsPanelLoginAndSecuritySubb1">
                    <h4 className="textAlignLeft H2medium mBottom50">Login</h4>
                    {/* <div className="verticallyAlignMidJustConStart mLeft8 mBottom30">
				    	<div>
                    <h5 className="H3medium">Email address</h5>
                    { email ?
                <input onChange={(e) => setEmail(e.target.value)} type="text" placeholder={props.user?.email ?? ""}/>
                : <p onClick={() => setEmail(true)} className="Pbold textAlignLeft">{props.user?.email ?? ""}</p>
							}

				    	</div>
				    	{ email && <button onClick={() => updateUserLogin("email")} className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold">Update</button>}
                </div>
                <div className="borderGrayTopInput mY20"></div> */}
                    <div className="verticallyAlignMidJustConStart ClientSettingsPanelLoginAndSecurityAll mLeft8 mTop30">
                        <div className="mBottom20">
                            <h5 className="H3medium textAlignLeft">Password</h5>
                            {password ? (
                                <>
                                    <p className="Pbold textAlignLeft">
                                        Last updated{" "}
                                        {getPasswordTime(props.user?.passwordChangedAt)}
                                    </p>

                                    {user.passwordChangedAt && (
                                        <input
                                            className="verticallyAlignMidJustConStart"
                                            onChange={(e) =>
                                                setPasswordData({
                                                    ...passwordData,
                                                    old: e.target.value,
                                                })
                                            }
                                            type="password"
                                            placeholder="Enter current password"
                                        />
                                    )}

                                    <input
                                        className="verticallyAlignMidJustConStart"
                                        onChange={(e) =>
                                            {setPasswordData({
                                                ...passwordData,
                                                new: e.target.value,
                                            }); setNewPassword()}
                                        }
                                        type="password"
                                        placeholder="Enter new password"
                                    />
                                    <input
                                        className="verticallyAlignMidJustConStart"
                                        onChange={(e) =>
                                            {setPasswordData({
                                                ...passwordData,
                                                repeat: e.target.value,
                                            }); setNewPassword()}
                                        }
                                        type="password"
                                        placeholder="Repeat new password"
                                    />
                                </>
                            ) : (
                                <p
                                    // onClick={() => setPassword(true)}
                                    className="Pbold textAlignLeft"
                                >
                                    Last updated {getPasswordTime(props.user?.passwordChangedAt)}
                                </p>
                            )}
                        </div>
                        {password ? (
                            <>
                                <button
                                    onClick={() => updateUserLogin("password")}
                                    className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold"
                                >
                                    Update
                                </button>
                                <button
                                    onClick={() => setPassword(false)}
                                    className="mLeft20 borderNone backColWhite borderBottomRedForButtons colErrorRed Pbold"
                                >
                                    Cancel
                                </button>
                            </>
                        ) : (
                            <button
                                onClick={() => setPassword(true)}
                                className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold"
                            >
                                Edit password
                            </button>
                        )}
                    </div>
                    {errorText && (
                        <div className="disFlex verticallyAlignMid">
                            <p className="P1bold colErrorRed">{errorText}</p>
                        </div>
                    )}
                    <div className="borderGrayTopInput mY20"></div>
                    <h4 className="textAlignLeft H2medium mTop50">Social Accounts</h4>
                    {/* <div className="verticallyAlignMidJustConStart ClientSettingsPanelLoginAndSecurityAll mLeft8 mTop30">
                        <div className="mBottom20">
                            <h5 className="H3medium textAlignLeft">Facebook</h5>
                            {user.facebookID ? (
                                <p className="Pbold textAlignLeft">Connected</p>
                            ) : (
                                <p className="Pbold textAlignLeft">Not connected</p>
                            )}
                        </div>
                        {user.facebookID ? (
                            <button onClick={() => socialConnection('facebookID')} className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold">
                                Disconnect
                            </button>
                        ) : (
                            <FacebookLogin
                                    appId="356156892738915"
                                    fields="email,name"
                                    callback={responseFacebook}
                                    render={(renderProps) => (
                                        <button
                                            onClick={renderProps.onClick}
                                            className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold"
                                        >
                                            Connect
                                        </button>
                                    )}
                            />
                        )}
                    </div> */}
                    <div className="borderGrayTopInput mY20"></div>
                    <div className="verticallyAlignMidJustConStart ClientSettingsPanelLoginAndSecurityAll mLeft8 mTop30">
                        <div className="mBottom20">
                            <h5 className="H3medium textAlignLeft">Google</h5>
                            {user.googleID ? (
                                <p className="textAlignLeft Pbold">Connected</p>
                            ) : (
                                <p className="textAlignLeft Pbold">Not connected</p>
                            )}
                        </div>
                        {user.googleID ? (
                            <button onClick={() => socialConnection('googleID')} className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold">
                                Disconnect
                            </button>
                        ) : (
                            // <button className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold">
                            //     Connect
                            // </button>
                            <div id="signInDiv" className="mLeftAuto"></div>
                        )}
                    </div>
                    <div className="borderGrayTopInput mY20"></div>
                    <h4 className="textAlignLeft H2medium mTop50 mBottom30">Account</h4>
                    <div className="verticallyAlignMidJustConStart ClientSettingsPanelLoginAndSecurityAll mLeft8">
                        {active ? (
                            <>
                                <p className="textAlignLeft Pbold">Activate your account</p>
                                <button
                                    onClick={() => updateUserLogin("active")}
                                    className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold"
                                >
                                    Activate
                                </button>
                            </>
                        ) : (
                            <>
                                <p className="textAlignLeft Pbold">Deactivate your account</p>
                                <button
                                    onClick={() => updateUserLogin("active")}
                                    className="mLeftAuto borderNone backColWhite borderBottomForButtons colSuccessGreen Pbold"
                                >
                                    Deactivate
                                </button>
                            </>
                        )}
                    </div>
                    <div className="borderGrayTopInput mY20 pBottom70"></div>
                </div>
            </div>
        </div>
    );
}

function getUserData(state) {
    return {
        user: state.auth.authData?.data.user || {},
        company: state.auth.authData?.company,
    };
}

export default connect(getUserData)(ClientSettingsPanelLoginAndSecurity);
