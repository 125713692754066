import { RiStarFill } from "react-icons/ri";
import Moment from 'moment';

const ReviewsCard = ({review}) => {

	let date 	= Moment(review.date).format('LLLL');
	date 		= Moment(review.date).format('MMMM YYYY');

	function getInitials(name) {
		let words = name.split(' ');

		return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
	}

   return (
      	<div className="backColElementBackgroundGray borderRadius12 ReviewsCardMainCard mRight8 w400 mBottom20">
			<div className="w90 mXAuto">
				<div className="verticallyAlignMidJustConStart fakeForReviewsCard1">
					<div className="pRight1 mRight5 mY20">
		    			<div className="verticallyAlignMidJustConStart reviewsCardImg borderRadius50">
		    				<img alt="" className="w100 h100" src="https://i.ibb.co/fD1yxzd/img.png" />
		    			</div>
		    		</div>
	    			<div className="verticallyAlignMidStretch mLeftAuto">
						<RiStarFill size="1.5em" className="cursorPointer colPrimaryGreen mRight8"/>
						<h5 className="Small-LabelCaps">{review.rating}/5</h5>
					</div>
	    		</div>
	    		<div className="verticallyAlignMidJustConStart">
		    		<div className="pRight1 mRight5 mY20 fakeForReviewsCard2">
		    			<div className="verticallyAlignMidJustConStart reviewsCardImg borderRadius50">
							{ review.clientID?.photo ?
                                <img alt="" className="w100 h100" src={review.clientID.photo} />
                            :
								<div className="userInitialsSmall">
									<p>
										{getInitials(review.clientID.fullName)}
									</p>
								</div>
							}
		    			</div>
		    		</div>
	    			<div>
	    				<div className="textAlignLeft Pbold">{review.clientID.fullName}</div>
	    				<p className="textAlignLeft Small-Label-11 colLabelGray">{date}</p>
	    			</div>
	    			<div className="verticallyAlignMidStretch mLeftAuto fakeForReviewsCard2">
						<RiStarFill size="1.5em" className="cursorPointer colPrimaryGreen mRight8"/>
						<h5 className="Small-LabelCaps">{review.rating}/5</h5>
					</div>
	    		</div>
	    		<p className="Small-LabelCaps textAlignLeft mBottom20">{review.text}</p>
	    	</div>
		</div>
   );
}

export default ReviewsCard;
