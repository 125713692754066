import { Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

function RestrictedMentorNotCreateYourFirstSessionCompletedRoute(props) {
	if(!props.userData.user.mentorOnboarding.welcomeCompleted) {
		return <Navigate to="/OnboardingMentor6" />;
	} else if(!props.userData.user.mentorOnboarding.firstServiceCompleted) {
		return <Outlet />;
	} else if(!props.userData.user.mentorOnboarding.availabilityCompleted){
		return <Navigate to="/OnboardingMentor8SetAvailability" />;
	} else if(!props.userData.user.mentorOnboarding.welcomeCompleted) {
		return <Navigate to="/OnboardingMentor10" />
	} else {
		return <Outlet />;
	}
};

function getUser(state) {
	// console.log('RestrictedMentorNotCreateYourFirstSessionCompletedRoute', state.auth.authData?.data.user)
	return {
		userData: {
			user : state.auth.authData?.data.user
		}
	}
}

export default connect(getUser)(RestrictedMentorNotCreateYourFirstSessionCompletedRoute);

