import CloseButton from "../../components/buttons/CloseButton/CloseButton";
import { BsCheckCircleFill } from "react-icons/bs";

function SuccessPopUpMentorFlow({ type, parentEvent }) {
    return (
        <div className="SuccessPopUpMentorFlow">
            <div className="mTop70 mBottom70 mXAuto ReschedulePopUpSubb borderRadius24 backColOffWhite">
                <div className="w90 mXAuto">
                    <div className="verticallyAlignMidJustConEnd mY30">
                        <CloseButton parentEvent={parentEvent} />
                    </div>
                    <div className="verticallyAlignMid mBottom70">
                        <div className="w300">
                            <BsCheckCircleFill
                                size="5em"
                                className="colPrimaryGreen mBottom12"
                            />
                            <p className="verticallyAlignMid P1medium">
                                Your session has been successfully{" "}
                                {type === "popupCancel"
                                    ? "cancelled"
                                    : "updated"}
                                .
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuccessPopUpMentorFlow;
