import { Link } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";

function MentorHome3Notifications() {
    return (
        <div className="MentorHome3Notifications">
            <div className="verticallyAlignMidJustConStart MentorHome3NotificationsSubb2 w80 mXAuto mTop70 mBottom40">
                <Link to="/MentorHome2SettingsPanel">
                    <div className="posRel mRight20">
                        <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                            <RiArrowLeftSLine
                                size="2em"
                                className="colPrimaryGreen"
                            />
                        </button>
                    </div>
                </Link>
                <h2 className="textAlignLeft H1regular">Notifications</h2>
            </div>
            <div className="w80 mXAuto mBottom50">
                <div className="w50 MentorHome3NotificationsSubb1">
                    <h4 className="textAlignLeft H2medium">MentorX Updates</h4>
                    <p className="Pregular textAlignLeft mBottom30">
                        Stay up date with persnalized recommendations and
                        special offers.
                    </p>
                    <div className="verticallyAlignMidJustConStart">
                        <div className="mBottom20">
                            <h5 className="textAlignLeft P1medium">
                                News and Offers
                            </h5>
                            <p className="textAlignLeft Pregular">On</p>
                        </div>
                        <label className="switch mLeftAuto">
                            <input className="" type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="verticallyAlignMidJustConStart">
                        <div className="mBottom20">
                            <h5 className="textAlignLeft P1medium">Feedback</h5>
                            <p className="textAlignLeft Pregular">On</p>
                        </div>
                        <label className="switch mLeftAuto">
                            <input className="" type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="borderGrayTopInput mY20"></div>
                    <h4 className="textAlignLeft H3bold">Remainders</h4>
                    <div className="verticallyAlignMidJustConStart">
                        <div className="mBottom20">
                            <h5 className="textAlignLeft P1medium">
                                Remainders
                            </h5>
                            <p className="textAlignLeft Pregular">On</p>
                        </div>
                        <label className="switch mLeftAuto">
                            <input className="" type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="verticallyAlignMidJustConStart">
                        <div className="mBottom20">
                            <h5 className="textAlignLeft P1medium">
                                Calendars
                            </h5>
                            <p className="textAlignLeft Pregular">
                            info@mentrx.io
                            </p>
                        </div>
                        <label className="switch mLeftAuto">
                            <input className="" type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="borderGrayTopInput mY20"></div>
                    <label
                        htmlFor="filter_mentor_depression"
                        className="filterLabelCheckBox colLinksAndTitlesBlack cursorPointer mRight10 Pregular"
                    >
                        Unsubscribe From All marketing Emails
                        <input
                            className="filterInputLabelCheckBox"
                            name="depression"
                            id="filter_mentor_depression"
                            type="checkbox"
                        />
                        <div className="divForCheckBox mLeft12"></div>
                    </label>
                </div>
            </div>
        </div>
    );
}

export default MentorHome3Notifications;
