import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setpassword, saveResetedPassword } from "../../actions/auth.js";
// import ButtonsType1 from '../../components/buttons/ButtonsType1/ButtonsType1';
// import Input from '../../components/input/Input.js';
import { RiArrowLeftSLine } from "react-icons/ri";
import { Link } from "react-router-dom";

const initialState = {
    password: "",
    passwordConfirm: "",
    token: "",
    signupExpires: "",
    role: "",
};

function LogInEnterNewPassword(props) {
    const company = props.company;

    const [formData, setFormData] = useState(initialState);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [errorText, setErrorText] = useState(false);
    //TO DO: does this work?
    const [errorToken, setErrorToken] = useState(false);

    useEffect(() => {
        async function getData() {
            if (formData.token !== "" && formData.signupExpires !== "") {
                if (formData.password === formData.passwordConfirm) {
                    setErrorText(false);
                } else {
                    setErrorText(true);
                    return false;
                }

                if (
                    new URLSearchParams(window.location.search).get(
                        "resetToken"
                    )
                ) {
                    dispatch(saveResetedPassword(formData, navigate));
                } else if (
                    new URLSearchParams(window.location.search).get(
                        "signupToken"
                    )
                ) {
                    const error = await dispatch(
                        setpassword(formData, navigate)
                    );
                    if (error && error.response) {
                        setErrorToken(true);
                    }
                } else {
                    return false;
                }
            }
        }

        getData();
    }, [formData, dispatch, navigate]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (errorText) {
            return false;
        }

        let token =
            new URLSearchParams(window.location.search).get("signupToken") ||
            new URLSearchParams(window.location.search).get("resetToken");
        let signupExpires = new URLSearchParams(window.location.search).get(
            "signupExpires"
        );
        let role = new URLSearchParams(window.location.search).get("role");

        // if (!signupExpires) {
        //     setErrorToken(true);
        //     return false;
        // }

        //CONTINUE HERE
        setFormData({
            ...formData,
            token: token,
            signupExpires: signupExpires,
            role: role,
        });
        // console.log(formData);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // console.log(formData);
    };

    return (
        <div className="LogInEnterNewPasswordWrapper">
            <div className="backColPrimaryLight borderRadius24 mTop50 mBottom70 w80 LogInEnterNewPasswordWrapperSubb1 mXAuto">
                <form onSubmit={handleSubmit} className="LogInEnterNewPassword">
                    <div className="posRel mX20 mY20">
                        <Link to="/LogIn">
                            <button className="borderRadius50 backButton verticallyAlignMid backColWhite borderNone boxShadowForSearchLanding">
                                <RiArrowLeftSLine
                                    size="2em"
                                    className="colPrimaryGreen"
                                />
                            </button>
                        </Link>
                    </div>
                    <div className="mXAuto mTop70 pBottom70 LogInEnterNewPasswordWrapperSubb4">
                        <h2 className="textAlignCenter H1medium mBottom8">
                            New Password
                        </h2>
                        <p className="textAlignCenter Pmedium mBottom20 w90 mXAuto">
                            Enter a new password here, it will be updated
                            immediately. Thank you!
                        </p>
                        <div className="mXAuto LogInEnterNewPasswordWrapperSubb2">
                            <div className="backColOffWhite borderRadius12 LogInEnterNewPasswordWrapperSubb3 mBottom20 pRight10 pLeft30 w340 mXAuto">
                                <p className="Small-Labelnormal textAlignLeft pTop10">
                                    PASSWORD
                                </p>
                                <input
                                    value={formData.password}
                                    name="password"
                                    className="Pmedium w100 borderNone backColOffWhite borderRadius12 mBottom10"
                                    type="password"
                                    placeholder="Enter Password"
                                    required
                                    onChange={handleChange}
                                    minLength={8}
                                />
                            </div>
                            <div className="backColOffWhite borderRadius12 LogInEnterNewPasswordWrapperSubb3 mBottom20 pRight10 pLeft30 w340 mXAuto">
                                <p className="Small-Labelnormal textAlignLeft pTop10">
                                    REPEAT PASSWORD
                                </p>
                                <input
                                    value={formData.passwordConfirm}
                                    name="passwordConfirm"
                                    className="Pmedium w100 borderNone backColOffWhite borderRadius12 mBottom10"
                                    type="password"
                                    placeholder="Confirm Password"
                                    required
                                    onChange={handleChange}
                                    minLength={8}
                                />
                            </div>
                            {errorText && (
                                <div className="disFlex verticallyAlignMid">
                                    <p className="P1bold colErrorRed">
                                        Passwords are not the same!
                                    </p>
                                </div>
                            )}
                            {errorToken && (
                                <div className="disFlex verticallyAlignMid">
                                    <p className="P1bold colErrorRed">
                                        Sign up token is invalid or has expired
                                    </p>
                                </div>
                            )}
                            <button
                                style={{
                                    color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                                    backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                                }}
                                type="submit"
                                className="verticallyAlignMid borderRadius12 mBottom20 w340 borderNone mTop20 Pmedium colOffWhite pX80 pY14 mXAuto"
                            >
                                Update Password
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

function getData(state) {
    return {
        company: state.auth?.authData?.company,
    };
}

export default connect(getData)(LogInEnterNewPassword);
