import { useEffect, useState } from "react";
import Moment from "moment";

const ConifrmSessionOrderSummaryComponent = ({
  mentor,
  client,
  service,
  session,
}) => {
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let rate = parseInt(service.rate);
    let duration = parseInt(service.duration);

    let total = Math.round((rate / 60) * duration * 100) / 100;

    setTotalPrice(total);
  }, [service]);

  function dateFormat(date) {
    return Moment(date).format("MMM DD, YYYY");
  }

  function timeFormat(time) {
    return Moment(time).format("hh:mm A");
  }

  return (
    <div className="backColElementBackgroundGray conifrmSessionOrderSummaryMain w540 borderRadius24 mLeftAuto">
      <div className="w80 mXAuto">
        <h2 className="H2regular textAlignLeft mTop50 mBottom12">
          Order Summary
        </h2>
        <div className="verticallyAlignMidJustConStart mBottom12 conifrmSessionOrderSummarySubbs">
          <h6 className="Pmedium textAlignLeft">Mentor</h6>
          <h6 className="Pbold textAlignLeft mLeftAuto">{mentor.fullName}</h6>
        </div>
        {session.isPayed ? (
          <>
            <div className="verticallyAlignMidJustConStart mBottom12 conifrmSessionOrderSummarySubbs">
              <h6 className="Pmedium textAlignLeft">Service</h6>
              <div className="mLeftAuto">
                <h6 className="Pbold textAlignLeft mLeftAuto">
                  {service.name}
                </h6>
                <h6 className="Pbold textAlignRight mLeftAuto colLabelGray conifrmSessionOrderSummaryViChAu">
                  {service.methods.forEach((el) => {
                    if (el === "video") {
                      return " video ";
                    } else if (el === "audio") {
                      return " audio ";
                    } else if (el === "chat") {
                      return " chat ";
                    }
                  })}
                </h6>
              </div>
            </div>
            <div className="borderGrayTopInput w100 mBottom12"></div>
            <div className="verticallyAlignMidJustConStart mBottom12 conifrmSessionOrderSummarySubbs">
              <h6 className="Pmedium textAlignLeft">Payment</h6>
              <h6 className="Pbold textAlignLeft mLeftAuto">VISA ...1234</h6>
            </div>
            <div className="verticallyAlignMidJustConStart mBottom12 conifrmSessionOrderSummarySubbs">
              <h6 className="Pmedium textAlignLeft">Name</h6>
              <h6 className="Pbold textAlignLeft mLeftAuto">
                {client.fullName}
              </h6>
            </div>
            <div className="verticallyAlignMidJustConStart mBottom12 conifrmSessionOrderSummarySubbs">
              <h6 className="Pmedium textAlignLeft">Confirmation</h6>
              <h6 className="Pbold textAlignLeft mLeftAuto">ABT558</h6>
            </div>
          </>
        ) : (
          <>
            <div className="verticallyAlignMidJustConStart mBottom12 conifrmSessionOrderSummarySubbs">
              <h6 className="Pmedium textAlignLeft">Service</h6>
              <h6 className="Pbold textAlignLeft mLeftAuto">{service.name}</h6>
            </div>
            <div className="borderGrayTopInput w100 mBottom12"></div>
            <div className="verticallyAlignMidJustConStart mBottom12 conifrmSessionOrderSummarySubbs">
              <h6 className="Pmedium textAlignLeft">Time</h6>
              <h6 className="Pbold textAlignLeft mLeftAuto">
                {timeFormat(session.date)}
              </h6>
            </div>
            <div className="verticallyAlignMidJustConStart mBottom12 conifrmSessionOrderSummarySubbs">
              <h6 className="Pmedium textAlignLeft">Date</h6>
              <h6 className="Pbold textAlignLeft mLeftAuto">
                {dateFormat(session.date)}
              </h6>
            </div>
            <div className="borderGrayTopInput w100 mBottom12"></div>
          </>
        )}

        <div className="verticallyAlignMidJustConStart mBottom12 conifrmSessionOrderSummarySubbs">
          <h6 className="Pmedium textAlignLeft">Duration</h6>
          <h6 className="Pbold textAlignLeft mLeftAuto">
            {service.duration} Minutes
          </h6>
        </div>
        <div className="verticallyAlignMidJustConStart mBottom50 conifrmSessionOrderSummarySubbs">
          {/* <h6 className="Pmedium textAlignLeft">Hourly Rate</h6> */}
          <h6 className="Pmedium textAlignLeft">Rate</h6>
          <h6 className="Pbold textAlignLeft mLeftAuto">${Math.round(service.rate / 60 * 100) / 100}/minute</h6>
        </div>
        <div className="verticallyAlignMidJustConStart mBottom50">
          <h6 className="Pmedium textAlignLeft">Total</h6>
          <h6 className="Pbold textAlignLeft mLeftAuto">${totalPrice}</h6>
        </div>
      </div>
    </div>
  );
};

export default ConifrmSessionOrderSummaryComponent;
