import { Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

function RestrictedMentorsRoute(props) {
	return props.userData.user.role === 'mentor' ? <Outlet /> : <Navigate to="/LogIn" />;
};

function getUser(state) {
	return {
		userData: {
			user : state.auth.authData?.data.user
		}
	}
}

export default connect(getUser)(RestrictedMentorsRoute);