import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { sendForgotPasswordEmail } from '../../api/index.js';
import { forgotPassword } from "../../actions/auth.js";
// import { RiGoogleFill, RiTwitterFill, RiFacebookBoxFill, RiArrowLeftSLine } from "react-icons/ri";
import { connect } from "react-redux";

function LogInForgotYourPassword(props) {
    const [email, setEmail] = useState("");
    const [errorEmail, setErrorEmail] = useState(false);

    const company = props.company;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    async function sendAgainFunction(e) {
        e.preventDefault();

        if (email === "") {
            return false;
        }

        const data = await dispatch(
            forgotPassword({ email, companyID: company._id }, navigate)
        );
        data && setErrorEmail(data.response.data.message);
    }

    return (
        <div className="LogInForgotYourPassword">
            <div className="backColPrimaryLight borderRadius24 mTop50 mBottom70 w80 mXAuto">
                <form
                    onSubmit={sendAgainFunction}
                    className="w50 mXAuto mTop70 pBottom70 LogInForgotYourPasswordSubb1"
                >
                    <h2 className="textAlignCenter H1medium mBottom8">
                        Forgot Your Password?
                    </h2>
                    <p className="textAlignCenter Pmedium mBottom20">
                        No worries, you just need to type your email addres and
                        we will send send you a link with further password reset
                        instructions.
                    </p>
                    <div className="backColOffWhite borderRadius12 mBottom20 pRight10 pLeft30 w340 mXAuto LogInForgotYourPasswordSubb2">
                        <p className="Small-Labelnormal textAlignLeft pTop10">
                            EMAIL
                        </p>
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            name=""
                            className="Pmedium w100 borderNone backColOffWhite borderRadius12 mBottom10"
                            type="email"
                            placeholder="Enter email"
                        />
                    </div>

                    {errorEmail && (
                        <div className="disFlex verticallyAlignMid">
                            <p className="P1bold colErrorRed">{errorEmail}</p>
                        </div>
                    )}

                    <button
                        style={{
                            color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                            backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                        }}
                        className="verticallyAlignMid LogInForgotYourPasswordSubb1SendMail borderRadius12 mBottom20 w340 borderNone mTop20 Pmedium colOffWhite pX80 pY14 mXAuto"
                    >
                        Send Email
                    </button>
                    <p className="textAlignCenter Pmedium mBottom20">
                        Already have an account?{" "}
                        <span className="mLeft4 colPrimaryGreen Pbold cursorPointer">
                            {" "}
                            <a href="/LogIn"> Log In! </a>
                        </span>
                    </p>
                    {/*<p className="textAlignCenter Pmedium mBottom20">Or sign up using</p>
                    <div className="verticallyAlignMid mXAuto">
                        <button className="borderRadiusForSocialMediaButtons backColGoogleRed borderNone pY14 w104 verticallyAlignMid mRight8 colOffWhite">
                            <RiGoogleFill size="1.5em" />
                        </button>
                        <button className="borderRadiusForSocialMediaButtons backColTwitterBlue borderNone pY14 w104 verticallyAlignMid mRight8 colOffWhite">
                            <RiTwitterFill size="1.5em" />
                        </button>
                        <button className="borderRadiusForSocialMediaButtons backColFacebookBlue borderNone pY14 w104 verticallyAlignMid colOffWhite">
                            <RiFacebookBoxFill size="1.5em" />
                        </button>
                    </div>*/}
                </form>
            </div>
        </div>
    );
}

function getUserData(state) {
    return {
        company: state.auth.authData?.company,
    };
}

export default connect(getUserData)(LogInForgotYourPassword);
